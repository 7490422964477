import { createLogger, Logger } from "libs/logger";

export type HttpResponse<Body = any> =
  | { status: 200; body: Body }
  | { status: number; body?: any };

// Only POST requests for now because this is only used for the API.
export async function httpRequest(args: {
  url: string;
  body?: Record<string, unknown>;
  logger?: Logger;
}): Promise<HttpResponse> {
  const { url, body: requestBody, logger = createLogger() } = args;

  let response: Response;

  try {
    response = await fetch(url, {
      method: "post",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody || null),
    });
  } catch (error) {
    console.error("httpRequest network error", error);
    // Offline
    return { status: 0 };
  }

  if (response.status === 200) {
    const body = await response.json();
    return { status: 200, body };
  }

  let responseBody: any;
  try {
    responseBody = await response.json();
  } catch (error) {
    logger.warn("Could not parse body of error response.");
  }

  return { status: response.status, body: responseBody };
}
