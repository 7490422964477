/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { INotificationDoc } from "@libs/firestore-models";
import {
  castToNonNullablePredicate,
  isNonNullable,
} from "@libs/utils/predicates";
import { parseDate as chronoParseDate } from "chrono-node";
import dayjs from "dayjs";
import * as parsing from "./regexps";

function parseDate(content: string) {
  return dayjs(
    chronoParseDate(content, new Date(), {
      forwardDate: true,
    }),
  );
}

export const SOMEDAY = dayjs(new Date(2500, 0, 1));

export function getSuggestions(
  input: string,
  notificationDoc: Pick<INotificationDoc, "triaged" | "isStarred">,
): [normalizedInput: string, suggestions: Suggestion[]] {
  if (input.length === 0) {
    // If the user hasn't typed anything lets just show the first
    // 5 suggestions. These will be our "default" suggestions.
    return ["", suggestions(notificationDoc).filter(isNonNullable).slice(0, 5)];
  }

  // Overview
  //
  // 1. We build up a tokenized representation of the
  // user input. For example, `9am on Monday` will be
  // tokenized as `{{TIME}} on Monday` while
  // `monday 9 9p` will be tokenized as
  // `monday {{NUMBER}} {{TIME}}`.
  //
  // 2. Later, having tokenized the user input and interpreted
  // the tokens, we'll reinsert the interpreted values
  // back into the user input to build a normalized user
  // input. For example
  // `9 monday` was tokenized as `{{NUMBER}} monday`
  // and then converted back to a normalized user
  // input as `9 am monday`. Similarly,
  // `jan 5 5` would go -> `jan {{NUMBER}} {{NUMBER}}`
  // and then `jan 5th 5 pm`.

  // NOTES:
  // 1. The order that we tokenize the user input matters.
  //    I.e. the order that these matchers are in matters.
  // 2. The regular expressions being passed to `matchAll()`
  //    have the "global" flag (which is required by
  //    `matchAll()`). The "global" flag makes the regular
  //    expressions stateful. Because of this, we need to
  //    recreate the regular expression *each time they are
  //    used*.

  // Step 1. Building the tokenized representation

  let tokenizedInput = input.replaceAll(
    // In the off chance the user entered something that looks
    // like our template variables, we should ignore it.
    parsing.templateVariable.toRegExp(),
    "",
  );

  /**
   * Before tokenizing the dates in the user input, we store those dates
   * in `dateMatches` for later use
   */
  let dateMatches: Array<RegExpMatchArray> | null = null;
  if (parsing.date.toRegExp().test(tokenizedInput)) {
    dateMatches = Array.from(tokenizedInput.matchAll(parsing.date.toRegExp()));

    tokenizedInput = tokenizedInput.replaceAll(
      parsing.date.toRegExp(),
      "{{DATE}}",
    );
  }

  /**
   * Before tokenizing the times in the user input, we store those times
   * in `timeMatches` for later use
   */
  let timeMatches: Array<RegExpMatchArray> | null = null;
  if (
    parsing.timeWithColonOrSpaceAndMaybeMeridiem.toRegExp().test(tokenizedInput)
  ) {
    timeMatches = Array.from(
      tokenizedInput.matchAll(
        parsing.timeWithColonOrSpaceAndMaybeMeridiem.toRegExp(),
      ),
    );

    tokenizedInput = tokenizedInput.replaceAll(
      parsing.timeWithColonOrSpaceAndMaybeMeridiem.toRegExp(),
      "{{TIME}}",
    );
  } else if (parsing.timeWithMeridiem.toRegExp().test(tokenizedInput)) {
    timeMatches = Array.from(
      tokenizedInput.matchAll(parsing.timeWithMeridiem.toRegExp()),
    );

    tokenizedInput = tokenizedInput.replaceAll(
      parsing.timeWithMeridiem.toRegExp(),
      "{{TIME}}",
    );
  }

  /**
   * Before tokenizing the numbers in the user input, we store those numbers
   * in `numberMatches` for later use
   */
  let numberMatches: Array<RegExpMatchArray> | null = null;
  if (parsing.number.toRegExp().test(tokenizedInput)) {
    numberMatches = Array.from(
      tokenizedInput.matchAll(parsing.number.toRegExp()),
    );

    tokenizedInput = tokenizedInput.replaceAll(
      parsing.number.toRegExp(),
      "{{NUMBER}}",
    );
  }

  // Step 2. Use our tokenized representation of the user input, we
  //         build a normalized version of the user input by
  //         reinserting our time, date, and number matches after
  //         having normalized them.

  let normalizedInput = tokenizedInput;
  const normalizedTimeMatches: string[] = [];
  const normalizedDateMatches: string[] = [];
  const normalizedNumberMatches: string[] = [];

  if (timeMatches) {
    for (const match of timeMatches) {
      const [, timeInput, meridiemInput] = match;
      const time = parsing.normalizeTimeFromInput(timeInput, meridiemInput);

      normalizedTimeMatches.push(time);
      normalizedInput = normalizedInput.replace("{{TIME}}", time);
    }
  }

  if (dateMatches) {
    for (const match of dateMatches) {
      const [, monthInput, dayInput, yearInput] = match;
      const date = parsing.normalizeDateFromInput(
        monthInput!,
        dayInput!,
        yearInput,
      );

      normalizedDateMatches.push(date);
      normalizedInput = normalizedInput.replace("{{DATE}}", date);
    }
  }

  if (numberMatches) {
    for (const match of numberMatches) {
      const [, number] = match;

      normalizedNumberMatches.push(number!);
      normalizedInput = normalizedInput.replace("{{NUMBER}}", number!);
    }
  }

  // We also use our matched time, date, and number values to
  // update our suggestion templates to generate our list of suggestions.

  const newSuggestions = suggestions(notificationDoc)
    .map((suggestion) => {
      if (!suggestion) return null;

      if (timeMatches && suggestion.template.includes("{{TIME}}")) {
        for (const time of normalizedTimeMatches) {
          suggestion.update("{{TIME}}", time);
        }
      }

      if (dateMatches && suggestion.template.includes("{{DATE}}")) {
        for (const date of normalizedDateMatches) {
          suggestion.update("{{DATE}}", date);
        }
      }

      if (numberMatches && suggestion.template.includes("{{NUMBER}}")) {
        for (const number of normalizedNumberMatches) {
          suggestion.update("{{NUMBER}}", number);
        }
      }

      return suggestion;
    })
    // we filter out any suggestions that either don't have content
    // or which still have raw template variables that never received
    // a value. This would happen if, e.g., a suggestion was of the
    // form `{{NUMBER}} monday` but the user never provided a number.
    .filter(
      castToNonNullablePredicate(
        (s) =>
          !!s?.content && !parsing.templateVariable.toRegExp().test(s.content),
      ),
    );

  return [normalizedInput, newSuggestions];
}

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
] as const;

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

const PERIODS = [
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "quarter",
  "year",
] as const;

function numberWithEnding(digit: string) {
  switch (digit) {
    case "11":
    case "12":
    case "13": {
      return `${digit}th`;
    }
  }

  switch (digit.at(-1)) {
    case "1": {
      return `${digit}st`;
    }
    case "2": {
      return `${digit}nd`;
    }
    case "3": {
      return `${digit}rd`;
    }
    default: {
      return `${digit}th`;
    }
  }
}

/**
 * This is a helper class that receives a string reminder suggestion template,
 * e.g. `{{TIME}} on February {{NUMBER}}`, and handles updating that
 * template in response to provided user time and number values. The
 * final result is a string that *might* match the user input. For example,
 * if a user provided a 9 am time value and a 15 number value as part of their input,
 * and we had `Suggestion` templates of the form `{{TIME}} on January {{NUMBER}}`
 * and `{{TIME}} on February {{NUMBER}}`, they would both be updated in response
 * to the user input like `9 am on January 15` and `9 am on February 15`. Then we
 * pass these strings to our preexisting fuzzy matching function to see if either
 * of these suggestion strings matches the user input. This approach makes it easier
 * for us to handle typos/misspellings.
 */
class Suggestion {
  template: string;
  /**
   * This is the WIP string that we'll eventually
   * display to the user.
   */
  content: string | null;
  /**
   * The date that the template is interpreted to mean
   */
  date: dayjs.Dayjs | null = dayjs();
  /**
   * Additional keywords that we can use for fuzzy
   * matching.
   */
  keywords: string[] = [];

  /**
   * A function that is used to update the `content` value
   * with template variable values. The updateFn can
   * choose to set the `content` to `null` to indicate
   * that, for whatever reason, this suggestion should not
   * be shown to the user.
   */
  protected updateFn(
    this: Suggestion,
    args: {
      /**
       * The WIP string value for this suggestion.
       * For example, "this Friday at {{TIME}}".
       */
      content: string;
      /** a token such as `"{{DATE}}"` or `"{{TIME}}"` */
      token: string;
      /** the value of this token such as `"02/28/2023"` or `"9 pm"` */
      value: string;
    },
  ): string | void {
    if (this.content === null) return;

    this.content = this.content.replace(args.token, args.value);
  }

  constructor(args: {
    template: string;
    /**
     * The date associated with this suggestion. Useful for
     * suggestions with a date that doesn't rely on user
     * input. A `null` value indicates that the suggestion
     * isn't actually a suggested reminder time (e.g.
     * `Star thread`).
     */
    date?: dayjs.Dayjs | null;
    /**
     * A function that is used to update the `content` value
     * with template variable values. The updateFn can
     * choose to set the `content` to `null` to indicate
     * that, for whatever reason, this suggestion should not
     * be shown to the user.
     */
    update?: Suggestion["updateFn"];
    /**
     * keywords that should be used to help match this suggestion
     * to the user input.
     */
    keywords?: string[];
  }) {
    this.template = args.template;
    this.content = args.template;

    const date = this.date;

    if (args.date !== undefined) this.date = args.date;
    if (args.update) this.updateFn = args.update;
    if (args.keywords) this.keywords = args.keywords.slice();

    if (date === this.date) {
      const date = parseDate(this.content);

      if (date.isValid()) {
        this.date = date;
      }
    }
  }

  /** Mutates object */
  update(token: string, value: string) {
    if (this.content === null) return;

    const date = this.date;

    const content = this.updateFn({ content: this.content, token, value });

    if (content) {
      this.content = content;
    }

    if (date === this.date) {
      this.date = parseDate(this.content);
    }
  }
}

/**
 * Examples of valid reminder times:
 *
 * - 9am
 *
 * - 9am on Monday
 * - 9am on Tuesday
 * - 9am on Wednesday
 * - 9am on Thursday
 * - 9am on Friday
 * - 9am on Saturday
 * - 9am on Sunday
 *
 * - 9am on January 3rd
 * - 9am on February 3rd
 * - 9am on March 3rd
 * - 9am on April 3rd
 * - 9am on May 3rd
 * - 9am on June 3rd
 * - 9am on July 3rd
 * - 9am on Auguest 3rd
 * - 9am on September 3rd
 * - 9am on October 3
 * - 9am on November 3
 * - 9am on December 3
 *
 * - 9 January at 3am
 * - 9 February at 3am
 * - 9 March at 3am
 * - 9 April at 3am
 * - 9 May at 3am
 * - 9 June at 3am
 * - 9 July at 3am
 * - 9 Auguest at 3am
 * - 9 September at 3am
 * - 9 October at 3am
 * - 9 November at 3am
 * - 9 December at 3am
 *
 * - 9th of January at 3am
 * - 9th of February at 3am
 * - 9th of March at 3am
 * - 9th of April at 3am
 * - 9th of May at 3am
 * - 9th of June at 3am
 * - 9th of July at 3am
 * - 9th of Auguest at 3am
 * - 9th of September at 3am
 * - 9th of October at 3am
 * - 9th of November at 3am
 * - 9th of December at 3am
 *
 * - m/d
 * - m/d/yy
 * - 9am on mm/dd/yy
 * - 9 on mm/dd/yy
 * - mm/dd/yy at 9
 * - mm/dd/yy at 9am
 *
 * - today at 1pm
 * - today at midnight
 * - today at noon
 *
 * - in a minute
 * - in an hour
 * - in a day
 * - in a week
 * - in a month
 * - in a quarter
 * - in a year
 *
 * - in 1 minute
 * - in 1 hour
 * - in 1 day
 * - in 1 week
 * - in 1 month
 * - in 1 quarter
 * - in 1 year
 *
 * - in 2 minutes
 * - in 2 hours
 * - in 2 days
 * - in 2 weeks
 * - in 2 months
 * - in 2 quarters
 * - in 2 years

 * - in 2 minutes on weekday
 * - in 2 hours on weekday
 * - in 2 days on weekday
 * - in 2 weeks on weekday
 * - in 2 months on weekday
 * - in 2 quarters on weekday
 * - in 2 years on weekday

 * - on Monday at 9am
 * - on Tuesday at 9am
 * - on Wednesday at 9am
 * - on Thursday at 9am
 * - on Friday at 9am
 * - on Saturday at 9am
 * - on Sunday at 9am
 *
 * - on Monday morning
 * - on Monday afternoon
 * - on Monday evening
 * - on Monday night
 * - on Monday morning
 * - on Monday at noon
 * - on Monday morning at 7am
 * - on Monday afternoon at 1pm
 * - on Monday evening at 8pm
 * - on Monday night at 8pm
 * 
 * Examples of valid reminder times which we
 * _don't_ need to parse. Instead, we'll just hardcode
 * suggestions for these times in and let the fuzzy
 * matcher filter them out.
 *
 * - next week
 * - next weekend
 * - next month
 * - next quarter
 * - next year
 * - next Monday
 * - next Tuesday
 * - next Wednesday
 * - next Thursday
 * - next Friday
 * - next Saturday
 * - next Sunday
 * - next January
 * - next February
 * - next March
 * - next April
 * - next May
 * - next June
 * - next July
 * - next August
 * - next September
 * - next October
 * - next November
 * - next December
 *
 * - this morning
 * - this afternoon
 * - this evening
 * - this night
 *
 * - this weekend
 * - this Monday
 * - this Tuesday
 * - this Wednesday
 * - this Thursday
 * - this Friday
 * - this Saturday
 * - this Sunday
 * - this January
 * - this February
 * - this March
 * - this April
 * - this May
 * - this June
 * - this July
 * - this August
 * - this September
 * - this October
 * - this November
 * - this December
 *
 * - today
 * - tomorrow
 * - someday
 * - never
 * - forever
 */

// Note, the order of these suggestions matters because, *all
// other things being equal*, suggestions will be displayed
// in this order here.
// In general, suggestions should be ordered from simpler to
// more complex and from most common to least common.
function suggestions(notification: { triaged: boolean; isStarred: boolean }) {
  return [
    // The first 5 suggestions are also shown when the user
    // hasn't typed anything. These are the "default" suggestions.
    new Suggestion({
      template: `tomorrow`,
      date: dayjs().add(1, "day").set("h", 8).startOf("h"),
    }),
    new Suggestion({
      template: `next week`,
      date: dayjs().add(1, "week").set("day", 1).set("hour", 8).startOf("h"),
    }),
    new Suggestion({
      template: `next weekend`,
      date: dayjs()
        .add(6 - dayjs().get("day") || 6, "days")
        .set("hour", 8)
        .startOf("hour"),
    }),
    notification.isStarred
      ? new Suggestion({
          template: "Unstar",
          date: null,
          keywords: ["Star"],
        })
      : new Suggestion({
          template: "Star",
          date: null,
          keywords: ["Unstar"],
        }),
    (notification.triaged &&
      new Suggestion({
        template: "Remove reminder & move to inbox",
        date: null,
      })) ||
      null,
    new Suggestion({
      template: `never`,
      date: SOMEDAY,
    }),
    // End default suggestions.

    new Suggestion({
      template: `someday`,
      date: SOMEDAY,
    }),

    new Suggestion({
      template: `tonight`,
      date: dayjs().set("hour", 20).startOf("hour"),
    }),
    new Suggestion({
      template: `forever`,
      date: SOMEDAY,
    }),

    // Monday
    ...DAYS_OF_WEEK.map(
      (d) =>
        new Suggestion({
          template: `on ${d}`,
          keywords: [`on ${d.toLowerCase()}`],
          date: parseDate(`on ${d} at 8 am`),
        }),
    ),

    new Suggestion({ template: `in a minute` }),
    new Suggestion({ template: `in an hour` }),
    new Suggestion({ template: `in a day` }),
    new Suggestion({ template: `in a week` }),
    new Suggestion({ template: `in a month` }),
    new Suggestion({ template: `in a quarter` }),
    new Suggestion({ template: `in a year` }),

    // in X minutes
    // in X hours, etc
    ...PERIODS.map(
      (p) =>
        new Suggestion({
          template: `in {{NUMBER}} ${p}(s)`,
          update({ value }) {
            if (value === "1") {
              this.keywords = [`1 ${p}`];
              return `in 1 ${p}`;
            } else {
              this.keywords = [`${value} ${p}s`];
              return `in ${value} ${p}s`;
            }
          },
        }),
    ),

    // today at 9
    new Suggestion({
      template: `today at {{NUMBER}}`,
      update({ value }) {
        this.keywords = [`${convertNumberToTime(value)} today`];
        return `today at ${convertNumberToTime(value)}`;
      },
    }),
    // "today at 9p" or just "9p"
    new Suggestion({
      template: `today at {{TIME}}`,
      update({ value }) {
        this.keywords = [`${value} today`, `${value.replace(":", " ")} today`];
        return `today at ${value}`;
      },
    }),

    // tomorrow at 9
    new Suggestion({
      template: `tomorrow at {{NUMBER}}`,
      update({ value }) {
        this.keywords = [`${convertNumberToTime(value)} tomorrow`];
        return `tomorrow at ${convertNumberToTime(value)}`;
      },
    }),
    // "tomorrow at 9p" or just "9p"
    new Suggestion({
      template: `tomorrow at {{TIME}}`,
      update({ value }) {
        this.keywords = [
          `${value} tomorrow`,
          `${value.replace(":", " ")} tomorrow`,
        ];
        return `tomorrow at ${value}`;
      },
    }),

    // Monday 8
    ...DAYS_OF_WEEK.map(
      (d) =>
        new Suggestion({
          template: `on ${d} at {{NUMBER}}`,
          update({ value }) {
            return `on ${d} at ${convertNumberToTime(value)}`;
          },
        }),
    ),
    // Monday at 8am
    ...DAYS_OF_WEEK.map(
      (d) =>
        new Suggestion({
          template: `on ${d} at {{TIME}}`,
        }),
    ),

    // 9 on Monday
    ...DAYS_OF_WEEK.map(
      (d) =>
        new Suggestion({
          template: `{{NUMBER}} on ${d}`,
          update({ value }) {
            return `${convertNumberToTime(value)} on ${d}`;
          },
        }),
    ),
    // 9am on Monday
    ...DAYS_OF_WEEK.map(
      (d) =>
        new Suggestion({
          template: `{{TIME}} on ${d}`,
        }),
    ),

    // January
    ...MONTHS.flatMap((m) => [
      new Suggestion({
        template: `on ${m} 1st`,
      }),
      new Suggestion({
        template: `on ${m} 2nd`,
      }),
      new Suggestion({
        template: `on ${m} 3rd`,
      }),
    ]),
    // January 9
    ...MONTHS.map(
      (m) =>
        new Suggestion({
          template: `on ${m} {{NUMBER}}`,
          update({ value }) {
            if (parseInt(value, 10) > 31) {
              this.content = null;
              return;
            }

            return `on ${m} ${numberWithEnding(value)}`;
          },
        }),
    ),
    // 9 January
    ...MONTHS.flatMap(
      (m) =>
        new Suggestion({
          template: `{{NUMBER}} of ${m}`,
          update({ value }) {
            if (parseInt(value, 10) > 31) {
              this.content = null;
              return;
            }

            return `${numberWithEnding(value)} of ${m} at 8 am`;
          },
        }),
    ),
    // 9 January 8am
    ...MONTHS.flatMap(
      (m) =>
        new Suggestion({
          template: `{{NUMBER}} of ${m} at {{TIME}}`,
          update({ content, token, value }) {
            if (token === "{{NUMBER}}") {
              if (parseInt(value, 10) > 31) {
                this.content = null;
                return;
              }

              return content.replace(token, numberWithEnding(value));
            } else {
              return content.replace(token, value);
            }
          },
        }),
    ),
    // 9am January
    ...MONTHS.flatMap((m) => [
      new Suggestion({
        template: `{{TIME}} on ${m} 1st`,
      }),
    ]),
    // 9am January 3
    ...MONTHS.map(
      (m) =>
        new Suggestion({
          template: `{{TIME}} on ${m} {{NUMBER}}`,
          update({ content, token, value }) {
            if (token === "{{NUMBER}}") {
              if (parseInt(value, 10) > 31) {
                this.content = null;
                return;
              }

              return content.replace(token, numberWithEnding(value));
            } else {
              return content.replace(token, value);
            }
          },
        }),
    ),
    // 9 January 9
    ...MONTHS.flatMap(
      (m) =>
        new Suggestion({
          template: `{{NUMBER}} of ${m} at {{NUMBER}}`,
          update({ content, token, value }) {
            if (content.indexOf(token) === 0) {
              if (parseInt(value, 10) > 31) {
                this.content = null;
                return;
              }

              return content.replace(token, numberWithEnding(value));
            } else {
              return content.replace(token, convertNumberToTime(value));
            }
          },
        }),
    ),
    // 9 on January 9
    // This differes from `9 Jan 9` in that the first 9 is changed
    // from a date to a time interpretation by the presence of "on"
    ...MONTHS.map(
      (m) =>
        new Suggestion({
          template: `{{NUMBER}} on ${m} {{NUMBER}}`,
          update({ content, token, value }) {
            if (content.indexOf(token) === 0) {
              return content.replace(token, convertNumberToTime(value));
            } else {
              if (parseInt(value, 10) > 31) {
                this.content = null;
                return;
              }

              return content.replace(token, numberWithEnding(value));
            }
          },
        }),
    ),
    // January 9 9
    ...MONTHS.flatMap(
      (m) =>
        new Suggestion({
          template: `${m} {{NUMBER}} at {{NUMBER}}`,
          update({ content, token, value }) {
            if (tokenCount(content, token) === 2) {
              if (parseInt(value, 10) > 31) {
                this.content = null;
                return;
              }

              return content.replace(token, numberWithEnding(value));
            } else {
              return content.replace(token, convertNumberToTime(value));
            }
          },
        }),
    ),
    // January 9 9p
    ...MONTHS.flatMap(
      (m) =>
        new Suggestion({
          template: `${m} {{NUMBER}} at {{TIME}}`,
          update({ content, token, value }) {
            if (token === "{{NUMBER}}") {
              if (parseInt(value, 10) > 31) {
                this.content = null;
                return;
              }

              return content.replace(token, numberWithEnding(value));
            } else {
              return content.replace(token, value);
            }
          },
        }),
    ),
    // 1/1/24
    new Suggestion({
      template: `on {{DATE}} at 8 am`,
    }),
    // 1/1 at 9
    new Suggestion({
      template: `on {{DATE}} at {{NUMBER}}`,
      update({ content, token, value }) {
        if (token === "{{NUMBER}}") {
          return content.replace(token, convertNumberToTime(value));
        } else {
          return content.replace(token, value);
        }
      },
    }),
    // 1/1 at 9am
    new Suggestion({
      template: `on {{DATE}} at {{TIME}}`,
    }),
    // 9 on 1/1
    new Suggestion({
      template: `{{NUMBER}} on {{DATE}}`,
      update({ content, token, value }) {
        if (token === "{{NUMBER}}") {
          return content.replace(token, convertNumberToTime(value));
        } else {
          return content.replace(token, value);
        }
      },
    }),
    // 9am on 1/1
    new Suggestion({
      template: `{{TIME}} on {{DATE}}`,
    }),
  ];
}

function convertNumberToTime(value: string) {
  let time: string;

  switch (String(value).length) {
    case 1:
    case 2: {
      // e.g. "5" or "12"
      time = value;
      break;
    }
    case 3: {
      // e.g. "130" -> "1:30" or "545" -> "5:45"
      time = `${value[0]}:${value.slice(1)}`;
      break;
    }
    case 4: {
      // e.g. "1030" -> "10:30" or "1245" -> "12:45"
      time = `${value.slice(0, 2)}:${value.slice(2)}`;
      break;
    }
    default: {
      time = "8";
      break;
    }
  }

  return parsing.normalizeTimeFromInput(time);
}

function tokenCount(content: string, token: string) {
  return Array.from(content.matchAll(new RegExp(token, "g"))).length;
}
