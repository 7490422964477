import { FcGoogle } from "react-icons/fc";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  User,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
  OAuthCredential,
} from "firebase/auth";
import {
  useLocation,
  Navigate,
  NavigateFunction,
  useNavigate,
  Location,
} from "react-router-dom";
import { auth, functions } from "../../firebase";
import { getDoc } from "firebase/firestore";
import { CURRENT_USER$, getCurrentUserId } from "~/environment/user.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { ComponentType } from "react";
import { setIsLoading } from "~/environment/loading.service";
import { Helmet } from "react-helmet-async";
import {
  NewUserDialog,
  NewUserDialogState,
} from "~/dialogs/user-new/NewUserDialog";
import { LoadingText } from "~/components/LoadingText";
import { PendingRequestBar } from "~/components/PendingRequestBar";
import { attemptToGetRedirectLocation } from "~/environment/navigate.service";
import { withIsOnlineGuard } from "~/route-guards/withIsOnlineGuard";
import { httpsCallable } from "firebase/functions";
import { useObservableState } from "observable-hooks";
import { RecordValue } from "libs/schema";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import Cookies from "js-cookie";
import { signinWithGoogle } from "~/actions/signinWithGoogle";

export const LoginView: ComponentType<{}> = withIsOnlineGuard(() => {
  const [currentUser] = useObservableState<
    RecordValue<"user_profile"> | null | "loading"
  >(() => CURRENT_USER$, "loading");

  const location = useLocation();
  const redirectTo = attemptToGetRedirectLocation(location);

  if (currentUser === "loading") {
    return (
      <PendingRequestBar>
        <LoadingText />
      </PendingRequestBar>
    );
  }

  if (currentUser) {
    return (
      <Navigate
        to={
          !redirectTo || redirectTo.pathname.startsWith("/login")
            ? "/inbox"
            : redirectTo
        }
        replace
      />
    );
  }

  return (
    <div className="h-dynamic-screen w-screen flex flex-col justify-center items-center space-y-10">
      <Helmet>
        <title>Login | Comms</title>
      </Helmet>

      <NewUserDialog />

      <h1 className="text-4xl font-bold">Login to Comms</h1>

      <button
        className="flex rounded-md px-8 py-4 bg-tealDark-6 text-white items-center space-x-3"
        onClick={() => signinWithGoogle(redirectTo)}
      >
        <FcGoogle /> <span>Login with Google</span>
      </button>
    </div>
  );
});
