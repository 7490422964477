import type {
  Attachment,
  AuthToken,
  Draft,
  DraftEmailRecipient,
  DraftGroupRecipient,
  DraftTag,
  DraftUserRecipient,
  InboxSection,
  InboxSubsection,
  MentionFrequency,
  Message,
  MessageAttachment,
  MessageEmailRecipient,
  MessageEmailReference,
  MessageGroupRecipient,
  MessageUserReaction,
  MessageUserRecipient,
  Notification,
  NotificationTag,
  Organization,
  OrganizationControlledDomain,
  OrganizationProfile,
  OrganizationUserInvitation,
  OrganizationUserMember,
  ProviderEmailMap,
  Tag,
  TagFolderMember,
  TagGroupMember,
  TagSubscription,
  TagUserMember,
  Thread,
  ThreadGroupPermission,
  ThreadReadReceipt,
  ThreadSeenReceipt,
  ThreadSubscription,
  ThreadTag,
  ThreadTimeline,
  ThreadUserParticipant,
  ThreadUserPermission,
  User,
  UserContactInfo,
  UserLesson,
  UserOAuth,
  UserProfile,
  UserSettings,
} from "@prisma/client/edge";

export * from "@prisma/client/edge";

import type { Merge, Simplify } from "type-fest";

/* -------------------------------------------------------------------------------------------------
 * Table of Contents i.e. TableToRecord
 * -----------------------------------------------------------------------------------------------*/

type TableToRecord = {
  attachment: AttachmentRecord;
  auth_token: AuthTokenRecord;
  draft_email_recipient: DraftEmailRecipientRecord;
  draft_group_recipient: DraftGroupRecipientRecord;
  draft_tag: DraftTagRecord;
  draft_user_recipient: DraftUserRecipientRecord;
  draft: DraftRecord;
  inbox_section: InboxSectionRecord;
  inbox_subsection: InboxSubsectionRecord;
  mention_frequency: MentionFrequencyRecord;
  message_attachment: MessageAttachmentRecord;
  message_email_recipient: MessageEmailRecipientRecord;
  message_email_reference: MessageEmailReferenceRecord;
  message_group_recipient: MessageGroupRecipientRecord;
  message_user_reaction: MessageUserReactionRecord;
  message_user_recipient: MessageUserRecipientRecord;
  message: MessageRecord;
  notification_tag: NotificationTagRecord;
  notification: NotificationRecord;
  organization_controlled_domain: OrganizationControlledDomainRecord;
  organization_profile: OrganizationProfileRecord;
  organization_user_invitation: OrganizationUserInvitationRecord;
  organization_user_member: OrganizationUserMemberRecord;
  organization: OrganizationRecord;
  provider_email_map: ProviderEmailMapRecord;
  tag_folder_member: TagFolderMemberRecord;
  tag_group_member: TagGroupMemberRecord;
  tag_subscription: TagSubscriptionRecord;
  tag_user_member: TagUserMemberRecord;
  tag: TagRecord;
  thread_group_permission: ThreadGroupPermissionRecord;
  thread_read_receipt: ThreadReadReceiptRecord;
  thread_seen_receipt: ThreadSeenReceiptRecord;
  thread_subscription: ThreadSubscriptionRecord;
  thread_tag: ThreadTagRecord;
  thread_timeline: ThreadTimelineRecord;
  thread_user_participant: ThreadUserParticipantRecord;
  thread_user_permission: ThreadUserPermissionRecord;
  thread: ThreadRecord;
  user_contact_info: UserContactInfoRecord;
  user_lesson: UserLessonRecord;
  user_oauth: UserOAuthRecord;
  user_profile: UserProfileRecord;
  user_settings: UserSettingsRecord;
  user: UserRecord;
};

// We use Prisma to generate our base record types. Prisma assumes that we're
// using Prisma client for querying so Date fields are returned from postgres
// as Date objects. We're not using Prisma client for querying so Date fields
// are returned from postgres as strings.
type WithDateAsString<T> = {
  [P in keyof T]: Date extends T[P]
    ? null extends T[P]
      ? string | null
      : string
    : T[P];
};

/* -----------------------------------------------------------------------------------------------*/

export type MessageType = ThreadType;
export type SubscriptionPreference = "all" | "all-new" | "involved";
export type ThreadVisibility = "SHARED" | "PRIVATE";
export type ThreadType = "COMMS" | "EMAIL" | "EMAIL_BCC";
export type MentionPriority = 100 | 200 | 300;

type AttachmentRecord = Simplify<WithDateAsString<Attachment>>;
type AuthTokenRecord = Simplify<WithDateAsString<AuthToken>>;
type DraftEmailRecipientRecord = Simplify<
  WithDateAsString<DraftEmailRecipient>
>;
type DraftGroupRecipientRecord = Simplify<
  WithDateAsString<DraftGroupRecipient>
>;
type DraftTagRecord = Simplify<WithDateAsString<DraftTag>>;
type DraftUserRecipientRecord = Simplify<WithDateAsString<DraftUserRecipient>>;
type DraftRecord = Simplify<
  WithDateAsString<Draft> & {
    type: "COMMS" | "EMAIL";
  }
>;
type InboxSectionRecord = Simplify<WithDateAsString<InboxSection>>;
type InboxSubsectionRecord = Simplify<WithDateAsString<InboxSubsection>>;
type MentionFrequencyRecord = Simplify<
  WithDateAsString<MentionFrequency> & {
    frequency: {
      /**
       * The ID might belong to a user or channel or something else that
       * can be mentioned.
       */
      [id: string]: IndividualMentionFrequencyCount;
    };
  }
>;

export interface IndividualMentionFrequencyCount {
  // We only want to track the number of mentions in the past month.
  // As usage patterns change, our frequency numbers will
  // reflect that. Week1 is the count for the first week of the month,
  // week2 the count for the second, etc. As one month moves into the
  // next, we'll overrite the week1 value for the previous month with
  // the value for the next month.
  week1Count: number;
  week1LastUpdatedAt: string;
  week2Count: number;
  week2LastUpdatedAt: string;
  week3Count: number;
  week3LastUpdatedAt: string;
  week4Count: number;
  week4LastUpdatedAt: string;
  week5Count: number;
  week5LastUpdatedAt: string;
}

type MessageAttachmentRecord = Simplify<WithDateAsString<MessageAttachment>>;
type MessageEmailRecipientRecord = Simplify<
  WithDateAsString<MessageEmailRecipient>
>;
type MessageEmailReferenceRecord = Simplify<
  WithDateAsString<MessageEmailReference>
>;
type MessageGroupRecipientRecord = Simplify<
  WithDateAsString<MessageGroupRecipient>
>;
type MessageUserReactionRecord = Simplify<
  WithDateAsString<MessageUserReaction>
>;
type MessageUserRecipientRecord = Simplify<
  WithDateAsString<MessageUserRecipient>
>;

type MessageRecord = Simplify<
  WithDateAsString<Message> & {
    type: MessageType;
  }
>;

type NotificationTagRecord = Simplify<WithDateAsString<NotificationTag>>;
type NotificationRecord = Simplify<WithDateAsString<Notification>>;
type OrganizationControlledDomainRecord = Simplify<
  WithDateAsString<OrganizationControlledDomain>
>;
type OrganizationProfileRecord = Simplify<
  WithDateAsString<OrganizationProfile>
>;
type OrganizationUserInvitationRecord = Simplify<
  WithDateAsString<OrganizationUserInvitation>
>;
/**
 * Organizations have a canonical "group" tag with the same ID
 * as the organization. Members of this group _are_ members of
 * this organization. We also store organization_user_member
 * records primarily as a performance optimization so that we can quickly
 * lookup which organizations a user is a member of.
 */
type OrganizationUserMemberRecord = Simplify<
  WithDateAsString<OrganizationUserMember>
>;
type OrganizationRecord = Simplify<WithDateAsString<Organization>>;
type ProviderEmailMapRecord = Simplify<WithDateAsString<ProviderEmailMap>>;
type TagFolderMemberRecord = Simplify<WithDateAsString<TagFolderMember>>;
type TagGroupMemberRecord = Simplify<WithDateAsString<TagGroupMember>>;
type TagSubscriptionRecord = Simplify<
  WithDateAsString<TagSubscription> & {
    preference: SubscriptionPreference;
  }
>;
type TagUserMemberRecord = Simplify<WithDateAsString<TagUserMember>>;
type TagRecord = Simplify<
  WithDateAsString<Tag> & {
    data: null | {
      is_organization_group?: boolean;
      subscribers_count?: number;
    };
  }
>;
type ThreadGroupPermissionRecord = Simplify<
  WithDateAsString<ThreadGroupPermission>
>;
type ThreadReadReceiptRecord = Simplify<WithDateAsString<ThreadReadReceipt>>;
type ThreadSeenReceiptRecord = Simplify<WithDateAsString<ThreadSeenReceipt>>;
type ThreadSubscriptionRecord = Simplify<
  WithDateAsString<ThreadSubscription> & {
    preference: SubscriptionPreference;
  }
>;
type ThreadTagRecord = Simplify<WithDateAsString<ThreadTag>>;
type ThreadTimelineRecord = Simplify<WithDateAsString<ThreadTimeline>>;
type ThreadUserParticipantRecord = Simplify<
  WithDateAsString<ThreadUserParticipant>
>;
type ThreadUserPermissionRecord = Simplify<
  WithDateAsString<ThreadUserPermission>
>;
type ThreadRecord = Simplify<
  WithDateAsString<Thread> & { type: ThreadType; visibility: ThreadVisibility }
>;
type UserContactInfoRecord = Simplify<WithDateAsString<UserContactInfo>>;
type UserLessonRecord = Simplify<WithDateAsString<UserLesson>>;
type UserOAuthRecord = Simplify<WithDateAsString<UserOAuth>>;

type UserProfileRecord = Simplify<
  WithDateAsString<UserProfile> & {
    /**
     * The name field is automatically generated in the database by
     * concatonating the first, middle, and last name fields. It is
     * read-only.
     */
    name: string;
  }
>;

type UserSettingsRecord = Merge<
  WithDateAsString<UserSettings>,
  {
    settings: {
      /**
       * - Consolidated inbox shows all of your messages at once,
       *   grouped by priority.
       * - Blocking inbox also groups messages by priority
       *   but only allows you to see the highest priority
       *   messages at any given time.
       */
      inbox_layout?: "consolidated-inbox" | "blocking-inbox";

      /**
       * Navigate "Back" when marking a thread as "Done". By default,
       * Comms will instead try to navigate the user to the next
       * thread, where "next thread" is context specific.
       */
      enable_nav_back_on_thread_done?: boolean;

      /**
       * Manually toggled by the user to enable focus mode until
       * they decide to un-toggle it.
       */
      enable_focus_mode?: boolean;
      /**
       * An array of priorities that should be delivered even
       * while focus mode is turned on.
       */
      focus_mode_exceptions?: number[];
      /**
       * When true, then inbox items are only delivered at the days/times
       * the user specifies.
       */
      enable_scheduled_delivery?: boolean;
      /**
       * On what days should scheduled delivery happen?
       */
      scheduled_days?: string[];
      /**
       * At what times should scheduled delivery happen?
       */
      scheduled_times?: string[];
      /**
       * The time the user most recently pressed the "deliver now"
       * button and bypassed their standard scheduled delivery.
       */
      most_recent_deliver_now?: number | null;
      /**
       * When this value is provided and greater than 0, the user
       * will be required to wait this many seconds in order to
       * disable scheduled delivery. A value of 0 will disable the
       * friction timer, allowing the user to toggle scheduled
       * delivery on and off instantly.
       */
      seconds_to_wait_to_disable_scheduled_delivery?: number;
      /**
       * - `true` if the user has linked their Gmail email account.
       * - `"reauthorize"` indicates that the user previously linked
       *   their Gmail email account, but for whatever reason they
       *   need to do so again.
       */
      linked_gmail_email_account?: boolean | "reauthorize";

      /**
       * After sending a message, the user will be given the option
       * to undo the sending for this many seconds. Note that,
       * unless this value is 0, Comms will schedule the message to
       * be sent in current time + secondsForUndoingSentMessage + 10
       * seconds. The 10 additional seconds is to accomodate undoing
       * the sending of the message on a slow network connection.
       *
       * @default 10
       */
      seconds_for_undoing_sent_message?: number;
    };
  }
>;
type UserRecord = Simplify<WithDateAsString<User>>;

/* -------------------------------------------------------------------------------------------------
 * Tag subtypes
 * -------------------------------------------------------------------------------------------------
 *
 * The following are some notable tag subtypes. They are all tags themselves.
 */

export interface GroupRecord extends TagRecord {
  type: "_GROUP";
}

/* -----------------------------------------------------------------------------------------------*/

export interface InboxSectionTagRecord extends TagRecord {
  id: InboxSectionRecord["id"];
  type: "_INBOX_SECTION";
  /** Equal to the name of the associated inbox section */
  name: string;
  description: null;
}

/* -----------------------------------------------------------------------------------------------*/

export interface InboxSubsectionTagRecord extends TagRecord {
  id: InboxSubsectionRecord["id"];
  type: "_INBOX_SUBSECTION";
  /** Equal to the name of the associated inbox subsection */
  name: string;
  description: null;
}

/* -----------------------------------------------------------------------------------------------*/

interface SingletonTag<T extends string> extends TagRecord {
  id: T;
  type: "_SINGLETON";
}

export type SpamTagRecord = SingletonTag<"SPAM">;
export type NotSpamTagRecord = SingletonTag<"NOT_SPAM">;

export const SpecialTagTypes = {
  GROUP: "_GROUP",
  INBOX_SECTION: "_INBOX_SECTION",
  INBOX_SUBSECTION: "_INBOX_SUBSECTION",
  SINGLETON: "_SINGLETON",
} as const;

/* -------------------------------------------------------------------------------------------------
 * Timeline entry subtypes
 * -------------------------------------------------------------------------------------------------
 */

export type ThreadTimelineSubtype =
  | ThreadTimelineMessageRecord
  | ThreadTimelineBranchedThreadRecord;

export interface ThreadTimelineBranchedThreadRecord
  extends ThreadTimelineRecord {
  /** Branched thread's ID */
  id: string;
  type: "BRANCHED_THREAD";
  /**
   * The order is determined by
   * 1. The message branched from "sent_at"
   * 2. The message branched from "scheduled_to_be_sent_at"
   * 3. The first message in the branched thread "sent_at"
   * 4. The first message in the branched thread "scheduled_to_be_sent_at"
   */
  order: string;
}

export interface ThreadTimelineMessageRecord extends ThreadTimelineRecord {
  /** Message's ID */
  id: string;
  type: "MESSAGE";
  /**
   * The order is determined by
   * 1. The related message "sent_at"
   * 2. The related message "scheduled_to_be_sent_at"
   */
  order: string;
}

/* -------------------------------------------------------------------------------------------------
 * Utilities
 * -----------------------------------------------------------------------------------------------*/

export type RecordMap = {
  [T in RecordTable]?: {
    [id: string]: TableToRecord[T];
  };
};

export type RecordTable = keyof TableToRecord;

export type RecordValue<T extends RecordTable = RecordTable> = TableToRecord[T];

export type PointerWithRecord<T extends RecordTable = RecordTable> = {
  [K in T]: { table: K; id: string; record: RecordValue<K> };
}[T];

export type RecordPointer<T extends RecordTable = RecordTable> = {
  [K in T]: { table: K; id: string };
}[T];

/**
 * -------------------------------------------------------------------------------------------------
 * tableProps
 * -------------------------------------------------------------------------------------------------
 *
 * A `true` prop value means that the property is required in order to create the record. A `false`
 * prop value means that the property is optional when creating the record. Some optional properties
 * may, in fact, be readonly.
 */

export const tablePropsMap = {
  attachment: {
    id: true,
    filename: true,
    content_type: true,
    content_disposition: true,
    size: true,
    url: true,
    owner_organization_id: true,
    version: false,
    created_at: false,
    updated_at: false,
    is_deleted: false,
  },
  auth_token: {
    id: true,
    user_id: true,
    expires_at: true,
    owner_organization_id: true,
    version: false,
    created_at: false,
    updated_at: false,
    is_deleted: false,
  },
  draft: {
    id: true,
    user_id: true,
    body_html: true,
    branched_from_message_id: true,
    branched_from_message_scheduled_to_be_sent_at: true,
    branched_from_message_sent_at: true,
    branched_from_thread_id: true,
    is_reply: true,
    new_thread_subject: true,
    new_thread_visibility: true,
    scheduled_to_be_sent: true,
    scheduled_to_be_sent_at: true,
    sent: true,
    sent_at: true,
    thread_id: true,
    type: true,
    owner_organization_id: true,
    version: false,
    created_at: false,
    updated_at: false,
    is_deleted: false,
  },
  draft_email_recipient: {
    created_at: false,
    draft_id: true,
    draft_user_id: true,
    email_address: true,
    id: true,
    is_implicit: true,
    is_deleted: false,
    owner_organization_id: true,
    is_mentioned: true,
    priority: true,
    type: true,
    updated_at: false,
    version: false,
  },
  draft_group_recipient: {
    created_at: false,
    draft_id: true,
    group_id: true,
    draft_user_id: true,
    id: true,
    is_implicit: true,
    is_deleted: false,
    is_mentioned: true,
    owner_organization_id: true,
    priority: true,
    updated_at: false,
    version: false,
  },
  draft_tag: {
    created_at: false,
    draft_id: true,
    draft_user_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    tag_id: true,
    updated_at: false,
    version: false,
  },
  draft_user_recipient: {
    created_at: false,
    draft_id: true,
    draft_user_id: true,
    id: true,
    is_implicit: true,
    is_deleted: false,
    is_mentioned: true,
    owner_organization_id: true,
    priority: true,
    updated_at: false,
    recipient_user_id: true,
    version: false,
  },
  inbox_section: {
    created_at: false,
    id: true,
    is_deleted: false,
    name: true,
    order: true,
    owner_organization_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  inbox_subsection: {
    created_at: false,
    description: true,
    id: true,
    inbox_section_id: true,
    is_deleted: false,
    name: true,
    order: true,
    owner_organization_id: true,
    parsed_query: true,
    query: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  mention_frequency: {
    created_at: false,
    frequency: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    subject_id: true,
    type: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  message: {
    body_html: true,
    body_text: true,
    created_at: false,
    email_message_id: true,
    id: true,
    is_deleted: false,
    is_reply: true,
    last_edited_at: true,
    owner_organization_id: true,
    reactions: true,
    scheduled_to_be_sent_at: true,
    sender_user_id: true,
    sent_at: true,
    timeline_order: true,
    subject: true,
    thread_id: true,
    type: true,
    updated_at: false,
    version: false,
    was_edited: true,
    email_sender: true,
  },
  message_attachment: {
    attachment_id: true,
    created_at: false,
    thread_id: true,
    id: true,
    is_deleted: false,
    message_id: true,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  message_email_recipient: {
    created_at: false,
    email_address: true,
    thread_id: true,
    id: true,
    is_deleted: false,
    is_implicit: true,
    is_mentioned: true,
    priority: true,
    message_id: true,
    owner_organization_id: true,
    type: true,
    updated_at: false,
    version: false,
  },
  message_email_reference: {
    created_at: false,
    email_message_id: true,
    thread_id: true,
    id: true,
    is_deleted: false,
    message_id: true,
    order: true,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  message_group_recipient: {
    created_at: false,
    group_id: true,
    thread_id: true,
    id: true,
    is_deleted: false,
    is_implicit: true,
    is_mentioned: true,
    message_id: true,
    owner_organization_id: true,
    priority: true,
    updated_at: false,
    version: false,
  },
  message_user_reaction: {
    created_at: false,
    thread_id: true,
    id: true,
    is_deleted: false,
    message_id: true,
    owner_organization_id: true,
    reactions: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  message_user_recipient: {
    created_at: false,
    thread_id: true,
    id: true,
    is_deleted: false,
    is_implicit: true,
    is_mentioned: true,
    message_id: true,
    owner_organization_id: true,
    priority: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  notification_tag: {
    created_at: false,
    id: true,
    is_deleted: false,
    notification_id: true,
    notification_user_id: true,
    owner_organization_id: true,
    tag_id: true,
    updated_at: false,
    version: false,
  },
  notification: {
    created_at: false,
    done_at: true,
    done_last_modified_by: true,
    has_reminder: true,
    id: true,
    is_deleted: false,
    is_done: true,
    message_id: true,
    message_type: true,
    oldest_sent_at_value_not_marked_done: true,
    owner_organization_id: true,
    sent_at: true,
    priority: true,
    remind_at: true,
    is_starred: true,
    starred_at: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  organization: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  organization_controlled_domain: {
    created_at: false,
    domain: true,
    id: true,
    is_deleted: false,
    organization_id: true,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  organization_profile: {
    created_at: false,
    id: true,
    is_deleted: false,
    name: true,
    name_short: true,
    owner_organization_id: true,
    photo_url: true,
    updated_at: false,
    version: false,
  },
  organization_user_invitation: {
    created_at: false,
    creator_user_id: true,
    email_address: true,
    expires_at: true,
    id: true,
    is_deleted: false,
    organization_id: true,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  organization_user_member: {
    created_at: false,
    creator_user_id: true,
    id: true,
    is_deleted: false,
    organization_id: true,
    owner_organization_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  provider_email_map: {
    created_at: false,
    email_message_id: true,
    id: true,
    is_deleted: false,
    message_id: true,
    owner_organization_id: true,
    provider: true,
    provider_id: true,
    provider_thread_id: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  tag: {
    created_at: false,
    data: true,
    description: true,
    icon: true,
    id: true,
    is_deleted: false,
    name: true,
    owner_organization_id: true,
    type: true,
    updated_at: false,
    version: false,
  },
  tag_folder_member: {
    created_at: false,
    creator_user_id: true,
    folder_id: true,
    tag_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  tag_group_member: {
    created_at: false,
    creator_user_id: true,
    group_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    tag_id: true,
    updated_at: false,
    version: false,
  },
  tag_subscription: {
    created_at: false,
    creator_user_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    preference: true,
    tag_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  tag_user_member: {
    created_at: false,
    creator_user_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    tag_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  thread: {
    branched_from_message_id: true,
    branched_from_message_scheduled_to_be_sent_at: true,
    branched_from_message_sent_at: true,
    branched_from_thread_id: true,
    is_branch: true,
    created_at: false,
    first_message_id: true,
    first_message_scheduled_to_be_sent_at: true,
    first_message_sent_at: true,
    id: true,
    is_deleted: false,
    last_message_id: true,
    last_message_scheduled_to_be_sent_at: true,
    last_message_sent_at: true,
    owner_organization_id: true,
    subject: true,
    type: true,
    updated_at: false,
    version: false,
    visibility: true,
    first_timeline_id: true,
    first_timeline_order: true,
    last_timeline_id: true,
    last_timeline_order: true,
  },
  thread_group_permission: {
    created_at: false,
    group_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    start_at: true,
    thread_id: true,
    updated_at: false,
    version: false,
  },
  thread_timeline: {
    created_at: false,
    data: true,
    id: true,
    is_deleted: false,
    order: true,
    owner_organization_id: true,
    thread_id: true,
    type: true,
    updated_at: false,
    version: false,
  },
  thread_read_receipt: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    read_to_timeline_id: true,
    read_to_timeline_order: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  thread_seen_receipt: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    seen_to_timeline_id: true,
    seen_to_timeline_order: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  thread_subscription: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    preference: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  thread_tag: {
    created_at: false,
    creator_user_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    tag_id: true,
    thread_id: true,
    updated_at: false,
    version: false,
  },
  thread_user_participant: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  thread_user_permission: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    start_at: true,
    thread_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  user: {
    created_at: false,
    email: true,
    email_verified: true,
    email_verified_at: true,
    firebase_auth_id: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    updated_at: false,
    version: false,
  },
  user_contact_info: {
    created_at: false,
    email_address: true,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    phone_number: true,
    updated_at: false,
    version: false,
  },
  user_lesson: {
    created_at: false,
    id: true,
    is_completed: true,
    is_deleted: false,
    lesson_id: true,
    lesson_version: false,
    owner_organization_id: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  user_oauth: {
    created_at: false,
    federated_id: true,
    firebase_auth_id: true,
    firebase_id_token: true,
    firebase_id_token_expires_at: true,
    firebase_refresh_token: true,
    id: true,
    is_deleted: false,
    is_linked_to_user: true,
    oauth_access_token: true,
    oauth_id_token: true,
    owner_organization_id: true,
    provider: true,
    updated_at: false,
    user_id: true,
    version: false,
  },
  user_profile: {
    created_at: false,
    first_name: true,
    id: true,
    is_deleted: false,
    last_name: true,
    owner_organization_id: true,
    photo_url: true,
    updated_at: false,
    version: false,
    middle_name: true,
    name: false,
  },
  user_settings: {
    created_at: false,
    id: true,
    is_deleted: false,
    owner_organization_id: true,
    settings: true,
    updated_at: false,
    version: false,
  },
} satisfies {
  [T in RecordTable]: { [P in keyof TableToRecord[T]]: boolean };
};

export type GetOptionalProps<T extends RecordTable> = {
  [P in keyof (typeof tablePropsMap)[T]]: true extends (typeof tablePropsMap)[T][P]
    ? never
    : P;
}[keyof (typeof tablePropsMap)[T]];

export const tableProps = Object.fromEntries(
  Object.entries(tablePropsMap).map(([table, propsMap]) => [
    table,
    Object.keys(propsMap),
  ]),
) as unknown as {
  readonly [T in RecordTable]: ReadonlyArray<keyof TableToRecord[T] & string>;
};

/* -------------------------------------------------------------------------------------------------
 * tablePKeys
 * -----------------------------------------------------------------------------------------------*/

/**
 * Comms requires that every record have an "id" value, but
 * some records derive that id value from other columns. This object
 * maps table names to the columns used to derive the id value. If
 * an id value isn't derived from other columns, then it is derived
 * from itself.
 */
export const tablePKeys = {
  attachment: ["id"],
  auth_token: ["id"],
  draft_email_recipient: ["draft_id", "email_address", "type"],
  draft_group_recipient: ["draft_id", "group_id"],
  draft_tag: ["draft_id", "tag_id"],
  draft_user_recipient: ["draft_id", "recipient_user_id"],
  draft: ["id"],
  inbox_section: ["id"],
  inbox_subsection: ["id"],
  mention_frequency: ["subject_id", "user_id", "type"],
  message_attachment: ["message_id", "attachment_id"],
  message_email_recipient: ["message_id", "email_address", "type"],
  message_email_reference: ["message_id", "email_message_id"],
  message_group_recipient: ["message_id", "group_id"],
  message_user_reaction: ["message_id", "user_id"],
  message_user_recipient: ["message_id", "user_id"],
  message: ["id"],
  notification_tag: ["notification_id", "tag_id"],
  notification: ["user_id", "thread_id"],
  organization_controlled_domain: ["domain", "organization_id"],
  organization_profile: ["id"],
  organization_user_invitation: ["organization_id", "email_address"],
  organization_user_member: ["organization_id", "user_id"],
  organization: ["id"],
  provider_email_map: ["user_id", "provider", "provider_id"],
  tag_folder_member: ["tag_id", "folder_id"],
  tag_group_member: ["tag_id", "group_id"],
  tag_subscription: ["user_id", "tag_id"],
  tag_user_member: ["tag_id", "user_id"],
  tag: ["id"],
  thread_group_permission: ["thread_id", "group_id"],
  thread_read_receipt: ["thread_id", "user_id"],
  thread_seen_receipt: ["thread_id", "user_id"],
  thread_subscription: ["user_id", "thread_id"],
  thread_tag: ["thread_id", "tag_id"],
  thread_timeline: ["id"],
  thread_user_participant: ["thread_id", "user_id"],
  thread_user_permission: ["thread_id", "user_id"],
  thread: ["id"],
  user_contact_info: ["id"],
  user_lesson: ["user_id", "lesson_id"],
  user_oauth: ["id"],
  user_profile: ["id"],
  user_settings: ["id"],
  user: ["id"],
} as const satisfies {
  readonly [Table in RecordTable]: ReadonlyArray<keyof RecordValue<Table>>;
};

export type JoinTable = {
  [Table in RecordTable]: (typeof tablePKeys)[Table] extends readonly ["id"]
    ? never
    : Table;
}[RecordTable];

export type NonJoinTable = {
  [Table in RecordTable]: (typeof tablePKeys)[Table] extends readonly ["id"]
    ? Table
    : never;
}[RecordTable];

export type TablePKey<T extends RecordTable> =
  // We need to help typescript realize that PKeys are always keyof the
  // associated record
  TablePKeyInner<T> extends keyof RecordValue<T> ? TablePKeyInner<T> : never;

type TablePKeyInner<T extends RecordTable> = (typeof tablePKeys)[T][number];

export type JoinRecordPKey<T extends JoinTable = JoinTable> = Pick<
  RecordValue<T>,
  TablePKey<T>
>;

export const joinTables = Object.keys(tablePKeys).filter(
  (table) => tablePKeys[table as RecordTable].length > 1,
) as JoinTable[];

/* -------------------------------------------------------------------------------------------------
 * tableFilterKeys
 * -----------------------------------------------------------------------------------------------*/

/**
 * This object maps table names to the columns that can be used to filter
 * records.
 */
export const tableFilterKeys = {
  attachment: [],
  auth_token: [],
  draft_email_recipient: ["draft_id", "email_address", "type"],
  draft_group_recipient: ["draft_id", "group_id"],
  draft_tag: ["draft_id", "tag_id"],
  draft_user_recipient: ["draft_id", "recipient_user_id"],
  draft: ["thread_id", "user_id", "type"],
  inbox_section: ["user_id"],
  inbox_subsection: ["user_id", "inbox_section_id"],
  mention_frequency: ["subject_id", "user_id", "type"],
  message_attachment: ["message_id", "attachment_id"],
  message_email_recipient: ["message_id", "email_address", "type"],
  message_email_reference: ["message_id", "email_message_id"],
  message_group_recipient: ["message_id", "group_id"],
  message_user_reaction: ["message_id", "user_id"],
  message_user_recipient: ["message_id", "user_id"],
  message: ["thread_id", "type"],
  notification_tag: ["notification_id", "tag_id"],
  notification: ["user_id", "thread_id", "message_id"],
  organization_controlled_domain: ["domain", "organization_id"],
  organization_profile: [],
  organization_user_invitation: ["organization_id", "email_address"],
  organization_user_member: ["organization_id", "user_id"],
  organization: [],
  provider_email_map: [
    "user_id",
    "provider",
    "provider_id",
    "message_id",
    "thread_id",
  ],
  tag_folder_member: ["tag_id", "folder_id"],
  tag_group_member: ["tag_id", "group_id"],
  tag_subscription: ["user_id", "tag_id"],
  tag_user_member: ["tag_id", "user_id"],
  tag: [],
  thread_group_permission: ["thread_id", "group_id"],
  thread_read_receipt: ["thread_id", "user_id"],
  thread_seen_receipt: ["thread_id", "user_id"],
  thread_subscription: ["user_id", "thread_id"],
  thread_tag: ["thread_id", "tag_id"],
  thread_timeline: ["thread_id"],
  thread_user_participant: ["thread_id", "user_id"],
  thread_user_permission: ["thread_id", "user_id"],
  thread: [],
  user_contact_info: [],
  user_lesson: ["user_id", "lesson_id"],
  user_oauth: ["user_id", "firebase_auth_id"],
  user_profile: [],
  user_settings: [],
  user: [],
} as const satisfies {
  readonly [Table in RecordTable]: ReadonlyArray<keyof RecordValue<Table>>;
};

export type TableHasFilterKeys = {
  [Table in RecordTable]: (typeof tableFilterKeys)[Table] extends readonly never[]
    ? never
    : Table;
}[RecordTable];

export type TableNoFilterKeys = {
  [Table in RecordTable]: (typeof tableFilterKeys)[Table] extends readonly never[]
    ? Table
    : never;
}[RecordTable];

export type TableFilterKey<T extends RecordTable> =
  // We need to help typescript realize that PKeys are always keyof the
  // associated record
  TableFilterKeyInner<T> extends keyof RecordValue<T>
    ? TableFilterKeyInner<T>
    : never;

type TableFilterKeyInner<T extends RecordTable> =
  (typeof tableFilterKeys)[T][number];

export type RecordFilterProps<
  T extends TableHasFilterKeys = TableHasFilterKeys,
> = Pick<RecordValue<T>, TableFilterKey<T>>;

export const filterableTables = Object.keys(tablePKeys).filter(
  (table) => tableFilterKeys[table as RecordTable].length > 1,
) as TableHasFilterKeys[];

/* -------------------------------------------------------------------------------------------------
 * canDeleteTables
 * -----------------------------------------------------------------------------------------------*/

export const canDeleteTables = {
  attachment: true,
  auth_token: false,
  draft_email_recipient: true,
  draft_group_recipient: true,
  draft_tag: true,
  draft_user_recipient: true,
  draft: true,
  inbox_section: true,
  inbox_subsection: true,
  mention_frequency: true,
  message_attachment: true,
  message_email_recipient: true,
  message_email_reference: true,
  message_group_recipient: true,
  message_user_reaction: true,
  message_user_recipient: true,
  message: true,
  notification_tag: true,
  notification: true,
  organization_controlled_domain: true,
  organization_profile: false,
  organization_user_invitation: true,
  organization_user_member: false,
  organization: false,
  provider_email_map: false,
  tag_folder_member: true,
  tag_group_member: true,
  tag_subscription: true,
  tag_user_member: true,
  tag: true,
  thread_group_permission: true,
  thread_read_receipt: true,
  thread_seen_receipt: true,
  thread_subscription: true,
  thread_tag: true,
  thread_timeline: true,
  thread_user_participant: true,
  thread_user_permission: true,
  thread: true,
  user_contact_info: false,
  user_lesson: true,
  user_oauth: false,
  user_profile: false,
  user_settings: false,
  user: false,
} as const satisfies { readonly [table in RecordTable]: boolean };

/* -----------------------------------------------------------------------------------------------*/

export const TABLE_NAMES = Object.keys(tablePKeys) as RecordTable[];

/* -----------------------------------------------------------------------------------------------*/
