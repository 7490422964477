import { RecordTable, RecordValue } from "libs/schema";
import { useObservable, useObservableState } from "observable-hooks";
import { useCallback, useMemo, useState } from "react";
import { map, Observable, switchMap } from "rxjs";
import { Simplify } from "type-fest";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  ClientRecordLoaderApi,
  ObserveQueryResult,
} from "~/environment/RecordLoader";
import { useCurrentUserId } from "~/environment/user.service";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useAssertInvariant } from "./useAssertInvariant";

export function useRecordLoader<
  T extends RecordTable = RecordTable,
  Deps extends [any, ...any[]] | [] = [],
  IsAuthOptional extends boolean | undefined = undefined,
  MapResult = ObserveQueryResult<T>,
>(
  props: UseRecordLoaderProps<T, Deps, IsAuthOptional, MapResult>,
): UseRecordLoaderResult<MapResult> {
  const {
    load,
    deps = [] as unknown as Deps,
    initialLimit,
    limitStep,
    map: mapResult = DEFAULT_MAP_RESULT as MapResultFn<T, MapResult>,
    mapDeps = [],
    isAuthOptional = false,
  } = props;

  useAssertInvariant(
    isAuthOptional,
    "useRecordLoader: attempted to change isAuthOptional argument",
  );

  const [limit, setLimit] = useState(initialLimit);
  const { recordLoader } = useClientEnvironment();

  const currentUserId = isAuthOptional
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useCurrentUserId()
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useAuthGuardContext().currentUser.id;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapFn = useCallback(mapResult, mapDeps);

  const query = useObservable(
    (inputs$) => {
      return inputs$.pipe(
        switchMap(([loader, limit, currentUserId, mapFn, ...deps]) =>
          load({
            loader,
            limit,
            currentUserId: currentUserId as never,
            deps,
          }).pipe(map(mapFn)),
        ),
      );
    },
    [recordLoader, limit, currentUserId, mapFn, ...deps],
  );

  const defaultValue = useMemo(
    () => mapFn(DEFAULT_VALUE as ObserveQueryResult<T>),
    [mapFn],
  );

  const result = useObservableState(query, defaultValue);

  const isLimitDefined = limit !== undefined && !!limitStep;

  const fetchMore = useCallback(() => {
    if (!isLimitDefined) return;
    setLimit((limit) => limit && limit + limitStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimitDefined]);

  return { ...result, fetchMore };
}

const DEFAULT_MAP_RESULT = (r: any) => r;

const DEFAULT_VALUE: ObserveQueryResult = Object.freeze({
  records: Object.freeze([]) as [],
  nextId: null,
  limit: null,
  isLoading: true,
});

export interface UseRecordLoaderProps<
  T extends RecordTable = RecordTable,
  Deps extends any[] = [],
  IsAuthOptional extends boolean | undefined = undefined,
  MapResult = UseRecordLoaderResult<T>,
> {
  load: (
    props: UseRecordLoaderLoadProps<Deps, IsAuthOptional>,
  ) => Observable<ObserveQueryResult<T>>;
  deps?: Deps;
  initialLimit?: number;
  limitStep?: number;
  map?: MapResultFn<T, MapResult>;
  mapDeps?: any[];
  /**
   * By default, this hook will pull in the currentUserId using the AuthGuardContext
   * and pass it to the provided `load()` function as a prop. By setting isAuthOptional,
   * you can override this behavior and the currentUserId will instead by grabbed using
   * the useCurrentUserId() hook. In this scenerio, the currentUserId passed to the
   * `load()` function may be undefined.
   *
   * Whatever option you choose, the value of `isAuthOptional` must not change after
   * initialization. If it does an error will be thrown.
   */
  isAuthOptional?: IsAuthOptional;
}

type MapResultFn<T extends RecordTable, MapResult> = (
  result: ObserveQueryResult<T>,
) => MapResult;

export interface UseRecordLoaderLoadProps<
  Deps extends any[],
  IsAuthOptional extends boolean | undefined = undefined,
> {
  loader: ClientRecordLoaderApi;
  limit: number | undefined;
  currentUserId: true extends IsAuthOptional ? string | undefined : string;
  deps: Deps;
}

export type UseRecordLoaderResult<MapResult> = Simplify<
  MapResult & { fetchMore: () => void }
>;

export function mapRecordsHelper<T extends RecordTable, N extends string, R>(
  result: ObserveQueryResult<T>,
  newRecordsName: N,
  newRecordsValue: R,
) {
  return {
    [newRecordsName]: newRecordsValue,
    nextId: result.nextId,
    limit: result.limit,
    isLoading: result.isLoading,
  } as Omit<ObserveQueryResult<T>, "records"> & Record<N, R>;
}
