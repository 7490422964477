import { useObservableEagerState, useObservableState } from "observable-hooks";
import { createContext, PropsWithChildren, useEffect } from "react";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  Subject,
} from "rxjs";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";

export type TSidebarLayoutFocusEvent = "Sidebar" | "Outlet";
export type TSidebarLayoutMode = "push" | "over";

export interface ISidebarLayoutContext {
  focusEvent$: Observable<TSidebarLayoutFocusEvent>;
  isSidebarOpen(): boolean;
  sidebarOpen$: Observable<boolean>;
  sidebarMode(): TSidebarLayoutMode;
  sidebarMode$: Observable<TSidebarLayoutMode>;
  emitFocusEvent(event: TSidebarLayoutFocusEvent): void;
  setSidebarOpen(isOpen: boolean): void;
  /**
   * Use `force = true` to override what would be the natural sidebar
   * mode and force it into another one. You can then clear the forced
   * setting and revert to the natural one by calling `setSidebarMode(null, true)`.
   */
  setSidebarMode(value: TSidebarLayoutMode, force?: boolean): void;
  setSidebarMode(value: { force: TSidebarLayoutMode | null }): void;
  useSidebarMode(): TSidebarLayoutMode;
  useIsSidebarOpen(): boolean;
}

export const SidebarLayoutContext = createContext<ISidebarLayoutContext | null>(
  null,
);

export const useSidebarLayoutContext = createUseContextHook(
  SidebarLayoutContext,
  "SidebarLayoutContext",
);

export function useForceSidebarIntoMode(mode: TSidebarLayoutMode) {
  const { setSidebarMode } = useSidebarLayoutContext();

  useEffect(() => {
    setSidebarMode({ force: mode });
    return () => setSidebarMode({ force: null });
  }, [mode, setSidebarMode]);
}

export function withProvideSidebarLayoutContext<
  P extends PropsWithChildren<unknown>,
>(Component: React.ComponentType<P>) {
  return function ComponentWithContext(props: P) {
    const context = useConstant(() => {
      const sidebarMode$ = new BehaviorSubject<TSidebarLayoutMode>("over");
      const sidebarForceMode$ = new BehaviorSubject<TSidebarLayoutMode | null>(
        null,
      );
      const sidebarOpen$ = new BehaviorSubject<boolean>(false);
      const focusEvent$ = new Subject<TSidebarLayoutFocusEvent>();

      const SIDEBAR_LAYOUT_CONTEXT: ISidebarLayoutContext = {
        focusEvent$,
        emitFocusEvent(event: TSidebarLayoutFocusEvent) {
          focusEvent$.next(event);
        },
        isSidebarOpen() {
          return sidebarOpen$.getValue();
        },
        sidebarOpen$: sidebarOpen$.pipe(distinctUntilChanged()),
        setSidebarOpen(isOpen: boolean) {
          sidebarOpen$.next(isOpen);
        },
        sidebarMode() {
          return sidebarForceMode$.getValue() || sidebarMode$.getValue();
        },
        sidebarMode$: combineLatest([sidebarMode$, sidebarForceMode$]).pipe(
          map(
            ([sidebarMode, sidebarForceMode]) =>
              sidebarForceMode || sidebarMode,
          ),
          distinctUntilChanged(),
        ),
        setSidebarMode(value) {
          if (typeof value === "object") {
            sidebarForceMode$.next(value.force);
          } else {
            sidebarMode$.next(value);
          }
        },
        useSidebarMode() {
          return useObservableEagerState(this.sidebarMode$);
        },
        useIsSidebarOpen() {
          return useObservableEagerState(this.sidebarOpen$);
        },
      };

      return SIDEBAR_LAYOUT_CONTEXT;
    });

    return (
      <SidebarLayoutContext.Provider value={context}>
        <Component {...props} />
      </SidebarLayoutContext.Provider>
    );
  };
}
