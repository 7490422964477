/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ClientPubsubMessage,
  ParsedServerPubsubMessage,
  serverPubSubMessageD,
} from "libs/PubSubTypes";
import { createLogger, Logger } from "libs/logger";
import { wait } from "@libs/utils/wait";
import { ClientConfig } from "./ClientConfig";
import { areDecoderErrors } from "ts-decoders";
import { CURRENT_USER_ID$ } from "./user.service";

export class WebsocketPubsubClient {
  private ws: WebSocket | undefined;
  private reconnectAttempt = 1;
  private logger: Logger;

  constructor(
    private args: {
      config: ClientConfig;
      logger?: Logger;
      onMessage: (messages: ParsedServerPubsubMessage[]) => void;
      onStart: () => void;
    },
  ) {
    this.logger = (args.logger || createLogger()).child({
      WebsocketPubsubClient: true,
    });

    CURRENT_USER_ID$.subscribe((userId) => {
      this.disconnect();
      if (!userId) return;
      this.connect();
    });

    // window.addEventListener("online", () => {
    //   this.reconnectAttempt = 1;
    //   this.connect();
    // });
  }

  private connect() {
    this.logger.debug("connecting...");
    const url = new URL(location.href);
    const protocol = url.protocol === "https:" ? "wss" : "ws";
    this.ws = new WebSocket(`${protocol}://${url.host}/api/connectToPubSub`);

    this.ws.onopen = () => {
      this.logger.debug("connected!");
      this.reconnectAttempt = 1;
      this.args.onStart();
    };

    this.ws.onmessage = (event) => {
      const rawMessages = JSON.parse(event.data);

      if (!Array.isArray(rawMessages)) {
        this.logger.error("unknown message", rawMessages);
        return;
      }

      const messages = rawMessages.reduce(
        (store: ParsedServerPubsubMessage[], message) => {
          const decoded = serverPubSubMessageD.decode(message);

          if (areDecoderErrors(decoded)) {
            this.logger.error("unknown message", decoded);
            return store;
          }

          store.push(decoded.value);
          return store;
        },
        [],
      );

      this.logger.debug("<", messages);
      this.args.onMessage(messages);
    };

    this.ws.onerror = (error) => {
      this.logger.debug("error", error);
    };

    this.ws.onclose = () => {
      this.logger.debug("closed");
      this.attemptReconnect();
    };
  }

  private disconnect() {
    if (!this.ws || this.ws.CLOSED || this.ws.CLOSING) return;
    this.ws.close();
  }

  private async attemptReconnect() {
    if (!navigator.onLine) return;
    await wait(2 ** this.reconnectAttempt * 1000);
    this.reconnectAttempt += 1;
    this.connect();
  }

  private send(message: ClientPubsubMessage) {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.logger.debug(">", message.type, message.key);
      this.ws.send(JSON.stringify(message));
    }
  }

  subscribe(key: string) {
    this.send({ type: "SUBSCRIBE", key });
  }

  unsubscribe(key: string) {
    this.send({ type: "UNSUBSCRIBE", key });
  }
}
