import { UnreachableCaseError } from "libs/errors";
import { mapRecordsHelper, useRecordLoader } from "./useRecordLoader";

export function useTagViewThreads(
  tagId: string | null | undefined,
  type: "tag" | "group",
) {
  return useRecordLoader({
    load({ loader, limit, deps: [tagId, type] }) {
      if (!tagId) {
        return loader.createObserveQueryResult<"thread">();
      }

      switch (type) {
        case "tag": {
          return loader.observeGetTagViewThreads({
            tagId,
            limit,
          });
        }
        case "group": {
          return loader.observeGetGroupViewThreads({
            groupId: tagId,
            limit,
          });
        }
        default: {
          throw new UnreachableCaseError(type);
        }
      }
    },
    initialLimit: 200,
    limitStep: 25,
    deps: [tagId, type],
    map: (result) => {
      return mapRecordsHelper(
        result,
        "threadIds",
        result.records.map((record) => record.id),
      );
    },
  });
}
