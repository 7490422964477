import { generateRecordId, getPointer } from "libs/schema";
import { op } from "libs/transaction";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { runTransaction } from "./write";

interface UpsertGroupParams {
  id: string;
  name: string;
  description: string | null;
  folderMemberIds?: string[];
  groupMemberIds?: string[];
  userMemberIds?: string[];
}

export function upsertGroup(params: UpsertGroupParams) {
  return runTransaction({
    tx: async (transaction) => {
      const currentUser = getAndAssertCurrentUser();
      const environment = getEnvironment();

      const tagPointer = getPointer({
        table: "tag",
        id: params.id,
      });

      const [tag] = await environment.recordLoader.getRecord(tagPointer);

      const isNew = !tag || tag.is_deleted;

      if (isNew) {
        const folderMemberIds = params.folderMemberIds || [];
        const groupMemberIds = params.groupMemberIds || [];
        const userMemberIds = params.userMemberIds || [];

        transaction = op.transaction({
          authorId: currentUser.id,
          operations: [
            op.set("tag", {
              id: params.id,
              type: "_GROUP",
              name: params.name,
              description: params.description,
              data: null,
              icon: null,
              owner_organization_id: currentUser.owner_organization_id,
            }),

            ...folderMemberIds.map((folderId) => {
              return op.set("tag_folder_member", {
                id: generateRecordId("tag_folder_member", {
                  folder_id: folderId,
                  tag_id: params.id,
                }),
                tag_id: params.id,
                folder_id: folderId,
                creator_user_id: currentUser.id,
                owner_organization_id: currentUser.owner_organization_id,
              });
            }),

            ...groupMemberIds.map((groupId) => {
              return op.set("tag_group_member", {
                id: generateRecordId("tag_group_member", {
                  group_id: groupId,
                  tag_id: params.id,
                }),
                tag_id: params.id,
                group_id: groupId,
                creator_user_id: currentUser.id,
                owner_organization_id: currentUser.owner_organization_id,
              });
            }),

            ...userMemberIds.map((userId) => {
              return op.set("tag_user_member", {
                id: generateRecordId("tag_user_member", {
                  user_id: userId,
                  tag_id: params.id,
                }),
                tag_id: params.id,
                user_id: userId,
                creator_user_id: currentUser.id,
                owner_organization_id: currentUser.owner_organization_id,
              });
            }),
          ],
        });
      } else {
        transaction = op.transaction({
          authorId: currentUser.id,
          operations: [
            op.set("tag", {
              id: params.id,
              type: "_GROUP",
              name: params.name,
              description: params.description,
              data: null,
              icon: null,
              owner_organization_id: currentUser.owner_organization_id,
            }),
          ],
        });

        if (params.folderMemberIds) {
          const [existingMemberFolders] =
            await environment.recordLoader.getTagFolderMembers({
              tag_id: params.id,
            });

          const newMemberFolderIds = params.folderMemberIds.filter(
            (folderId) =>
              !existingMemberFolders.some(
                (folder) => folder.folder_id === folderId,
              ),
          );

          transaction.operations.push(
            ...newMemberFolderIds.map((folderId) => {
              return op.set("tag_folder_member", {
                id: generateRecordId("tag_folder_member", {
                  folder_id: folderId,
                  tag_id: params.id,
                }),
                tag_id: params.id,
                folder_id: folderId,
                creator_user_id: currentUser.id,
                owner_organization_id: currentUser.owner_organization_id,
              });
            }),
          );

          const deletedMemberFolderIds = existingMemberFolders
            .filter(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ({ folder_id }) => !params.folderMemberIds!.includes(folder_id),
            )
            .map(({ folder_id }) => folder_id);

          transaction.operations.push(
            ...deletedMemberFolderIds.map((folder_id) => {
              return op.update(
                {
                  table: "tag_folder_member",
                  id: generateRecordId("tag_folder_member", {
                    folder_id,
                    tag_id: params.id,
                  }),
                },
                { is_deleted: true },
              );
            }),
          );
        }

        if (params.groupMemberIds) {
          const [existingMemberGroups] =
            await environment.recordLoader.getTagGroupMembers({
              tag_id: params.id,
            });

          const existingMemberGroupIds = existingMemberGroups.map(
            ({ group_id }) => group_id,
          );

          const newMemberGroupIds = params.groupMemberIds.filter(
            (groupId) => !existingMemberGroupIds.includes(groupId),
          );

          transaction.operations.push(
            ...newMemberGroupIds.map((groupId) => {
              return op.set("tag_group_member", {
                id: generateRecordId("tag_group_member", {
                  group_id: groupId,
                  tag_id: params.id,
                }),
                tag_id: params.id,
                group_id: groupId,
                creator_user_id: currentUser.id,
                owner_organization_id: currentUser.owner_organization_id,
              });
            }),
          );

          const deletedMemberGroupIds = existingMemberGroupIds.filter(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (groupId) => !params.groupMemberIds!.includes(groupId),
          );

          transaction.operations.push(
            ...deletedMemberGroupIds.map((groupId) => {
              return op.update(
                {
                  table: "tag_group_member",
                  id: generateRecordId("tag_group_member", {
                    group_id: groupId,
                    tag_id: params.id,
                  }),
                },
                { is_deleted: true },
              );
            }),
          );
        }

        if (params.userMemberIds) {
          const [existingMemberUsers] =
            await environment.recordLoader.getTagUserMembers({
              tag_id: params.id,
            });

          const existingMemberUserIds = existingMemberUsers.map(
            ({ user_id }) => user_id,
          );

          const newMemberUserIds = params.userMemberIds.filter(
            (groupId) => !existingMemberUserIds.includes(groupId),
          );

          transaction.operations.push(
            ...newMemberUserIds.map((userId) => {
              return op.set("tag_user_member", {
                id: generateRecordId("tag_user_member", {
                  user_id: userId,
                  tag_id: params.id,
                }),
                tag_id: params.id,
                user_id: userId,
                creator_user_id: currentUser.id,
                owner_organization_id: currentUser.owner_organization_id,
              });
            }),
          );

          const deletedMemberUserIds = existingMemberUserIds.filter(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (userId) => !params.userMemberIds!.includes(userId),
          );

          transaction.operations.push(
            ...deletedMemberUserIds.map((userId) => {
              return op.update(
                {
                  table: "tag_user_member",
                  id: generateRecordId("tag_user_member", {
                    user_id: userId,
                    tag_id: params.id,
                  }),
                },
                { is_deleted: true },
              );
            }),
          );
        }
      }
    },
  });
}
