import { customAlphabet } from "nanoid";

export const UID_ALPHABET =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

/**
 * This is the ID for the special "Comms" admin user.
 */
export const COMMS_ADMIN_USER_ID = "M9N7Rstn9TfpKmkw5vpFKt";

/**
 * Generates a random, 22 character secure unique ID suitable
 * for use in URLs.
 */
const uid = customAlphabet(
  // By default, nanoid's can include `_` and `-` characters. React Router
  // doesn't seem to like these characters at the beginning of a route so
  // we're removing them from the alphabet and increasing the ID length by 1
  // to (more than) compensate.
  UID_ALPHABET,
  22,
);

export default uid;
