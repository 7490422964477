import {
  JoinRecordPKey,
  JoinTable,
  RecordPointer,
  RecordTable,
  RecordValue,
  getPointer,
} from "libs/schema";
import { throwUnreachableCaseError } from "@libs/utils/errors";
import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { observeRecord } from "~/observables/observeRecord";

type UseRecordResult<T extends RecordTable> = [
  RecordValue<T> | null,
  { isLoading: boolean },
];

const DEFAULT_VALUE = Object.freeze({ record: null, isLoading: true });

export function useRecord<T extends JoinTable>(
  table: T,
  key: Partial<JoinRecordPKey<T>>,
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  table: T,
  id?: string | null,
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  pointer?: RecordPointer<T> | null,
): UseRecordResult<T>;
export function useRecord<T extends RecordTable>(
  a?: RecordPointer<T> | T | null,
  b?: string | null | JoinRecordPKey,
) {
  const pointer = getPointerFromInput(a, b);

  const query = useObservable(
    (inputs$) => {
      return inputs$.pipe(
        switchMap(([table, id]) =>
          table && id
            ? observeRecord({ table, id } as RecordPointer<T>)
            : of({ record: null, isLoading: false }),
        ),
      );
    },
    [pointer?.table, pointer?.id],
  );

  const { record, isLoading } = useObservableState(query, DEFAULT_VALUE);

  return [record, { isLoading }];
}

function getPointerFromInput<T extends RecordTable>(
  a?: RecordPointer<T> | T | null,
  b?: string | null | JoinRecordPKey,
): RecordPointer<T> | undefined {
  if (!a) return;
  if (typeof a === "string") {
    if (!b) return;

    return typeof b === "string"
      ? getPointer(a, b)
      : typeof b === "object"
      ? Object.values(b).includes(undefined)
        ? undefined
        : getPointer<T>(a, b as any)
      : throwUnreachableCaseError(b);
  }

  return a;
}
