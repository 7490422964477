import { IPostDoc } from "@libs/firestore-models";
import { ComponentType } from "react";
import { IListOnEntryActionEvent, List } from "~/components/list";
import { Avatar } from "~/components/Avatar";
import { useThread } from "~/hooks/useThread";
import { useMessage } from "~/hooks/useMessage";
import { useMessageSender } from "~/hooks/useMessageSender";
import {
  entryCSSClasses,
  EntryTimestamp,
  Recipients,
  Summary,
} from "~/components/content-list/layout";
import { RecordValue } from "libs/schema";
import { isModKeyActive } from "~/environment/command.service";
import { openLinkInNewTabOrWindow } from "~/environment/navigate.service";
import { navigateToEntry } from "~/components/content-list/ContentList";

export const ThreadEntry: ComponentType<{
  threadId: string;
  relativeOrder: number;
}> = (props) => {
  const [thread] = useThread(props.threadId);
  const [message] = useMessage(thread?.last_message_id);
  const sender = useMessageSender(thread?.last_message_id);

  if (!thread || !message) return null;

  const senderName = sender?.name || "unknown";

  return (
    <List.Entry<RecordValue<"thread">>
      id={props.threadId}
      data={thread}
      relativeOrder={props.relativeOrder}
    >
      <div className={entryCSSClasses}>
        <Avatar label={senderName} photoURL={sender?.photoUrl} width="30px" />

        <Recipients>{senderName}</Recipients>

        <Summary
          subject={message.subject}
          reply={message.is_reply}
          details={message.body_text}
        />

        <EntryTimestamp datetime={message.sent_at} />
      </div>
    </List.Entry>
  );
};

export function onThreadSelectNavigateToThread({
  entry,
  event,
}: IListOnEntryActionEvent<RecordValue<"thread">>) {
  const url = `/threads/${entry.id}`;

  if (isModKeyActive(event)) {
    openLinkInNewTabOrWindow(url);
  } else {
    navigateToEntry(entry.id, url);
  }
}
