import { RecordValue } from "libs/schema";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { observeGroupsUserIsAMemberOf } from "./observeGroupsUserIsAMemberOf";
import { observeRecord } from "./observeRecord";
import { observeTagFolderAncestorRecords } from "./observeTagFolderAncestorRecords";

export type ObserveGroupsUserIsAMemberOfWithFolderAncestorsRecordsResult = {
  records: Array<{
    record: RecordValue<"tag">;
    folderPaths: RecordValue<"tag">[][];
  }>;
  isLoading: boolean;
};

export function observeGroupsUserIsAMemberOfWithFolderAncestorsRecords(args: {
  userId: string;
}): Observable<ObserveGroupsUserIsAMemberOfWithFolderAncestorsRecordsResult> {
  return observeGroupsUserIsAMemberOf(args).pipe(
    switchMap(({ groupIds, isLoading }) => {
      if (groupIds.length === 0) return of({ records: [], isLoading });

      return combineLatest(
        groupIds.map((groupId) =>
          combineLatest([
            observeRecord({ table: "tag", id: groupId }),
            observeTagFolderAncestorRecords({ tagId: groupId }),
          ]),
        ),
      ).pipe(
        map((results) => {
          return results.reduce(
            (
              store: ObserveGroupsUserIsAMemberOfWithFolderAncestorsRecordsResult,
              [{ record, isLoading }, ancestors],
            ) => {
              store.isLoading =
                store.isLoading || isLoading || ancestors.isLoading;

              if (record) {
                store.records.push({
                  record,
                  folderPaths: ancestors.folderPaths,
                });
              }

              return store;
            },
            { records: [], isLoading: false },
          );
        }),
      );
    }),
  );
}
