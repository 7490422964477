import { isDefined } from "@libs/utils/predicates";
import { Simplify } from "type-fest";
import { UnreachableCaseError } from "./errors";
import {
  createRecordMapFromPointersWithRecords,
  RecordMap,
  RecordPointer,
  RecordTable,
  RecordValue,
  tableFilterKeys,
  TableHasFilterKeys,
  TABLE_NAMES,
} from "./schema";

import { PointerWithRecord } from "libs/schema";
import { Logger } from "libs/logger";
import {
  extractRecordsFromNamespacedRows,
  namespaceAllTableColumns,
  sql,
  Statement,
} from "libs/sql-statement";
import { ValidationError } from "libs/errors";
import { Decoder, areDecoderErrors } from "ts-decoders";

/* -------------------------------------------------------------------------------------------------
 *  SqlDatabaseBase
 * -------------------------------------------------------------------------------------------------
 *
 * All the SqlDatabaseBase methods. There needs to be an API endpoint of the same
 * name for each of these methods.
 *
 * Use this list, combined with the vscode "sort lines ascending" command,
 * to keep the methods sorted.
 *
 * - - - - - - - - - - - -
 * Non standard methods
 * - - - - - - - - - - - -
 * getDrafts
 * getGroupChildrenUserIsSubscribedTo
 * getGroupsUserIsSubscribedTo
 * getGroupViewThreads
 * getTagViewThreads
 * getThreadTimelineEntries
 * getUserOrganizationProfiles
 *
 * - - - - - - - - - - - -
 * Standard methods
 * - - - - - - - - - - - -
 * getDraftEmailRecipients
 * getDraftGroupRecipients
 * getDraftTags
 * getDraftUserRecipients
 * getInboxSections
 * getInboxSubsections
 * getMessageEmailRecipients
 * getMessageGroupRecipients
 * getMessageUserRecipients
 * getNotificationTags
 * getOrganizationUserMembers
 * getTagFolderMembers
 * getTagGroupMembers
 * getTagUserMembers
 * getThreadGroupPermissions
 * getThreadTags
 * getThreadUserParticipants
 * getThreadUserPermissions
 * getUserLessons
 */

export abstract class SqlDatabaseBase {
  protected abstract logger: Logger;
  protected abstract recordDecoderMap?: {
    [T in RecordTable]: Decoder<RecordValue<T>>;
  };
  protected abstract query(
    statement: Statement,
  ): Promise<Record<string, any>[]>;

  async getRecord<T extends RecordTable>(
    table: T,
    id: string,
  ): Promise<RecordValue<T> | null>;
  async getRecord<T extends RecordTable>(
    pointer: RecordPointer<T>,
  ): Promise<RecordValue<T> | null>;
  async getRecord<T extends RecordTable>(
    a: T | RecordPointer<T>,
    b?: string,
  ): Promise<RecordValue<T> | null> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pointer = typeof a === "string" ? { table: a, id: b! } : a;

    if (!TABLE_NAMES.includes(pointer.table)) {
      throw new ValidationError(
        `getRecord: invalid record table "${pointer.table}"`,
      );
    }

    const [result] = await this.query(
      sql`
        SELECT
          * 
        FROM 
          "${sql.raw(pointer.table)}"
        WHERE 
          "${sql.raw(pointer.table)}".id = ${pointer.id}
        LIMIT 1`,
    );

    return result ? this.decodeRecord(pointer.table, result) : null;
  }

  ////////
  // Non standard methods
  //

  getDrafts(params: GetDraftsParams): SqlDatabaseBaseResult<"draft"> {
    const statement = sql`
      SELECT 
        *
      FROM
        draft
      WHERE
        draft.user_id = ${params.userId}
      AND
        draft.scheduled_to_be_sent = ${params.scheduledToBeSent || false}
      ${params.startAt ? sql`AND draft.id >= ${params.startAt}` : sql.EMPTY}
      ORDER BY
        draft.id ASC
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.mapRawDbRecordsToRecordMap("draft", result);

    return {
      primaryTable: "draft",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  getGroupChildrenUserIsSubscribedTo(
    params: GetGroupChildrenUserIsSubscribedToParams,
  ): SqlDatabaseBaseResult<"tag"> {
    const { userId, groupId } = params;

    const statement = sql`
      SELECT 
        ${namespaceAllTableColumns(
          "tag_group_member",
          "tag",
          "tag_subscription",
        )}
      FROM
        tag_group_member
      JOIN
        tag ON tag.id = tag_group_member.tag_id
      JOIN
        tag_subscription ON tag_subscription.tag_id = tag_group_member.tag_id
      WHERE 
        tag_group_member.group_id = ${groupId}
      AND
        tag_subscription.user_id = ${userId}
      AND
        tag_subscription.preference IN ('all', 'all-new')
      ${params.startAt ? sql`AND tag.id >= ${params.startAt}` : sql.EMPTY}
      ORDER BY
        tag.name ASC
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};
      `;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.extractRecordsFromNamespacedRows(
        result,
        "tag_group_member",
        "tag",
        "tag_subscription",
      );

    return {
      primaryTable: "tag",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  getGroupsUserIsSubscribedTo(
    params: GetGroupsUserIsSubscribedToParams,
  ): SqlDatabaseBaseResult<"tag"> {
    const statement = sql`
      SELECT 
        ${namespaceAllTableColumns("tag", "tag_subscription")}
      FROM
        tag
      JOIN
        tag_subscription ON tag_subscription.tag_id = tag.id
      WHERE
        tag.type = '_GROUP'
      AND
        tag_subscription.preference IN ('all', 'all-new')
      AND
        tag_subscription.user_id = ${params.userId}
      ${params.startAt ? sql`AND tag.id >= ${params.startAt}` : sql.EMPTY}
      ORDER BY
        tag.name ASC
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.extractRecordsFromNamespacedRows(result, "tag", "tag_subscription");

    return {
      primaryTable: "tag",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  getGroupViewThreads(
    params: GetGroupViewThreadsParams,
  ): SqlDatabaseBaseResult<"thread"> {
    console.log("getGroupViewThreads called");
    const statement = sql`
      SELECT 
        ${namespaceAllTableColumns(
          "thread",
          "thread_group_permission",
          "message",
          "user_profile",
        )}
      FROM
        thread_group_permission 
      JOIN 
        thread ON thread.id = thread_group_permission.thread_id 
      JOIN
        message ON message.id = thread.last_message_id
      LEFT JOIN
        user_profile ON user_profile.id = message.sender_user_id
      WHERE 
        thread_group_permission.group_id = ${params.groupId}
      ${params.startAt ? sql`AND thread.id >= ${params.startAt}` : sql.EMPTY}
      ORDER BY
        thread.last_message_sent_at ASC,
        thread.last_message_scheduled_to_be_sent_at ASC
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.extractRecordsFromNamespacedRows(
        result,
        "thread",
        "thread_group_permission",
        "message",
        "user_profile",
      );

    return {
      primaryTable: "thread",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  getTagViewThreads(
    params: GetTagViewThreadsParams,
  ): SqlDatabaseBaseResult<"thread"> {
    const statement = sql`
      SELECT 
        ${namespaceAllTableColumns("thread", "thread_tag", "message")}
      FROM
        thread_tag 
      JOIN 
        thread ON thread.id = thread_tag.thread_id 
      JOIN
        message ON message.id = thread.last_message_id
      WHERE 
        thread_tag.tag_id = ${params.tagId}
      ${params.startAt ? sql`AND thread.id >= ${params.startAt}` : sql.EMPTY}
      ORDER BY
        thread.last_message_sent_at ASC,
        thread.last_message_scheduled_to_be_sent_at ASC
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.extractRecordsFromNamespacedRows(
        result,
        "thread",
        "thread_tag",
        "message",
      );

    return {
      primaryTable: "thread",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  getThreadTimelineEntries(
    params: GetThreadTimelineEntriesParams,
  ): SqlDatabaseBaseResult<"thread_timeline"> {
    const statement = sql`
    SELECT 
      * 
    FROM
      thread_timeline 
    WHERE 
      thread_timeline.thread_id = ${params.thread_id}
    ${
      params.startAt
        ? sql`AND thread_timeline.id >= ${params.startAt}`
        : sql.EMPTY
    }
    ORDER BY
      thread_timeline.id ASC
    ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.mapRawDbRecordsToRecordMap("thread_timeline", result);

    return {
      primaryTable: "thread_timeline",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  getUserOrganizationProfiles(
    params: GetUserOrganizationProfilesParams,
  ): SqlDatabaseBaseResult<"organization_profile"> {
    const statement = sql`
      SELECT
        ${namespaceAllTableColumns(
          "organization_profile",
          "organization_user_member",
        )}
      FROM
        organization_profile
      JOIN
        organization_user_member ON organization_user_member.organization_id = organization_profile.id
      WHERE
        organization_user_member.user_id = ${params.userId}
      ${
        params.startAt
          ? sql`AND organization_profile.id >= ${params.startAt}`
          : sql.EMPTY
      }
      ORDER BY
        organization_profile.name ASC
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.extractRecordsFromNamespacedRows(
        result,
        "organization_profile",
        "organization_user_member",
      );

    return {
      primaryTable: "organization_profile",
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  ////////
  // Standard methods
  //

  getDraftEmailRecipients(
    params: GetDraftEmailRecipientsParams,
  ): SqlDatabaseBaseResult<"draft_email_recipient"> {
    return this.simpleFilterableTableQuery({
      table: "draft_email_recipient",
      params,
    });
  }

  getDraftGroupRecipients(
    params: GetDraftGroupRecipientsParams,
  ): SqlDatabaseBaseResult<"draft_group_recipient"> {
    return this.simpleFilterableTableQuery({
      table: "draft_group_recipient",
      params,
    });
  }

  getDraftTags(params: GetDraftTagsParams): SqlDatabaseBaseResult<"draft_tag"> {
    return this.simpleFilterableTableQuery({
      table: "draft_tag",
      params,
    });
  }

  getDraftUserRecipients(
    params: GetDraftUserRecipientsParams,
  ): SqlDatabaseBaseResult<"draft_user_recipient"> {
    return this.simpleFilterableTableQuery({
      table: "draft_user_recipient",
      params,
    });
  }

  getInboxSections(
    params: GetInboxSectionsParams,
  ): SqlDatabaseBaseResult<"inbox_section"> {
    return this.simpleFilterableTableQuery({
      table: "inbox_section",
      params,
    });
  }

  getInboxSubsections(
    params: GetInboxSubsectionsParams,
  ): SqlDatabaseBaseResult<"inbox_subsection"> {
    return this.simpleFilterableTableQuery({
      table: "inbox_subsection",
      params,
    });
  }

  getMessageEmailRecipients(
    params: GetMessageEmailRecipientsParams,
  ): SqlDatabaseBaseResult<"message_email_recipient"> {
    return this.simpleFilterableTableQuery({
      table: "message_email_recipient",
      params,
    });
  }

  getMessageGroupRecipients(
    params: GetMessageGroupRecipientsParams,
  ): SqlDatabaseBaseResult<"message_group_recipient"> {
    return this.simpleFilterableTableQuery({
      table: "message_group_recipient",
      params,
    });
  }

  getMessageUserRecipients(
    params: GetMessageUserRecipientsParams,
  ): SqlDatabaseBaseResult<"message_user_recipient"> {
    return this.simpleFilterableTableQuery({
      table: "message_user_recipient",
      params,
    });
  }

  getNotificationTags(
    params: GetNotificationTagsParams,
  ): SqlDatabaseBaseResult<"notification_tag"> {
    return this.simpleFilterableTableQuery({
      table: "notification_tag",
      params,
    });
  }

  getOrganizationUserMembers(
    params: GetOrganizationUserMembersParams,
  ): SqlDatabaseBaseResult<"organization_user_member"> {
    return this.simpleFilterableTableQuery({
      table: "organization_user_member",
      params,
    });
  }

  getTagFolderMembers(
    params: GetTagFolderMembersParams,
  ): SqlDatabaseBaseResult<"tag_folder_member"> {
    return this.simpleFilterableTableQuery({
      table: "tag_folder_member",
      params,
    });
  }

  getTagGroupMembers(
    params: GetTagGroupMembersParams,
  ): SqlDatabaseBaseResult<"tag_group_member"> {
    return this.simpleFilterableTableQuery({
      table: "tag_group_member",
      params,
    });
  }

  getTagUserMembers(
    params: GetTagUserMembersParams,
  ): SqlDatabaseBaseResult<"tag_user_member"> {
    return this.simpleFilterableTableQuery({
      table: "tag_user_member",
      params,
    });
  }

  getThreadGroupPermissions(
    params: GetThreadGroupPermissionsParams,
  ): SqlDatabaseBaseResult<"thread_group_permission"> {
    return this.simpleFilterableTableQuery({
      table: "thread_group_permission",
      params,
    });
  }

  getThreadTags(
    params: GetThreadTagsParams,
  ): SqlDatabaseBaseResult<"thread_tag"> {
    return this.simpleFilterableTableQuery({
      table: "thread_tag",
      params,
    });
  }

  getThreadUserParticipants(
    params: GetThreadUserParticipantsParams,
  ): SqlDatabaseBaseResult<"thread_user_participant"> {
    return this.simpleFilterableTableQuery({
      table: "thread_user_participant",
      params,
    });
  }

  getThreadUserPermissions(
    params: GetThreadUserPermissionsParams,
  ): SqlDatabaseBaseResult<"thread_user_permission"> {
    return this.simpleFilterableTableQuery({
      table: "thread_user_permission",
      params,
    });
  }

  getUserLessons(
    params: GetUserLessonsParams,
  ): SqlDatabaseBaseResult<"user_lesson"> {
    return this.simpleFilterableTableQuery({
      table: "user_lesson",
      params,
    });
  }

  protected simpleFilterableTableQuery<T extends TableHasFilterKeys>(args: {
    table: T;
    params: FilterableTableQueryParams<T>;
  }): SqlDatabaseBaseResult<T> {
    const { table, params } = args;

    const filterKeys = tableFilterKeys[
      table
    ] as unknown as (keyof typeof params)[];

    const definedProps = getAndAssertDefinedParams(params, ...filterKeys);

    const statement = sql`
      SELECT 
        * 
      FROM
        ${sql.raw(table)} 
      WHERE 
        ${sql.join(
          definedProps.map(
            (prop) => sql`${sql.raw(table)}.${sql.raw(prop)} = ${params[prop]}`,
          ),
          " AND ",
        )}
      ${
        params.startAt
          ? sql`AND ${sql.raw(table)}.id >= ${params.startAt}`
          : sql.EMPTY
      }
      ${params.limit ? sql`LIMIT ${params.limit}` : sql.EMPTY};`;

    const parseQueryResult = (result: SqlDatabaseRawResult) =>
      this.mapRawDbRecordsToRecordMap(table, result);

    return {
      primaryTable: table,
      statement,
      parseQueryResult,
      runQuery: () => this.query(statement).then(parseQueryResult),
    };
  }

  protected extractRecordsFromNamespacedRows(
    rows: Record<string, any>[],
    ...tables: RecordTable[]
  ): RecordMap {
    return extractRecordsFromNamespacedRows({
      rows,
      tables,
      decodeRecord: this.decodeRecord.bind(this),
    });
  }

  protected mapRawDbRecordsToRecordMap<Table extends RecordTable>(
    table: Table,
    records: Record<string, any>[],
  ): RecordMap {
    return createRecordMapFromPointersWithRecords(
      records.map((record) =>
        this.mapRawDbRecordToRecordWithPointer<Table>(table, record),
      ),
    );
  }

  protected mapRawDbRecordToRecordWithPointer<Table extends RecordTable>(
    table: Table,
    row: Record<string, any>,
  ) {
    const record = this.decodeRecord(table, row);

    return {
      table,
      id: record.id,
      record: record,
    } as PointerWithRecord<Table>;
  }

  protected decodeRecord<T extends RecordTable>(
    table: T,
    row: Record<string, any>,
  ): RecordValue<T> {
    if (!this.recordDecoderMap) return row as RecordValue<T>;

    const decoder = this.recordDecoderMap[table];

    if (!decoder) {
      throw new Error(`No decoder found for table "${table}"`);
    }

    const result = decoder.decode(row);

    if (areDecoderErrors(result)) {
      this.logger.error("Error parsing record from database", result);
      throw new Error(`Error parsing "${table}" record from database`);
    }

    return result.value;
  }
}

/* -----------------------------------------------------------------------------------------------*/

function getAndAssertDefinedParams<T, K extends keyof T>(
  params: T,
  ...props: K[]
) {
  const keyProps = props.filter((p) => !(p === "limit" || p === "startAt"));
  const oneValueIsProvided = keyProps.some((p) => params[p] !== undefined);

  if (keyProps.length === 1 && oneValueIsProvided) {
    return props.filter((p) => isDefined(params[p])) as unknown as [
      K & string,
      ...(K & string)[],
    ];
  }

  const everyValueIsProvided = keyProps.every((p) => params[p] !== undefined);

  if (oneValueIsProvided && !everyValueIsProvided) {
    return props.filter((p) => isDefined(params[p])) as unknown as [
      K & string,
      ...(K & string)[],
    ];
  }

  if (!oneValueIsProvided) {
    throw new ValidationError(
      `Must provide either "${props.join('" or "')}" as a parameter.`,
    );
  }

  throw new ValidationError(
    `Must not provide all of "${props.join(
      '" and "',
    )}" as parameters. Use getRecord instead.`,
  );
}

/* -------------------------------------------------------------------------------------------------
 *  Types
 * -------------------------------------------------------------------------------------------------
 */

export type SqlDatabaseBaseKey = keyof SqlDatabaseBase;

export type SqlDatabaseParams<T extends SqlDatabaseBaseKey> = Parameters<
  SqlDatabaseBase[T]
>[0];

export type SqlDatabaseRawResult = Record<string, any>[];

export interface SqlDatabaseBaseResult<T extends RecordTable> {
  primaryTable: T;
  statement: Statement;
  parseQueryResult: (result: SqlDatabaseRawResult) => RecordMap;
  /** Note that the results returned by runQuery have already been parsed */
  runQuery: () => Promise<RecordMap>;
}

export interface GetRecordParams<T extends RecordTable = RecordTable> {
  table: T;
  id: string;
}

export type FilterableTableQueryParams<T extends TableHasFilterKeys> = Params<
  (typeof tableFilterKeys)[T][number]
>;

type Params<T extends string> = Simplify<
  { [K in T]?: string } & BaseQueryOptions
>;

export interface BaseQueryOptions {
  limit?: number;
  startAt?: string;
}

export interface GetDraftsParams extends BaseQueryOptions {
  userId: string;
  scheduledToBeSent?: boolean;
}

export interface GetGroupChildrenUserIsSubscribedToParams
  extends BaseQueryOptions {
  userId: string;
  groupId: string;
}

export interface GetGroupsUserIsSubscribedToParams extends BaseQueryOptions {
  userId: string;
}

export type GetDraftEmailRecipientsParams =
  FilterableTableQueryParams<"draft_email_recipient">;

export type GetDraftGroupRecipientsParams =
  FilterableTableQueryParams<"draft_group_recipient">;

export type GetDraftTagsParams = FilterableTableQueryParams<"draft_tag">;

export type GetDraftUserRecipientsParams =
  FilterableTableQueryParams<"draft_user_recipient">;

export type GetInboxSectionsParams =
  FilterableTableQueryParams<"inbox_section">;

export type GetInboxSubsectionsParams =
  FilterableTableQueryParams<"inbox_subsection">;

export type GetMessageEmailRecipientsParams =
  FilterableTableQueryParams<"message_email_recipient">;

export type GetMessageGroupRecipientsParams =
  FilterableTableQueryParams<"message_group_recipient">;

export type GetMessageUserRecipientsParams =
  FilterableTableQueryParams<"message_user_recipient">;

export type GetNotificationTagsParams =
  FilterableTableQueryParams<"notification_tag">;

export type GetOrganizationUserMembersParams =
  FilterableTableQueryParams<"organization_user_member">;

export type GetTagFolderMembersParams =
  FilterableTableQueryParams<"tag_folder_member">;

export type GetTagGroupMembersParams =
  FilterableTableQueryParams<"tag_group_member">;

export type GetTagUserMembersParams =
  FilterableTableQueryParams<"tag_user_member">;

export interface GetGroupViewThreadsParams extends BaseQueryOptions {
  groupId: string;
}

export interface GetTagViewThreadsParams extends BaseQueryOptions {
  tagId: string;
}

export type GetThreadGroupPermissionsParams =
  FilterableTableQueryParams<"thread_group_permission">;

export type GetThreadTagsParams = FilterableTableQueryParams<"thread_tag">;

export type GetThreadTimelineEntriesParams =
  FilterableTableQueryParams<"thread_timeline">;

export type GetThreadUserParticipantsParams =
  FilterableTableQueryParams<"thread_user_participant">;

export type GetThreadUserPermissionsParams =
  FilterableTableQueryParams<"thread_user_permission">;

export type GetUserLessonsParams = FilterableTableQueryParams<"user_lesson">;

export interface GetUserOrganizationProfilesParams extends BaseQueryOptions {
  userId: string;
}

/* -------------------------------------------------------------------------------------------------
 * RecordLoaderApi
 * -------------------------------------------------------------------------------------------------
 */

export type RecordLoaderApi = {
  [K in ApiQueryType]: K extends "getRecord"
    ? {
        <T extends RecordTable>(params: RecordPointer<T>): Promise<
          RecordLoaderGetResult<T>
        >;
        <T extends RecordTable>(table: T, id: string): Promise<
          RecordLoaderGetResult<T>
        >;
      }
    : (
        params: Parameters<SqlDatabaseBase[K]>[0],
      ) => ReturnType<SqlDatabaseBase[K]> extends SqlDatabaseBaseResult<infer T>
        ? Promise<RecordLoaderQueryResult<T>>
        : never;
};

export type RecordLoaderGetResult<T extends RecordTable = RecordTable> = [
  RecordValue<T> | null,
  ...unknown[],
];

export type RecordLoaderQueryResult<T extends RecordTable = RecordTable> = [
  RecordValue<T>[],
  ...unknown[],
];

/* -----------------------------------------------------------------------------------------------*/

export type ApiQueryType = keyof SqlDatabaseBase;

export type ApiQuery<T extends ApiQueryType = ApiQueryType> = ApiQueryMap[T];

type ApiQueryMap = {
  [K in ApiQueryType]: {
    type: K;
    params: Parameters<SqlDatabaseBase[K]>[0];
  };
};

export type ApiQueryMinusGetRecordsType = Exclude<
  ApiQueryType,
  "getRecord" | "getRecords" | "unsafeGetRecord" | "unsafeGetRecords"
>;

export type ApiQueryMinusGetRecords<
  T extends ApiQueryMinusGetRecordsType = ApiQueryMinusGetRecordsType,
> = ApiQueryMinusGetRecordsMap[T];

type ApiQueryMinusGetRecordsMap = {
  [K in ApiQueryMinusGetRecordsType]: {
    type: K;
    params: Parameters<SqlDatabaseBase[K]>[0];
  };
};
/* -----------------------------------------------------------------------------------------------*/

export type GetQueryTable<T extends ApiQueryMinusGetRecordsType> =
  (typeof queryToTableMap)[T];

export const queryToTableMap = {
  getDrafts: "draft",
  getGroupChildrenUserIsSubscribedTo: "tag",
  getGroupsUserIsSubscribedTo: "tag",
  getGroupViewThreads: "thread",
  getDraftEmailRecipients: "draft_email_recipient",
  getDraftGroupRecipients: "draft_group_recipient",
  getDraftTags: "draft_tag",
  getDraftUserRecipients: "draft_user_recipient",
  getInboxSections: "inbox_section",
  getInboxSubsections: "inbox_subsection",
  getMessageEmailRecipients: "message_email_recipient",
  getMessageGroupRecipients: "message_group_recipient",
  getMessageUserRecipients: "message_user_recipient",
  getNotificationTags: "notification_tag",
  getOrganizationUserMembers: "organization_user_member",
  getTagFolderMembers: "tag_folder_member",
  getTagGroupMembers: "tag_group_member",
  getTagUserMembers: "tag_user_member",
  getTagViewThreads: "thread",
  getThreadGroupPermissions: "thread_group_permission",
  getThreadTags: "thread_tag",
  getThreadTimelineEntries: "thread_timeline",
  getThreadUserParticipants: "thread_user_participant",
  getThreadUserPermissions: "thread_user_permission",
  getUserLessons: "user_lesson",
  getUserOrganizationProfiles: "organization_profile",
} satisfies {
  [QueryType in ApiQueryMinusGetRecordsType]: RecordTable;
};

/* -----------------------------------------------------------------------------------------------*/

export type SimpleFilterQueries = keyof typeof isFilterQueryMap;
export type NonSimpleFilterQueries = Exclude<
  keyof typeof queryToTableMap,
  SimpleFilterQueries
>;

const isFilterQueryMap = {
  getDraftEmailRecipients: true,
  getDraftGroupRecipients: true,
  getDraftTags: true,
  getDraftUserRecipients: true,
  getInboxSections: true,
  getInboxSubsections: true,
  getMessageEmailRecipients: true,
  getMessageGroupRecipients: true,
  getMessageUserRecipients: true,
  getNotificationTags: true,
  getOrganizationUserMembers: true,
  getTagFolderMembers: true,
  getTagGroupMembers: true,
  getTagUserMembers: true,
  getThreadGroupPermissions: true,
  getThreadTags: true,
  getThreadUserParticipants: true,
  getThreadUserPermissions: true,
  getUserLessons: true,
} satisfies {
  [QueryType in ApiQueryMinusGetRecordsType]?: true;
};

/* -------------------------------------------------------------------------------------------------
 *  getQueryCacheKeyBase
 * -------------------------------------------------------------------------------------------------
 */

export function getQueryCacheKeyBase<T extends ApiQueryMinusGetRecords["type"]>(
  type_: T,
  params_: ApiQueryMinusGetRecords<T>["params"],
): string {
  // This small song and dance is necessary for typescript to properly type narrow
  // the switch statement below ¯\_(ツ)_/¯
  const { type, params } = {
    type: type_,
    params: params_,
  } as ApiQueryMinusGetRecords;

  switch (type) {
    case "getDrafts": {
      return [type, params.scheduledToBeSent || false].join(":");
    }
    case "getGroupChildrenUserIsSubscribedTo": {
      return getKey(type, params, ["groupId", "userId"]);
    }
    case "getGroupsUserIsSubscribedTo": {
      return getKey(type, params, ["userId"]);
    }
    case "getGroupViewThreads": {
      return getKey(type, params, ["groupId"]);
    }
    case "getTagViewThreads": {
      return getKey(type, params, ["tagId"]);
    }
    case "getThreadTimelineEntries": {
      return getKey(type, params, ["thread_id"]);
    }
    case "getUserOrganizationProfiles": {
      return getKey(type, params, ["userId"]);
    }
    default: {
      return getKey(type, params);
    }
  }
}

function getKey<T extends SimpleFilterQueries>(
  type: T,
  params: Parameters<SqlDatabaseBase[T]>[0],
): string;
function getKey<
  T extends NonSimpleFilterQueries,
  P,
  K extends keyof P & string,
>(type: T, params: P, props: K[]): string;
function getKey<P, K extends keyof P & string>(
  type: string,
  params: P,
  props?: K[],
): string {
  if (!(type in queryToTableMap)) {
    throw new UnreachableCaseError(type as never);
  }

  if (props) {
    return [
      type,
      ...props
        .map((prop) => params[prop] && `${prop}:${params[prop]}`)
        .filter(isDefined),
    ].join(":");
  }

  const knownProps = tableFilterKeys[
    queryToTableMap[type as keyof typeof queryToTableMap] as never
  ] as K[];

  return [
    type,
    ...knownProps
      .map((prop) => params[prop] && `${prop}:${params[prop]}`)
      .filter(isDefined),
  ].join(":");
}

/* -----------------------------------------------------------------------------------------------*/
