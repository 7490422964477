export function wait(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}

export interface ResolvablePromise<T> extends Promise<T> {
  complete: boolean;
  resolved: T | undefined;
  rejected: any;
  resolve(value: T): void;
  reject(error: any): void;
}

export function resolvablePromise<T>() {
  let resolve: (value: T) => void;
  let reject: (error: any) => void;

  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  }) as ResolvablePromise<T>;

  promise.complete = false;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.resolve = (value: T) => {
    promise.complete = true;
    promise.resolved = value;
    resolve!(value);
  };
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.reject = (err: any) => {
    promise.complete = true;
    promise.rejected = err;
    reject!(err);
  };

  return promise;
}

export async function promiseAllKeyed<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: Promise<any> },
>(obj: T) {
  const entries = Object.entries(obj);

  const results = await Promise.all(
    entries.map(([key, value]) =>
      value.then((result) => [key, result] as const),
    ),
  );

  return Object.fromEntries(results) as Promise<{
    [K in keyof T]: Awaited<T[K]>;
  }>;
}

export async function promiseAllSettledKeyed<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: Promise<any> },
>(obj: T) {
  const entries = Object.entries(obj);

  const results = await Promise.all(
    entries.map(([key, value]) =>
      value.then(
        (result) => [key, { status: "fulfilled", value: result }],
        (error) => [key, { status: "rejected", reason: error }],
      ),
    ),
  );

  return Object.fromEntries(results) as Promise<{
    [K in keyof T]: Awaited<PromiseSettledResult<T[K]>>;
  }>;
}

export async function promiseRaceKeyed<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { [key: string]: Promise<any> },
>(obj: T) {
  const entries = Object.entries(obj);

  const result = await Promise.race(
    entries.map(([key, value]) =>
      value.then((result) => [key, result] as const),
    ),
  );

  return result as { [K in keyof T]: Awaited<T[K]> }[keyof T];
}

export async function promiseRaceSome(
  promises: Promise<boolean>[],
): Promise<boolean> {
  const promise = promiseRaceMatch(promises, (value) => value);
  const value = await promise;
  return !!value;
}

export async function promiseRaceMatch<T>(
  promises: Promise<T>[],
  predicate: (value: T) => boolean,
): Promise<T | undefined> {
  const conditionPromise = resolvablePromise<T | undefined>();

  const allPromise = Promise.all(
    promises.map(async (promise) => {
      const value = await promise;

      if (predicate(value)) {
        conditionPromise.resolve(value);
      }
    }),
  );

  allPromise
    .then(() => {
      if (conditionPromise.complete) return;
      conditionPromise.resolve(undefined);
    })
    .catch((err) => {
      if (conditionPromise.complete) {
        console.error("promiseRaceMatch a promise errored", err);
        return;
      }

      conditionPromise.reject(err);
    });

  return conditionPromise;
}
