import {
  RecordPointer,
  RecordTable,
  RecordValue,
  TablePKey,
  getPointer,
} from "libs/schema";
import { Observable, of, switchMap } from "rxjs";
import { ENVIRONMENT$ } from "~/environment/ClientEnvironmentContext";
import { ObserveGetResult } from "~/environment/RecordLoader";

export type ObserveRecordResult<T extends RecordTable> = ObserveGetResult<T>;

export function observeRecord<T extends RecordTable>(
  pointer:
    | ({ table: T } & Pick<RecordValue<T>, TablePKey<T>>)
    | RecordPointer<T>,
): Observable<ObserveRecordResult<T>>;
export function observeRecord<T extends RecordTable>(
  pointerLike:
    | ({ table: T } & Pick<RecordValue<T>, TablePKey<T>>)
    | RecordPointer<T>,
): Observable<ObserveRecordResult<T>> {
  const pointer = getPointer(pointerLike);

  return ENVIRONMENT$.pipe(
    switchMap((environment) => {
      if (!environment) {
        return of({
          record: null,
          isLoading: false,
        });
      }

      return environment.recordLoader.observeGetRecord(pointer);
    }),
  );
}
