import { router } from "./Routes";
import { PortalContext } from "~/environment/portal.service";
import useConstant from "use-constant";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { TooltipProvider } from "./components/Tooltip";
import { PendingRequestBarProvider } from "./components/PendingRequestBar";
import { HintViewport } from "./environment/hint-service";
import { lazy, Suspense } from "react";
import { OfflineBanner } from "./components/OfflineBanner";
import { RouterProvider } from "react-router-dom";
import { LoadingModal } from "./dialogs/LoadingModal";
import { DarkModeProvider } from "./components/DarkMode";
import { ClientEnvironmentProvider } from "./environment/ClientEnvironmentContext";

const UnsupportedBrowserWarningBanner = lazy(() =>
  import("~/environment/detect-browser.service").then((m) => ({
    default: m.UnsupportedBrowserWarningBanner,
  })),
);

export default function App() {
  const portalContainer = useConstant(() => document.body);

  return (
    <ClientEnvironmentProvider>
      <HelmetProvider>
        <DarkModeProvider>
          <PortalContext.Provider value={{ container: portalContainer }}>
            <TooltipProvider>
              <PendingRequestBarProvider>
                <Helmet>
                  {/* 
                    This acts as the default page title for the app. It is expected that
                    this is overwritten by child components. It may be shown while a page
                    is loading. 
                  */}
                  <title>Comms</title>
                </Helmet>

                <Suspense fallback={null}>
                  <UnsupportedBrowserWarningBanner />
                </Suspense>

                <LoadingModal />
                <RouterProvider router={router} />
                <HintViewport />
                <OfflineBanner />
              </PendingRequestBarProvider>
            </TooltipProvider>
          </PortalContext.Provider>
        </DarkModeProvider>
      </HelmetProvider>
    </ClientEnvironmentProvider>
  );
}
