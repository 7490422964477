import { ComponentType, createContext, useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { ClientEnvironment } from "./ClientEnvironment";
import { createEnvironment } from "./environment";

const ClientEnvironmentContext = createContext<ClientEnvironment | undefined>(
  undefined,
);

export const ClientEnvironmentProvider: ComponentType<{}> = (props) => {
  const [environment, setReactEnvironment] = useState<
    ClientEnvironment | undefined
  >();

  useEffect(() => {
    createEnvironment().then((env) => {
      setEnvironment(env);
      setReactEnvironment(env);
    });
  }, []);

  if (!environment) return null;

  return (
    <ClientEnvironmentContext.Provider value={environment}>
      {props.children}
    </ClientEnvironmentContext.Provider>
  );
};

export const useClientEnvironment = createUseContextHook(
  ClientEnvironmentContext,
  "ClientEnvironmentContext",
);

export const ENVIRONMENT$ = new BehaviorSubject<ClientEnvironment | null>(null);

export const SUBSCRIPTION_MANAGER$ = new BehaviorSubject<
  ClientEnvironment["subscriptionManager"] | null
>(null);

let environment: ClientEnvironment | null = null;

export function getEnvironment() {
  if (!environment) {
    throw new Error("Environment not set");
  }

  return environment;
}

export function setEnvironment(env: ClientEnvironment | null) {
  environment = env;
  (globalThis as any).environment = env;
  ENVIRONMENT$.next(env);
  SUBSCRIPTION_MANAGER$.next(env?.subscriptionManager || null);
}
