import {
  ComponentType,
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { NavLink, useMatch } from "react-router-dom";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { css, cx } from "@emotion/css";
import { ShortcutHint, sidebarEntryCSS, SidebarGroups } from "./SidebarGroups";
import { IListRef, List, ListScrollbox } from "~/components/list";
import { TSidebarLayoutMode, useSidebarLayoutContext } from "./context";
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  merge,
  withLatestFrom,
} from "rxjs";
import { Transition } from "@headlessui/react";
import { NAVIGATION_EVENTS } from "~/environment/navigate.service";
import { HelpDialogState } from "~/dialogs/help/HelpDialog";
import { Avatar } from "~/components/Avatar";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import {
  callCommandById,
  useRegisterCommands,
  withNewCommandContext,
} from "~/environment/command.service";
import { KBarState } from "~/dialogs/kbar";
import { isEqual } from "@libs/utils/isEqual";
import { WINDOW_SIZE$ } from "~/utils/dom-helpers";
import {
  composeMessageCommand,
  openHelpCommand,
} from "~/utils/common-commands";
import { wait } from "@libs/utils/wait";
import { useRegisterGeneralNavigationCommands } from "./commands";
import { getClassForPrivateMessageTourStep } from "~/environment/lesson-service/lessons/private-message-walkthrough";

export const Sidebar = withNewCommandContext<{
  mode: TSidebarLayoutMode;
}>((props) => {
  useReactToSidebarFocusEvents();
  useReactToWindowResizeEvents();
  useReactToNavigationEvents();
  useReactToCommandDialogOpenEvents(props.mode);

  return props.mode === "over" ? <SidebarModeOver /> : <SidebarContent />;
});

/**
 * Open/close the sidebar in response to sidebar focus events
 */
function useReactToSidebarFocusEvents() {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = context.focusEvent$
      .pipe(distinctUntilChanged())
      .subscribe((e) => {
        context.setSidebarOpen(e === "Sidebar");
      });

    return () => sub.unsubscribe();
  }, [context]);
}

/**
 * Switch sidebar mode depending on the window size
 */
function useReactToWindowResizeEvents() {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = WINDOW_SIZE$.pipe(
      map(({ width }) => (width > 1000 ? "push" : "over")),
      distinctUntilChanged(),
    ).subscribe((mode) => {
      context.setSidebarMode(mode);
    });

    return () => sub.unsubscribe();
  }, [context]);
}

/**
 * Automatically close the sidebar and focus the outlet
 * on a navigation event
 */
function useReactToNavigationEvents() {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = NAVIGATION_EVENTS.subscribe(() => {
      context.setSidebarOpen(false);
      context.emitFocusEvent("Outlet");
    });

    return () => sub.unsubscribe();
  }, [context]);
}

/**
 * Automatically close the sidebar and focus the outlet
 * when the kbar is opened.
 */
function useReactToCommandDialogOpenEvents(mode: TSidebarLayoutMode) {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    if (mode === "push") return;

    const sub = KBarState.beforeOpen$
      .pipe(withLatestFrom(context.sidebarOpen$))
      .subscribe(([, isOpen]) => {
        if (!isOpen) return;
        context.setSidebarOpen(false);
        context.emitFocusEvent("Outlet");
      });

    return () => sub.unsubscribe();
  }, [mode, context]);
}

const SidebarModeOver = memo(() => {
  const context = useSidebarLayoutContext();

  const onBackdropClick = useCallback(() => {
    context.emitFocusEvent("Outlet");
  }, [context]);

  const isSidebarOpen = context.useIsSidebarOpen();

  return (
    <>
      <Transition
        // Note the "as" property. This will be rendered as an `<aside>`.
        as="aside"
        show={isSidebarOpen}
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className={cx(
          "fixed top-0 left-0",
          "w-64 h-screen shrink-0 border-r",
          "border-gray-8 bg-inherit z-[201]",
          "ease-in-out duration-75",
          "flex flex-col",
        )}
      >
        {isSidebarOpen && (
          <>
            <SidebarHotkeys />
            <SidebarContent />
          </>
        )}
      </Transition>

      <Transition
        show={isSidebarOpen}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={cx("ease-in-out duration-75")}
      >
        <div
          onClick={onBackdropClick}
          className={cx(
            "fixed top-0 left-0",
            "w-screen h-screen",
            "bg-blackA-10 z-[200]",
            "ease-in-out duration-75",
          )}
        />
      </Transition>
    </>
  );
}, isEqual);

const SidebarHotkeys = memo(
  withNewCommandContext({
    updateStrategy: "replace",
    Component: () => {
      const context = useSidebarLayoutContext();

      useRegisterGeneralNavigationCommands();

      useRegisterCommands({
        commands: () => {
          return [
            {
              label: "Close sidebar",
              hotkeys: ["Escape"],
              callback: () => {
                context.emitFocusEvent("Outlet");
              },
            },
            {
              label: "Open Command Bar",
              hotkeys: ["$mod+k"],
              showInKBar: false,
              callback: () => {
                KBarState.open();
              },
            },
            openHelpCommand({
              callback: () => {
                context.emitFocusEvent("Outlet");
                setTimeout(() => HelpDialogState.open(), 0);
              },
            }),
          ];
        },
        deps: [context],
      });

      return null;
    },
  }),
  isEqual,
);

const SidebarContent = memo(() => {
  const { currentUser } = useAuthGuardContext();
  const context = useSidebarLayoutContext();
  const listRef = useRef<IListRef<string> | null>(null);
  const scrollboxRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  useReactToFocusSidebarEvents(listRef);

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
  });

  const onArrowRight = useCallback(() => {
    context.emitFocusEvent("Outlet");

    if (context.sidebarMode() === "over") {
      context.setSidebarOpen(false);
    }
  }, [context]);

  const userName = `${currentUser.first_name} ${currentUser.last_name}`;

  return (
    <aside
      className={cx(
        "fixed top-0 left-0 h-screen w-64 bg-white flex flex-col",
        "border-r border-slate-7",
      )}
    >
      <div
        ref={headerRef}
        className="flex items-center pl-9 pr-4 py-[26px] transition-shadow duration-300"
      >
        <Avatar photoURL={currentUser.photo_url} label={userName} />
        <span className="ml-2 font-medium">{userName}</span>
      </div>

      <List<string>
        ref={listRef}
        focusEntryOnMouseOver
        onArrowRight={onArrowRight}
      >
        <ListScrollbox>
          <div ref={scrollboxRef} className="overflow-y-auto">
            <nav className="list-none pb-4">
              <InboxLink />
              <ComposeMessageButton />
              <SidebarNavLink to="starred" label="Starred" shortcutHint="g r" />
              <SidebarNavLink to="drafts" label="Drafts" shortcutHint="g d" />
              <SidebarNavLink to="sent" label="Sent" shortcutHint="g t" />
              <SidebarNavLink to="done" label="Done" shortcutHint="g e" />
              <SidebarNavLink
                to="reminders"
                label="Reminders"
                shortcutHint="g h"
              />
              <SidebarNavLink to="search" label="Search" shortcutHint="/" />
              <SidebarNavLink
                to="shared-messages"
                label="Shared Messages"
                shortcutHint="g s"
                className={getClassForPrivateMessageTourStep(3)}
              />

              {currentUser.owner_organization_id && (
                <SidebarNavLink
                  to={`organizations/${currentUser.owner_organization_id}/explore-channels`}
                  label="Explore Channels"
                />
              )}

              <HelpButton />
            </nav>

            <SidebarGroups />
          </div>
        </ListScrollbox>
      </List>
    </aside>
  );
}, isEqual);

const inboxLinkCSS = css`
  .notification-count {
    border-style: solid;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &:focus {
    .notification-count {
      border-width: 1px;
      padding-left: calc(0.5rem - 1px);
      padding-right: calc(0.5rem - 1px);
    }
  }
`;

function useReactToFocusSidebarEvents(
  listRef: MutableRefObject<IListRef<string> | null>,
) {
  const context = useSidebarLayoutContext();

  useEffect(() => {
    const sub = merge(
      context.sidebarOpen$,
      // Even if the sidebar is already open, we want to
      // focus the listRef when a focusEvent is emitted.
      context.focusEvent$.pipe(map((e) => e === "Sidebar")),
    )
      .pipe(
        filter((open) => open),
        delay(1),
      )
      .subscribe(() => {
        listRef.current?.focus();
      });

    return () => sub.unsubscribe();
  }, [context, listRef]);
}

const InboxLink: ComponentType<{}> = () => {
  return (
    <SidebarNavLink
      to="inbox"
      label="Inbox"
      shortcutHint="g i"
      className={inboxLinkCSS}
    />
  );
};

const ComposeMessageButton: ComponentType<{}> = () => {
  const context = useSidebarLayoutContext();

  return (
    <li>
      <List.Entry<never>
        id="compose-new-message-btn"
        onEntryAction={async () => {
          // The compose new message command isn't available if the
          // side bar is "open" and "over" so we make sure it's closed
          // before calling our command.
          context.setSidebarOpen(false);
          await wait(10);
          callCommandById(composeMessageCommand.id);
        }}
      >
        <button
          type="button"
          className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}
        >
          Compose Message
          <span className="flex-1" />
          <ShortcutHint hint="C" />
        </button>
      </List.Entry>
    </li>
  );
};

const HelpButton: ComponentType<{}> = () => {
  return (
    <li>
      <List.Entry<never>
        id="nav-help"
        onEntryAction={() => {
          callCommandById(openHelpCommand.id);
        }}
      >
        <button
          type="button"
          className={cx(sidebarEntryCSS, "hover:text-transparent w-full")}
        >
          Help
          <span className="flex-1" />
          <ShortcutHint hint="Shift+/" />
        </button>
      </List.Entry>
    </li>
  );
};

const SidebarNavLink: ComponentType<{
  to: string;
  label: string;
  shortcutHint?: string;
  className?: string;
}> = ({ to, label, shortcutHint, className }) => {
  const isActive = !!useMatch(to);

  return (
    <li>
      <List.Entry<never> id={`nav-${label}`}>
        <NavLink
          to={to}
          className={cx(
            sidebarEntryCSS,
            "hover:text-transparent",
            { ["font-bold"]: isActive },
            className,
          )}
        >
          {label}
          {shortcutHint && <ShortcutHint hint={shortcutHint} />}
        </NavLink>
      </List.Entry>
    </li>
  );
};
