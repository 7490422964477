import { UnreachableCaseError } from "libs/errors";
import { getRecordId, SubscriptionPreference } from "libs/schema";
import { op } from "libs/transaction";
import { toast } from "~/environment/toast-service";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { runTransaction } from "./write";

export function updateTagSubscription(args: {
  tagId: string;
  preference: SubscriptionPreference;
}) {
  return runTransaction({
    tx: async (transaction) => {
      switch (args.preference) {
        case "all": {
          toast("vanilla", {
            subject: `Subscribed to All.`,
            description: `
              You will receive a notification for every message 
              in this channel.
            `,
          });
          break;
        }
        case "all-new": {
          toast("vanilla", {
            subject: `Subscribed.`,
            description: `
              You will receive a notification for every new
              thread created in this channel.
            `,
          });
          break;
        }
        case "involved": {
          toast("vanilla", {
            subject: `Unsubscribed.`,
            description: `
              You will only receive notifications for 
              threads you are participating or @mentioned in.
            `,
          });
          break;
        }
        default: {
          throw new UnreachableCaseError(args.preference);
        }
      }

      const currentUser = getAndAssertCurrentUser();

      transaction.operations.push(
        op.set("tag_subscription", {
          id: getRecordId("tag_subscription", {
            tag_id: args.tagId,
            user_id: currentUser.id,
          }),
          creator_user_id: currentUser.id,
          owner_organization_id: currentUser.owner_organization_id,
          preference: args.preference,
          tag_id: args.tagId,
          user_id: currentUser.id,
        }),
      );
    },
  });
}
