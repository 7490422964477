import { Context, useContext } from "react";

export function createUseContextHook<T>(context: Context<T>, name: string) {
  return () => {
    const c = useContext(context);

    if (c === null || c === undefined) {
      throw new Error(`Oops! You need to provide ${name}`);
    }

    return c as NonNullable<T>;
  };
}
