import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { NewUserDialogState } from "~/dialogs/user-new/NewUserDialog";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { setIsLoading } from "~/environment/loading.service";
import { ILocation, navigateService } from "~/environment/navigate.service";
import { getCurrentUserId, signout } from "~/environment/user.service";
import { auth } from "~/firebase";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";

export const signinWithGoogle = onlyCallFnOnceWhilePreviousCallIsPending(
  setIsLoading(async (redirectTo?: ILocation) => {
    // Set language to the default browser preference
    auth.useDeviceLanguage();

    if (
      import.meta.env.VITE_FIREBASE_EMULATORS === "true" &&
      !navigator.onLine
    ) {
      throw new Error("Not implemented");
    } else {
      const signinPromise = signInWithPopup(auth, new GoogleAuthProvider());

      const credential = await signinPromise
        .then(GoogleAuthProvider.credentialFromResult)
        .catch(GoogleAuthProvider.credentialFromError);

      if (!credential?.accessToken) return;

      const env = getEnvironment();

      const response = await env.api.login({
        accessToken: credential.accessToken,
        providerId: credential.providerId,
      });

      if (response.status !== 200) {
        return;
      }

      const { recordMap } = response.body;

      await env.db.writeRecordMap(recordMap);

      const currentUserId = getCurrentUserId();

      if (!currentUserId) {
        console.warn("Login appears to have failed.");
        return;
      }

      const [user] = await env.recordLoader.getRecord(
        { table: "user_profile", id: currentUserId },
        { fetchStrategy: "cache-only" },
      );

      if (user) {
        if (user.is_deleted) {
          // This shouldn't happen, but just in case.
          await signout();
          return;
        }

        navigateService(redirectTo || "/inbox", { replace: true });
        return;
      }

      NewUserDialogState.open({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        interruptTextMessages: false,
      });
    }
  }),
);

// // Only used for debugging purposes with permission from Sam
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// async function impersonateUser() {
//   const { data } = await httpsCallable<{ code: string }, { token: string }>(
//     functions,
//     "impersonateUser",
//   )({ code: import.meta.env.VITE_IMPERSONATE_USER_CODE });

//   return signInWithCustomToken(auth, data.token);
// }
