import {
  PointerWithRecord,
  RecordTable,
  RecordValue,
  tableProps,
} from "libs/schema";
import { sql, Sql } from "libs/sql-statement";

/* -------------------------------------------------------------------------------------------------
 * getSqlToUpsertRecord
 * -------------------------------------------------------------------------------------------------
 */

/**
 * @returns a Sql object containing a SQL statement and params for upserting the
 *   given record to postgres.
 */
export function getSqlToUpsertRecord<T extends RecordTable>(
  pointerWithRecord: PointerWithRecord<T>,
  /**
   * Unless `true`, updates will be ignored unless the existing record version
   * is less than the incoming record version.
   */
  forceUpdate: boolean,
): Sql {
  return getUpsertFn(
    pointerWithRecord.table,
    pointerWithRecord.record,
    forceUpdate,
  );
}

function getUpsertFn<T extends RecordTable>(
  table: T,
  record: RecordValue<T>,
  forceUpdate: boolean,
) {
  const keys = tableProps[table] as Array<keyof RecordValue<T> & string>;

  console.log("record", record);

  const filteredKeys = keys.filter(
    (prop) => (record as RecordValue<T>)[prop] !== undefined,
  );

  return sql`
    INSERT INTO "${sql.raw(table)}" 
      (${sql.join(
        filteredKeys.map((k) => sql.raw(`"${k}"`)),
        ",\n",
      )}) 
    VALUES (
      ${sql.join(
        filteredKeys.map((prop) => (record as RecordValue<T>)[prop]),
        ",\n",
      )}
    )
    ON CONFLICT (id) 
    DO UPDATE
    SET
      ${sql.join(
        filteredKeys.map(
          (prop) =>
            sql`"${sql.raw(prop)}" = ${(record as RecordValue<T>)[prop]}`,
        ),
        ",\n",
      )}
    ${
      forceUpdate
        ? sql.EMPTY
        : sql`WHERE "${sql.raw(table)}".version < ${record.version}`
    };
  `;
}
