import { RecordValue } from "libs/schema";
import { startWith } from "@libs/utils/rxjs-operators";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { observeRecord } from "./observeRecord";
import { observeTagFolderAncestors } from "./observeTagFolderAncestors";

export type ObserveTagFolderAncestorRecordResult = {
  folderPaths: RecordValue<"tag">[][];
  isLoading: boolean;
};

/** Like `observeTagFolderAncestors` but returns whole records */
export function observeTagFolderAncestorRecords(props: {
  tagId: string;
  maxDepth?: number;
  /**
   * If true, then this observable will emit an updated value as new folders
   * load. Otherwise, this observable will only emit once all folders have loaded.
   */
  emitIncrementally?: boolean;
}): Observable<ObserveTagFolderAncestorRecordResult> {
  const { tagId, maxDepth = Infinity, emitIncrementally = false } = props;

  return observeTagFolderAncestors({
    tagId,
    maxDepth,
    emitIncrementally,
  }).pipe(
    switchMap(({ folderPaths, isLoading }) => {
      if (folderPaths.length === 0) return of({ folderPaths: [], isLoading });

      const observe = (path: string[]) =>
        path.length === 0
          ? of({ records: [], isLoading: false })
          : combineLatest(
              path.map((folderId) =>
                observeRecord({ table: "tag", id: folderId }),
              ),
            ).pipe(
              map((records) => {
                let isSomeRecordLoading = false;
                const results: RecordValue<"tag">[] = [];

                for (const { record, isLoading } of records) {
                  if (isLoading) isSomeRecordLoading = true;
                  if (!record) continue;
                  results.push(record);
                }

                return {
                  records: results,
                  isLoading: isSomeRecordLoading,
                };
              }),
            );

      const observables = !emitIncrementally
        ? folderPaths.map(observe)
        : folderPaths.map((path) =>
            observe(path).pipe(
              startWith(() => ({ records: [], isLoading: true })),
            ),
          );

      return combineLatest(observables).pipe(
        map((paths) => {
          let isSomeAncestorLoading = false;
          const results: RecordValue<"tag">[][] = [];

          for (const { records, isLoading } of paths) {
            if (isLoading) isSomeAncestorLoading = true;
            if (!records) continue;
            results.push(records);
          }

          return {
            folderPaths: results,
            isLoading: isSomeAncestorLoading || isLoading,
          };
        }),
      );
    }),
  );
}
