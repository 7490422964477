export * from "./context";
export * from "./utils";
export type { IListProps, IListRef } from "./List";

export type { IListEntryProps } from "./Entry";

export { ListScrollbox, useListScrollboxContext } from "./ListScrollbox";

import { List as _List } from "./List";
import { Entry } from "./Entry";

/**
 * Keyboard Accessible List component which allows focusing entries via
 * ArrowUp and ArrowDown. Remembers last focused element and returns focus to it when
 * tabbing from another element to this list.
 *
 * Example:
 *
 * ```ts
 * <List<IPostDoc>>
 *   <ul>
 *     {posts.map((post) => (
 *       <li>
 *         <List.Entry<IPostDoc>
 *           key={post.id}
 *           id={post.id}
 *           data={post}
 *         >
 *           <Post post={post} />
 *         </List.Entry>
 *       </li>
 *     ))}
 *   </ul>
 * </List>
 * ```
 */
const List = _List as typeof _List & {
  /**
   * An `<Entry>` for the `<List>` component. The entry component
   * expects a single child which accepts props for
   * - tabIndex
   * - onFocus
   * - onBlur
   * - onKeyDown
   * - onClick
   *
   * These props will be automatically applied by the `<List.Entry`
   * component. The easiest way to fulfill this requirement is for
   * the entry's child to spread any additional, provided props on it's
   * own root element.
   *
   * Example:
   *
   * ```ts
   * <List<IPostDoc>>
   *   <ul>
   *     {posts.map((post) => (
   *       <li>
   *         <List.Entry<IPostDoc>
   *           key={post.id}
   *           id={post.id}
   *           data={post}
   *         >
   *           <Post post={post} />
   *         </List.Entry>
   *       </li>
   *     ))}
   *   </ul>
   * </List>
   * ```
   */
  Entry: typeof Entry;
};

List.Entry = Entry;

export { List };
