import { RecordValue } from "libs/schema";
import { isNonNullable } from "@libs/utils/predicates";
import { getTagFolderAncestors } from "./getTagFolderAncestors";
import { FetchStrategy } from "~/environment/RecordLoader";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";

export type GetTagFolderAncestorRecordResult = {
  folderPaths: RecordValue<"tag">[][];
};

/** Like `observeTagFolderAncestors` but returns whole records */
export async function getTagFolderAncestorRecords(props: {
  tagId: string;
  maxDepth?: number;
  /** "cache-then-network" is the default */
  fetchStrategy?: FetchStrategy;
}) {
  const { tagId, maxDepth = Infinity, fetchStrategy } = props;
  const { recordLoader } = getEnvironment();

  const { folderPaths } = await getTagFolderAncestors({
    tagId,
    maxDepth,
    fetchStrategy,
  });

  if (folderPaths.length === 0) return { folderPaths: [] };

  const paths = await Promise.all(
    folderPaths.map(async (path) => {
      const records = await Promise.all(
        path.map(async (folderId) => {
          const [tag] = await recordLoader.getRecord(
            { table: "tag", id: folderId },
            { fetchStrategy },
          );

          return tag;
        }),
      );

      return records.filter(isNonNullable);
    }),
  );

  return {
    folderPaths: paths.filter((path) => path.length > 0),
  };
}
