import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { observeIsTagPrivate } from "~/observables/observeIsTagPrivate";

const DEFAULT_VALUE = Object.freeze({ isTagPrivate: false, isLoading: true });

export function useIsTagPrivate(tagId: string | null | undefined) {
  const query = useObservable(
    (input$) =>
      input$.pipe(
        switchMap(([tagId]) => {
          if (!tagId) return of({ isTagPrivate: false, isLoading: false });
          return observeIsTagPrivate(tagId);
        }),
      ),
    [tagId],
  );

  return useObservableState(query, DEFAULT_VALUE);
}
