import { ICommandArgs, callCommandById } from "~/environment/command.service";
import {
  navigateService,
  navigateBackOrToInbox,
  getMostRecentInboxPagePathname,
} from "~/environment/navigate.service";
import { SetOptional } from "type-fest";
import { KBarState } from "~/dialogs/kbar";
import { INotificationDoc, IThreadDoc } from "@libs/firestore-models";
import { triageThread } from "~/actions/triageThread";
import { updateTagSubscription } from "~/actions/updateTagSubscription";
import { toast } from "~/environment/toast-service";
import { updateThreadSubscription } from "~/actions/updateThreadSubscription";

export const ESCAPE_TO_INBOX_COMMAND: ICommandArgs = {
  id: "Escape to Inbox",
  label: "Go to Inbox",
  hotkeys: ["Escape"],
  showInKBar: false,
  triggerHotkeysWhenInputFocused: true,
  callback: () => {
    navigateService(getMostRecentInboxPagePathname());
  },
};

export const ESCAPE_TO_BACK_COMMAND: ICommandArgs = {
  id: "Escape to Back",
  label: "Go Back",
  hotkeys: ["Escape"],
  callback: () => {
    navigateBackOrToInbox();
  },
};

export function getCommandFactory<
  A extends string,
  B extends (options: ICommandArgs) => ICommandArgs,
>(id: A, fn: B) {
  const wrappedFn = ((options, ...args) =>
    fn({ id, ...options }, ...args)) as B & { id: A; trigger(): void };

  wrappedFn.id = id;

  wrappedFn.trigger = () => callCommandById(id);

  return wrappedFn;
}

/**
 * Used to navigate "back" to the most recent route which is not
 * rendered by the current view.
 *
 * E.g. if viewing a specific thread (i.e. `/threads/fdlaskfj2343j90jfaf`),
 * then you are on the ThreadView. You might use this command to navigate you back
 * to the most recent route which does not start with `/threads`/.
 *
 * Note from John: I'm open to suggestions for a better name for
 * this command.
 */
export const closeViewCommand = getCommandFactory(
  "CLOSE_VIEW",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Close view",
    hotkeys: ["Escape"],
    ...options,
  }),
);

export const openHelpCommand = getCommandFactory(
  "OPEN_HELP",
  (options: SetOptional<ICommandArgs, "label" | "hotkeys">): ICommandArgs => ({
    label: "Open help",
    hotkeys: ["?", "Shift+?"],
    ...options,
  }),
);

export const composeMessageCommand = getCommandFactory(
  "COMPOSE_NEW_MESSAGE",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => ({
    label: "Compose message",
    altLabels: ["New post", "New message", "Compose post"],
    hotkeys: ["c"],
    ...options,
  }),
);

export const composeEmailCommand = getCommandFactory(
  "COMPOSE_NEW_EMAIL",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => ({
    label: "Compose email",
    altLabels: ["New email", "Compose email"],
    ...options,
  }),
);

export const deleteDraftCommand = getCommandFactory(
  "DELETE_DRAFT",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => ({
    label: "Delete draft",
    altLabels: ["Discard draft"],
    hotkeys: [
      "$mod+Shift+,",
      // on windows shift+, results in an event for the "<" key
      // whereas on macos it results in an event for the "," key
      "$mod+Shift+Comma",
    ],
    ...options,
  }),
);

export const expandAllMessagesCommand = getCommandFactory(
  "EXPAND_ALL_MESSAGES",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels">,
  ): ICommandArgs => ({
    label: "Expand all messages",
    altLabels: ["Expand all posts"],
    ...options,
  }),
);

export const collapseAllMessagesCommand = getCommandFactory(
  "COLLAPSE_ALL_MESSAGES",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels">,
  ): ICommandArgs => ({
    label: "Collapse all messages",
    altLabels: ["Collapse all posts"],
    ...options,
  }),
);

export const updateTagSubscriptionCommand = getCommandFactory(
  "UPDATE_TAG_SUBSCRIPTION",
  (
    options?: SetOptional<
      ICommandArgs,
      "label" | "hotkeys" | "closeKBarOnSelect" | "callback"
    >,
  ): ICommandArgs => {
    return {
      label: "Update subscription...",
      hotkeys: ["s"],
      closeKBarOnSelect: false,
      callback() {
        KBarState.open({
          path: ["Update subscription"],
          mode: "hotkey",
        });
      },
      ...options,
    };
  },
);

export const subscribeToTagCommand = getCommandFactory(
  "SUBSCRIBE_TO_TAG",
  (
    options: SetOptional<
      ICommandArgs,
      "id" | "label" | "keywords" | "path" | "hotkeys"
    >,
  ): ICommandArgs => {
    return {
      id: "subscribeToTagCommand",
      label: (
        <span>
          Subscribe{" "}
          <span className="text-slate-9">
            (get notifications for new threads)
          </span>
        </span>
      ),
      keywords: [`Subscribe (get notifications for new threads)`],
      path: ["Update subscription"],
      hotkeys: ["s"],
      ...options,
    };
  },
);

export const subscribeAllToTagCommand = getCommandFactory(
  "SUBSCRIBE_ALL_TO_TAG",
  (
    options: SetOptional<
      ICommandArgs,
      "id" | "label" | "keywords" | "path" | "hotkeys"
    >,
  ): ICommandArgs => {
    return {
      id: "subscribeAllToTagCommand",
      label: (
        <span>
          Subscribe to all{" "}
          <span className="text-slate-9">(get all notifications)</span>
        </span>
      ),
      keywords: [`Subscribe to all (get all notifications)`],
      path: ["Update subscription"],
      hotkeys: ["a"],
      ...options,
    };
  },
);

export const unsubscribeFromTagCommand = getCommandFactory(
  "UNSUBSCRIBE_FROM_TAG",
  (
    options: SetOptional<
      ICommandArgs,
      "id" | "label" | "keywords" | "path" | "hotkeys"
    >,
  ): ICommandArgs => {
    return {
      id: "unsubscribeFromTagCommand",
      label: (
        <span>
          Unsubscribe{" "}
          <span className="text-slate-9">
            (only get notifications if @mentioned or participating)
          </span>
        </span>
      ),
      keywords: [
        `Unsubscribe (only get notifications if @mentioned or participating)`,
      ],
      path: ["Update subscription"],
      hotkeys: ["u"],
      ...options,
    };
  },
);

export function tagSubscriptionCommands(tagId: string | null) {
  const commands: ICommandArgs[] = [];

  if (!tagId) {
    const noTagFocusedWarning = () => {
      toast("vanilla", {
        subject: "No group/tag focused",
        description: `
          You must first focus an entry by using the arrow
          keys or hovering over it with your mouse.
        `,
      });
    };

    commands.push(
      updateTagSubscriptionCommand({
        callback: noTagFocusedWarning,
      }),
      subscribeToTagCommand({
        callback: noTagFocusedWarning,
      }),
      unsubscribeFromTagCommand({
        callback: noTagFocusedWarning,
      }),
    );
  } else {
    commands.push(
      updateTagSubscriptionCommand(),
      subscribeToTagCommand({
        callback: () => {
          updateTagSubscription({
            tagId,
            preference: "all-new",
          });
        },
      }),
      subscribeAllToTagCommand({
        callback: () => {
          updateTagSubscription({
            tagId,
            preference: "all",
          });
        },
      }),
      unsubscribeFromTagCommand({
        callback: () => {
          updateTagSubscription({
            tagId,
            preference: "involved",
          });
        },
      }),
    );
  }

  return commands;
}

export const updateThreadSubscriptionCommand = getCommandFactory(
  "UPDATE_THREAD_SUBSCRIPTION",
  (
    options?: SetOptional<
      ICommandArgs,
      "label" | "hotkeys" | "closeKBarOnSelect" | "callback"
    >,
  ): ICommandArgs => {
    return {
      label: "Update subscription...",
      altLabels: ["Edit subscription..."],
      hotkeys: ["s"],
      closeKBarOnSelect: false,
      callback: () => {
        KBarState.open({
          path: ["Update subscription"],
          mode: "hotkey",
        });
      },
      ...options,
    };
  },
);

export const subscribeToThreadCommand = getCommandFactory(
  "SUBSCRIBE_TO_THREAD",
  (
    options: SetOptional<ICommandArgs, "label" | "path" | "hotkeys">,
  ): ICommandArgs => {
    return {
      label: `Subscribe to thread`,
      path: ["Update subscription"],
      hotkeys: ["s"],
      ...options,
    };
  },
);

export const unsubscribeFromThreadCommand = getCommandFactory(
  "UNSUBSCRIBE_FROM_THREAD",
  (
    options: SetOptional<ICommandArgs, "label" | "path" | "hotkeys">,
  ): ICommandArgs => {
    return {
      label: `Unsubscribe from thread`,
      path: ["Update subscription"],
      hotkeys: ["u"],
      ...options,
    };
  },
);

export const threadSubscriptionCommands = ({
  threadId,
  notification,
}: {
  threadId?: IThreadDoc["id"] | null;
  notification?: INotificationDoc | null;
}) => {
  const commands: ICommandArgs[] = [];

  if (!threadId) {
    const noThreadFocusedWarning = () => {
      toast("vanilla", {
        subject: "No thread focused",
        description: `
          You must first focus a thread entry by using the arrow
          keys or hovering over it with your mouse.
        `,
      });
    };

    commands.push(
      updateThreadSubscriptionCommand({
        callback: noThreadFocusedWarning,
      }),
      subscribeToThreadCommand({
        callback: noThreadFocusedWarning,
      }),
      unsubscribeFromThreadCommand({
        callback: noThreadFocusedWarning,
      }),
    );
  } else {
    commands.push(
      updateThreadSubscriptionCommand(),
      subscribeToThreadCommand({
        callback: () => {
          updateThreadSubscription({
            threadId,
            preference: "all",
          });
        },
      }),
      unsubscribeFromThreadCommand({
        callback: () => {
          updateThreadSubscription({
            threadId,
            preference: "involved",
          });
        },
      }),
    );

    if (notification?.isStarred) {
      commands.push(
        unstarThreadCommand({
          label: `Unstar thread`,
          path: ["Update subscription"],
          hotkeys: ["r"],
          callback: () => {
            triageThread({
              threadId,
              isStarred: false,
            });
          },
        }),
      );
    } else if (notification !== undefined) {
      commands.push(
        starThreadCommand({
          label: `Star thread`,
          path: ["Update subscription"],
          hotkeys: ["r"],
          callback: () => {
            triageThread({
              threadId,
              isStarred: true,
            });
          },
        }),
      );
    }
  }

  return commands;
};

export const markDoneCommand = getCommandFactory(
  "MARK_THREAD_DONE",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => {
    return {
      label: "Mark done",
      altLabels: ["Move to Done"],
      hotkeys: ["e"],
      ...options,
    };
  },
);

export const markNotDoneCommand = getCommandFactory(
  "MARK_THREAD_NOT_DONE",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => {
    return {
      label: "Mark not done",
      altLabels: ["Move to Inbox"],
      hotkeys: ["Shift+E"],
      ...options,
    };
  },
);

export const setThreadReminderCommand = getCommandFactory(
  "SET_THREAD_REMINDER",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => {
    return {
      label: "Remind me",
      altLabels: [
        "Set reminder",
        "Snooze thread",
        "Snooze notification",
        "Update reminder",
        "Edit reminder",
        "Update snooze",
        "Edit snooze",
      ],
      hotkeys: ["h"],
      ...options,
    };
  },
);

export const removeThreadReminderCommand = getCommandFactory(
  "REMOVE_THREAD_REMINDER",
  (
    options: SetOptional<ICommandArgs, "label" | "altLabels" | "hotkeys">,
  ): ICommandArgs => {
    return {
      label: "Remove reminder",
      altLabels: [
        "Delete reminder",
        "Unsnooze thread",
        "Unsnooze notification",
        "Remove snooze",
        "Delete snooze",
      ],
      hotkeys: ["Shift+H"],
      ...options,
    };
  },
);

export const starThreadCommand = getCommandFactory(
  "STAR_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => {
    return {
      label: "Star",
      keywords: ["Unstar", "Remove star", "Mark unstarred"],
      altLabels: ["Mark starred"],
      ...options,
    };
  },
);

export const unstarThreadCommand = getCommandFactory(
  "UNSTAR_THREAD",
  (options: SetOptional<ICommandArgs, "label" | "altLabels">): ICommandArgs => {
    return {
      label: "Unstar",
      keywords: ["Star", "Mark starred"],
      altLabels: ["Remove star", "Mark unstarred"],
      ...options,
    };
  },
);

export const closeDialogCommand = getCommandFactory(
  "CLOSE_DIALOG",
  (
    options: SetOptional<
      ICommandArgs,
      "label" | "hotkeys" | "triggerHotkeysWhenInputFocused"
    >,
  ): ICommandArgs => {
    return {
      label: "Close dialog",
      hotkeys: ["Escape"],
      triggerHotkeysWhenInputFocused: true,
      ...options,
    };
  },
);

export const deliverMessagesNowCommand = getCommandFactory(
  "DELIVER_MESSAGES_NOW",
  (options: SetOptional<ICommandArgs, "label">): ICommandArgs => {
    return {
      label: "Deliver messages now",
      ...options,
    };
  },
);
