import { UnreachableCaseError } from "libs/errors";
import { parseStringToEmailAddress } from "libs/parseEmailAddress";
import { RecordValue } from "libs/schema";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useMessage } from "./useMessage";
import { useUserProfile } from "./useUserProfile";

export function useMessageSender(messageId?: string | null) {
  const { currentUser } = useAuthGuardContext();
  const [message] = useMessage(messageId);
  const [sender] = useUserProfile(message?.sender_user_id);

  if (!message) return null;

  const label = getMessageSenderName({
    message,
    sender,
    currentUserId: currentUser?.id || null,
  });

  if (!label) return null;

  return {
    label,
    name: sender?.name || null,
    firstName: sender?.first_name || null,
    middleName: sender?.middle_name || null,
    lastName: sender?.last_name || null,
    photoUrl: sender?.photo_url || null,
  };
}

/**
 * If a currentUserId is provided and if the post was created by the current
 * user, then "Me" is returned.
 */
function getMessageSenderName(args: {
  message: RecordValue<"message">;
  sender?: RecordValue<"user_profile"> | null;
  currentUserId?: string | null;
}): string | undefined {
  const { message, sender, currentUserId } = args;

  if (currentUserId && message.sender_user_id === currentUserId) {
    return "Me";
  }

  switch (message.type) {
    case "COMMS": {
      // Comms messages will always have a sender
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return sender?.name;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      if (sender) {
        return sender.name;
      }

      if (!message.email_sender) return;

      const emailSender = parseStringToEmailAddress(message.email_sender);

      return emailSender?.label || emailSender?.address;
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}
