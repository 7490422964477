import {
  getDatabaseDecoderBases,
  NonNullableJsonD,
  RecordTable,
  tablePropsMap,
} from "libs/schema";
import { assert } from "ts-decoders";
import * as d from "ts-decoders/decoders";
import { JsonValue } from "type-fest";

/**
 * The initial schema for our SQLite database. The database is stored
 * in-memory, so this schema is loaded from scratch every time the
 * app is booted up.
 */
export const schema = `
-- CreateTable
CREATE TABLE
    "attachment" (
        "id" TEXT NOT NULL,
        "filename" TEXT NOT NULL,
        "content_disposition" TEXT,
        "content_type" TEXT NOT NULL,
        "url" TEXT NOT NULL,
        "size" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "attachment_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "auth_token" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "expires_at" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "auth_token_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "draft_email_recipient" (
        "id" TEXT NOT NULL,
        "draft_id" TEXT NOT NULL,
        "draft_user_id" TEXT NOT NULL,
        "email_address" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "is_implicit" INTEGER NOT NULL,
        "is_mentioned" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "draft_email_recipient_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "draft_group_recipient" (
        "id" TEXT NOT NULL,
        "draft_id" TEXT NOT NULL,
        "draft_user_id" TEXT NOT NULL,
        "group_id" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "is_implicit" INTEGER NOT NULL,
        "is_mentioned" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "draft_group_recipient_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "draft_tag" (
        "id" TEXT NOT NULL,
        "draft_id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "draft_user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "draft_tag_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "draft_user_recipient" (
        "id" TEXT NOT NULL,
        "draft_id" TEXT NOT NULL,
        "recipient_user_id" TEXT NOT NULL,
        "draft_user_id" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "is_implicit" INTEGER NOT NULL,
        "is_mentioned" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "draft_user_recipient_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "draft" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "new_thread_subject" TEXT,
        "new_thread_visibility" TEXT,
        "branched_from_thread_id" UUID,
        "branched_from_message_id" UUID,
        "branched_from_message_sent_at" TIMESTAMP(6),
        "branched_from_message_scheduled_to_be_sent_at" TIMESTAMP(6),
        "is_reply" INTEGER NOT NULL,
        "body_html" TEXT NOT NULL,
        "sent" INTEGER NOT NULL,
        "sent_at" TIMESTAMP(6),
        "scheduled_to_be_sent" INTEGER NOT NULL,
        "scheduled_to_be_sent_at" TIMESTAMP(6),
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "draft_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "inbox_section" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "name" TEXT NOT NULL,
        "order" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "inbox_section_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "inbox_subsection" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "inbox_section_id" TEXT NOT NULL,
        "name" TEXT NOT NULL,
        "order" INTEGER NOT NULL,
        "description" TEXT,
        "query" TEXT NOT NULL,
        "parsed_query" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "inbox_subsection_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "user_lesson" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "lesson_id" TEXT NOT NULL,
        "lesson_version" INTEGER NOT NULL,
        "is_completed" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "user_lesson_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "mention_frequency" (
        "id" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "subject_id" TEXT NOT NULL,
        "frequency" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "mention_frequency_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message_attachment" (
        "id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "attachment_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_attachment_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message_email_recipient" (
        "id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "email_address" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "is_implicit" INTEGER NOT NULL,
        "is_mentioned" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_email_recipient_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message_email_reference" (
        "id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "email_message_id" TEXT NOT NULL,
        "order" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_email_reference_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message_group_recipient" (
        "id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "group_id" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "is_implicit" INTEGER NOT NULL,
        "is_mentioned" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_group_recipient_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message_user_reaction" (
        "id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "reactions" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_user_reaction_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message_user_recipient" (
        "id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "is_implicit" INTEGER NOT NULL,
        "is_mentioned" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_user_recipient_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "message" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "is_reply" INTEGER NOT NULL,
        "subject" TEXT NOT NULL,
        "body_text" TEXT NOT NULL,
        "body_html" TEXT NOT NULL,
        "sent_at" TEXT NOT NULL,
        "scheduled_to_be_sent_at" TEXT NOT NULL,
        "timeline_order" TEXT NOT NULL,
        "sender_user_id" UUID,
        "was_edited" INTEGER NOT NULL,
        "last_edited_at" TIMESTAMP(6),
        "reactions" TEXT NOT NULL,
        "email_message_id" TEXT,
        "email_sender" TEXT,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "message_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "notification_tag" (
        "id" TEXT NOT NULL,
        "notification_id" TEXT NOT NULL,
        "notification_user_id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "notification_tag_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "notification" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "message_type" TEXT NOT NULL,
        "is_done" INTEGER NOT NULL,
        "done_at" TIMESTAMP(6),
        "done_last_modified_by" TEXT NOT NULL,
        "oldest_sent_at_value_not_marked_done" TIMESTAMP(6),
        "sent_at" TEXT NOT NULL,
        "priority" INTEGER NOT NULL,
        "has_reminder" INTEGER NOT NULL,
        "remind_at" TIMESTAMP(6),
        "is_starred" INTEGER NOT NULL,
        "starred_at" TIMESTAMP(6),
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "notification_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "organization_profile" (
        "id" TEXT NOT NULL,
        "name" TEXT NOT NULL,
        "name_short" TEXT NOT NULL,
        "photo_url" TEXT,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "organization_profile_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "organization_user_invitation" (
        "id" TEXT NOT NULL,
        "organization_id" TEXT NOT NULL,
        "email_address" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "expires_at" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "organization_user_invitation_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "organization_user_member" (
        "id" TEXT NOT NULL,
        "organization_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "organization_user_member_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "organization_controlled_domain" (
        "id" TEXT NOT NULL,
        "domain" TEXT NOT NULL,
        "organization_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "organization_controlled_domain_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "organization" (
        "id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "organization_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "provider_email_map" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "provider" TEXT NOT NULL,
        "provider_id" TEXT NOT NULL,
        "provider_thread_id" TEXT NOT NULL,
        "email_message_id" TEXT NOT NULL,
        "message_id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "provider_email_map_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "tag_folder_member" (
        "id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "folder_id" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "tag_folder_member_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "tag_group_member" (
        "id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "group_id" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "tag_group_member_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "tag_subscription" (
        "id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "preference" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "tag_subscription_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "tag_user_member" (
        "id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "tag_user_member_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "tag" (
        "id" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "name" TEXT NOT NULL,
        "icon" TEXT,
        "description" TEXT,
        "data" TEXT,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "tag_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_group_permission" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "group_id" TEXT NOT NULL,
        "start_at" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_group_permission_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_read_receipt" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "read_to_timeline_id" TEXT NOT NULL,
        "read_to_timeline_order" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_read_receipt_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_seen_receipt" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "seen_to_timeline_id" TEXT NOT NULL,
        "seen_to_timeline_order" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_seen_receipt_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_subscription" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "preference" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_subscription_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_tag" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "tag_id" TEXT NOT NULL,
        "creator_user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_tag_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_timeline" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "order" TEXT NOT NULL,
        "data" TEXT,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_timeline_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_user_participant" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_user_participant_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread_user_permission" (
        "id" TEXT NOT NULL,
        "thread_id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "start_at" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_user_permission_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "thread" (
        "id" TEXT NOT NULL,
        "type" TEXT NOT NULL,
        "subject" TEXT NOT NULL,
        "visibility" TEXT NOT NULL,
        "first_message_id" TEXT NOT NULL,
        "first_message_sent_at" TEXT NOT NULL,
        "first_message_scheduled_to_be_sent_at" TEXT NOT NULL,
        "last_message_id" TEXT NOT NULL,
        "last_message_sent_at" TEXT NOT NULL,
        "last_message_scheduled_to_be_sent_at" TEXT NOT NULL,
        "first_timeline_id" TEXT NOT NULL,
        "first_timeline_order" TEXT NOT NULL,
        "last_timeline_id" TEXT NOT NULL,
        "last_timeline_order" TEXT NOT NULL,
        "is_branch" INTEGER NOT NULL,
        "branched_from_thread_id" UUID,
        "branched_from_message_id" UUID,
        "branched_from_message_sent_at" TIMESTAMP(6),
        "branched_from_message_scheduled_to_be_sent_at" TIMESTAMP(6),
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "thread_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "user_contact_info" (
        "id" TEXT NOT NULL,
        "email_address" CITEXT NOT NULL,
        "phone_number" TEXT,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "user_contact_info_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "user_oauth" (
        "id" TEXT NOT NULL,
        "user_id" TEXT NOT NULL,
        "provider" TEXT NOT NULL,
        "federated_id" TEXT NOT NULL,
        "oauth_id_token" TEXT,
        "oauth_access_token" TEXT,
        "firebase_auth_id" TEXT NOT NULL,
        "firebase_id_token" TEXT NOT NULL,
        "firebase_id_token_expires_at" TEXT NOT NULL,
        "firebase_refresh_token" TEXT NOT NULL,
        "is_linked_to_user" INTEGER NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "user_oauth_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "user_profile" (
        "id" TEXT NOT NULL,
        "first_name" TEXT NOT NULL,
        "middle_name" TEXT,
        "last_name" TEXT NOT NULL,
        "photo_url" TEXT,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "user_profile_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "user_settings" (
        "id" TEXT NOT NULL,
        "settings" TEXT NOT NULL,
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "user_settings_pkey" PRIMARY KEY ("id")
    );

-- CreateTable
CREATE TABLE
    "user" (
        "id" TEXT NOT NULL,
        "firebase_auth_id" TEXT NOT NULL,
        "email" CITEXT NOT NULL,
        "email_verified" INTEGER NOT NULL,
        "email_verified_at" TIMESTAMP(6),
        "owner_organization_id" TEXT NOT NULL,
        "version" INTEGER NOT NULL,
        "created_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "updated_at" TEXT NOT NULL DEFAULT CURRENT_TIMESTAMP,
        "is_deleted" INTEGER NOT NULL DEFAULT 0,
        CONSTRAINT "user_pkey" PRIMARY KEY ("id")
    );

-- CreateIndex
CREATE UNIQUE INDEX "user_contact_info_email_address_key" ON "user_contact_info" ("email_address");

-- CreateIndex
CREATE UNIQUE INDEX "user_email_key" ON "user" ("email");

-- Ensure the name column exists
ALTER TABLE user_profile ADD COLUMN name TEXT;

-- Create the trigger
CREATE TRIGGER update_name
AFTER UPDATE OF first_name, last_name ON user_profile
FOR EACH ROW
BEGIN
    UPDATE user_profile SET name = NEW.first_name || ' ' || NEW.last_name
    WHERE rowid = NEW.rowid;
END;
`;

const fromDatabaseDecoderBases = getDatabaseDecoderBases({
  BooleanD: d.numberD().map((i) => !!i),
  DateTimeD: d.stringD(),
  IntegerD: d.integerD(),
  JsonD: d.stringD().map((i) => JSON.parse(i) as JsonValue),
  TextD: d.stringD(),
  UuidD: d.stringD(),
});

export const fromDatabaseDecoders = Object.fromEntries(
  Object.entries(fromDatabaseDecoderBases).map(([table, base]) => [
    table,
    d.objectD(base),
  ]),
);

/**
 * Useful to decoding a SQL response that only returns a few columns
 * from a table.
 */
export const fromDatabasePartialDecoders = Object.fromEntries(
  Object.entries(fromDatabaseDecoderBases).map(([table, base]) => [
    table,
    d.objectD(
      Object.fromEntries(
        Object.entries(base).map(([prop, decoder]) => [
          prop,
          d.undefinableD(decoder),
        ]),
      ),
      { removeUndefinedProperties: true },
    ),
  ]),
);

const toDatabaseDecoderBases = getDatabaseDecoderBases({
  BooleanD: d.booleanD().map((i) => (i ? 1 : 0)),
  DateTimeD: d.stringD(),
  IntegerD: d.integerD(),
  JsonD: NonNullableJsonD.map((i) => (i === null ? null : JSON.stringify(i))),
  TextD: d.stringD(),
  UuidD: d.stringD(),
});

export const toDatabaseDecoders = Object.fromEntries(
  Object.entries(toDatabaseDecoderBases).map(([table, base]) => {
    // We want to make sure that optional properties are optional.
    // The tablePropsMap object is used to determine which properties
    // are required.
    const props = tablePropsMap[table as RecordTable];

    if (!props) {
      throw new Error(`"${table}" not in tablePropsMap`);
    }

    const newBase = Object.fromEntries(
      Object.entries(base).map(([prop, decoder]) => {
        const isRequired = (props as { [key: string]: boolean })[prop];

        return [prop, isRequired ? decoder : d.undefinableD(decoder)];
      }),
    );

    return [table, d.objectD(newBase, { removeUndefinedProperties: true })];
  }),
);

/**
 * Functions that map a client record to the expected database input.
 * Will throw an error if passed an unexpected/invalid value.
 */
export const recordToDatabaseFnMap = Object.fromEntries(
  Object.entries(toDatabaseDecoders).map(([table, decoder]) => [
    table,
    assert(decoder),
  ]),
);

/**
 * Used for decoding part of a record to the expected database input.
 * Useful in update operations.
 */
export const toDatabasePartialDecoders = Object.fromEntries(
  Object.entries(toDatabaseDecoderBases).map(([table, base]) => [
    table,
    d.objectD(
      Object.fromEntries(
        Object.entries(base).map(([prop, decoder]) => [
          prop,
          d.undefinableD(decoder),
        ]),
      ),
      { removeUndefinedProperties: true },
    ),
  ]),
);

/**
 * Functions that map a client record partial object to the expected
 * database input. Will throw an error if passed an unexpected/invalid value.
 */
export const partialRecordToDatabaseFnMap = Object.fromEntries(
  Object.entries(toDatabasePartialDecoders).map(([table, decoder]) => [
    table,
    assert(decoder),
  ]),
);
