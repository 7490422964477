import { forwardRef, useCallback, useEffect, useState } from "react";
import { IFormControl } from "solid-forms-react";
import {
  Root as CheckboxRoot,
  CheckboxIndicator,
  CheckedState,
} from "@radix-ui/react-checkbox";
import { css, cx } from "@emotion/css";
import { BsCheckLg } from "react-icons/bs";
import { blue, slate } from "@radix-ui/colors";
import { observable, useControlState } from "~/components/forms/utils";

export const CheckboxInput = forwardRef<
  HTMLButtonElement,
  {
    control: IFormControl<unknown>;
    id?: string;
    checkedValue: unknown;
    uncheckedValue: unknown;
    indeterminateValue?: unknown;
  }
>((props, ref) => {
  const controlValueAsCheckedState = useCallback(() => {
    return props.control.value === props.checkedValue
      ? true
      : props.control.value === props.indeterminateValue
      ? "indeterminate"
      : false;
  }, [props.control, props.checkedValue, props.indeterminateValue]);

  // We want the control to be able to store different values than just
  // `true, false, "indeterminate"` depending on the checked state, so
  // we're storing the underlying input's value in this useState hook.
  const [value, setValue] = useState<CheckedState>(
    controlValueAsCheckedState(),
  );

  // Respond to useState changes
  useEffect(() => {
    if (value === "indeterminate") {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      props.control.setValue(props.indeterminateValue!);
    } else if (value) {
      props.control.setValue(props.checkedValue);
    } else {
      props.control.setValue(props.uncheckedValue);
    }
  }, [
    props.control,
    value,
    props.checkedValue,
    props.uncheckedValue,
    props.indeterminateValue,
  ]);

  // Respond to control value changes
  useEffect(() => {
    const sub = observable(() => props.control.value).subscribe(() => {
      if (value === controlValueAsCheckedState()) return;

      setValue(controlValueAsCheckedState());
    });

    return () => sub.unsubscribe();
  }, [props.control, value, controlValueAsCheckedState]);

  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  return (
    <CheckboxRoot
      ref={ref}
      id={props.id}
      checked={value}
      onCheckedChange={setValue}
      className={cx(checkboxCSS, value && "checked")}
      onKeyDown={(e) => {
        if (e.key !== "Enter" || e.metaKey || e.shiftKey || e.ctrlKey) {
          return;
        }

        setValue((v) => (v === "indeterminate" ? true : !v));
        e.preventDefault();
      }}
      disabled={isDisabled}
    >
      <CheckboxIndicator className="CheckboxIndicator">
        <BsCheckLg />
      </CheckboxIndicator>
    </CheckboxRoot>
  );
});

const checkboxCSS = css`
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid ${slate.slate9};
  border-radius: 3px;

  &:focus {
    box-shadow: 0 0 0 2px ${blue.blue5};
  }

  &.checked {
    background-color: ${slate.slate9};

    .CheckboxIndicator {
      color: white;
    }
  }

  & .CheckboxIndicator {
    color: ${slate.slate9};
    transform: scale(0.8);
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;
