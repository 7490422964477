/* eslint-disable @typescript-eslint/ban-types */
import {
  createContext,
  ReactNode,
  useEffect,
  ComponentType,
  PropsWithChildren,
  useMemo,
  forwardRef,
  ForwardRefExoticComponent,
} from "react";
import {
  distinctUntilChanged,
  fromEvent,
  Observable,
  share,
  Subject,
} from "rxjs";
import { numberComparer } from "@libs/utils/comparers";
import uid from "@libs/utils/uid";
import { groupBy } from "lodash-comms";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";
import { updatableBehaviorSubject } from "@libs/utils/updatableBehaviorSubject";
import { UnreachableCaseError } from "@libs/utils/errors";
import { BsOption } from "react-icons/bs";
import { FiCommand } from "react-icons/fi";
import { ImCtrl } from "react-icons/im";
import { createKeybindingsHandler } from "@libs/tinykeys";
import { isEqual } from "@libs/utils/isEqual";

/* -------------------------------------------------------------------------------------------------
 * ICommandArgs
 * -----------------------------------------------------------------------------------------------*/

export interface ICommandArgs {
  id?: string;

  /** The default label/name of the command in the UI */
  label: string | ReactNode;

  /**
   * Keywords associated with the default label that will
   * be used to create a search index for this command.
   * Keywords are not visible in the UI. They only affect
   * filtering/searching for commands.
   */
  keywords?: string[];

  /**
   * An array of labels for this command. If the user is
   * viewing the command bar and hasn't typed anything into
   * the search bar, then only the default "label" prop
   * will be rendered and these alternate labels will be ignored.
   *
   * If the user has typed something into the command bar, then
   * these alternate labels will be combined with the default label
   * and only the best match will be rendered in the command list.
   */
  altLabels?: Array<
    | string
    | {
        /** The way this label should be rendered in the UI */
        render: string | ReactNode;

        /**
         * Keywords that will be used to create a search index
         * for this command. The "render" value will be added to
         * this list if it is a string.
         */
        keywords: string[];
      }
  >;

  /** Currently unused */
  icon?: ReactNode | null;
  /**
   * Optionally provide an array of strings to namespace
   * this command under. The hotkey(s) associated with
   * this command will only be active when this path is
   * active in the kbar.
   *
   * Provide the special value `["global"]` to make this
   * command available at all paths.
   */
  path?: string[];
  /**
   * An array of hotkey shortcuts that the user can use
   * to activate this command. If multiple hotkeys are
   * provided for a single command, in general to UI
   * will only provide the first option to users as a
   * hint (though both options will work).
   *
   * e.g. ["g i", "Shift+E"]
   */
  hotkeys?: string[];
  triggerHotkeysWhenInputFocused?: boolean;
  showInKBar?: boolean;
  closeKBarOnSelect?: boolean;
  /**
   * By default, `event.preventDefault()` will be called on the
   * event which triggered the callback. Return `false` from the
   * callback to stop this from happening.
   */
  callback: CommandCallback;
}

export type CommandCallback = (
  /**
   * A KeyboardEvent is only provided when the command is
   * triggered by a hotkey/shortcut.
   */
  event?: KeyboardEvent,
) => boolean | void | Promise<void>;

/* -------------------------------------------------------------------------------------------------
 * CommandContext
 * -----------------------------------------------------------------------------------------------*/

export type TUpdateStrategy = "merge" | "replace";

export interface ICommandConfig {
  id: string;
  priority: number;
  commands: IActiveCommandMap;
}

export interface IActiveCommandMap {
  [commandId: string]: ICommand;
}

/** Normalized command. A command that has been processed. */
export type ICommand = Required<ICommandArgs> & { priority: number };

export class CommandContext {
  id: Function;
  updateStrategy: TUpdateStrategy;
  /**
   * This acts as the default priority for the `configsCommands`.
   * May be overridden on an per-command basis.
   */
  defaultPriority: number;
  configs = new Map<string, ICommandConfig>();
  /**
   * The commands for this context's `configs` property, merged.
   */
  configsCommands: ICommand[] = [];
  /**
   * The `configsCommands` and childContexts `configsCommands`,
   * merged.
   */
  activeCommands: ICommand[] = [];
  /**
   * Will be "replace" if either this context's updateStrategy
   * is "replace" or there is a childContext with an
   * updateStrategy of "replace".
   */
  activeCommandsUpdateStrategy: TUpdateStrategy = "merge";
  childContexts = new Map<Function, CommandContext>();
  parentContext: CommandContext | null;

  constructor(args: {
    id: Function;
    updateStrategy: TUpdateStrategy;
    defaultPriority: number;
    parentContext: CommandContext | null;
  }) {
    this.id = args.id;
    this.updateStrategy = args.updateStrategy;
    this.defaultPriority = args.defaultPriority;
    this.parentContext = args.parentContext;
  }

  mergeConfig(config: ICommandConfig) {
    this.configs.set(config.id, config);
    this.updateConfigsCommands();
    this.updateActiveCommands();
  }

  removeConfig(id: string) {
    this.configs.delete(id);
    this.updateConfigsCommands();
    this.updateActiveCommands();
  }

  mergeChildContext(context: CommandContext) {
    this.childContexts.set(context.id, context);
    context.parentContext = this;
    this.updateActiveCommands();
  }

  removeChildContext(id: Function) {
    const child = this.childContexts.get(id);

    if (!child) return;

    child.parentContext = null;
    this.childContexts.delete(id);
    this.updateActiveCommands();
  }

  updateActiveCommands() {
    const children = Array.from(this.childContexts.values());

    const child = this.getChildContextWithReplaceUpdateStrategy();

    const contexts = child ? [child] : children;

    let wipActiveCommands: ICommand[];

    if (child) {
      this.activeCommandsUpdateStrategy = "replace";
      wipActiveCommands = contexts.flatMap((context) => context.activeCommands);
    } else if (this.updateStrategy === "replace") {
      this.activeCommandsUpdateStrategy = "replace";

      wipActiveCommands = [
        ...this.configsCommands,
        ...contexts.flatMap((context) => context.activeCommands),
      ];
    } else {
      this.activeCommandsUpdateStrategy = "merge";

      wipActiveCommands = [
        ...this.configsCommands,
        ...contexts.flatMap((context) => context.activeCommands),
      ];
    }

    const groupedDictionary = groupBy(wipActiveCommands, (item) => item.id);

    wipActiveCommands = Object.values(groupedDictionary).map((items) => {
      if (items.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return items[0]!;
      }

      // groupBy creates a dictionary with properties that contain at least one value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return items
        .sort((a, b) => numberComparer(b.priority, a.priority))
        .at(0)!;
    });

    this.activeCommands = wipActiveCommands;

    this.parentContext?.updateActiveCommands();
  }

  private updateConfigsCommands() {
    const activeCommandMap = Array.from(this.configs.values())
      .sort((a, b) => numberComparer(a.priority, b.priority))
      .reduce((store, curr) => {
        return { ...store, ...curr.commands };
      }, {} as IActiveCommandMap);

    this.configsCommands = Object.values(activeCommandMap);
  }

  private getChildContextWithReplaceUpdateStrategy() {
    const [childA, childB] = Array.from(this.childContexts.values())
      .filter(
        (context) =>
          context.updateStrategy === "replace" ||
          context.activeCommandsUpdateStrategy === "replace",
      )
      .sort((a, b) => b.defaultPriority - a.defaultPriority);

    if (childA && childB && childA.defaultPriority === childB.defaultPriority) {
      throw new Error(`
        withNewCommandContext: A component cannot have two 
        children which both use updateStrategy: "replace" and have the same
        priority.
      `);
    }

    return childA;
  }
}

/* -------------------------------------------------------------------------------------------------
 * ACTIVE_COMMANDS$
 * -----------------------------------------------------------------------------------------------*/

const _ACTIVE_COMMANDS$ = updatableBehaviorSubject([] as ICommand[]);
export const ACTIVE_COMMANDS$ = _ACTIVE_COMMANDS$.pipe(
  distinctUntilChanged(isEqual),
);

export function getCommandById(id: string) {
  return _ACTIVE_COMMANDS$.getValue().find((cmd) => cmd.id === id);
}

export function callCommandById(id: string) {
  return getCommandById(id)?.callback();
}

/* -------------------------------------------------------------------------------------------------
 * ACTIVE_PATH$
 * -----------------------------------------------------------------------------------------------*/

export const ACTIVE_PATH$ = updatableBehaviorSubject<string[]>([]);

/* -------------------------------------------------------------------------------------------------
 * COMMAND_EVENTS$
 * -----------------------------------------------------------------------------------------------*/

export interface ICommandEvent {
  type: "hotkey" | "kbar";
  command: ICommand;
}

/**
 * Observable that emits with an ICommandEvent object whenever the user
 * executes a command.
 */
export const COMMAND_EVENTS$ = new Subject<ICommandEvent>();

/** Handler for all hotkeys */
// eslint-disable-next-line @typescript-eslint/no-empty-function
let commandEventHandler: EventListener = () => {};

/** Handler for hotkeys which can be triggered while an input has focus */
// eslint-disable-next-line @typescript-eslint/no-empty-function
let inputCommandEventHandler: EventListener = () => {};

/** A variable that can be used to disable hotkey listeners for the Command service */
let isCommandServiceHotkeyListenerDisabled = false;

/**
 * Use this to globally enable/disable hotkey listeners for the Command service.
 */
export function disableCommandServiceHotkeyListener(value: boolean) {
  isCommandServiceHotkeyListenerDisabled = value;
}

/* -------------------------------------------------------------------------------------------------
 * utils
 * -----------------------------------------------------------------------------------------------*/

/**
 * On Apple devices the platform modifier key is
 * the command key and on other devices it is the
 * control key.
 */
export const PLATFORM_MODIFIER_KEY =
  typeof navigator === "object" &&
  /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? ({ name: "Command", shortName: "Cmd", symbol: FiCommand } as const)
    : ({ name: "Control", shortName: "Ctrl", symbol: ImCtrl } as const);

/**
 * On Apple devices the "Alt" key is generally referred
 * to as "Option" whereas on other devices it is refered
 * to as "Alt".
 */
export const PLATFORM_ALT_KEY =
  typeof navigator === "object" &&
  /Mac|iPod|iPhone|iPad/.test(navigator.platform)
    ? ({ name: "Option", shortName: "Opt", symbol: BsOption } as const)
    : ({ name: "Alt", shortName: "Alt", symbol: BsOption } as const);

export function isModKeyActive(event: KeyboardEvent | MouseEvent) {
  const key = PLATFORM_MODIFIER_KEY.name;

  switch (key) {
    case "Command": {
      return event.metaKey;
    }
    case "Control": {
      return event.ctrlKey;
    }
    default: {
      throw new UnreachableCaseError(key);
    }
  }
}

export function normalizeCommand(
  command: ICommandArgs,
  priority: number,
): ICommand {
  const id = command.id || command.label;

  if (typeof id !== "string") {
    throw new Error(
      `Commands with non-string labels are required to provide an ID`,
    );
  }

  return {
    priority,
    path: [],
    hotkeys: [],
    keywords: [],
    showInKBar: true,
    closeKBarOnSelect: true,
    triggerHotkeysWhenInputFocused: false,
    icon: null,
    altLabels: [],
    ...command,
    id,
  };
}

/* -------------------------------------------------------------------------------------------------
 * ROOT_COMMAND_CONTEXT
 * -----------------------------------------------------------------------------------------------*/

export function buildRootCommandContext(
  updateStrategy: TUpdateStrategy = "merge",
) {
  if (import.meta.env.MODE === "test") {
    _ACTIVE_COMMANDS$.next([]);
    reindexActiveHotkeyCommands([]);
  }

  const context = new CommandContext({
    id: () => {},
    updateStrategy,
    defaultPriority: 0,
    parentContext: null,
  });

  const originalUpdateCmdsFn = context.updateActiveCommands.bind(context);

  // we patch the root context's update function to also
  // emit updates from ACTIVE_COMMANDS$. This function will
  // be called when any child updates.
  context.updateActiveCommands = function () {
    originalUpdateCmdsFn();
    reindexActiveHotkeyCommands(this.activeCommands);
    _ACTIVE_COMMANDS$.next(this.activeCommands);
  };

  return context;
}

const ROOT_COMMAND_CONTEXT = buildRootCommandContext();

/* -------------------------------------------------------------------------------------------------
 * withNewCommandContext
 * -----------------------------------------------------------------------------------------------*/

export type TPriority = number | { delta: number };

export interface INewCommandContextOptions<Props> {
  Component: ComponentType<Props>;
  forwardRef?: boolean;
  updateStrategy?: TUpdateStrategy;
  /**
   * If a number is provided, then that number is the priority.
   * If a `{delta: number}` object is provided, then the delta
   * number is added to the defaultPriority to determine the
   * priority.
   */
  priority?: TPriority;
}

/**
 * Increments the default command registration priority by 1
 * for this component and it's children. This ensures that any
 * commands this component registers take precidence if they
 * conflict with a command that a parent has registered.
 */
export function withNewCommandContext<Props = {}>(
  Component: ComponentType<Props>,
): ComponentType<Props>;
export function withNewCommandContext<Props = {}, Ref = unknown>(
  Component: INewCommandContextOptions<Props> & { forwardRef: true },
): ForwardRefExoticComponent<
  React.PropsWithoutRef<Props> & React.RefAttributes<Ref>
>;
export function withNewCommandContext<Props = {}>(
  Component: INewCommandContextOptions<Props>,
): ComponentType<Props>;
export function withNewCommandContext<Props = {}, Ref = unknown>(
  Component: ComponentType<Props> | INewCommandContextOptions<Props>,
) {
  const _options = typeof Component === "function" ? { Component } : Component;
  const options = { ...DEFAULT_OPTIONS, ..._options };

  function useContext() {
    const parentContext = useCommandContext();

    const context = useMemo(() => {
      const defaultPriority =
        options.priority === undefined
          ? parentContext.defaultPriority + 1
          : typeof options.priority === "number"
          ? options.priority
          : parentContext.defaultPriority + options.priority.delta;

      return new CommandContext({
        id: options.Component,
        updateStrategy: options.updateStrategy,
        defaultPriority,
        parentContext,
      });
    }, [parentContext]);

    useEffect(() => {
      parentContext.mergeChildContext(context);

      return () => {
        parentContext.removeChildContext(context.id);
      };
    }, [parentContext, context]);

    return context;
  }

  if (options.forwardRef) {
    return forwardRef<Ref, Props>((props, ref) => {
      const context = useContext();

      return (
        <ReactCommandContext.Provider value={context}>
          <options.Component ref={ref} {...props} />
        </ReactCommandContext.Provider>
      );
    });
  } else {
    return (props: PropsWithChildren<Props>) => {
      const context = useContext();

      return (
        <ReactCommandContext.Provider value={context}>
          <options.Component {...props} />
        </ReactCommandContext.Provider>
      );
    };
  }
}

const DEFAULT_OPTIONS = {
  updateStrategy: "merge",
} as const;

export const ReactCommandContext =
  createContext<CommandContext>(ROOT_COMMAND_CONTEXT);

const useCommandContext = createUseContextHook(
  ReactCommandContext,
  "ReactCommandContext",
);

/* -------------------------------------------------------------------------------------------------
 * useRegisterCommands
 * -----------------------------------------------------------------------------------------------*/

/**
 * Updates the Hotkey Service with hotkey commands for the current
 * context using an update strategy. Hotkey events are processed
 * by the tinykeys library. Hotkeys should be written in the format
 * tinykeys expects. See https://github.com/jamiebuilds/tinykeys
 *
 * Note `$mod` is a special key you can use in hotkey triggers. On
 * Apple devices it translates to the "command" key and on other
 * devices it translates to the "control" key.
 *
 * Update Strategy Options:
 * - "merge" (default) If this context provides commands which conflict with
 *   hotkey commands provided in a parent context, this context's
 *   commands will take precedence until this context is removed.
 * - "replace" This context's commands will be the only available
 *   commands until this context is removed (at which point the
 *   previous contexts will be used again).
 */
export function useRegisterCommands({
  id: _id,
  commands: commandsFactory,
  priority: _priority,
  deps = [],
}: {
  id?: string;
  commands: () => ICommandArgs[] | Observable<ICommandArgs[]>;
  /**
   * If a number is provided, then that number is the priority.
   * If a `{delta: number}` object is provided, then the delta
   * number is added to the defaultPriority to determine the
   * priority.
   */
  priority?: TPriority;
  /**
   * The dependencies for the `commands` factory function. The
   * commands are only rebuild when the deps change.
   */
  deps?: unknown[];
}): void {
  const context = useCommandContext();
  const id = useConstant(() => _id || uid());

  const priority = useMemo(() => {
    return _priority === undefined
      ? context.defaultPriority
      : typeof _priority === "number"
      ? _priority
      : context.defaultPriority + _priority.delta;
  }, [_priority, context.defaultPriority]);

  useEffect(
    () => {
      const mergeConfig = (commands: ICommandArgs[]) => {
        context.mergeConfig({
          id,
          priority,
          commands: Object.fromEntries(
            commands.map((cmd) => {
              const normCmd = normalizeCommand(cmd, priority);
              return [normCmd.id, normCmd];
            }),
          ),
        });
      };

      const commands = commandsFactory();

      if (Array.isArray(commands)) {
        mergeConfig(commands);
        return;
      }

      const sub = commands.subscribe(mergeConfig);

      return () => sub.unsubscribe();
    },
    // The `commands` prop doesn't need to be included because the `deps` array
    // lists it's dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context, id, priority, ...deps],
  );

  useEffect(() => {
    return () => {
      context.removeConfig(id);
    };
  }, [context, id]);
}

/**
 * When provided an array of active commands, this function will rebuild
 * the commandEventHandler and inputCommandEventHandler functions.
 */
function reindexActiveHotkeyCommands(activeCommands: ICommand[]) {
  const activePath = ACTIVE_PATH$.getValue();

  const activeHotkeyCommands = activeCommands
    .filter((command) => {
      if (command.hotkeys.length === 0) return false;
      if (isEqual(command.path, ["global"] as const)) return true;
      if (command.path.length !== activePath.length) return false;

      return command.path.every((segment, i) => activePath[i] === segment);
    })
    .sort((a, b) => numberComparer(a.priority, b.priority));

  if (import.meta.env.MODE === "development") {
    const hotkeysSet = new Map<string, ICommandArgs>();

    for (const command of activeHotkeyCommands) {
      for (const hotkey of command.hotkeys) {
        if (!hotkeysSet.has(hotkey)) {
          hotkeysSet.set(hotkey, command);
          continue;
        }

        console.debug(
          `Two commands were registered using the same hotkey: "${hotkey}".`,
          `This can be perfectly valid and expected; it can also be unexpected.`,
          `To avoid this, you can use the "priority" option in useRegisterCommands()`,
          command,
          hotkeysSet.get(hotkey),
        );
      }
    }
  }

  const keyBindingMap = Object.fromEntries(
    activeHotkeyCommands.flatMap(mapCommandToKeyBinding),
  );

  const inputKeyBindingMap = Object.fromEntries(
    activeHotkeyCommands
      .filter((command) => command.triggerHotkeysWhenInputFocused)
      .flatMap(mapCommandToKeyBinding),
  );

  if (import.meta.env.MODE !== "test") {
    console.debug(
      "command keyBindingMap changed",
      keyBindingMap,
      inputKeyBindingMap,
      ROOT_COMMAND_CONTEXT,
    );
  }

  commandEventHandler = createKeybindingsHandler(keyBindingMap);
  inputCommandEventHandler = createKeybindingsHandler(inputKeyBindingMap);
}

if (import.meta.env.MODE !== "test") {
  // Here we reindexActiveHotkeyCommands when the active path changes.
  ACTIVE_PATH$.subscribe(() => {
    const activeCommands = _ACTIVE_COMMANDS$.getValue();

    reindexActiveHotkeyCommands(activeCommands);
  });
}

export const WINDOW_EVENTS$ = fromEvent<KeyboardEvent>(window, "keydown").pipe(
  share(),
);

WINDOW_EVENTS$.subscribe(commandServiceHandleKeyDown);

export function commandServiceHandleKeyDown(event: KeyboardEvent) {
  console.debug("event", event);

  if (isCommandServiceHotkeyListenerDisabled) return;

  const target = event.target as HTMLElement | null;
  const tagName = target?.tagName;

  if (
    tagName === "INPUT" ||
    tagName === "SELECT" ||
    tagName === "TEXTAREA" ||
    target?.isContentEditable
  ) {
    inputCommandEventHandler(event);
    return;
  }

  commandEventHandler(event);
}

function mapCommandToKeyBinding(command: ICommand) {
  const processedEventStore = new WeakSet<KeyboardEvent>();

  return command.hotkeys.map((trigger) => [
    trigger,
    (event: KeyboardEvent) => {
      // A single command can have multiple hotkey triggers.
      // Sometimes, we'll have similar variations of a trigger
      // targeting different browsers. This can cause some
      // browsers to trigger multiple times depending on how
      // they interpret the trigger (different browsers interpret
      // KeyboardEvents differently). Here we guard against that
      // possibility by using a WeakSet to make sure we only
      // call a given callback once per event.
      //
      // Additionally, it's possible that some application logic
      // manually imports and calls `handleKeyDown` with an event
      // before it reaches the window. In this case, it's possible
      // that the same event might be processed by this service
      // multiple times.
      if (processedEventStore.has(event)) return;

      processedEventStore.add(event);

      if (import.meta.env.MODE === "development") {
        console.log("hotkey triggered", trigger, event, command);
      } else if (import.meta.env.MODE === "production") {
        console.debug("hotkey triggered", trigger, event, command);
      }

      COMMAND_EVENTS$.next({ type: "hotkey", command });

      const result = command.callback(event);

      if (result === false) return;

      // At least one command does need this prevent default:
      // when you press "c" to open the compose post modal,
      // if we don't prevent default then the "to" field will
      // have a "c" in it on open.
      event.preventDefault();
    },
  ]);
}
