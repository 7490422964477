import { Loader } from "@libs/chet-stack/Loader";
import { ApiResponse } from "libs/ApiTypes";
import {
  ApiQuery,
  ApiQueryType,
  ApiQueryMinusGetRecords,
  ApiQueryMinusGetRecordsType,
  getQueryCacheKeyBase,
  queryToTableMap,
} from "libs/QueryApi";
import { ClientDatabase } from "./database";
import { getEnvironment } from "./ClientEnvironmentContext";
import { RecordPointer } from "libs/schema";

export function loadRecord(args: {
  pointer: RecordPointer;
  /**
   * Fetch the value from the server unless we know the cache is
   * up-to-date.
   */
  forceFromServer?: boolean;
}): Loader {
  const { pointer, forceFromServer = false } = args;
  const { loaderCache, db, api } = getEnvironment();

  // If there is an existing loader for this record, it indicates we
  // have the record loaded in the cache AND we are maintaining a
  // subscription for record updates.
  const existingLoader = loaderCache.get({
    type: "getRecord",
    params: pointer,
  });

  if (existingLoader && !forceFromServer) return existingLoader;

  const loader = new Loader();

  loaderCache.set("getRecord", pointer, loader);

  // When we request records using the API, we will often get back
  // more records than requested and store them in the RecordCache.
  // As such it's possible for us to already have a record cached
  // even if we've never requested it. It may be outdated, but we're
  // ok with showing stale data. We can also have a record here if
  // we previously fetched it but then stopped maintaining our
  // subscription to updates, but haven't yet purged the record
  // from the cache.
  if (!forceFromServer) {
    db.getRecord(pointer).then((record) => {
      if (!record) return;
      loader.resolve();
    });
  }

  api
    .getRecords({ pointers: [pointer] })
    .then((response) => {
      console.log("load response", response);
      handleApiResponse(response, db, loader);
    })
    .catch((err) => {
      console.error("getRecords", err);
      loader.reject(err);
    });

  return loader;
}

export function loadQuery<T extends ApiQueryMinusGetRecordsType>(args: {
  type: ApiQueryMinusGetRecords<T>["type"];
  params: ApiQueryMinusGetRecords<T>["params"];
}): Loader {
  const { type, params } = args;
  const query = { type, params } as ApiQuery<T>;
  const { loaderCache, db, api } = getEnvironment();
  const { limit } = query.params as { limit?: number };

  // If there is an existing loader for this record, it indicates we
  // have the record loaded in the cache AND we are maintaining a
  // subscription for record updates.
  const existingLoader = loaderCache.get(query);

  if (existingLoader && (!limit || existingLoader.limit >= limit)) {
    return existingLoader;
  }

  if (!(query.type in queryToTableMap)) {
    throw new Error("Invalid query type: " + query.type);
  }

  const loader = new Loader(limit);

  loaderCache.set(query.type, query.params, loader);

  api[query.type](query.params as never)
    .then((response) => {
      handleApiResponse(response, db, loader);
    })
    .catch((err) => {
      console.error(query.type, err);
      loader.reject(err);
    });

  return loader;
}

function handleApiResponse(
  response: ApiResponse<any>,
  db: ClientDatabase,
  loader: Loader,
) {
  if (response.status === 200) {
    const { recordMap } = response.body;

    if (recordMap) {
      db.writeRecordMap(recordMap);
      // recordStorage.writeRecordMap(recordMap);
    }

    return loader.resolve();
  }

  // If we're offline, then we want to wait to see if its cached.
  if (response.status === 0) {
    loader.reject(new Error("Offline cache miss."));
    return loader;
    // return cached
    // 	.then((threads) => {
    // 		if (!threads) loader.reject(new Error("Offline cache miss."))
    // 	})
    // 	.catch(loader.reject)
  }

  console.error("Network error: ", response);

  return loader.reject(new Error("Network error: " + response.status));
}

export class LoaderCache {
  cache = new Map<string, Loader>();

  get<T extends ApiQueryType>(
    type: T,
    params: ApiQuery<T>["params"],
  ): Loader | undefined;
  get<T extends ApiQueryType>(query: ApiQuery<T>): Loader | undefined;
  get<T extends ApiQueryType>(
    a: T | ApiQuery<T>,
    params?: ApiQuery<T>["params"],
  ): Loader | undefined {
    const query =
      typeof a === "object" ? a : ({ type: a, params } as ApiQuery<T>);

    const key = this.getKey(query);
    return this.cache.get(key);
  }

  set<T extends ApiQueryType>(
    type: T,
    params: ApiQuery<T>["params"],
    loader: Loader,
  ) {
    const key = this.getKey({ type, params });
    this.cache.set(key, loader);
  }

  delete<T extends ApiQueryType>(type: T, params: ApiQuery<T>["params"]) {
    const key = this.getKey({ type, params });
    return this.cache.delete(key);
  }

  private getKey(query: ApiQuery<any>) {
    const key =
      query.type === "getRecord"
        ? `${query.params.table}:${query.params.id}`
        : getQueryCacheKeyBase(query.type, query.params);

    return key;
  }
}
