import { ComponentType, Ref } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { TextInput } from "~/components/forms/TextInput";
import {
  IOption,
  TAutocompleteSelectRef,
  useAutocompleteMenuPositioning,
} from "~/components/forms/AutocompleteSelect";
import { navigateService } from "~/environment/navigate.service";
import {
  getGroupOptions,
  GroupSelect,
  IGroupOption,
} from "~/components/forms/GroupSelect";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import { TextareaInput } from "~/components/forms/Textarea";
import {
  handleSubmit,
  onSubmitFn,
  useControlState,
} from "~/components/forms/utils";
import { toast } from "~/environment/toast-service";
import { isAppOnline } from "~/environment/network-connection.service";
import { useRegisterCommands } from "~/environment/command.service";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { SubmitDialogHint } from "../DialogLayout";
import { CheckboxInput } from "~/components/forms/CheckboxInput";
import { Tooltip } from "~/components/Tooltip";
import { cx } from "@emotion/css";
import { generateRecordId } from "libs/schema";
import { upsertGroup } from "~/actions/upsertGroup";
import { isNonNullable } from "@libs/utils/predicates";

export type IEditGroupDialogData =
  | {
      prefill?: {
        id?: string | null;
        folderIds?: string[];
        name?: string;
        description?: string | null;
        isPrivate?: boolean;
      };
    }
  | undefined;

export type IEditGroupDialogReturnData = { success: boolean } | void;

export const EditGroupDialogState = new DialogState<
  IEditGroupDialogData,
  IEditGroupDialogReturnData
>();

interface IFormValue {
  id: string | null;
  folders: IOption<string>[];
  name: string;
  description: string;
  isPrivate: boolean;
}

export const EditGroupDialog = withModalDialog({
  dialogState: EditGroupDialogState,
  useOnDialogContainerRendered: () => {
    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "New group",
            altLabels: ["Create group"],
            callback: () => {
              if (!isAppOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't create groups when offline.",
                });

                return;
              }

              EditGroupDialogState.open();
            },
          },
        ];
      },
    });
  },
  async loadData(data) {
    const prefill = data?.prefill || {};
    const folderIds = prefill.folderIds || [];

    const folders = await Promise.all(
      folderIds.map(async (folderId) => (await getGroupOptions(folderId))[0]),
    );

    return {
      id: prefill.id || null,
      folderOptions: folders.filter(isNonNullable),
      name: prefill.name || "",
      description: prefill.description || "",
      isPrivate: prefill.isPrivate || false,
    };
  },
  Component: (props) => {
    const control = useControl(() => {
      return createFormGroup({
        id: createFormControl(props.data.id || null),
        folders: createFormControl(props.data.folderOptions, {
          validators: required,
          required: true,
        }),
        name: createFormControl(props.data.name, {
          required: true,
        }),
        description: createFormControl(props.data.description),
        isPrivate: createFormControl(props.data.isPrivate, {
          disabled: !!props.data,
        }),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Close dialog",
            hotkeys: ["Escape"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              EditGroupDialogState.close();
            },
          },
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              console.log("attempting submit");
              handleSubmit(control, submit);
            },
          },
        ];
      },
    });

    const [
      groupsAutocompleteRef,
      groupsAutocompletePortalEl,
      groupsAutocompletePortalJSX,
    ] = useAutocompleteMenuPositioning<IGroupOption, true>();

    return (
      <>
        <DialogTitle>
          <h2>
            {props.data.name
              ? `Update "${props.data.name}" group`
              : `Create group`}
          </h2>
        </DialogTitle>

        <form
          onSubmit={onSubmitFn(control, submit)}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <Groups
            autocompleteRef={groupsAutocompleteRef}
            control={control.controls.folders}
            autocompleteMenuEl={groupsAutocompletePortalEl}
          />

          <div className="flex px-4">
            <div className="flex flex-1 py-2 border-b border-mauve-5">
              <TextInput control={control.controls.name} name="name" />

              <IsPrivateInput control={control.controls.isPrivate} />
            </div>
          </div>

          <Description control={control.controls.description} />
        </form>

        {groupsAutocompletePortalJSX}

        <SubmitDialogHint />
      </>
    );
  },
});

const IsPrivateInput: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  const isDisabled = useControlState(
    () => props.control.isDisabled,
    [props.control],
  );

  return (
    <Tooltip
      side="bottom"
      content={
        isDisabled
          ? "Cannot edit after creation."
          : "Private groups are only visible to invited members."
      }
    >
      <div className="flex items-center">
        <label
          htmlFor="group-privacy-input"
          className={cx(
            "mx-2",
            isDisabled && "text-slate-9 cursor-not-allowed",
          )}
        >
          Private group?
        </label>

        <CheckboxInput
          id="group-privacy-input"
          control={props.control}
          checkedValue={true}
          uncheckedValue={false}
        />
      </div>
    </Tooltip>
  );
};

function required(value: IOption<string>[]) {
  return value.length > 0 ? null : { required: "Required." };
}

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (values: IFormValue) => {
    console.log("submitting...", values);

    const currentUser = getAndAssertCurrentUser();
    const folderMemberIds = values.folders.map((option) => option.value);
    const groupMemberIds = values.isPrivate
      ? []
      : [currentUser.owner_organization_id];

    EditGroupDialogState.close({ success: true });

    if (values.id) {
      const { optimisticWritePromise, serverWritePromise } = upsertGroup({
        id: values.id,
        name: values.name,
        description: values.description || null,
        folderMemberIds,
        groupMemberIds,
      });

      await optimisticWritePromise;

      toast("vanilla", {
        subject: "Group updated. Syncing...",
      });

      try {
        await serverWritePromise;
      } catch (err) {
        console.warn("Group update failed to sync to server.", err);

        toast("vanilla", {
          subject: "Failed to sync group update to server.",
        });

        return;
      }

      console.debug("Group update synced.");

      toast("vanilla", {
        subject: "Group update synced.",
      });
    } else {
      const groupId = generateRecordId("tag");

      const { optimisticWritePromise, serverWritePromise } = upsertGroup({
        id: groupId,
        name: values.name,
        description: values.description || null,
        folderMemberIds,
        groupMemberIds,
      });

      await optimisticWritePromise;

      toast("vanilla", {
        subject: "Group created. Syncing...",
      });

      navigateService(`/groups/${groupId}`);

      try {
        await serverWritePromise;
      } catch (err) {
        console.warn("New group failed to sync to server.", err);

        toast("vanilla", {
          subject: "Failed to sync new group to server.",
        });

        return;
      }

      console.debug("Group creation synced.");

      toast("vanilla", {
        subject: "New group synced.",
      });
    }
  }),
);

const Groups: ComponentType<{
  autocompleteRef?: Ref<TAutocompleteSelectRef<IGroupOption, true>>;
  control: IFormControl<IGroupOption[]>;
  autocompleteMenuEl?: HTMLDivElement | null;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);

  const error = useControlState(
    () => props.control.errors?.required as string | undefined,
    [props.control],
  );

  const touched = useControlState(
    () => props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex px-4">
      <GroupSelect
        autocompleteRef={props.autocompleteRef}
        label="In"
        value={value}
        multiple
        autoFocus
        error={error}
        touched={touched}
        autocompleteMenuEl={props.autocompleteMenuEl}
        onBlur={() => props.control.markTouched(true)}
        onChange={(newValue) =>
          props.control.setValue(newValue as IGroupOption[])
        }
      />
    </div>
  );
};

const Description: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex flex-1 overflow-y-auto p-4" tabIndex={-1}>
      <TextareaInput control={props.control} name="description" />
    </div>
  );
};
