import { isEqual } from "@libs/utils/isEqual";
import { RecordMap, RecordPointer, setMapRecord } from "libs/schema";
import {
  applyOperation,
  invertOperation,
  op,
  Transaction,
} from "libs/transaction";
import { compact, uniqWith } from "lodash-comms";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";

export async function write(
  environment: ClientEnvironment,
  transaction: Transaction,
) {
  const { transactionQueue, undoRedo } = environment;

  const undoTransaction = await invertTransaction(environment, transaction);

  if (undoTransaction) {
    undoRedo.redoStack = [];
    undoRedo.undoStack.push(undoTransaction);
  }

  await optimisticWrite(environment, transaction);
  await transactionQueue.enqueue(transaction);
}

export const undo = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: ClientEnvironment) => {
    const { undoRedo, transactionQueue } = environment;

    const undoTransaction = undoRedo.undoStack.pop();

    if (!undoTransaction) return;

    const redoTransaction = await invertTransaction(
      environment,
      undoTransaction,
    );

    if (!redoTransaction) {
      throw new Error("Undo transactions should always be invertible.");
    }

    undoRedo.redoStack.push(redoTransaction);

    await optimisticWrite(environment, undoTransaction);
    await transactionQueue.enqueue(undoTransaction);
  },
);

export const redo = onlyCallFnOnceWhilePreviousCallIsPending(
  async (environment: ClientEnvironment) => {
    const { undoRedo, transactionQueue } = environment;

    const redoTransaction = undoRedo.redoStack.pop();

    if (!redoTransaction) return;

    const undoTransaction = await invertTransaction(
      environment,
      redoTransaction,
    );

    if (!undoTransaction) {
      throw new Error("Undo transactions should always be invertible.");
    }

    undoRedo.undoStack.push(undoTransaction);

    await optimisticWrite(environment, redoTransaction);
    await transactionQueue.enqueue(redoTransaction);
  },
);

export async function runTransaction(props: {
  tx: (transaction: Transaction) => Promise<void>;
  onUndo?: () => Promise<void> | void;
}) {
  const currentUser = getAndAssertCurrentUser();
  const environment = getEnvironment();

  const transaction = op.transaction({
    authorId: currentUser.id,
    operations: [],
    onUndo: props.onUndo,
  });

  await props.tx(transaction);

  write(environment, transaction);
}

async function optimisticWrite(
  environment: ClientEnvironment,
  transaction: Transaction,
) {
  const { db } = environment;

  // Get cached records for this write.
  const pointers = uniqWith(
    transaction.operations.map(
      ({ table, id }) => ({ table, id } as RecordPointer),
    ),
    isEqual,
  );

  const recordMap: RecordMap = {};
  for (const pointer of pointers) {
    const record = await db.getRecord(pointer);
    if (!record) continue;
    setMapRecord(recordMap, pointer, record);
  }

  // Optimist update.
  for (const operation of transaction.operations) {
    applyOperation(recordMap, operation, true);
  }

  await db.writeRecordMap(recordMap);
}

async function invertTransaction(
  environment: ClientEnvironment,
  transaction: Transaction,
) {
  const { db } = environment;

  // Get cached records for this write.
  const pointers = uniqWith(
    transaction.operations.map(
      ({ table, id }) => ({ table, id } as RecordPointer),
    ),
    isEqual,
  );

  const recordMap: RecordMap = {};
  for (const pointer of pointers) {
    const record = await db.getRecord(pointer);
    if (!record) continue;
    setMapRecord(recordMap, pointer, record);
  }

  const undoOperations = compact(
    transaction.operations.map((op) => invertOperation(recordMap, op)),
  );

  if (!undoOperations.length) return;

  const undoTransaction: Transaction = {
    txId: window.crypto.randomUUID(),
    authorId: transaction.authorId,
    operations: undoOperations,
    onUndo: transaction.onUndo,
  };

  return undoTransaction;
}
