import { Observable, of, switchMap } from "rxjs";
import { CURRENT_USER_ID$ } from "~/environment/user.service";
import { observeRecord, ObserveRecordResult } from "./observeRecord";

/**
 * This can return null if the user is currently offline.
 */
export function observeCurrentUserSettings(): Observable<
  ObserveRecordResult<"user_settings">
> {
  return CURRENT_USER_ID$.pipe(
    switchMap((currentUserId) =>
      !currentUserId
        ? of({ record: null, isLoading: false })
        : observeRecord({
            table: "user_settings",
            id: currentUserId,
          }),
    ),
  );
}
