import {
  throwUnreachableCaseError,
  UnreachableCaseError,
} from "@libs/utils/errors";
import type {
  FilterValue,
  LogicalOperator,
  ParsedToken,
  TextToken,
  Token,
} from "@libs/utils/searchQueryParser";
import {
  EMAIL_ADDRESS_REGEXP,
  parseStringToEmailAddress,
} from "libs/parseEmailAddress";
import { uniq } from "lodash-comms";
import { getPointer, getRecordId, RecordValue } from "libs/schema";
import { RecordLoaderApi } from "./QueryApi";

export interface ISearchQueryMatcherProps {
  currentUserId: string;
  messageId: string;
  threadId: string;
  parsedQuery: ParsedToken[];
  allowTopLevelFullTextQuery?: boolean;
  loader: RecordLoaderApi;
}

// export interface ISearchQueryMatcherService {
//   getUserContactInfo(
//     id: string,
//   ): Promise<RecordValue<"user_contact_info"> | null>;
//   getUserProfile(id: string): Promise<RecordValue<"user_profile"> | null>;
//   getMessage(id: string): Promise<RecordValue<"message"> | null>;
//   getMessageEmailRecipients(args: {
//     messageId: string;
//     type: "FROM" | "TO" | "CC" | "BCC";
//   }): Promise<RecordValue<"message_email_recipient">[]>;
//   getMessageUserRecipients(
//     messageId: string,
//   ): Promise<RecordValue<"message_user_recipient">[]>;
//   getMessageGroupRecipients(
//     messageId: string,
//   ): Promise<RecordValue<"message_group_recipient">[]>;
//   getThread(id: string): Promise<RecordValue<"thread"> | null>;
//   getThreadPermittedUsers(
//     threadId: string,
//   ): Promise<RecordValue<"thread_user_permission">[]>;
//   getThreadParticipatingUsers(
//     threadId: string,
//   ): Promise<RecordValue<"thread_user_participant">[]>;
//   getThreadPermittedGroups(
//     threadId: string,
//   ): Promise<RecordValue<"thread_group_permission">[]>;
//   getThreadTags(threadId: string): Promise<RecordValue<"thread_tag">[]>;
//   getTag(id: string): Promise<RecordValue<"tag"> | null>;
//   getNotification(
//     userId: string,
//     notificationId: string,
//   ): Promise<RecordValue<"notification"> | null>;
//   getNotificationTags(
//     userId: string,
//     notificationId: string,
//   ): Promise<RecordValue<"notification_tag">[]>;
//   getThreadSeenReceipt(
//     userId: string,
//     threadId: string,
//   ): Promise<RecordValue<"thread_seen_receipt"> | null>;
//   getGroup(channelId: string): Promise<RecordValue<"tag"> | null>;
//   convertStringToTimestamp(dateString: string): string | null;
// }

interface IFilterProps<T> extends ISearchQueryMatcherProps {
  token: T;
}

export async function findMatchingSubsection(args: {
  currentUserId: string;
  messageId: string;
  threadId: string;
  inboxSection: RecordValue<"inbox_section">;
  inboxSubsections: RecordValue<"inbox_subsection">[];
  loader: RecordLoaderApi;
}) {
  for (const subsection of args.inboxSubsections) {
    const matchesSubsection = await searchQueryMatcher({
      currentUserId: args.currentUserId,
      messageId: args.messageId,
      loader: args.loader,
      threadId: args.threadId,
      parsedQuery: subsection.parsed_query as ParsedToken[],
      allowTopLevelFullTextQuery: true,
    });

    if (!matchesSubsection) continue;

    return {
      section: args.inboxSection,
      subsection: subsection,
    };
  }

  return null;
}

// Equal to the same similarity threadshold that our postgres
// instance users
// const FUZZY_MATCH_SIMILARITY_THRESHOLD = 0.6;

export async function searchQueryMatcher(
  props: ISearchQueryMatcherProps,
): Promise<boolean> {
  if (props.parsedQuery.length === 0) {
    return false;
  }

  const parsedQuery = bundleOrOperators(props.parsedQuery) as [
    ParsedToken,
    ...ParsedToken[],
  ];

  // Unfortunately, the "trigram-similarity" npm package attempts to mimic
  // "similarity" matching in postgres rather than "word_similarity" matching
  // (which is what Comms users). Additionally, while it produces a pretty
  // similar similarity score, it isn't an exact match. As such, we're temporarily
  // disabling fuzzy matching. In the client, if someone attempts to add
  // a fuzzy text filter we show them an alert and then prevent them.
  //
  // // If the search query contains a plain text search phrase, the first
  // // ParsedToken in the response will be of type "text". See the
  // // searchQueryParser.ts module for more information.
  // if (parsedQuery[0].type === "text" && props.allowTopLevelFullTextQuery) {
  //   const token = parsedQuery.shift() as TextToken;
  //   const postText = props.message.subject + " " + props.message.bodyText;
  //   const similarity = trigramSimilarity(postText, token.value);
  //   if (similarity < FUZZY_MATCH_SIMILARITY_THRESHOLD) return false;
  // }

  return areAllResultsTrue(
    parsedQuery.map((token) => matchQueryToken(token, props)),
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function matchQueryToken(
  token: ParsedToken,
  props: ISearchQueryMatcherProps,
): Promise<boolean> {
  const getFilterProps = <T>(token: T) => ({ ...props, token });

  switch (token.type) {
    case "text": {
      return textFilter(getFilterProps(token));
    }
    case "from:": {
      return areAllResultsTrue(
        token.value.map((value) => fromFilter(getFilterProps(value))),
      );
    }
    case "to:": {
      return areAllResultsTrue(
        token.value.map((value) => toFilter(getFilterProps(value))),
      );
    }
    case "is:": {
      return areAllResultsTrue(
        token.value.map((value) => isFilter(getFilterProps(value))),
      );
    }
    case "after:": {
      return areAllResultsTrue(
        token.value.map((value) => afterFilter(getFilterProps(value))),
      );
    }
    case "before:": {
      return areAllResultsTrue(
        token.value.map((value) => beforeFilter(getFilterProps(value))),
      );
    }
    case "viewer:": {
      return areAllResultsTrue(
        token.value.map((value) => viewerFilter(getFilterProps(value))),
      );
    }
    case "participating:": {
      return areAllResultsTrue(
        token.value.map((value) => participatingFilter(getFilterProps(value))),
      );
    }
    case "channel:": {
      return areAllResultsTrue(
        token.value.map((value) => groupFilter(getFilterProps(value))),
      );
    }
    case "tag:": {
      return areAllResultsTrue(
        token.value.map((value) => tagFilter(getFilterProps(value))),
      );
    }
    case "subject:": {
      return areAllResultsTrue(
        token.value.map((value) => subjectFilter(getFilterProps(value))),
      );
    }
    case "body:": {
      return areAllResultsTrue(
        token.value.map((value) => bodyFilter(getFilterProps(value))),
      );
    }
    case "has:": {
      return areAllResultsTrue(
        token.value.map((value) => hasFilter(getFilterProps(value))),
      );
    }
    case "remind-after:": {
      return areAllResultsTrue(
        token.value.map((value) => remindAfterFilter(getFilterProps(value))),
      );
    }
    case "remind-before:": {
      return areAllResultsTrue(
        token.value.map((value) => remindBeforeFilter(getFilterProps(value))),
      );
    }
    case "mentions:": {
      return areAllResultsTrue(
        token.value.map((value) => mentionsFilter(getFilterProps(value))),
      );
    }
    case "priority:": {
      return areAllResultsTrue(
        token.value.map(async (value) => priorityFilter(getFilterProps(value))),
      );
    }
    case "and()": {
      return andOperator({
        ...props,
        token: token as LogicalOperator<"and()">,
      });
    }
    case "or()": {
      return orOperator({
        ...props,
        token: token as LogicalOperator<"or()">,
      });
    }
    case "not()": {
      return notOperator({
        ...props,
        token: token as LogicalOperator<"not()">,
      });
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function textFilter(props: IFilterProps<TextToken>) {
  const lowercaseInput = props.token.value.toLowerCase();
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  return (
    message.subject.toLowerCase().includes(lowercaseInput) ||
    message.body_text.toLowerCase().includes(lowercaseInput)
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function fromFilter(props: IFilterProps<FilterValue<"from">>) {
  const { token, currentUserId } = props;

  switch (token.type) {
    case "text": {
      if (token.value === "me") {
        const [message] = await props.loader.getRecord({
          table: "message",
          id: props.messageId,
        });

        if (!message) return false;
        return message.sender_user_id === currentUserId;
      } else if (isEmail(token.value)) {
        return isMessageSentByEmail(props, token.value);
      } else {
        return isMessageSentByName(props, token.value);
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return isMessageSentByUser(props, subjectId);
        }
        case "channel":
        case "tag": {
          // TODO
          // if someone indicates "from" a channel or tag, they probably mean that
          // they want messages which they received an inbox notification for
          // because the message was sent to that channel or tag (and, presumably,
          // the current user had a subscription to the channel/tag at the time).
          // This is something we should try to support.
          console.warn(`Attempted to filter on "from:" ${subject}, ignoring.`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

async function isMessageSentByEmail(
  props: IFilterProps<unknown>,
  input: string,
) {
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      if (!message.sender_user_id) return false;
      const [user] = await props.loader.getRecord({
        table: "user_contact_info",
        id: message.sender_user_id,
      });

      return user?.email_address.toLowerCase() === lowercaseInput;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      const [emailRecipients] = await props.loader.getMessageEmailRecipients({
        message_id: message.id,
        type: "FROM",
      });

      return emailRecipients.some((r) => {
        const email = parseStringToEmailAddress(r.email_address);
        if (!email) return false;

        return typeof email.address === "string"
          ? email.address.toLowerCase() === lowercaseInput
          : email.addresses
          ? email.addresses.some(
              (g) => g.address.toLowerCase() === lowercaseInput,
            )
          : throwUnreachableCaseError(email);
      });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentByName(
  props: IFilterProps<unknown>,
  input: string,
) {
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      if (!message.sender_user_id) return false;
      const [user] = await props.loader.getRecord(
        "user_profile",
        message.sender_user_id,
      );

      return user?.name.toLowerCase() === lowercaseInput;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      const [emailRecipients] = await props.loader.getMessageEmailRecipients({
        message_id: message.id,
        type: "FROM",
      });

      return emailRecipients.some((r) => {
        const email = parseStringToEmailAddress(r.email_address);
        if (!email) return false;

        return (
          email.label?.toLowerCase().includes(lowercaseInput) ||
          (typeof email.address === "string"
            ? email.address.toLowerCase().includes(lowercaseInput)
            : email.addresses
            ? email.addresses.some((g) =>
                g.address.toLowerCase().includes(lowercaseInput),
              )
            : throwUnreachableCaseError(email))
        );
      });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentByUser(
  props: IFilterProps<unknown>,
  userId: string,
) {
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  switch (message.type) {
    case "COMMS": {
      return message.sender_user_id === userId;
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      if (message.sender_user_id === userId) return true;

      const [user] = await props.loader.getRecord("user_contact_info", userId);

      if (!user) return false;

      const email = parseStringToEmailAddress(user.email_address);

      if (!email?.address) return false;

      return isMessageSentByEmail(props, email.address);
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function toFilter(props: IFilterProps<FilterValue<"to">>) {
  const { token, currentUserId } = props;

  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  switch (token.type) {
    case "text": {
      if (token.value === "me") {
        const [recipients] = await props.loader.getMessageUserRecipients({
          message_id: message.id,
        });

        return recipients.some(({ id }) => id === currentUserId);
      } else if (isEmail(token.value)) {
        return isMessageSentToEmail(props, token.value);
      } else {
        return isMessageSentToName(props, token.value);
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          return isMessageSentToUser(props, subjectId);
        }
        case "channel": {
          return isMessageSentToGroup(props, subjectId);
        }
        case "tag": {
          console.warn(`Attempted to filter on "to:" tag, ignoring.`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

async function isMessageSentToEmail(
  props: IFilterProps<unknown>,
  input: string,
) {
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;
  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      const [recipients] = await props.loader.getMessageUserRecipients({
        message_id: message.id,
      });

      const users = await Promise.all(
        recipients.map((r) =>
          props.loader.getRecord("user_contact_info", r.user_id),
        ),
      );

      return users.some(([user]) => {
        if (!user) return false;
        const email = parseStringToEmailAddress(user.email_address);
        if (!email?.address) return false;
        return email.address.toLowerCase() === lowercaseInput;
      });
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      const [recipients] = await props.loader.getMessageEmailRecipients({
        message_id: message.id,
        type: "TO",
      });

      return recipients.some((r) => {
        const email = parseStringToEmailAddress(r.email_address);
        if (!email) return false;

        return typeof email.address === "string"
          ? email.address.toLowerCase() === lowercaseInput
          : email.addresses
          ? email.addresses.some(
              (g) => g.address.toLowerCase() === lowercaseInput,
            )
          : throwUnreachableCaseError(email);
      });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentToName(
  props: IFilterProps<unknown>,
  input: string,
) {
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;
  const lowercaseInput = input.toLowerCase();

  switch (message.type) {
    case "COMMS": {
      const [recipients] = await props.loader.getMessageUserRecipients({
        message_id: message.id,
      });

      const users = await Promise.all(
        recipients.map((r) =>
          props.loader.getRecord("user_profile", r.user_id),
        ),
      );

      return users.some(([user]) => {
        if (!user) return false;
        return user.name.toLowerCase() === lowercaseInput;
      });
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      const [recipients] = await props.loader.getMessageEmailRecipients({
        message_id: message.id,
        type: "TO",
      });

      return recipients.some((r) => {
        const email = parseStringToEmailAddress(r.email_address);
        if (!email) return false;

        return (
          email.label?.toLowerCase().includes(lowercaseInput) ||
          (typeof email.address === "string"
            ? email.address.toLowerCase().includes(lowercaseInput)
            : email.addresses
            ? email.addresses.some((g) =>
                g.address.toLowerCase().includes(lowercaseInput),
              )
            : throwUnreachableCaseError(email))
        );
      });
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentToUser(
  props: IFilterProps<unknown>,
  userId: string,
) {
  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  switch (message.type) {
    case "COMMS": {
      const [recipients] = await props.loader.getMessageUserRecipients({
        message_id: message.id,
      });

      return recipients.some((r) => r.user_id === userId);
    }
    case "EMAIL":
    case "EMAIL_BCC": {
      const [[recipients], [user]] = await Promise.all([
        props.loader.getMessageUserRecipients({ message_id: message.id }),
        props.loader.getRecord("user_contact_info", userId),
      ]);

      if (recipients.some((r) => r.user_id === userId)) return true;
      if (!user) return false;

      const email = parseStringToEmailAddress(user.email_address);

      if (!email?.address) return false;

      return isMessageSentToEmail(props, email.address);
    }
    default: {
      throw new UnreachableCaseError(message.type);
    }
  }
}

async function isMessageSentToGroup(
  props: IFilterProps<unknown>,
  groupId: string,
) {
  const [recipients] = await props.loader.getMessageGroupRecipients({
    message_id: props.messageId,
  });

  return recipients.some((r) => r.group_id === groupId);
}

/* -----------------------------------------------------------------------------------------------*/

async function isFilter(props: IFilterProps<FilterValue<"is">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "is:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  switch (token.value) {
    case "done": {
      const [notification] = await props.loader.getRecord(
        getPointer("notification", {
          thread_id: props.threadId,
          user_id: props.currentUserId,
        }),
      );

      return !!notification?.is_done;
    }
    case "branch": {
      const [thread] = await props.loader.getRecord({
        table: "thread",
        id: props.threadId,
      });

      return !!thread?.is_branch;
    }
    case "private": {
      const [thread] = await props.loader.getRecord({
        table: "thread",
        id: props.threadId,
      });

      return thread?.visibility === "PRIVATE";
    }
    case "shared": {
      const [thread] = await props.loader.getRecord({
        table: "thread",
        id: props.threadId,
      });

      return thread?.visibility === "SHARED";
    }
    case "seen": {
      const [message] = await props.loader.getRecord({
        table: "message",
        id: props.messageId,
      });

      if (!message) return false;
      if (message.type === "EMAIL_BCC") return true;

      const [seen] = await props.loader.getRecord(
        getPointer("thread_seen_receipt", {
          thread_id: props.threadId,
          user_id: props.currentUserId,
        }),
      );

      return !!seen;
    }
    case "email": {
      const [message] = await props.loader.getRecord({
        table: "message",
        id: props.messageId,
      });

      if (!message) return false;
      return message.type === "EMAIL" || message.type === "EMAIL_BCC";
    }
    default: {
      console.warn(`Unknown option passed to "is:".`, token);
      return false;
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function afterFilter(props: IFilterProps<FilterValue<"after">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "after:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const timestamp = convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "after:" filter`);
    return false;
  }

  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  return message.sent_at >= timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function beforeFilter(props: IFilterProps<FilterValue<"before">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "before:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const timestamp = convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "after:" filter`);
    return false;
  }

  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  return message.sent_at < timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function viewerFilter(props: IFilterProps<FilterValue<"viewer">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [permittedUsers] = await props.loader.getThreadUserPermissions({
        thread_id: props.threadId,
      });

      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return permittedUsers.length === asNumber;
      } else if (token.value === "me") {
        return permittedUsers.some(({ id }) => id === props.currentUserId);
      } else if (isEmail(token.value)) {
        const lowercaseEmail = token.value.toLowerCase();

        const users = await Promise.all(
          permittedUsers.map((user) =>
            props.loader.getRecord("user_contact_info", user.id),
          ),
        );

        return users.some(([user]) => {
          if (!user) return false;
          const email = parseStringToEmailAddress(user.email_address);
          if (!email?.address) return false;
          return email.address.toLowerCase() === lowercaseEmail;
        });
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const users = await Promise.all(
          permittedUsers.map((user) =>
            props.loader.getRecord("user_profile", user.id),
          ),
        );

        return users.some(([user]) =>
          user?.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const [permittedUsers] = await props.loader.getThreadUserPermissions({
            thread_id: props.threadId,
          });

          return permittedUsers.some(({ id }) => id === subjectId);
        }
        case "channel":
        case "tag": {
          console.warn(`User provided a specified a ${subject} for "viewer:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function participatingFilter(
  props: IFilterProps<FilterValue<"participating">>,
) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [participatingUsers] = await props.loader.getThreadUserParticipants(
        {
          thread_id: props.threadId,
        },
      );

      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return participatingUsers.length === asNumber;
      } else if (token.value === "me") {
        return participatingUsers.some(({ id }) => id === props.currentUserId);
      } else if (isEmail(token.value)) {
        const lowercaseEmail = token.value.toLowerCase();

        const users = await Promise.all(
          participatingUsers.map((user) =>
            props.loader.getRecord("user_contact_info", user.id),
          ),
        );

        return users.some(([user]) => {
          if (!user) return false;
          const email = parseStringToEmailAddress(user.email_address);
          if (!email?.address) return false;
          return email.address.toLowerCase() === lowercaseEmail;
        });
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const users = await Promise.all(
          participatingUsers.map((user) =>
            props.loader.getRecord("user_profile", user.id),
          ),
        );

        return users.some(([user]) =>
          user?.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const [participatingUsers] =
            await props.loader.getThreadUserParticipants({
              thread_id: props.threadId,
            });

          return participatingUsers.some(({ id }) => id === subjectId);
        }
        case "channel":
        case "tag": {
          console.warn(`User provided a ${subject} for "participating:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function groupFilter(props: IFilterProps<FilterValue<"channel">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [permittedGroups] = await props.loader.getThreadGroupPermissions({
        thread_id: props.threadId,
      });

      const asNumber = Number.parseInt(token.value, 10);

      if (Number.isInteger(asNumber)) {
        return permittedGroups.length === asNumber;
      } else {
        const lowercaseInput = token.value.toLowerCase();

        const groups = await Promise.all(
          permittedGroups.map(({ id }) => props.loader.getRecord("tag", id)),
        );

        return groups.some(([group]) =>
          group?.name.toLowerCase().includes(lowercaseInput),
        );
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          console.warn(`User provided a user for "channel:".`);
          return false;
        }
        case "channel": {
          const [permittedGroups] =
            await props.loader.getThreadGroupPermissions({
              thread_id: props.threadId,
            });

          return permittedGroups.some(({ id }) => id === subjectId);
        }
        case "tag": {
          console.warn(`User provided a tag for "channel:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function tagFilter(props: IFilterProps<FilterValue<"tag">>) {
  const { token } = props;

  const [[threadTags], [notificationTags]] = await Promise.all([
    props.loader.getThreadTags({ thread_id: props.threadId }),
    props.loader.getNotificationTags({
      notification_id: getRecordId("notification", {
        thread_id: props.threadId,
        user_id: props.currentUserId,
      }),
    }),
  ]);

  const tagIds = uniq([
    ...threadTags.map((t) => t.tag_id),
    ...notificationTags.map((t) => t.tag_id),
  ]);

  const tags = await Promise.all(
    tagIds.map((id) => props.loader.getRecord("tag", id)),
  );

  switch (token.type) {
    case "text": {
      const lowercaseInput = token.value.toLowerCase();

      return tags.some(([tag]) => tag?.name.toLowerCase() === lowercaseInput);
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          console.warn(`User specified a user for "tag:".`);
          return false;
        }
        case "channel": {
          console.warn(`User specified a channel for "tag:".`);
          return false;
        }
        case "tag": {
          return tags.some(([tag]) => tag?.id === subjectId);
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function subjectFilter(props: IFilterProps<FilterValue<"subject">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [thread] = await props.loader.getRecord({
        table: "thread",
        id: props.threadId,
      });

      if (!thread) return false;
      return thread.subject.toLowerCase().includes(token.value.toLowerCase());
    }
    case "DocId": {
      console.warn(`provided a docId to "subject:".`);
      return false;
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function bodyFilter(props: IFilterProps<FilterValue<"body">>) {
  const { token } = props;

  const [message] = await props.loader.getRecord({
    table: "message",
    id: props.messageId,
  });

  if (!message) return false;

  switch (token.type) {
    case "text": {
      return message.body_text
        .toLowerCase()
        .includes(token.value.toLowerCase());
    }
    case "DocId": {
      console.warn(`provided a docId to "body:".`);
      return false;
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function hasFilter(props: IFilterProps<FilterValue<"has">>) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "has:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const [notification] = await props.loader.getRecord(
    getPointer("notification", {
      thread_id: props.threadId,
      user_id: props.currentUserId,
    }),
  );

  if (!notification) return false;

  switch (token.value) {
    case "reminder": {
      return notification.has_reminder;
    }
    case "notification": {
      return !!notification;
    }
    default: {
      console.warn(`provided unknown value "${token.value}" to "has:".`);
      return false;
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function remindAfterFilter(
  props: IFilterProps<FilterValue<"remindAfter">>,
) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "remind-after:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const [notification] = await props.loader.getRecord(
    getPointer("notification", {
      thread_id: props.threadId,
      user_id: props.currentUserId,
    }),
  );

  if (!notification?.remind_at) return false;

  const timestamp = convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "remind-after:" filter`);
    return false;
  }

  return notification.remind_at >= timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function remindBeforeFilter(
  props: IFilterProps<FilterValue<"remindBefore">>,
) {
  const { token } = props;

  if (token.type === "DocId") {
    console.warn(`Document ID passed to "remind-before:".`);
    return false;
  } else if (token.type !== "text") {
    throw new UnreachableCaseError(token);
  }

  const [notification] = await props.loader.getRecord(
    getPointer("notification", {
      thread_id: props.threadId,
      user_id: props.currentUserId,
    }),
  );

  if (!notification?.remind_at) return false;

  const timestamp = convertStringToTimestamp(token.value);

  if (!timestamp) {
    console.warn(`Invalid value provided to "remind-before:" filter`);
    return false;
  }

  return notification.remind_at < timestamp;
}

/* -----------------------------------------------------------------------------------------------*/

async function mentionsFilter(props: IFilterProps<FilterValue<"mentions">>) {
  const { token } = props;

  switch (token.type) {
    case "text": {
      const [recipients] = await props.loader.getMessageUserRecipients({
        message_id: props.messageId,
      });

      const mentionedUserIds = recipients
        .filter((r) => r.is_mentioned)
        .map((r) => r.user_id);

      if (token.value === "me") {
        return mentionedUserIds.some(
          (userId) => userId === props.currentUserId,
        );
      } else if (isEmail(token.value)) {
        const users = await Promise.all(
          mentionedUserIds.map((userId) =>
            props.loader.getRecord("user_contact_info", userId),
          ),
        );

        const lowercaseInput = token.value.toLowerCase();

        return users.some(([user]) => {
          if (!user) return false;

          const email = parseStringToEmailAddress(user.email_address);
          if (!email) return false;

          return typeof email.address === "string"
            ? email.address.toLowerCase() === lowercaseInput
            : email.addresses
            ? email.addresses.some(
                (g) => g.address.toLowerCase() === lowercaseInput,
              )
            : throwUnreachableCaseError(email);
        });
      } else {
        const users = await Promise.all(
          mentionedUserIds.map((userId) =>
            props.loader.getRecord("user_contact_info", userId),
          ),
        );

        const lowercaseInput = token.value.toLowerCase();

        return users.some(([user]) => {
          if (!user) return false;

          const email = parseStringToEmailAddress(user.email_address);
          if (!email) return false;

          return (
            email.label?.toLowerCase().includes(lowercaseInput) ||
            (typeof email.address === "string"
              ? email.address.toLowerCase().includes(lowercaseInput)
              : email.addresses
              ? email.addresses.some((g) =>
                  g.address.toLowerCase().includes(lowercaseInput),
                )
              : throwUnreachableCaseError(email))
          );
        });
      }
    }
    case "DocId": {
      const { subject, subjectId } = parseDocId(token);

      switch (subject) {
        case "user": {
          const [recipients] = await props.loader.getMessageUserRecipients({
            message_id: props.messageId,
          });

          const isSubjectMentioned = recipients
            .filter((r) => r.is_mentioned)
            .some((r) => r.user_id === subjectId);

          return isSubjectMentioned;
        }
        case "channel": {
          const [recipients] = await props.loader.getMessageGroupRecipients({
            message_id: props.messageId,
          });

          const isSubjectMentioned = recipients
            .filter((r) => r.is_mentioned)
            .some((r) => r.group_id === subjectId);

          return isSubjectMentioned;
        }
        case "tag": {
          console.warn(`User provided a tag for "mentions:".`);
          return false;
        }
        default: {
          throw new UnreachableCaseError(subject);
        }
      }
    }
    default: {
      throw new UnreachableCaseError(token);
    }
  }
}

/* -----------------------------------------------------------------------------------------------*/

async function priorityFilter(props: IFilterProps<FilterValue<"priority">>) {
  const { token } = props;

  if (token.type !== "text") {
    throw new UnreachableCaseError(token.value as never);
  }

  const [notification] = await props.loader.getRecord(
    getPointer("notification", {
      thread_id: props.threadId,
      user_id: props.currentUserId,
    }),
  );

  if (!notification) return false;

  const hasPriority = (priority: number) => notification.priority === priority;

  if (token.value.includes("@@@") || token.value === "100") {
    return hasPriority(100);
  } else if (token.value.includes("@@") || token.value === "200") {
    return hasPriority(200);
  } else if (token.value.includes("@") || token.value === "300") {
    return hasPriority(300);
  } else if (token.value === "participating" || token.value === "400") {
    return hasPriority(400);
  } else if (token.value === "subscriber" || token.value === "500") {
    return hasPriority(500);
  } else {
    throw new UnreachableCaseError(token.value as never);
  }
}

/* -----------------------------------------------------------------------------------------------*/

function andOperator(props: IFilterProps<LogicalOperator<"and()">>) {
  return areAllResultsTrue(
    bundleOrOperators(props.token.value).map((token) =>
      matchQueryToken(token, props),
    ),
  );
}

/* -----------------------------------------------------------------------------------------------*/

function orOperator(props: IFilterProps<LogicalOperator<"or()">>) {
  return isAnyResultTrue(
    bundleOrOperators(props.token.value).map((token) =>
      matchQueryToken(token, props),
    ),
  );
}

/* -----------------------------------------------------------------------------------------------*/

async function notOperator(props: IFilterProps<LogicalOperator<"not()">>) {
  const result = await areAllResultsTrue(
    bundleOrOperators(props.token.value).map((token) =>
      matchQueryToken(token, props),
    ),
  );

  return !result;
}

/* -----------------------------------------------------------------------------------------------*/

function isEmail(input: string) {
  return EMAIL_ADDRESS_REGEXP.test(input);
}

function areAllResultsTrue(results: Promise<boolean>[] | boolean[]) {
  return Promise.all(results).then((result) => result.every((value) => value));
}

function isAnyResultTrue(results: Promise<boolean>[] | boolean[]) {
  return Promise.all(results).then((result) => result.some((value) => value));
}

function parseDocId(token: Token<"DocId", string>) {
  const [subject, mentionLevel, subjectId] = token.value.split("::") as [
    "user" | "channel" | "tag",
    string,
    string,
  ];

  return { subject, mentionLevel, subjectId };
}

/**
 * In search, "or()" operators are compared to each each other and one
 * of them must be `true`. In Comms, we bundle all the "or()"
 * clauses together to make a single "or()" operator which compares all
 * the clauses looking for one which is true.
 */
function bundleOrOperators(tokens: ParsedToken[]) {
  const { orOperator, otherTokens } = tokens.reduce(
    (store, curr) => {
      if (curr.type === "or()") {
        store.orOperator.value.push({
          ...curr,
          type: "and()",
        });
      } else {
        store.otherTokens.push(curr);
      }

      return store;
    },
    {
      otherTokens: [] as ParsedToken[],
      orOperator: {
        type: "or()",
        value: [],
      } as LogicalOperator<"or()">,
    },
  );

  if (orOperator.value.length === 0) return otherTokens;

  // The orOperator should not be placed first. If a fulltext search query
  // is present we expect it to be the first token.
  return [...otherTokens, orOperator];
}

/* -----------------------------------------------------------------------------------------------*/

function convertStringToTimestamp(input: string): string | null {
  const dateMatch = input.match(/(\d{4})\/(\d{1,2})\/(\d{1,2})/);

  if (!dateMatch) return null;

  const date = new Date(dateMatch[0]);

  if (isNaN(date.valueOf())) return null;

  return date.toISOString();
}

/* -----------------------------------------------------------------------------------------------*/
