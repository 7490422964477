import { usePrevious } from "react-use";

export function useAssertInvariant(value: unknown, msg?: string) {
  const previous = usePrevious(value);

  if (previous !== undefined && value !== previous) {
    console.error(
      `useAssertInvarient: value changed from ${previous} to ${value}`,
    );

    throw new Error(
      msg
        ? `useAssertInvariant: ${msg}`
        : `useAssertInvarient: value changed from ${previous} to ${value}`,
    );
  }
}
