import * as _uuid from "uuid";

export const uuid = _uuid.v4;

/**
 * This is the ID for the special "Comms" admin user.
 */
export const COMMS_ADMIN_USER_ID = "f6455fae-df94-4cf0-8970-8f4d1d563942";

/**
 * This accepts a spread of input strings and uses them to derive a
 * new UUID. The UUID is guaranteed to be the same for the same input
 * strings, but different for different input strings.
 */
export function deriveUUID(...input: string[]) {
  const combined = input.sort().join("");

  return _uuid.v5(combined, UUID_NAMESPACE);
}

const UUID_NAMESPACE = "2ac01d6b-180b-49b3-9bcc-c9ce1af42afc";
