import {
  switchMap,
  of,
  shareReplay,
  Observable,
  distinctUntilChanged,
  map,
  pipe,
  filter,
  combineLatest,
  tap,
  interval,
} from "rxjs";
import {
  assertPredicate,
  catchFailedPredicateError,
  startWith,
} from "@libs/utils/rxjs-operators";
import { isNonNullable } from "@libs/utils/predicates";
import { useObservableEagerState, useObservableState } from "observable-hooks";
import { RecordValue } from "libs/schema";
import { observeRecord } from "~/observables/observeRecord";
import { ENVIRONMENT$, getEnvironment } from "./ClientEnvironmentContext";
import { signOut } from "firebase/auth";
import { auth } from "~/firebase";
import Cookies from "js-cookie";

export const CURRENT_USER_ID$ = interval(1000).pipe(
  startWith(() => null),
  map(() => Cookies.get("userId") || null),
  distinctUntilChanged(),
  shareReplay(1),
);

CURRENT_USER_ID$.subscribe((userId) => {
  console.log("CURRENT_USER_ID$", userId);
});

/**
 * Important!
 * Most components should get the currentUser from the AuthGuardContext
 * via useAuthGuardContext. Only use this hook if outside the
 * AuthGuardContext (rare).
 */
export function useCurrentUserId() {
  return useObservableEagerState(CURRENT_USER_ID$);
}

export const CURRENT_USER$: Observable<RecordValue<"user_profile"> | null> =
  combineLatest([CURRENT_USER_ID$, ENVIRONMENT$]).pipe(
    filter(([_0, env]) => !!env),
    switchMap(([userId]) => {
      if (!userId) return of(null);

      return observeRecord({
        table: "user_profile",
        id: userId,
      }).pipe(
        tap(
          (r) => console.log("observeRecord", r),
          (err) => console.error("observeRecord", err),
        ),
        filter((r) => !r.isLoading),
        map((r) => r.record),
      );
    }),
    shareReplay(1),
  );

CURRENT_USER$.subscribe((user) => {
  currentUser = user;
});

export const ASSERT_CURRENT_USER$: Observable<RecordValue<"user_profile">> =
  CURRENT_USER$.pipe(assertPredicate("ASSERT_CURRENT_USER", isNonNullable));

export const ASSERT_CURRENT_USER_ID$: Observable<string> =
  ASSERT_CURRENT_USER$.pipe(
    map((user) => user.id),
    distinctUntilChanged(),
  );

/**
 * The `catchNoCurrentUserError` is intended to be used in
 * conjunction with the `ASSERT_CURRENT_USER$` or
 * `ASSERT_CURRENT_USER_ID$` observables.
 * Together, they allow us to subscribe to the current user
 * and pretend like it will always be non-null in observables
 * that should only be called while the user is logged in.
 *
 * See https://github.com/ReactiveX/rxjs/discussions/6992#discussioncomment-2936961
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function catchNoCurrentUserError<I extends any[]>(
  defaultValue: () => never[],
): (source: Observable<I>) => Observable<I>;
export function catchNoCurrentUserError<I, T>(
  defaultValue: () => T,
): (source: Observable<I>) => Observable<I | T>;
export function catchNoCurrentUserError<I, T>(
  defaultValue: () => T,
): (source: Observable<I>) => Observable<I | T> {
  return pipe(catchFailedPredicateError("ASSERT_CURRENT_USER", defaultValue));
}

export function useCurrentUser() {
  return useObservableState(() => CURRENT_USER$, null);
}

export async function signout() {
  const env = getEnvironment();
  return Promise.all([signOut(auth), env.api.logout()]);
}

export function getCurrentUserId(): string | null {
  return Cookies.get("userId") || null;
}

export function getAndAssertCurrentUserId(): string {
  const currentUserId = getCurrentUserId();

  if (!currentUserId) {
    throw new Error("Expected current user to be signed in but they are not.");
  }

  return currentUserId;
}

let currentUser: RecordValue<"user_profile"> | null = null;

export function getCurrentUser(): RecordValue<"user_profile"> | null {
  return currentUser;
}

export function getAndAssertCurrentUser(): RecordValue<"user_profile"> {
  if (!currentUser) {
    throw new Error("Expected current user to be signed in but they are not.");
  }

  return currentUser;
}
