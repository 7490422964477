import sqlite3InitModule, { Database, SqlValue } from "@sqlite.org/sqlite-wasm";
import { Observable, from, share, switchMap, throttle } from "rxjs";
import {
  iterateRecordMap,
  RecordMap,
  RecordPointer,
  RecordTable,
  RecordValue,
  setMapRecord,
  TABLE_NAMES,
} from "libs/schema";
import { ValidationError } from "libs/transaction";
import { getSqlToUpsertRecord } from "libs/getSqlToUpsertRecord";
import { hasIntersection } from "@libs/utils/predicates";
import { astVisitor, parse } from "pgsql-ast-parser";
import {
  DatabaseAdapter,
  DatabaseChange,
  RecordsResult,
  Statement,
} from "./ClientDatabaseApi";
import { fromDatabaseDecoders, recordToDatabaseFnMap, schema } from "./schema";
import { Logger } from "libs/logger";
import { Decoder, isDecoderSuccess } from "ts-decoders";
import { arrayD } from "ts-decoders/decoders";
import { cacheReplayForTime } from "@libs/utils/rxjs-operators";
import { SqlDatabaseBase } from "libs/QueryApi";
import { sql } from "libs/sql-statement";

const modulePromise = sqlite3InitModule({
  print: console.log,
  printErr: console.error,
});

export class SQLiteDatabase extends SqlDatabaseBase implements DatabaseAdapter {
  static async init(props: { logger: Logger }) {
    const sqlite3 = await modulePromise;
    const sqliteDB = new sqlite3.oo1.DB(":memory:");
    sqliteDB.exec(schema);
    const db = new SQLiteDatabase(sqliteDB, props.logger);
    await db.logDatabaseState();
    return db;
  }

  private changeSubscriptions = new Set<(change: DatabaseChange) => void>();
  protected recordDecoderMap?: {
    [T in RecordTable]: Decoder<RecordValue<T>>;
  };

  private constructor(private db: Database, protected logger: Logger) {
    super();
  }

  protected async query(statement: Statement) {
    const { resultRows } = await this.exec({
      sql: statement.text,
      bind: statement.params || statement.values,
    });

    return resultRows;
  }

  async exec(
    args: SQLiteClientExecProps,
  ): Promise<{ resultRows: Array<{ [columnName: string]: SqlValue }> }> {
    const resultRows = this.db.exec({
      sql: args.sql,
      bind: args.bind,
      returnValue: "resultRows",
      rowMode: "object",
    });

    return { resultRows };
  }

  async getRecords<Table extends RecordTable>(
    table: Table,
    statement: Statement,
  ): Promise<RecordsResult<RecordValue<Table>>> {
    if (statement.params && statement.values) {
      throw new ValidationError(
        `getRecords: use Statement#params or Statement#values, not both.`,
      );
    }

    const { resultRows } = await this.exec({
      sql: statement.text,
      bind: statement.params || statement.values,
    });

    const decoder = fromDatabaseDecoders[table];

    if (!decoder) {
      this.logger.error(`getRecords: could not find decoder for ${table}`);
      return { records: [] };
    }

    const decoded = arrayD(decoder).decode(resultRows);

    if (isDecoderSuccess(decoded)) {
      return { records: decoded.value as RecordValue<Table>[] };
    }

    this.logger.error(`getRecords: decoder error`, decoded);

    return { records: [] };
  }

  // async getPartialRecords<
  //   Table extends RecordTable,
  //   Result extends Partial<RecordValue<Table>> = Partial<RecordValue<Table>>,
  // >(table: Table, statement: Statement): Promise<RecordsResult<Result>> {
  //   if (statement.params && statement.values) {
  //     throw new ValidationError(
  //       `getRecords: use Statement#params or Statement#values, not both.`,
  //     );
  //   }

  //   const { resultRows } = await this.exec({
  //     sql: statement.sql,
  //     bind: statement.params || statement.values,
  //   });

  //   const decoder = fromDatabasePartialDecoders[table];

  //   if (!decoder) {
  //     this.logger.error(
  //       `getPartialRecords: could not find decoder for ${table}`,
  //     );
  //     return { records: [] };
  //   }

  //   const decoded = arrayD(decoder).decode(resultRows);

  //   if (isDecoderSuccess(decoded)) {
  //     return { records: decoded.value as Result[] };
  //   }

  //   this.logger.error(`getPartialRecords: decoder error`, decoded);

  //   return { records: [] };
  // }

  recordCache = new Map<string, Observable<RecordValue | null>>();

  observeRecord<T extends RecordTable>(
    pointer: RecordPointer<T>,
  ): Observable<RecordValue<T> | null> {
    const { table, id } = pointer;

    const cacheKey = `${table}:${id}`;
    const cachedQuery = this.recordCache.get(cacheKey);

    if (cachedQuery) {
      return cachedQuery as Observable<RecordValue<T> | null>;
    }

    if (!TABLE_NAMES.includes(table)) {
      throw new ValidationError(`liveRecord: invalid record table "${table}"`);
    }

    const runQuery = () => this.getRecord(pointer);

    const subscribe = (onChange: () => void) =>
      this.subscribeToRecordChanges(({ changes }) => {
        if (!changes[table] || !(id in (changes[table] as object))) return;
        onChange();
      });

    const observable = queryObservable<RecordValue<T> | null>({
      runQuery,
      subscribe,
    }).pipe(
      cacheReplayForTime({
        timeMs: 100,
        onInit: () => {
          this.recordCache.set(cacheKey, observable);
        },
        onCleanup: () => {
          this.recordCache.delete(cacheKey);
        },
      }),
    );

    return observable;
  }

  observeRecords<Table extends RecordTable>(
    table: Table,
    statement: Statement,
  ): Observable<RecordsResult<RecordValue<Table>>> {
    const affectedTableNames = parseTableNames(statement.text);

    if (affectedTableNames.length === 0) {
      throw new Error("Could not calculate selected table names");
    }

    const runQuery = () => this.getRecords(table, statement);

    const subscribe = (onChange: () => void) =>
      this.subscribeToRecordChanges(({ tableNames }) => {
        if (!hasIntersection(affectedTableNames, tableNames)) return;
        onChange();
      });

    return queryObservable<RecordsResult<RecordValue<Table>>>({
      runQuery,
      subscribe,
    });
  }

  // observePartialRecords<
  //   Table extends RecordTable,
  //   Result extends Partial<RecordValue<Table>> = Partial<RecordValue<Table>>,
  // >(table: Table, statement: Statement): Observable<RecordsResult<Result>> {
  //   const affectedTableNames = parseTableNames(statement.sql);

  //   if (affectedTableNames.length === 0) {
  //     throw new Error("Could not calculate selected table names");
  //   }

  //   const runQuery = () =>
  //     this.getPartialRecords<Table, Result>(table, statement);

  //   const subscribe = (onChange: () => void) =>
  //     this.subscribeToRecordChanges(({ tableNames }) => {
  //       if (!hasIntersection(affectedTableNames, tableNames)) return;
  //       onChange();
  //     });

  //   return queryObservable<RecordsResult<Result>>({
  //     runQuery,
  //     subscribe,
  //   });
  // }

  async writeRecordMap(
    recordMap: RecordMap,
    options: { forceUpdate?: boolean } = {},
  ) {
    const tables = Object.keys(recordMap);

    if (tables.length === 0) return;

    const { forceUpdate = false } = options;

    console.debug("writeRecordMap", recordMap);

    const changeRecordMap: RecordMap = {};

    this.db.transaction((db) => {
      for (const pointerWithRecord of iterateRecordMap(recordMap)) {
        const mapRecordToDatabase =
          recordToDatabaseFnMap[pointerWithRecord.table];

        if (!mapRecordToDatabase) {
          throw new Error(
            `writeRecordMap: could not find record mapper for ${pointerWithRecord.table}`,
          );
        }

        const statement = sql`
          SELECT
            version
          FROM
            "${sql.raw(pointerWithRecord.table)}"
          WHERE
            id = ${pointerWithRecord.id}
          LIMIT
            1;
        `;

        const rows = this.db.exec({
          sql: statement.text,
          bind: statement.values as any[],
          returnValue: "resultRows",
          rowMode: "object",
        });

        const oldVersion = rows[0]?.version as number | undefined;

        if (
          forceUpdate ||
          oldVersion === undefined ||
          pointerWithRecord.record.version > oldVersion
        ) {
          setMapRecord(
            changeRecordMap,
            pointerWithRecord,
            pointerWithRecord.record,
          );

          let record: any;

          try {
            record = mapRecordToDatabase(pointerWithRecord.record);
          } catch (e) {
            console.error(e, pointerWithRecord);
            throw e;
          }

          const { text, values } = getSqlToUpsertRecord(
            {
              table: pointerWithRecord.table,
              id: pointerWithRecord.id,
              record,
            } as any,
            forceUpdate,
          );

          db.exec({
            sql: text,
            bind: values as SqlValue[],
            returnValue: "resultRows",
            rowMode: "object",
          });
        }
      }
    });

    const changedTables = Object.keys(changeRecordMap);

    console.debug(
      "writeRecordMap changes",
      changedTables.length,
      changeRecordMap,
    );

    if (changedTables.length === 0) return;

    this.emitTableChanges({
      tableNames: changedTables,
      changes: changeRecordMap,
    });
  }

  /**
   * Currently emissions may contain records which are not changed.
   */
  subscribeToRecordChanges(callback: (change: DatabaseChange) => void) {
    this.changeSubscriptions.add(callback);

    return () => {
      this.changeSubscriptions.delete(callback);
    };
  }

  private emitTableChanges(change: DatabaseChange) {
    console.debug("emitTableChanges", change);
    for (const callback of this.changeSubscriptions) {
      callback(change);
    }
  }

  private async logDatabaseState() {
    const tables = this.db
      .exec("SELECT name FROM sqlite_master WHERE type='table';", {
        returnValue: "resultRows",
        rowMode: "object",
      })
      .map((row) => row.name as string);

    for (const table of tables) {
      const result = await this.exec({
        sql: `SELECT * FROM ${table};`,
      });

      console.log(`Table: ${table}`);
      console.table(result.resultRows);
    }
  }
}

export function queryObservable<T>(args: {
  runQuery: () => Promise<T>;
  subscribe: (onChanges: () => void) => () => void;
}): Observable<T> {
  const { runQuery, subscribe } = args;

  let query: Promise<T>;

  return new Observable((subscriber) => {
    const unsubscribe = subscribe(async () => subscriber.next(null));
    subscriber.next(null);

    return () => {
      unsubscribe();
    };
  }).pipe(
    throttle(
      () => {
        // This works because we have `leading: true` so emitted values
        // first pass through `throttle` (triggering switchMap) before
        // this factory function is called (confirmed expirimentally).
        // Additionally, it's important that we're using `share()` so
        // that there's only one subscriber updating the `query`
        // variable.
        return from(query);
      },
      {
        leading: true,
        trailing: true,
      },
    ),
    switchMap(() => {
      query = runQuery();
      return query;
    }),
    share({ resetOnRefCountZero: true }),
  );
}

/**
 * Note that this function internally uses a SQL parser
 * made for Postgres' SQL syntax. It's possible we might
 * run into edge cases in the future where a SQLite query
 * causes this to error.
 */
export function parseTableNames(sqlQuery: string): string[] {
  const statements = parse(sqlQuery);

  if (statements.length !== 1) {
    throw new Error(`parseTableNames: Must receive exactly one SQL statement`);
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const statement = statements[0]!;

  const tables = new Set<string>();

  const visitor = astVisitor(() => ({
    tableRef: (t) => tables.add(t.name),
  }));

  visitor.statement(statement);

  return Array.from(tables);
}

type SQLiteClientExecProps = {
  sql: string;
  /**
   * Binds one or more values to its bindable parameters. It accepts 1 or 2 arguments:
   *
   * If passed a single argument, it must be either an array, an object, or a value of
   * a bindable type (see below). Its bind index is assumed to be 1.
   *
   * If passed 2 arguments, the first one is the 1-based bind index or bindable
   * parameter name and the second one must be a value of a bindable type.
   *
   * Bindable value types:
   * - null is bound as NULL.
   * - undefined as a standalone value is a no-op: passing undefined as a value to this
   *   function will not actually bind anything and this function will skip confirmation
   *   that binding is even legal. (Those semantics simplify certain client-side uses.)
   *   Conversely, a value of undefined as an array or object property when binding an
   *   array/object (see below) is treated the same as null.
   * - Numbers are bound as either doubles or integers: doubles if they are larger than
   *   32 bits, else double or int32, depending on whether they have a fractional part.
   *   Booleans are bound as integer 0 or 1. It is not expected the distinction of
   *   binding doubles which have no fractional parts as integers is significant for the
   *   majority of clients due to sqlite3's data typing model. If BigInt support is
   *   enabled then this routine will bind BigInt values as 64-bit integers if they'll
   *   fit in 64 bits. If that support is disabled, it will store the BigInt as an int32
   *   or a double if it can do so without loss of precision. In either case, if a BigInt
   *   is too BigInt then it will throw.
   * - Strings are bound as strings (use bindAsBlob() to force blob binding).
   * - Uint8Array, Int8Array, and ArrayBuffer instances are bound as blobs.
   *
   * If passed an array, each element of the array is bound at the parameter index equal
   * to the array index plus 1 (because arrays are 0-based but binding is 1-based).
   *
   * If passed an object, each object key is treated as a bindable parameter name. The
   * object keys must match any bindable parameter names, including any $, @, or : prefix.
   * Because $ is a legal identifier chararacter in JavaScript, that is the suggested
   * prefix for bindable parameters: stmt.bind({$a: 1, $b: 2}).
   *
   * It returns this object on success and throws on error. Errors include:
   * - Any bind index is out of range, a named bind parameter does not match, or this
   *   statement has no bindable parameters.
   * - Any value to bind is of an unsupported type.
   * - Passed no arguments or more than two.
   * - The statement has been finalized.
   */
  bind?: any[] | { [key: string]: any };
  /**
   * One of
   * - 'array' (the default) causes the results of stmt.get([]) to be passed to the
   *   callback and/or appended to resultRows.
   * - 'object' causes the results of stmt.get(Object.create(null)) to be passed to the
   *   callback and/or appended to resultRows. Achtung: an SQL result may have multiple
   *   columns with identical names. In that case, the right-most column will be the one
   *   set in this object!
   */
  // rowMode?: "object" | "array";
};
