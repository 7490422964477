import { Loader } from "@libs/chet-stack/Loader";
import { startWith } from "@libs/utils/rxjs-operators";
import { UnreachableCaseError } from "libs/errors";
import {
  ApiQueryMinusGetRecords,
  GetQueryTable,
  GetRecordParams,
  queryToTableMap,
  RecordLoaderApi,
  RecordLoaderGetResult,
  RecordLoaderQueryResult,
} from "libs/QueryApi";
import {
  getMapRecords,
  getPointer,
  RecordPointer,
  RecordTable,
  RecordValue,
} from "libs/schema";
import { difference, lowerFirst } from "lodash-comms";
import {
  combineLatest,
  from,
  map,
  Observable,
  of,
  pairwise,
  switchMap,
} from "rxjs";
import { Simplify } from "type-fest";
import { ENVIRONMENT$ } from "./ClientEnvironmentContext";
import { ClientQueryApi, OriginalMethod, RecordsResult } from "./database";
import { LoaderCache, loadQuery, loadRecord } from "./LoaderCache";
import { getQuerySubscriptionKeys } from "./SubscriptionManager";
import {
  CURRENT_USER_ID$,
  getAndAssertCurrentUserId,
  getCurrentUserId,
} from "./user.service";

/**
 * ClientRecordLoader
 *
 * The client record loader handles loading records and queries from the local
 * cache/database as well transparently as fetching data from the server, as
 * appropriate. When subscribing to queries, the record loader will also
 * subscribe to query updates from the server.
 */
export type ClientRecordLoaderApi = Simplify<
  GetClientQueryApi &
    ObserveClientQueryApi & {
      createObserveGetResult<T extends RecordTable>(
        options?: Partial<ObserveGetResult<T>>,
      ): Observable<ObserveGetResult<T>>;
      createObserveQueryResult<T extends RecordTable>(
        options?: Partial<ObserveQueryResult<T>>,
      ): Observable<ObserveQueryResult<T>>;
      observeRecords<T extends RecordTable>(
        pointers: RecordPointer<T>[],
        options?: GetOptions,
      ): Observable<{ records: RecordValue<T>[]; isLoading: boolean }>;
    }
>;

export function createRecordLoader(db: ClientQueryApi) {
  const _proxy = new Proxy(db, {
    get(_0, prop: keyof ClientRecordLoaderApi) {
      if (prop === "getRecord") {
        return async (
          a: RecordTable | GetRecordParams,
          b?: string | GetOptions,
          c?: GetOptions,
        ): Promise<GetResult> => {
          const pointer =
            typeof a === "string" ? getPointer(a, b as string) : getPointer(a);

          const options = (typeof b === "string" ? c : b) || {};

          const { fetchStrategy = "server-first" } = options;

          if (pointer.id === "me") {
            const currentUserId = getCurrentUserId();

            if (!currentUserId) {
              return [null];
            }

            pointer.id = currentUserId;
          }

          switch (fetchStrategy) {
            case "server-first": {
              const loader = loadRecord({ pointer });
              await loader.promise;
              const record = await db.getRecord(pointer);
              return [record || null];
            }
            case "refetch": {
              const loader = loadRecord({ pointer, forceFromServer: true });
              await loader.promise;
              const record = await db.getRecord(pointer);
              return [record || null];
            }
            case "cache-first": {
              let record = await db.getRecord(pointer);

              if (record) return [record];

              const loader = loadRecord({ pointer });

              if (loader.resolved) {
                // This indicates that we've already tried to load this record
                // and it doesn't exist.
                return [null];
              }

              await loader.promise;

              record = await db.getRecord(pointer);
              return [record || null];
            }
            case "cache-only": {
              const record = await db.getRecord(pointer);
              return [record || null];
            }
            default: {
              throw new UnreachableCaseError(fetchStrategy);
            }
          }
        };
      }

      if (prop.startsWith("get")) {
        const methodName = prop as Exclude<GetClientQueryApiKeys, "getRecord">;

        return async (
          params: { limit?: number },
          options: GetOptions = {},
        ): Promise<QueryResult> => {
          const { fetchStrategy = "server-first" } = options;
          const { limit = null } = params;
          // We overfetch by one so that we can determine if there are
          // more records to fetch.
          const modifiedParams = limit
            ? { ...params, limit: limit + 1 }
            : params;

          if ("userId" in modifiedParams && modifiedParams.userId === "me") {
            const currentUserId = getAndAssertCurrentUserId();
            modifiedParams.userId = currentUserId;
          }

          if ("user_id" in modifiedParams && modifiedParams.user_id === "me") {
            const currentUserId = getAndAssertCurrentUserId();
            modifiedParams.user_id = currentUserId;
          }

          const result = db[methodName](modifiedParams as never);

          const runQuery = async (): Promise<QueryResult> => {
            const queryResults = await result.runQuery();

            const totalRecords = getMapRecords(
              queryResults,
              result.primaryTable,
            );

            const records = limit ? totalRecords.slice(0, limit) : totalRecords;
            const nextId =
              totalRecords.length > records.length
                ? totalRecords.at(-1)?.id || null
                : null;

            return [
              records,
              {
                nextId,
                limit,
              },
            ];
          };

          switch (fetchStrategy) {
            case "server-first":
            case "refetch": {
              const loader = loadQuery({
                type: methodName,
                params: modifiedParams,
              });

              await loader.promise;
              return runQuery();
            }
            case "cache-first": {
              const query = await runQuery();

              if (query[0].length > 0) return query;

              const loader = loadQuery({
                type: methodName,
                params: modifiedParams,
              });

              if (loader.resolved) {
                // This indicates that we've already tried to load this query
                // and it's empty.
                return [[], { nextId: null, limit }];
              }

              await loader.promise;

              return runQuery();
            }
            case "cache-only": {
              return runQuery();
            }
            default: {
              throw new UnreachableCaseError(fetchStrategy);
            }
          }
        };
      }

      if (prop === "observeGetRecord") {
        return (
          params: GetRecordParams,
          options: GetOptions = {},
        ): Observable<ObserveGetResult> => {
          const pointer = getPointer(params);
          const subscriptionKeys = getQuerySubscriptionKeys({
            type: "getRecord",
            params: pointer,
          });

          const doesIdEqualMe = pointer.id === "me";

          return combineLatest([ENVIRONMENT$, CURRENT_USER_ID$]).pipe(
            switchMap(([environment, currentUserId]) => {
              if (!environment) {
                return of({
                  record: null,
                  isLoading: false,
                });
              }

              if (doesIdEqualMe) {
                if (!currentUserId) {
                  return of({
                    record: null,
                    isLoading: false,
                  });
                }

                pointer.id = currentUserId;
              }

              const { subscriptionManager, db } = environment;

              return new Observable<ObserveGetResult>((subscriber) => {
                const loader = loadRecord({ pointer });

                const pubsubUnsubscribeFns: Array<() => void> = [];

                for (const key of subscriptionKeys) {
                  pubsubUnsubscribeFns.push(subscriptionManager.subscribe(key));
                }

                const isLoading$ = loader.resolved
                  ? of(false)
                  : from(
                      loader.promise.then(
                        () => false,
                        () => false,
                      ),
                    ).pipe(startWith(() => true));

                const record$ = db.observeRecord(pointer);

                const query$ = combineLatest([record$, isLoading$]).pipe(
                  map(([record, isLoading]) => ({ record, isLoading })),
                );

                const unsubscribeFromQuery = query$.subscribe(subscriber);

                return () => {
                  pubsubUnsubscribeFns.forEach((fn) => fn());
                  unsubscribeFromQuery.unsubscribe();
                };
              });
            }),
          );
        };
      }

      if (prop.startsWith("observe")) {
        const methodName = prop as Exclude<
          ObserveClientQueryApiKeys,
          "observeGetRecord"
        >;

        const originalMethodName = lowerFirst(
          prop.replace(/^observe/, ""),
        ) as Exclude<GetClientQueryApiKeys, "getRecord">;

        const table = queryToTableMap[originalMethodName];

        if (!table) {
          throw new UnreachableCaseError(table);
        }

        return (
          params: { limit?: number },
          options: QueryOptions = {},
        ): Observable<ObserveQueryResult> => {
          const { limit = null } = params;

          const doesUserIdEqualMe =
            "userId" in params && params.userId === "me";

          const doesUser_IdEqualMe =
            "user_id" in params && params.user_id === "me";

          return combineLatest([ENVIRONMENT$, CURRENT_USER_ID$]).pipe(
            switchMap(([environment, currentUserId]) => {
              if (!environment) {
                return of({
                  records: [],
                  nextId: null,
                  limit,
                  isLoading: false,
                } as ObserveQueryResult);
              }

              if (doesUserIdEqualMe || doesUser_IdEqualMe) {
                if (!currentUserId) {
                  return of({
                    records: [],
                    nextId: null,
                    limit,
                    isLoading: false,
                  } as ObserveQueryResult);
                }
              }

              return new Observable<ObserveQueryResult>((subscriber) => {
                // We overfetch by one so that we can determine if there are
                // more records to fetch.
                const modifiedParams = limit
                  ? { ...params, limit: limit + 1 }
                  : { ...params };

                if (doesUserIdEqualMe) {
                  (modifiedParams as any).userId = currentUserId;
                }

                if (doesUser_IdEqualMe) {
                  (modifiedParams as any).user_id = currentUserId;
                }

                const query = {
                  type: originalMethodName,
                  params: modifiedParams,
                } as ApiQueryMinusGetRecords;

                const loader = loadQuery(query);

                const pubsubUnsubscribeFns: Array<() => void> = [];

                for (const key of getQuerySubscriptionKeys(query)) {
                  pubsubUnsubscribeFns.push(
                    environment.subscriptionManager.subscribe(key),
                  );
                }

                const isLoading$ = loader.resolved
                  ? of(false)
                  : from(loader.promise.then(() => false)).pipe(
                      startWith(() => true),
                    );

                const observable = db[methodName](modifiedParams as never);

                // In addition to subscribing to updates to this query from the server,
                // we also need to subscribe to update to the individual records
                // returned by this query. This is because
                // 1. If a record returned by this query is deleted, this query might not
                //    necessarily update unless we're also subscribed to this record.
                // 2. If we use a property of the records returned by this query that is
                //    something other than "id" (basically, a property of this record that
                //    is mutable), we need to know if/when that property mutates and to
                //    do that we need to subscribe to these records.

                const individualRecordSubscriptionKeys = new Map<
                  string,
                  () => void
                >();

                const individualRecordsSubscription = (
                  observable as Observable<RecordsResult<{ id: string }>>
                )
                  .pipe(
                    map(({ records }) => records.map((r) => r.id)),
                    startWith(() => []),
                    pairwise(),
                  )
                  .subscribe(([prevIds, nextIds]) => {
                    const deletedIds = difference(prevIds, nextIds);
                    const addedIds = difference(nextIds, prevIds);
                    const getKeys = (id: string) =>
                      getQuerySubscriptionKeys({
                        type: "getRecord",
                        params: { table, id },
                      });

                    for (const id of deletedIds) {
                      for (const key of getKeys(id)) {
                        const unsubscribe =
                          individualRecordSubscriptionKeys.get(key);

                        unsubscribe?.();

                        individualRecordSubscriptionKeys.delete(key);
                      }
                    }

                    for (const id of addedIds) {
                      for (const key of getKeys(id)) {
                        individualRecordSubscriptionKeys.set(
                          key,
                          environment.subscriptionManager.subscribe(key),
                        );

                        const pointer = { table, id };

                        const existingLoader = environment.loaderCache.get(
                          "getRecord",
                          pointer,
                        );

                        if (existingLoader) continue;

                        const loader = new Loader();
                        loader.resolve();

                        environment.loaderCache.set(
                          "getRecord",
                          pointer,
                          loader,
                        );
                      }
                    }
                  });

                const query$ = combineLatest([observable, isLoading$]).pipe(
                  map(([queryResults, isLoading]) => {
                    const records = limit
                      ? queryResults.records.slice(0, limit)
                      : queryResults.records;

                    const nextId =
                      queryResults.records.length > records.length
                        ? queryResults.records.at(-1)?.id || null
                        : null;

                    return {
                      records,
                      nextId,
                      limit,
                      isLoading,
                    } as ObserveQueryResult;
                  }),
                );

                // Important that this comes after the individualRecordsSubscription
                // so that, by the time this query emits we've already subscribed
                // to all of the individual records.
                const unsubscribeFromQuery = query$.subscribe(subscriber);

                return () => {
                  individualRecordSubscriptionKeys.forEach((fn) => fn());
                  pubsubUnsubscribeFns.forEach((fn) => fn());
                  individualRecordsSubscription.unsubscribe();
                  unsubscribeFromQuery.unsubscribe();
                };
              });
            }),
          );
        };
      }

      if (prop === "createObserveGetResult") {
        return (
          options: Partial<ObserveGetResult> = {},
        ): Observable<ObserveGetResult> =>
          of({
            record: null,
            isLoading: false,
            ...options,
          });
      }

      if (prop === "createObserveQueryResult") {
        return (
          options: Partial<ObserveQueryResult> = {},
        ): Observable<ObserveQueryResult> =>
          of({
            isLoading: false,
            limit: null,
            nextId: null,
            records: [],
            ...options,
          });
      }

      if (prop === "observeRecords") {
        return <T extends RecordTable>(
          pointers: RecordPointer<T>[],
          options?: GetOptions,
        ): Observable<{ records: RecordValue<T>[]; isLoading: boolean }> => {
          if (pointers.length === 0) {
            return of({ records: [], isLoading: false });
          }

          return combineLatest(
            pointers.map((pointer) => {
              return proxy.observeGetRecord(pointer, options);
            }),
          ).pipe(
            map((results) => {
              return results.reduce(
                (store, { record, isLoading }) => {
                  store.isLoading = store.isLoading || isLoading;

                  if (record) {
                    store.records.push(record);
                  }

                  return store;
                },
                {
                  records: [] as RecordValue<T>[],
                  isLoading: false as boolean,
                },
              );
            }),
          );
        };
      }

      throw new Error(`Unexpected property called on RecordLoader: ${prop}`);
    },
  });

  const proxy = _proxy as unknown as ClientRecordLoaderApi;

  return proxy;
}

type GetClientQueryApiKeys = keyof RecordLoaderApi;

type GetClientQueryApi = {
  [K in GetClientQueryApiKeys]: K extends "getRecord"
    ? {
        <T extends RecordTable>(
          params: RecordPointer<T>,
          options?: GetOptions,
        ): Promise<GetResult<T>>;
        <T extends RecordTable>(
          table: T,
          id: string,
          options?: GetOptions,
        ): Promise<GetResult<T>>;
      }
    : (
        params: Parameters<RecordLoaderApi[K]>[0],
        options?: GetOptions,
      ) => Promise<
        QueryResult<GetQueryTable<K extends "getRecord" ? never : K>>
      >;
};

export type GetResult<T extends RecordTable = RecordTable> =
  RecordLoaderGetResult<T>;

export type QueryResult<T extends RecordTable = RecordTable> = [
  RecordValue<T>[],
  { nextId: string | null; limit: number | null },
];

export type ObserveClientQueryApiKeys = {
  [K in keyof ClientQueryApi]: K extends `observe${string}` ? K : never;
}[keyof ClientQueryApi];

export type ObserveClientQueryApi = {
  [K in ObserveClientQueryApiKeys]: K extends "observeGetRecord"
    ? <T extends RecordTable = RecordTable>(
        params: GetRecordParams<T>,
        options?: GetOptions,
      ) => Observable<ObserveGetResult<T>>
    : (
        params: Parameters<ClientQueryApi[K]>[0],
        options?: QueryOptions,
      ) => ObserveClientQueryReturnType<K>;
};

type ObserveClientQueryReturnType<K extends ObserveClientQueryApiKeys> =
  Observable<ObserveQueryResult<GetQueryTable<OriginalMethod<K>>>>;

export type ObserveGetResult<T extends RecordTable = RecordTable> = {
  record: RecordValue<T> | null;
  isLoading: boolean;
};

export type ObserveQueryResult<T extends RecordTable = RecordTable> = {
  records: RecordValue<T>[];
  nextId: string | null;
  limit: number | null;
  isLoading: boolean;
};

export type GetOptions = {
  fetchStrategy?: FetchStrategy;
};

export type QueryOptions = {};

export type FetchStrategy =
  | "server-first"
  | "refetch"
  | "cache-only"
  | "cache-first";
