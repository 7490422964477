import { of, switchMap } from "rxjs";
import { ENVIRONMENT$ } from "~/environment/ClientEnvironmentContext";
import {
  ObserveClientQueryApi,
  ObserveClientQueryApiKeys,
} from "~/environment/RecordLoader";

export function observeQuery<
  Name extends Exclude<ObserveClientQueryApiKeys, "observeGetRecord">,
>(
  methodName: Name,
  params: Parameters<ObserveClientQueryApi[Name]>[0],
): ReturnType<ObserveClientQueryApi[Name]> {
  return ENVIRONMENT$.pipe(
    switchMap((environment) => {
      if (!environment) {
        return of({
          records: [],
          limit: null,
          nextId: null,
          isLoading: false,
        });
      }

      return environment.recordLoader[methodName](params as any);
    }),
  ) as ReturnType<ObserveClientQueryApi[Name]>;
}
