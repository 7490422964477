import { useObservable, useObservableState } from "observable-hooks";
import { of, switchMap } from "rxjs";
import { observeTagsInFolderThatUserIsSubscribedTo } from "~/observables/observeTagsInFolderThatUserIsSubscribedTo";

export interface UseTagsInFolderThatUserIsSubscribedToResult {
  tagIds: string[];
  isLoading: boolean;
}

/**
 * This returns all direct children of the provided group that the user
 * is subscibed to as well as direct children that the user is not
 * subscribed to but which the user is subscribed to a child of (the
 * child does not need to be a direct child).
 */
export function useTagsInFolderThatUserIsSubscribedTo(props: {
  userId: string | null | undefined;
  folderId: string | null | undefined;
}): UseTagsInFolderThatUserIsSubscribedToResult {
  const query = useObservable(
    (inputs$) => {
      return inputs$.pipe(
        switchMap(([userId, folderId]) =>
          !userId || !folderId
            ? of({ tagIds: [], isLoading: false })
            : observeTagsInFolderThatUserIsSubscribedTo({
                userId,
                folderId,
              }),
        ),
      );
    },
    [props.userId, props.folderId],
  );

  return useObservableState(query, DEFAULT_VALUE);
}

const DEFAULT_VALUE: UseTagsInFolderThatUserIsSubscribedToResult =
  Object.freeze({
    tagIds: Object.freeze([]) as [],
    isLoading: true,
  });
