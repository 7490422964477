import { encodeValue } from "./codec";
import { getRecordId } from "./generateRecordId";
import {
  RecordMap,
  PointerWithRecord,
  JoinTable,
  RecordValue,
  TablePKey,
  RecordPointer,
  RecordTable,
  ThreadTimelineSubtype,
} from "./schema";

/** Given a pointer, gets a record from a record map. */
export function getMapRecord<T extends JoinTable>(
  recordMap: RecordMap,
  pointer:
    | ({ table: T } & Pick<RecordValue<T>, TablePKey<T>>)
    | RecordPointer<T>,
): RecordValue<T> | undefined;
export function getMapRecord<T extends RecordTable>(
  recordMap: RecordMap,
  pointer: RecordPointer<T>,
): RecordValue<T> | undefined;
export function getMapRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
  pointer: { table: T; id: string },
): NonNullable<R[T]>[string] | undefined;
export function getMapRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
  pointer: { table: T; [key: string]: string },
): NonNullable<R[T]>[string] | undefined {
  const { table, ...props } = pointer;

  const id = props.id || getRecordId(table as JoinTable, props);

  return recordMap[table]?.[id];
}

/**
 * Given a record and a pointer, sets a record in a record map.
 *
 * _NOTE: this function mutates the recordMap!_
 */
export function setMapRecord<T extends RecordTable>(
  recordMap: RecordMap,
  pointer: RecordPointer<T>,
  record: RecordValue<T>,
): void;
export function setMapRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R,
>(recordMap: R, pointer: { table: T; id: string }, record: R[T][string]): void;
export function setMapRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R,
>(recordMap: R, pointer: { table: T; id: string }, record: R[T][string]): void {
  const { table, id } = pointer;

  // @ts-ignore
  if (!recordMap[table]) recordMap[table] = {};
  // @ts-ignore
  recordMap[table][id] = record;
}

/**
 * Given a pointer, deletes a record in a record map.
 *
 * _NOTE: this function mutates the recordMap!_
 */
export function deleteMapRecord<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(recordMap: R, pointer: { table: T; id: string }) {
  const { table, id } = pointer;

  if (recordMap[table]) {
    delete recordMap[table]![id];
    if (Object.keys(recordMap[table]!).length === 0) {
      delete recordMap[table];
    }
  }
}

export function getPointer<Table extends JoinTable>(
  table: Table,
  props: Pick<RecordValue<Table>, TablePKey<Table>>,
): RecordPointer<Table>;
export function getPointer<Table extends RecordTable>(
  table: Table,
  id: string,
): RecordPointer<Table>;
export function getPointer<Table extends RecordTable>(
  pointerLike:
    | ({ table: Table } & Pick<RecordValue<Table>, TablePKey<Table>>)
    | RecordPointer<Table>,
): RecordPointer<Table>;
export function getPointer<Table extends RecordTable>(
  a:
    | Table
    | ({ table: Table } & Pick<RecordValue<Table>, TablePKey<Table>>)
    | RecordPointer<Table>,
  b?: string | object,
): RecordPointer<Table> {
  if (typeof a === "string") {
    return typeof b === "string"
      ? { table: a, id: b }
      : { table: a, id: getRecordId(a, b as any) };
  }

  const { table, ...props } = a;

  return {
    table,
    id: getRecordId(table, props as any),
  };
}

export function iterateRecordMap(
  recordMap: RecordMap,
): Generator<PointerWithRecord>;
export function iterateRecordMap<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
): Generator<{ table: T; id: string; record: NonNullable<R[T]>[string] }>;
export function* iterateRecordMap<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
): Generator<{ table: T; id: string; record: NonNullable<R[T]>[string] }> {
  for (const [table, idMap] of Object.entries(recordMap)) {
    for (const [id, record] of Object.entries(idMap)) {
      yield { table: table as T, id, record };
    }
  }
}

export function assignToRecordMap(
  mutatedRecordMap: RecordMap,
  otherRecordMap: RecordMap,
): void;
export function assignToRecordMap(
  mutatedRecordMap: RecordMap,
  pointerWithRecords: Array<PointerWithRecord | null | undefined>,
): void;
export function assignToRecordMap(
  mutatedRecordMap: RecordMap,
  a: RecordMap | Array<PointerWithRecord | null | undefined>,
) {
  if (Array.isArray(a)) {
    for (const pointerWithRecord of a) {
      if (!pointerWithRecord) continue;

      setMapRecord(
        mutatedRecordMap,
        pointerWithRecord,
        pointerWithRecord.record,
      );
    }
  } else {
    for (const { table, id, record } of iterateRecordMap(a)) {
      setMapRecord(mutatedRecordMap, { table, id }, record);
    }
  }
}

export function createRecordMapFromPointersWithRecords<
  T extends RecordTable = RecordTable,
>(
  pointersWithRecords: Array<PointerWithRecord<T> | undefined | null>,
): RecordMap {
  const recordMap: RecordMap = {};

  for (const pointer of pointersWithRecords) {
    if (!pointer) continue;
    setMapRecord(recordMap, pointer, pointer.record);
  }

  return recordMap;
}

export function getMapRecords<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
  table: T,
  predicate?: (record: NonNullable<R[T]>[string]) => boolean,
): Array<NonNullable<R[T]>[string]>;
export function getMapRecords<T extends RecordTable>(
  recordMap: RecordMap,
  pointers: Array<RecordPointer<T> | null | undefined>,
): Array<PointerWithRecord<T>>;
export function getMapRecords<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
  predicate: (record: {
    table: T;
    id: string;
    record: NonNullable<R[T]>[string];
  }) => boolean,
): Array<{ table: T; id: string; record: NonNullable<R[T]>[string] }>;
export function getMapRecords<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  R extends { [table: string]: { [id: string]: any } },
  T extends keyof R & string,
>(
  recordMap: R,
  a:
    | T
    | RecordPointer[]
    | ((record: {
        table: T;
        id: string;
        record: NonNullable<R[T]>[string];
      }) => boolean),
  b?: (record: NonNullable<R[T]>[string]) => boolean,
):
  | Array<{ table: T; id: string; record: NonNullable<R[T]>[string] }>
  | Array<NonNullable<R[T]>[string]> {
  if (typeof a === "string") {
    const table = a;
    const predicate = b;
    const tableMap = (recordMap[table] || {}) as NonNullable<R[T]>;
    if (!predicate) return Object.values(tableMap);
    return Object.values(tableMap).filter((record) => predicate(record));
  } else if (Array.isArray(a)) {
    const results: Array<PointerWithRecord> = [];

    for (const pointer of a) {
      if (!pointer) continue;
      const record = getMapRecord(recordMap, pointer);
      if (!record) continue;
      results.push({ ...pointer, record });
    }

    return results as any;
  } else {
    const predicate = a;
    const results: Array<{
      table: T;
      id: string;
      record: NonNullable<R[T]>[string];
    }> = [];

    for (const pointerWithRecord of iterateRecordMap<R, T>(recordMap)) {
      if (!predicate(pointerWithRecord)) continue;
      results.push(pointerWithRecord);
    }

    return results;
  }
}

export const timelineEntryOrderEncoders = {
  BRANCHED_THREAD: (
    thread: Pick<
      RecordValue<"thread">,
      | "first_message_sent_at"
      | "first_message_scheduled_to_be_sent_at"
      | "branched_from_message_sent_at"
      | "branched_from_message_scheduled_to_be_sent_at"
    >,
  ) => {
    const {
      first_message_sent_at,
      first_message_scheduled_to_be_sent_at,
      branched_from_message_sent_at,
      branched_from_message_scheduled_to_be_sent_at,
    } = thread;

    if (
      !first_message_sent_at ||
      !first_message_scheduled_to_be_sent_at ||
      !branched_from_message_sent_at ||
      !branched_from_message_scheduled_to_be_sent_at
    ) {
      throw new Error("Missing required thread properties");
    }

    // Note that order is important here.
    return encodeValue([
      first_message_sent_at,
      first_message_scheduled_to_be_sent_at,
      branched_from_message_sent_at,
      branched_from_message_scheduled_to_be_sent_at,
    ]);
  },
  MESSAGE: (
    message: Pick<
      RecordValue<"message">,
      "sent_at" | "scheduled_to_be_sent_at"
    >,
  ) => {
    const { sent_at, scheduled_to_be_sent_at } = message;

    // Note that order is important here.
    return encodeValue([sent_at, scheduled_to_be_sent_at]);
  },
} satisfies {
  [T in ThreadTimelineSubtype["type"]]: (...args: any[]) => string;
};
