import { BehaviorSubject, Subject } from "rxjs";
import { ReactNode } from "react";

export interface IHintState {
  timeoutId: number;
  subject?: string;
  content: string | ReactNode;
}

export const LOUD_HINT_EVENTS$ = new Subject<{ content: string | ReactNode }>();
export const QUIET_HINT_STATE$ = new BehaviorSubject<IHintState | null>(null);

const QUIET_HINT_DURATION = 3000;

function setQuietHint(hint: IHintState | null) {
  const existingHint = QUIET_HINT_STATE$.getValue();

  if (existingHint) {
    clearTimeout(existingHint.timeoutId);
  }

  QUIET_HINT_STATE$.next(hint);
}

export function pauseQuietHintTimeout() {
  const existingHint = QUIET_HINT_STATE$.getValue();

  if (existingHint) {
    clearTimeout(existingHint.timeoutId);
  }
}

export function resumeQuietHintTimeout() {
  const existingHint = QUIET_HINT_STATE$.getValue();

  if (!existingHint) return;

  if (existingHint) {
    clearTimeout(existingHint.timeoutId);
  }

  existingHint.timeoutId = setTimeout(
    () => setQuietHint(null),
    QUIET_HINT_DURATION,
  ) as unknown as number;
}

/**
 * Display a hint to the user.
 * - "quiet" hints look similar to toast notifications
 *   and appear briefly in the lower left corner of the viewport.
 * - "loud" hints briefly take up the full screen and are
 *   intended to instruct the user on the proper keyboard shortcut
 *   in response to an invalid mouse event.
 */
export function hint(
  type: "quiet",
  options: Omit<IHintState, "timeoutId">,
): void;
// Loud hints are currently not used in Comms
// export function hint(
//   type: "loud",
//   options: { content: string | ReactNode },
// ): void;
export function hint(
  type: "quiet" | "loud",
  options: Omit<IHintState, "timeoutId"> | { content: string | ReactNode },
) {
  if (type === "quiet") {
    const timeoutId = setTimeout(
      () => setQuietHint(null),
      QUIET_HINT_DURATION,
    ) as unknown as number;

    const state: IHintState = {
      timeoutId,
      subject: "Hint:",
      ...options,
    };

    setQuietHint(state);
  } else {
    LOUD_HINT_EVENTS$.next(options);
  }
}
