import { BsLockFill } from "react-icons/bs";
import { combineLatest, map } from "rxjs";
import { toggleFocusMode } from "~/actions/toggleFocusMode";
import { redo, undo } from "~/actions/write";
import { EditGroupDialogState } from "~/dialogs/group-edit/EditGroupDialog";
import { KBarState } from "~/dialogs/kbar";
import {
  getEnvironment,
  useClientEnvironment,
} from "~/environment/ClientEnvironmentContext";
import {
  ICommandArgs,
  useRegisterCommands,
} from "~/environment/command.service";
import {
  getMostRecentInboxPagePathname,
  navigateService,
} from "~/environment/navigate.service";
import {
  APP_ONLINE$,
  DEVICE_ONLINE$,
  isAppOnline,
} from "~/environment/network-connection.service";
import { showNotImplementedToastMsg, toast } from "~/environment/toast-service";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { observeCurrentUserSettings } from "~/observables/observeCurrentUserSettings";
import { observeGroupsUserIsAMemberOfWithFolderAncestorsRecords } from "~/observables/observeGroupsUserIsAMemberOfWithFolderAncestorsRecords";
import { observeRecord } from "~/observables/observeRecord";
import { openComposeNewThreadDialog } from "~/page-dialogs/page-dialog-state";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import {
  composeEmailCommand,
  composeMessageCommand,
} from "~/utils/common-commands";
import { useSidebarLayoutContext } from "./context";

export function useRegisterGeneralNavigationCommands() {
  useRegisterCommands({
    commands() {
      const commands: ICommandArgs[] = [
        {
          label: "Go to Inbox",
          hotkeys: ["g i"],
          callback: () => {
            navigateService(getMostRecentInboxPagePathname());
          },
        },
        {
          label: "Go to Starred",
          hotkeys: ["g r"],
          callback: () => {
            navigateService("/starred");
          },
        },
        {
          label: "Go to Drafts",
          hotkeys: ["g d"],
          callback: () => {
            navigateService("/drafts");
          },
        },
        {
          label: "Go to Sent",
          hotkeys: ["g t"],
          callback: () => {
            navigateService("/sent");
          },
        },
        {
          label: "Go to Done",
          hotkeys: ["g e"],
          callback: () => {
            navigateService("/done");
          },
        },
        {
          label: "Go to Reminders",
          hotkeys: ["g h"],
          callback: () => {
            navigateService("/reminders");
          },
        },
        {
          label: "Go to Search",
          hotkeys: ["/"],
          callback: () => {
            navigateService("/search");
          },
        },
        {
          label: "Go to Shared Messages",
          hotkeys: ["g s"],
          callback: () => {
            navigateService("/shared-messages");
          },
        },
        {
          // TODO:
          // Update this command when we support users being part of
          // multiple organizations and when we support users not
          // being a part of an organization.
          label: "Go to Explore Channels",
          altLabels: ["View all channels"],
          callback: () => {
            const currentUser = getAndAssertCurrentUser();

            navigateService(
              `/organizations/${currentUser.owner_organization_id}/explore-channels`,
            );
          },
        },
        {
          label: "Go to Settings",
          callback: () => {
            navigateService("/settings");
          },
        },
        {
          label: "Go to channel...",
          keywords: ["channel"],
          hotkeys: ["g c"],
          closeKBarOnSelect: false,
          callback: () => {
            KBarState.open({
              path: ["Channels"],
              mode: "search",
            });
          },
        },
      ];

      return commands;
    },
  });
}

export function useRegisterSidebarLayoutCommands() {
  const context = useSidebarLayoutContext();

  useRegisterCommands({
    commands: () => {
      const commands: ICommandArgs[] = [
        {
          label: "Open sidebar",
          altLabels: ["focus sidebar"],
          hotkeys: ["ArrowLeft"],
          callback: () => {
            context.emitFocusEvent("Sidebar");
          },
        },
        {
          label: "Update your profile (n/a)",
          callback: () => showNotImplementedToastMsg(),
        },
        {
          label: "Delete channel group (n/a)",
          callback: () => showNotImplementedToastMsg(),
        },
        {
          label: "Edit organization (n/a)",
          keywords: ["Update organization"],
          callback: () => showNotImplementedToastMsg(),
        },
        {
          label: "Delete organization (n/a)",
          callback: () => showNotImplementedToastMsg(),
        },
      ];

      return commands;
    },
    deps: [context],
  });
}

export function useRegisterComposeNewMessageCommands() {
  const environment = useClientEnvironment();

  useRegisterCommands({
    commands() {
      return observeCurrentUserSettings().pipe(
        map((settings) => {
          const commands: ICommandArgs[] = [
            composeMessageCommand({
              callback: () => {
                openComposeNewThreadDialog("new");
              },
            }),
          ];

          const canUserSendEmail =
            import.meta.env.VITE_FIREBASE_EMULATORS === "true" ||
            settings.record?.settings.linked_gmail_email_account === true;

          if (canUserSendEmail) {
            commands.push(
              composeEmailCommand({
                callback: () => {
                  openComposeNewThreadDialog("new-email");
                },
              }),
            );
          }

          return commands;
        }),
      );
    },
    deps: [environment],
  });
}

export function useRegisterToggleOfflineModeCommands() {
  useRegisterCommands({
    commands: () => {
      return combineLatest([APP_ONLINE$, DEVICE_ONLINE$]).pipe(
        map(([appOnLine]) => {
          const commands: ICommandArgs[] = [];

          if (appOnLine) {
            commands.push({
              label: "Enable offline mode",
              altLabels: ["Go offline", "Disable online mode"],
              keywords: ["Disable offline mode"],
              callback: () => {
                toast("vanilla", {
                  subject: "Offline support is temporarily disabled :(",
                });

                // toast("vanilla", {
                //   subject: "Offline mode enabled",
                // });

                // forceOfflineMode(true);
              },
            });
          }
          // } else if (deviceOnLine) {
          //   commands.push({
          //     label: "Disable offline mode",
          //     altLabels: ["Go online", "Enable online mode"],
          //     keywords: ["Enable offline mode"],
          //     callback: () => {
          //       toast("vanilla", {
          //         subject: "Offline mode disabled",
          //       });

          //       forceOfflineMode(false);
          //     },
          //   });
          // } else {
          //   commands.push({
          //     label: "Disable offline mode",
          //     altLabels: ["Go online", "Enable online mode"],
          //     keywords: ["Enable offline mode"],
          //     callback: () => {
          //       toast("vanilla", {
          //         subject: "Device offline",
          //         description: `
          //           Your device appears to not be connected to the internet.
          //           We've disabled offline mode, but you still don't have
          //           internet.
          //         `,
          //         duration: 15_000,
          //       });

          //       forceOfflineMode(false);
          //     },
          //   });
          // }

          return commands;
        }),
      );
    },
  });
}

export function useRegisterGroupCommands() {
  const { currentUser } = useAuthGuardContext();
  const { recordLoader } = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return combineLatest([
        observeGroupsUserIsAMemberOfWithFolderAncestorsRecords({
          userId: currentUser.id,
        }),
        recordLoader.observeGetOrganizationUserMembers({
          user_id: currentUser.id,
        }),
      ]).pipe(
        map(([groups, { records: orgUserMembers }]) => {
          const commands: ICommandArgs[] = [];

          if (groups.records.length === 0) return commands;

          const organizationIds = orgUserMembers.map((m) => m.organization_id);

          commands.push(
            {
              label: "Edit group...",
              altLabels: ["Update group...", "Rename group..."],
              closeKBarOnSelect: false,
              callback: () => {
                KBarState.open({
                  path: ["Edit group"],
                  mode: "search",
                });
              },
            },
            {
              label: "View group subscribers...",
              closeKBarOnSelect: false,
              callback: () => {
                KBarState.open({
                  path: ["Group subscribers"],
                  mode: "search",
                });
              },
            },
            // {
            //   label: "Subscribe users to channel",
            //   altLabels: [
            //     "Invite users to channel",
            //     `Add users to channel`,
            //     `Subscribe users to channel`,
            //     `Send channel invites`,
            //     `Send channel invitations`,
            //     `Invite to channel`,
            //   ],
            //   callback: () => {
            //     ChannelInviteDialogState.toggle(true);
            //   },
            // },
          );

          groups.records.forEach(({ record: group, folderPaths }) => {
            const ancestorNames = folderPaths.map((path) =>
              path.map((doc) => doc.name).join(" > "),
            );

            // We consider a group private if an organization isn't a member
            const isPrivate = !folderPaths.some((path) =>
              path.some((doc) => organizationIds.includes(doc.id)),
            );

            commands.push(
              // Add the ~ "Go to group" command
              {
                id: `Go to ${group.id}`,
                label: (
                  <>
                    <span className="text-slateA-10">Go to </span>
                    <span>#{group.name}</span>
                    {isPrivate && (
                      <span className="inline-flex ml-1 hover:cursor-help mt-1">
                        <small>
                          <BsLockFill />
                        </small>
                      </span>
                    )}
                    <span className="text-slateA-8 ml-4">
                      {ancestorNames.join(", ")}
                    </span>
                  </>
                ),
                keywords: [
                  `Go to #${group.name}`,
                  ...ancestorNames.map((name) => `#${group.name} ${name}`),
                ],
                path: ["Groups"],
                callback: () => {
                  navigateService(`/groups/${group.id}`);
                },
              },
              // Add the ~ "View group subscribers" command
              {
                id: `View ${group.name} subscribers`,
                label: (
                  <>
                    <span>View #{group.name}</span>
                    {isPrivate && (
                      <span className="inline-flex ml-1 hover:cursor-help mt-1">
                        <small>
                          <BsLockFill />
                        </small>
                      </span>
                    )}
                    <span className="ml-1">subscribers</span>
                    <span className="text-slateA-8 ml-4">
                      {ancestorNames.join(", ")}
                    </span>
                  </>
                ),
                keywords: [`View #${group.name} subscribers`],
                path: ["Group subscribers"],
                callback: () => {
                  navigateService(`/groups/${group.id}/subscribers`);
                },
              },
            );

            commands.push({
              id: `Edit "${group.id}" group`,
              label: `Edit "${group.name}" group`,
              altLabels: [
                `Update "${group.name}" group`,
                `Rename "${group.name}" group`,
              ],
              path: ["Edit group"],
              callback: () => {
                if (!isAppOnline()) {
                  toast("vanilla", {
                    subject: "Not supported in offline mode",
                    description: "Can't edit groups when offline.",
                  });

                  return;
                }

                EditGroupDialogState.open({
                  prefill: {
                    id: group.id,
                    name: group.name,
                    description: group.description,
                    isPrivate,
                  },
                });
              },
            });
          });

          return commands;
        }),
      );
    },
    deps: [currentUser.id, recordLoader],
  });
}

export function useRegisterOrganizationCommands() {
  const { currentUser } = useAuthGuardContext();
  const { recordLoader } = useClientEnvironment();

  useRegisterCommands({
    commands: () => {
      return recordLoader
        .observeGetUserOrganizationProfiles({
          userId: currentUser.id,
        })
        .pipe(
          map(({ records: organizationProfiles }) => {
            return [
              {
                label: "View organization members...",
                closeKBarOnSelect: false,
                callback: () => {
                  KBarState.open({
                    path: ["Organization members"],
                    mode: "search",
                  });
                },
              },
              ...organizationProfiles.flatMap((organization) => {
                return [
                  {
                    id: `View "${organization.id}" members`,
                    label: `View "${organization.name}" members`,
                    path: ["Organization members"],
                    callback: () => {
                      navigateService(
                        `/organizations/${organization.id}/members`,
                      );
                    },
                  },
                ];
              }),
            ];
          }),
        );
    },
    deps: [currentUser.id, recordLoader],
  });
}

export function useRegisterSettingsCommands() {
  const { currentUser } = useAuthGuardContext();

  useRegisterCommands({
    commands: () => {
      return observeRecord({
        table: "user_settings",
        id: currentUser.id,
      }).pipe(
        map(({ record }) => {
          const commands: ICommandArgs[] = [];

          const settings = record?.settings || {};

          const isFocusModeEnabled = settings.enable_focus_mode ?? false;

          if (isFocusModeEnabled) {
            commands.push({
              label: "Disable focus mode",
              keywords: ["Enable focus mode"],
              altLabels: ["Toggle focus mode"],
              callback: toggleFocusMode(false),
            });
          } else {
            commands.push({
              label: "Enable focus mode",
              keywords: ["Disable focus mode"],
              altLabels: ["Toggle focus mode"],
              callback: toggleFocusMode(true),
            });
          }

          commands.push({
            label: "Update focus mode",
            altLabels: ["Edit focus mode"],
            callback: () => {
              navigateService("/settings#focus-mode");
            },
          });

          commands.push({
            label: "Edit scheduled delivery",
            altLabels: [
              "Update scheduled delivery",
              "Edit batched delivery",
              "Update batched delivery",
            ],
            callback: () => {
              navigateService("/settings#scheduled-delivery");
            },
          });

          commands.push({
            label: "Notification preferences",
            altLabels: [
              "Edit notification preferences",
              "Update notification preferences",
            ],
            callback: () => {
              if (!isAppOnline()) {
                toast("vanilla", {
                  subject: "Not supported in offline mode",
                  description: "Can't edit your profile while offline.",
                });

                return;
              }

              navigateService("/settings#notification-preferences");
            },
          });

          return commands;
        }),
      );
    },
    deps: [currentUser.id],
  });
}

export function useRegisterUserProfileCommands() {
  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Edit your user profile",
          altLabels: [
            "Update your user profile",
            "Edit my profile",
            "Update my profile",
            "Change phone number",
            "Update phone number",
            "Edit phone number",
          ],
          callback: () => {
            if (!isAppOnline()) {
              toast("vanilla", {
                subject: "Not supported in offline mode",
                description: "Can't edit your profile while offline.",
              });

              return;
            }

            navigateService("/settings#user-profile");
          },
        },
      ];
    },
  });
}

export function useRegisterUndoRedoCommands() {
  useRegisterCommands({
    commands: () => {
      return [
        {
          label: "Undo",
          keywords: ["Redo"],
          hotkeys: ["$mod+z"],
          callback: () => {
            const environment = getEnvironment();
            undo(environment);
          },
        },
        {
          label: "Redo",
          keywords: ["Redo"],
          hotkeys: ["$mod+Shift+Z"],
          callback: () => {
            const environment = getEnvironment();
            redo(environment);
          },
        },
      ];
    },
  });
}
