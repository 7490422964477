import { ComponentType } from "react";
import {
  DialogState,
  DialogTitle,
  DIALOG_CONTENT_WRAPPER_CSS,
  withModalDialog,
} from "~/dialogs/withModalDialog";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { TextInput } from "~/components/forms/TextInput";
import { setIsLoading } from "~/environment/loading.service";
import { navigateService } from "~/environment/navigate.service";
import {
  createFormControl,
  createFormGroup,
  IFormControl,
  useControl,
} from "solid-forms-react";
import { handleSubmit, useControlState } from "~/components/forms/utils";
import {
  PLATFORM_MODIFIER_KEY,
  useRegisterCommands,
} from "~/environment/command.service";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { css, cx } from "@emotion/css";
import { createUser, CreateUserParams } from "~/actions/createUser";
import { getAndAssertCurrentUserId } from "~/environment/user.service";
import { SwitchInput } from "~/components/forms/SwitchInput";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";

interface INewUserDialogData {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  interruptTextMessages: boolean | null;
}

type INewUserDialogReturnData = { success?: boolean } | null;

export const NewUserDialogState = new DialogState<
  INewUserDialogData,
  INewUserDialogReturnData
>();

type IFormValue = CreateUserParams;

export const NewUserDialog = withModalDialog({
  dialogState: NewUserDialogState,
  // This disables the default onBackdropClick action of "close"
  onBackdropClick: () => {},
  Component: (props) => {
    if (!props.data) {
      alert("Data must be supplied to the NewUserDialog");
      throw new Error("Data must be supplied to the NewUserDialog");
    }

    const control = useControl(() => {
      return createFormGroup({
        firstName: createFormControl(props.data?.firstName || "", {
          required: true,
        }),
        lastName: createFormControl(props.data?.lastName || "", {
          required: true,
        }),
        email: createFormControl(props.data?.email || "", {
          required: true,
        }),
        phoneNumber: createFormControl(props.data?.phoneNumber || "", {
          validators: (rawValue) =>
            !rawValue.trim() || isPossiblePhoneNumber(rawValue)
              ? null
              : { invalidPhoneNumber: true },
        }),
        interruptMessageText: createFormControl(
          props.data?.interruptTextMessages || false,
          {
            required: true,
          },
        ),
      });
    });

    useRegisterCommands({
      commands: () => {
        return [
          {
            label: "Submit form",
            hotkeys: ["$mod+Enter"],
            triggerHotkeysWhenInputFocused: true,
            callback: () => {
              console.debug("attempting submit");
              handleSubmit(control, submit);
            },
          },
        ];
      },
    });

    const hasPhoneNumber = useControlState(
      () => !!control.rawValue.phoneNumber,
      [control],
    );

    return (
      <>
        <DialogTitle>
          <h2>New User Details</h2>
        </DialogTitle>

        <form
          onSubmit={(e) => e.preventDefault()}
          className={DIALOG_CONTENT_WRAPPER_CSS}
        >
          <p className="m-4">
            <em>
              Welcome to Comms! To get started, can we have your name, email
              address, and phone number? Press{" "}
              <kbd>{PLATFORM_MODIFIER_KEY.name}</kbd> + <kbd>Enter</kbd> to
              submit this form. You'll use {PLATFORM_MODIFIER_KEY.name} +{" "}
              <kbd>Enter</kbd> to submit all forms in the Comms app.
            </em>
          </p>

          <FirstName control={control.controls.firstName} />
          <LastName control={control.controls.lastName} />
          <Email control={control.controls.email} />
          <PhoneNumber control={control.controls.phoneNumber} />

          {hasPhoneNumber && (
            <InterruptTextMessages
              control={control.controls.interruptMessageText}
            />
          )}
        </form>
      </>
    );
  },
});

const submit = onlyCallFnOnceWhilePreviousCallIsPending(
  setIsLoading(async (_values: IFormValue) => {
    // Apparently Firebase callable functions error when receiving a proxy
    // object as an argument (and solid-forms controls are proxy objects)
    // See https://github.com/firebase/firebase-js-sdk/issues/6429
    const values = { ..._values };

    const env = getEnvironment();

    await createUser(env, values);

    const currentUserId = getAndAssertCurrentUserId();

    const [user] = await env.recordLoader.getRecord(
      "user_profile",
      currentUserId,
    );

    if (!user) {
      env.logger.debug("submission failed");
      return;
    }

    env.logger.debug("submitted successfully!");

    NewUserDialogState.close({ success: true });

    navigateService("/inbox", { replace: true });
  }),
);

const FirstName: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="firstName" className="mr-4">
          First name
        </label>

        <TextInput
          id="firstName"
          name="firstName"
          placeholder="First name"
          control={props.control}
        />
      </div>
    </div>
  );
};

const LastName: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="lastName" className="mr-4">
          Last name
        </label>

        <TextInput
          id="lastName"
          name="lastName"
          placeholder="Last name"
          control={props.control}
        />
      </div>
    </div>
  );
};

const Email: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="email" className="mr-4">
          Email
        </label>

        <TextInput
          id="email"
          name="email"
          type="email"
          control={props.control}
        />
      </div>
    </div>
  );
};

const PhoneNumber: ComponentType<{
  control: IFormControl<string>;
}> = (props) => {
  const value = useControlState(() => props.control.value, [props.control]);
  const isInvalid = useControlState(
    () => !props.control.isValid && props.control.isTouched,
    [props.control],
  );

  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="phone-number" className="mr-4">
          Phone number
        </label>

        <PhoneInput
          id="phone-number"
          international
          placeholder="Phone number"
          value={value}
          onChange={(value) => {
            props.control.setValue(value || "");
          }}
          onBlur={() => props.control.markTouched(true)}
          defaultCountry="US"
          autoFocus
          className={cx(phoneInputCSS, isInvalid && "is-invalid")}
        />
      </div>
    </div>
  );
};

const phoneInputCSS = css`
  flex: 1;

  & input {
    outline: none;
  }

  &.is-invalid {
    color: red;
    border: 1px solid red;
  }
`;

const InterruptTextMessages: ComponentType<{
  control: IFormControl<boolean>;
}> = (props) => {
  return (
    <div className="flex px-4">
      <div className="flex flex-1 py-2 border-b border-mauve-5">
        <label htmlFor="interruptTextMessages" className="mr-4">
          Do you want to receive text notifications for time sensitive messages?
        </label>

        <SwitchInput id="interruptTextMessages" control={props.control} />
      </div>
    </div>
  );
};
