import { ComponentType } from "react";
import { Outlet } from "react-router-dom";
import {
  useAuthGuardContext,
  withAuthGuard,
} from "~/route-guards/withAuthGuard";
// import { EditChannelGroupDialog } from "~/dialogs/channel-group-edit/EditChannelGroupDialog";
// import { USER_CHANNELS$ } from "~/services/channels.service";
// import { OrganizationInviteDialog } from "~/dialogs/organization-invite/OrganizationInviteDialog";
import { Sidebar } from "./Sidebar";
import {
  SidebarLayoutContext,
  useSidebarLayoutContext,
  withProvideSidebarLayoutContext,
} from "./context";
import { combineLatest, map, switchMap } from "rxjs";
// import { HelpDialog } from "~/dialogs/help/HelpDialog";
import { PageDialogRenderer } from "~/page-dialogs/PageDialogRenderer";
import { showNotImplementedToastMsg } from "~/environment/toast-service/toast.state";
import { KBarDialog, KBarState } from "~/dialogs/kbar";
import {
  ICommandArgs,
  useRegisterCommands,
  withNewCommandContext,
} from "~/environment/command.service";
import { navigateService } from "~/environment/navigate.service";
// import { RemindMeDialog } from "~/dialogs/remind-me";
import {
  APP_ONLINE$,
  DEVICE_ONLINE$,
  isAppOnline,
} from "~/environment/network-connection.service";
import { toast } from "~/environment/toast-service";
// import { EditChannelDialog } from "~/dialogs/channel-edit/EditChannelDialog";
// import { USER_CHANNEL_GROUPS$ } from "~/services/organization.service";
// import { withAPIVersionGuard } from "~/route-guards/withApiVersionGuard";
import { BsLockFill } from "react-icons/bs";
// import {
//   ChannelInviteDialog,
//   ChannelInviteDialogState,
// } from "~/dialogs/channel-invite/ChannelInviteDialog";
import {
  composeEmailCommand,
  composeMessageCommand,
} from "~/utils/common-commands";
// import { DeliverMessagesNowDialog } from "~/dialogs/deliver-messages-now/DeliverMessagesNowDialog";
import { HelpIcon } from "./HelpIcon";
// import {
//   OpenComposeMessageService,
//   openComposeNewThreadDialog,
// } from "~/page-dialogs/page-dialog-state";
// import { ToggleScheduledDeliveryDialog } from "~/dialogs/toggle-scheduled-delivery/ToggleScheduledDeliveryDialog";
// import { EditInboxSectionsDialog } from "~/dialogs/edit-inbox-sections/EditInboxSectionsDialog";
import { DarkMode } from "~/components/DarkMode";
import {
  useRegisterComposeNewMessageCommands,
  useRegisterGeneralNavigationCommands,
  useRegisterGroupCommands,
  useRegisterOrganizationCommands,
  useRegisterSettingsCommands,
  useRegisterSidebarLayoutCommands,
  useRegisterToggleOfflineModeCommands,
  useRegisterUserProfileCommands,
} from "./commands";
// import { CURRENT_USER_MAIN_SETTINGS$ } from "~/services/settings.service";

export const SidebarLayout: ComponentType<{}> = withAuthGuard(
  withNewCommandContext(
    withProvideSidebarLayoutContext(() => {
      const context = useSidebarLayoutContext();

      useRegisterSidebarLayoutCommands();
      useRegisterGeneralNavigationCommands();
      useRegisterComposeNewMessageCommands();
      useRegisterGroupCommands();
      useRegisterOrganizationCommands();
      useRegisterSettingsCommands();
      useRegisterUserProfileCommands();
      useRegisterToggleOfflineModeCommands();

      const mode = context.useSidebarMode();

      return (
        <SidebarLayoutContext.Provider value={context}>
          <HelpIcon />

          <Sidebar mode={mode} />

          <div className={mode === "push" ? "ml-[256px]" : ""}>
            <PageDialogRenderer>
              <Outlet />
            </PageDialogRenderer>
          </div>

          {/* 
            *tl;dr;*
            - Order these dialog components in the order you
              would like their kbar commands to appear.

            Many of these Dialog components also register kbar
            commands. At the moment, we don't use many weights
            when ordering kbar commands. If two commands match
            the current search term equally well, they'll 
            appear in the order they were registered. As such,
            the order of these dialogs can affects the order
            commands are displayed in via the kbar. E.g. the
            EditChannelGroupDialog and the EditChannelDialog both
            register commands beginning with "create channel".
            We choose to render EditChannelDialog first so that
            its command is displayed first in this edge case.
            In the future, we should have a better system for
            ordering commands and this order here won't matter.
          */}

          <KBarDialog />
          {/*
            <HelpDialog />
            <RemindMeDialog />
            <OrganizationInviteDialog />
            <ChannelInviteDialog />
            <EditChannelDialog />
            <EditChannelGroupDialog />
            <DeliverMessagesNowDialog />
            <ToggleScheduledDeliveryDialog />
            <EditInboxSectionsDialog />
            <PendingUpdatesSpinner />
            <OpenComposeMessageService />          
            */}

          <DarkMode />
        </SidebarLayoutContext.Provider>
      );
    }),
  ),
);
