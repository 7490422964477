import { css, cx } from "@emotion/css";
import { forwardRef } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Tooltip } from "~/components/Tooltip";
import { useSidebarLayoutContext } from "../sidebar-layout";

const newCSS = css`
  transform: scale(1.5);
`;

export const SidebarIcon = forwardRef<
  HTMLButtonElement,
  { theme?: "light" | "dark" }
>((props, ref) => {
  const context = useSidebarLayoutContext();

  const sidebarMode = context.useSidebarMode();

  const showSidebarIcon = sidebarMode === "over";

  if (!showSidebarIcon) return null;

  return (
    <div className="absolute top-[27px] left-0 z-[75]">
      <Tooltip
        side="right"
        content={
          <span>
            <kbd>←</kbd> (Left Arrow)
          </span>
        }
      >
        <button
          ref={ref}
          type="button"
          tabIndex={-1} // Doesn't need to be tabbable because of hotkeys
          className={cx(
            "absolute p-2 hover:pointer",
            props.theme === "dark"
              ? "text-white hover:bg-slateDarkA-5"
              : "hover:bg-slateA-5",
          )}
          onClick={() => {
            context.emitFocusEvent("Sidebar");
          }}
        >
          <GiHamburgerMenu className={newCSS} />
        </button>
      </Tooltip>
    </div>
  );
});
