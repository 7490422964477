import { RecordValue } from "libs/schema";
import { startWith } from "@libs/utils/rxjs-operators";
import { combineLatest, map, of, switchMap } from "rxjs";
import { observeRecord } from "./observeRecord";
import { observeTagFolderAncestorRecords } from "./observeTagFolderAncestorRecords";
import { observeGroupsUserIsAMemberOf } from "./observeGroupsUserIsAMemberOf";

export type ObserveUserGroupsWithFolderAncestorRecordsResult = {
  groups: Array<{
    group: RecordValue<"tag">;
    folderPaths: RecordValue<"tag">[][];
  }>;
  isLoading: boolean;
};

export function observeUsersGroupsWithFolderAncestorRecords(args: {
  userId: string;
  maxDepth?: number;
  emitIncrementally?: boolean;
}) {
  const { userId, maxDepth, emitIncrementally } = args;

  return observeGroupsUserIsAMemberOf({
    userId,
  }).pipe(
    switchMap(({ groupIds }) => {
      if (groupIds.length === 0) return of([]);

      const observeGroup = !emitIncrementally
        ? (groupId: string) => observeRecord({ table: "tag", id: groupId })
        : (groupId: string) =>
            observeRecord({ table: "tag", id: groupId }).pipe(
              startWith(() => ({
                record: null as RecordValue<"tag"> | null,
                isLoading: true,
              })),
            );

      return combineLatest(
        groupIds.map((groupId) =>
          combineLatest([
            observeGroup(groupId),
            observeTagFolderAncestorRecords({
              tagId: groupId,
              maxDepth,
              emitIncrementally,
            }),
          ]),
        ),
      );
    }),
    map((data): ObserveUserGroupsWithFolderAncestorRecordsResult => {
      let isLoading = false;

      const results: ObserveUserGroupsWithFolderAncestorRecordsResult["groups"] =
        [];

      for (const [group, ancestor] of data) {
        isLoading = isLoading || group.isLoading || ancestor.isLoading;
        if (!group.record) continue;

        results.push({
          group: group.record,
          folderPaths: ancestor.folderPaths,
        });
      }

      return { groups: results, isLoading };
    }),
  );
}
