import { PropsWithChildren } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useIsAppOnline } from "~/environment/network-connection.service";

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the client's API version is up-to-date.
 */
export function withIsOnlineGuard<P extends PropsWithChildren<unknown>>(
  Component: React.ComponentType<P>,
) {
  return function GuardedRoute(props: P) {
    const online = useIsAppOnline();
    const location = useLocation();

    if (!online) {
      return <Navigate to={"/offline"} state={{ from: location }} replace />;
    }

    return <Component {...props} />;
  };
}
