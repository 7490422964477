import {
  JoinTable,
  joinTables,
  NonJoinTable,
  RecordTable,
  RecordValue,
  TablePKey,
} from "./schema";
import { deriveUUID, uuid } from "@libs/utils/uuid";

/** Generates a new ID for a record */
export function generateRecordId<Table extends JoinTable>(
  table: Table,
  record: Pick<RecordValue<Table>, TablePKey<Table>>,
): string;
export function generateRecordId<Table extends NonJoinTable>(
  table: Table,
): string;
export function generateRecordId<Table extends RecordTable>(
  table: Table,
  record?: Table extends JoinTable
    ? Pick<RecordValue<Table>, TablePKey<Table>>
    : never,
): string {
  if (joinTables.includes(table as JoinTable)) {
    if (!record) {
      throw new Error("[generateRecordId] missing required arguments");
    }

    return deriveUUID(...Object.values(record));
  }

  return uuid();
}

// export function parseRecordId<Table extends RecordTable>(
//   table: Table,
//   id: string,
// ): Pick<RecordValue<Table>, TablePKey<Table>> {
//   if (table in recordIdParsers) {
//     return recordIdParsers[table as JoinTable](id) as unknown as Pick<
//       RecordValue<Table>,
//       TablePKey<Table>
//     >;
//   }

//   return { id } as unknown as Pick<RecordValue<Table>, TablePKey<Table>>;
// }

/**
 * This returns the "id" value for the provided record.
 */
export function getRecordId<Table extends JoinTable>(
  table: Table,
  record: Pick<RecordValue<Table>, TablePKey<Table>>,
): string;
export function getRecordId<Table extends RecordTable>(
  table: Table,
  record: { id: string },
): string;
export function getRecordId<Table extends RecordTable>(
  table: Table,
  record: Pick<RecordValue<Table>, TablePKey<Table>> | { id: string },
): string {
  const id =
    "id" in record
      ? record.id
      : deriveUUID(...(Object.values(record) as string[]));

  if (!id) {
    console.error(
      "getRecordId: missing expected arguments. Arguments: ",
      table,
      record,
    );

    throw new Error("getRecordId: missing expected arguments");
  }

  return id;
}

// const recordIdGenerators = {
//   draft_email_recipient: (r) => `${r.draft_id}:${r.type}:${r.email_address}`,
//   draft_group_recipient: (r) => `${r.draft_id}:${r.group_id}`,
//   draft_tag: (r) => `${r.draft_id}:${r.tag_id}`,
//   draft_user_recipient: (r) => `${r.draft_id}:${r.user_id}`,
//   mention_frequency: (r) => `${r.user_id}:${r.type}:${r.subject_id}`,
//   message_attachment: (r) => `${r.message_id}:${r.attachment_id}`,
//   message_email_recipient: (r) =>
//     `${r.message_id}:${r.type}:${r.email_address}`,
//   message_email_reference: (r) => `${r.message_id}:${r.email_message_id}`,
//   message_group_recipient: (r) => `${r.message_id}:${r.group_id}`,
//   message_user_reaction: (r) => `${r.message_id}:${r.user_id}`,
//   message_user_recipient: (r) => `${r.message_id}:${r.user_id}`,
//   notification: (r) => `${r.user_id}:${r.thread_id}`,
//   organization_controlled_domain: (r) => `${r.organization_id}:${r.domain}`,
//   organization_user_invitation: (r) =>
//     `${r.organization_id}:${r.email_address}`,
//   organization_user_member: (r) => `${r.organization_id}:${r.user_id}`,
//   provider_email_map: (r) => `${r.user_id}:${r.provider}:${r.provider_id}`,
//   tag_folder_member: (r) => `${r.tag_id}:${r.group_id}`,
//   tag_group_member: (r) => `${r.tag_id}:${r.group_id}`,
//   tag_subscription: (r) => `${r.user_id}:${r.tag_id}`,
//   tag_user_member: (r) => `${r.tag_id}:${r.user_id}`,
//   thread_group_permission: (r) => `${r.thread_id}:${r.group_id}`,
//   thread_read_receipt: (r) => `${r.thread_id}:${r.user_id}`,
//   thread_seen_receipt: (r) => `${r.thread_id}:${r.user_id}`,
//   thread_subscription: (r) => `${r.user_id}:${r.thread_id}`,
//   thread_tag: (r) => `${r.thread_id}:${r.tag_id}`,
//   thread_user_participant: (r) => `${r.thread_id}:${r.user_id}`,
//   thread_user_permission: (r) => `${r.thread_id}:${r.user_id}`,
//   user_lesson: (r) => `${r.user_id}:${r.lesson_id}`,
// } satisfies {
//   [Table in JoinTable]: (
//     record: Pick<RecordValue<Table>, TablePKey<Table>>,
//   ) => string;
// };

// /** Note that the order the inputs are provided in matters! */
// function splitId<Table extends RecordTable>(): {
//   <A extends keyof RecordValue<Table>>(id: string, a: A): Pick<
//     RecordValue<Table>,
//     A
//   >;
//   <A extends keyof RecordValue<Table>, B extends keyof RecordValue<Table>>(
//     id: string,
//     a: A,
//     b: B,
//   ): Pick<RecordValue<Table>, A | B>;
//   <
//     A extends keyof RecordValue<Table>,
//     B extends keyof RecordValue<Table>,
//     C extends keyof RecordValue<Table>,
//   >(
//     id: string,
//     a: A,
//     b: B,
//     c: C,
//   ): Pick<RecordValue<Table>, A | B | C>;
// };
// function splitId() {
//   return (id: string, ...props: string[]) => {
//     const parts = id.split(":");

//     if (parts.length !== props.length) {
//       throw new Error("splitId: id does not have expected parts");
//     }

//     return Object.fromEntries(
//       parts.map((part, i) => [props[i], part] as const),
//     );
//   };
// }

// const recordIdParsers = {
//   draft_email_recipient: (id) =>
//     splitId<"draft_email_recipient">()(id, "draft_id", "type", "email_address"),
//   draft_group_recipient: (id) =>
//     splitId<"draft_group_recipient">()(id, "draft_id", "group_id"),
//   draft_tag: (id) => splitId<"draft_tag">()(id, "draft_id", "tag_id"),
//   draft_user_recipient: (id) =>
//     splitId<"draft_user_recipient">()(id, "draft_id", "user_id"),
//   mention_frequency: (id) =>
//     splitId<"mention_frequency">()(id, "user_id", "type", "subject_id"),
//   message_attachment: (id) =>
//     splitId<"message_attachment">()(id, "message_id", "attachment_id"),
//   message_email_recipient: (id) =>
//     splitId<"message_email_recipient">()(
//       id,
//       "message_id",
//       "type",
//       "email_address",
//     ),
//   message_email_reference: (id) =>
//     splitId<"message_email_reference">()(id, "message_id", "email_message_id"),
//   message_group_recipient: (id) =>
//     splitId<"message_group_recipient">()(id, "message_id", "group_id"),
//   message_user_reaction: (id) =>
//     splitId<"message_user_reaction">()(id, "message_id", "user_id"),
//   message_user_recipient: (id) =>
//     splitId<"message_user_recipient">()(id, "message_id", "user_id"),
//   notification: (id) => splitId<"notification">()(id, "user_id", "thread_id"),
//   organization_controlled_domain: (id) =>
//     splitId<"organization_controlled_domain">()(
//       id,
//       "organization_id",
//       "domain",
//     ),
//   organization_user_invitation: (id) =>
//     splitId<"organization_user_invitation">()(
//       id,
//       "organization_id",
//       "email_address",
//     ),
//   organization_user_member: (id) =>
//     splitId<"organization_user_member">()(id, "organization_id", "user_id"),
//   provider_email_map: (id) =>
//     splitId<"provider_email_map">()(id, "user_id", "provider", "provider_id"),
//   tag_group_member: (id) =>
//     splitId<"tag_group_member">()(id, "tag_id", "group_id"),
//   tag_subscription: (id) =>
//     splitId<"tag_subscription">()(id, "user_id", "tag_id"),
//   tag_user_member: (id) =>
//     splitId<"tag_user_member">()(id, "tag_id", "user_id"),
//   thread_group_permission: (id) =>
//     splitId<"thread_group_permission">()(id, "thread_id", "group_id"),
//   thread_read_receipt: (id) =>
//     splitId<"thread_read_receipt">()(id, "thread_id", "user_id"),
//   thread_seen_receipt: (id) =>
//     splitId<"thread_seen_receipt">()(id, "thread_id", "user_id"),
//   thread_subscription: (id) =>
//     splitId<"thread_subscription">()(id, "user_id", "thread_id"),
//   thread_tag: (id) => splitId<"thread_tag">()(id, "thread_id", "tag_id"),
//   thread_user_participant: (id) =>
//     splitId<"thread_user_participant">()(id, "thread_id", "user_id"),
//   thread_user_permission: (id) =>
//     splitId<"thread_user_permission">()(id, "thread_id", "user_id"),
//   user_lesson: (id) => splitId<"user_lesson">()(id, "user_id", "lesson_id"),
// } satisfies {
//   [Table in JoinTable]: (
//     id: string,
//   ) => Pick<RecordValue<Table>, TablePKey<Table>>;
// };
