import Pino from "pino";

export type Logger = ReturnType<typeof createLogger>;

export function createLogger() {
  return Pino({
    browser: {
      asObject: true,
      transmit: {
        // For someone reason, defining this send function is necessary
        // for Pino to log "debug" events in Cloudflare.
        send() {},
      },
    },
  });
}
