import { render, unmountComponentAtNode } from "react-dom";
import Shepherd from "shepherd.js";
import { disableCommandServiceHotkeyListener } from "../command.service";
import { createKeybindingsHandler } from "@libs/tinykeys";
import { Subject } from "rxjs";
import {
  ComponentType,
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "~/components/Tooltip";
import { css, cx } from "@emotion/css";
import { updateLessonForCurrentUser } from "~/actions/updateLessonForCurrentUser";

export type ICommsTour<
  Events,
  Variables extends { [key: string]: unknown } | undefined = undefined,
> = Shepherd.Tour & {
  lessonName: string;
  lessonVersion: number;
  event$: Subject<Events>;
  navigationContext: ReturnType<typeof addTourNavListeners>;
} & { context: Variables };

export function createTour<
  Events,
  Variables extends { [key: string]: unknown } | undefined = undefined,
>(
  lessonName: string,
  lessonVersion: number,
  options: Omit<Shepherd.Tour.TourOptions, "tourName">,
) {
  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    keyboardNavigation: false,
    exitOnEsc: false,
    ...options,
    tourName: lessonName,
  }) as ICommsTour<Events, Variables>;

  tour.lessonName = lessonName;
  tour.lessonVersion = lessonVersion;
  tour.event$ = new Subject<Events>();
  tour.navigationContext = addTourNavListeners(tour, lessonName, lessonVersion);

  return tour;
}

export function renderStep(opts: {
  content: string | ReactElement;
  noBack?: boolean;
  noNext?: boolean;
  showComplete?: boolean;
  showEscape?: boolean;
}) {
  const div = document.createElement("div");

  const doNotRenderFooter = opts.noBack && opts.noNext && !opts.showEscape;

  const getActiveTour = () =>
    Shepherd.activeTour as ICommsTour<unknown> | undefined;

  render(
    <div className="flex flex-col bg-violet-4 text-violet-11 rounded overflow-hidden">
      <div className="p-3">{opts.content}</div>

      {!doNotRenderFooter && (
        <div className="flex px-3 py-2 uppercase text-xs bg-violet-11 text-white">
          {!opts.noBack && (
            <button
              type="button"
              className="uppercase hover:cursor-pointer"
              onClick={() => {
                getActiveTour()?.back();
              }}
            >
              ← back
            </button>
          )}

          {opts.showEscape ? (
            <span className="flex-1 flex justify-center">Escape to exit</span>
          ) : (
            <span className="flex-1" />
          )}

          {!opts.noNext && (
            <button
              type="button"
              className="uppercase hover:cursor-pointer"
              onClick={() => {
                getActiveTour()?.next();
              }}
            >
              {opts.showComplete ? "complete" : "next"} →
            </button>
          )}
        </div>
      )}

      <div className="WALKTHROUGH-close-message-overlay-container absolute top-0 left-0" />
    </div>,
    div,
  );

  return div;
}

export function addTourNavListeners(
  tour: Shepherd.Tour,
  lessonName: string,
  lessonVersion: number,
) {
  const clickPreventionBackdropElID =
    "WALKTHROUGH_CLICK_PREVENTION_BACKDROP_EL";

  const context = {
    stopBack: false,
    stopNext: false,
    allowEscape: false,
    nextIsComplete: false,
    showCloseDialog() {
      removeEventListener("keydown", keyboardNavListener);

      const el = document.querySelector<HTMLDivElement>(
        `.shepherd-element:not([hidden]) .WALKTHROUGH-close-message-overlay-container`,
      );

      if (!el) {
        // el should never be null
        tour.cancel();
        return;
      }

      el.style.width = "100%";
      el.style.height = "100%";

      render(
        <div className="flex items-center justify-center text-white bg-[rgba(0,0,0,0.8)] h-full w-full">
          <ul className="list-disc">
            <li>Press Enter to close and not show again.</li>
            <li>Press Escape to just close.</li>
          </ul>
        </div>,
        el,
      );

      const unmount = () => {
        el.style.width = "";
        el.style.height = "";
        unmountComponentAtNode(el);
      };

      const keyboardCloseListener = createKeybindingsHandler({
        Enter: (e) => {
          e.preventDefault();
          tour.complete();
          unmount();
          removeEventListener("keydown", keyboardCloseListener);
        },
        Escape: (e) => {
          e.preventDefault();
          tour.cancel();
          unmount();
          removeEventListener("keydown", keyboardCloseListener);
        },
      });

      addEventListener("keydown", keyboardCloseListener);
    },
    /**
     * Add a transparent backdrop to prevent the user from clicking
     * things behind the walkthrough
     */
    addTransparentBackdrop() {
      const existingEl = document.getElementById(clickPreventionBackdropElID);

      if (existingEl) return;

      const el = document.querySelector<SVGElement>(
        `.shepherd-modal-overlay-container`,
      );

      if (!el) return;

      const clickPreventionBackdropEl = document.createElement("div");

      clickPreventionBackdropEl.id = clickPreventionBackdropElID;
      clickPreventionBackdropEl.style.width = "100vw";
      clickPreventionBackdropEl.style.height = "100vh";
      clickPreventionBackdropEl.style.position = "fixed";
      clickPreventionBackdropEl.style.top = "0";
      clickPreventionBackdropEl.style.left = "0";
      clickPreventionBackdropEl.style.zIndex = "7000";

      document.body.insertBefore(clickPreventionBackdropEl, el);
    },
    /**
     * Remove the transparent backdrop, potentially allowing users
     * to click things behind the walkthrough again.
     */
    removeTransparentBackdrop() {
      const existingEl = document.getElementById(clickPreventionBackdropElID);

      if (!existingEl) return;

      existingEl.remove();
    },
  };

  const keyboardNavListener = createKeybindingsHandler({
    Tab: (e) => {
      e.preventDefault();
    },
    ArrowLeft: (e) => {
      if (context.stopBack) return;
      e.preventDefault();
      tour.back();
    },
    ArrowRight: (e) => {
      if (context.stopNext) return;
      e.preventDefault();

      if (context.nextIsComplete) {
        tour.complete();
      } else {
        tour.next();
      }
    },
    Escape: (e) => {
      if (!context.allowEscape) return;
      e.preventDefault();
      context.showCloseDialog();
    },
  });

  tour.on("start", () => {
    addEventListener("keydown", keyboardNavListener);
    disableCommandServiceHotkeyListener(true);
    setTimeout(context.addTransparentBackdrop, 10);
  });

  const cleanupOnClose = () => {
    context.stopBack = false;
    context.allowEscape = false;
    context.stopNext = false;
    context.nextIsComplete = false;
    removeEventListener("keydown", keyboardNavListener);

    setTimeout(() => {
      context.removeTransparentBackdrop();
      // We call disableCommandServiceHotkeyListener inside setTimeout
      // because otherwise it's possible that the
      // event which triggered the cancel would immediately trigger
      // another event.
      disableCommandServiceHotkeyListener(false);
    }, 10);
  };

  tour.on("cancel", cleanupOnClose);

  tour.on("complete", () => {
    updateLessonForCurrentUser({
      name: lessonName,
      version: lessonVersion,
      isComplete: true,
    });

    cleanupOnClose();
  });

  return context;
}

// John 1/19/23
// If this is typed as ICommsTour<unknown>, typescript won't let us
// pass, e.g., ICommsTour<TNewPrivateMessageWalkthroughEvent, {}> to this
// function...So we're using `any` here instead. I run into this typescript
// limitation constantly. I seem to remember that the typescript team
// considers this working as intended, but it sure seems like a typescript
// error to me.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useIsTourInProgress(tour: ICommsTour<any, {}>) {
  const [isInProgress, setIsInProgress] = useState(tour.isActive());

  useEffect(() => {
    setIsInProgress(tour.isActive());

    const isInProgress = () => setIsInProgress(true);
    const isNotInProgress = () => setIsInProgress(false);

    tour.on("start", isInProgress);
    tour.on("cancel", isNotInProgress);
    tour.on("complete", isNotInProgress);

    return () => {
      tour.off("start", isInProgress);
      tour.off("cancel", isNotInProgress);
      tour.off("complete", isNotInProgress);
    };
  }, [tour]);

  return isInProgress;
}

export const AVAILABLE_LESSON_BOX_SHADOW_PULSE = css`
  animation: available-lesson-pulse 1s infinite;

  @keyframes available-lesson-pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(90, 60, 196, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(90, 60, 196, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(90, 60, 196, 0);
    }
  }
`;

export const AvailableLessonBadge: ComponentType<{
  tooltip?: string | ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "span" | "button";
  inline?: boolean;
  className?: string;
}> = (props) => {
  const Tag = props.type || "span";

  return (
    <Tooltip side="bottom" content={props.tooltip || ""}>
      <Tag
        type={props.type === "button" ? "button" : undefined}
        className={cx(
          props.inline ? "inline-flex" : "flex h-4 w-4",
          "rounded-full cursor-help",
          props.className || "relative",
        )}
        onClick={props.onClick}
      >
        <span
          className={cx(
            "animate-ping absolute inline-flex rounded-full bg-violet-9 opacity-75",
            props.inline ? "w-[1em] h-[1em]" : "h-full w-full",
          )}
        ></span>
        <FaQuestionCircle className="text-violet-9 bg-white rounded-full" />
      </Tag>
    </Tooltip>
  );
};
