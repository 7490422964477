import { ComponentType, PropsWithChildren, Ref, useMemo } from "react";
import { css, cx } from "@emotion/css";
import { red } from "@radix-ui/colors";
import {
  AutocompleteSelect,
  getFuzzyFilteringFn,
  IOption,
  TAutocompleteSelectRef,
} from "./AutocompleteSelect";
import { MultiValue, OptionProps, SingleValue } from "react-select";
import { useUsersGroupsWithFolderAncestorRecords } from "~/hooks/useUsersGroupsWithFolderAncestorRecords";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { RecordValue } from "libs/schema";
import { getTagFolderAncestorRecords } from "~/queries/getTagFolderAncestorRecords";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";

export interface IGroupOption extends IOption<string> {
  folderPath: RecordValue<"tag">[];
}

export async function getGroupOptions(
  groupId: string,
): Promise<IGroupOption[]> {
  const { recordLoader } = getEnvironment();

  const [[group], { folderPaths }] = await Promise.all([
    recordLoader.getRecord({ table: "tag", id: groupId }),
    getTagFolderAncestorRecords({ tagId: groupId }),
  ]);

  if (!group) {
    return [];
  }

  return folderPaths.map((path) => {
    return {
      label: group.name,
      value: group.id,
      folderPath: path,
    };
  });
}

/**
 * Component for selecting a channel group with autocomplete.
 */
export function GroupSelect<M extends boolean>(
  props: PropsWithChildren<{
    autocompleteRef?: Ref<TAutocompleteSelectRef<IGroupOption, M>>;
    value?: M extends true
      ? MultiValue<IGroupOption>
      : SingleValue<IGroupOption>;
    touched?: boolean;
    error?: string;
    onChange?: M extends true
      ? (newValue: MultiValue<IGroupOption>) => void
      : (newValue: SingleValue<IGroupOption>) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    autoFocus?: boolean;
    autocompleteMenuEl?: HTMLDivElement | null;
    multiple?: M;
    label?: string;
  }>,
) {
  const { currentUser } = useAuthGuardContext();

  const { groups } = useUsersGroupsWithFolderAncestorRecords({
    userId: currentUser.id,
  });

  const options: IGroupOption[] = useMemo(() => {
    return groups.flatMap(({ group, folderPaths }) => {
      return folderPaths.map((path) => {
        return {
          label: group.name,
          value: group.id,
          folderPath: path,
        };
      });
    });
  }, [groups]);

  return (
    <div
      className={cx("flex flex-1 py-2 border-b border-mauve-5", {
        [showLabelOnFocusCSS]: props.multiple
          ? (props.value as MultiValue<IOption>)?.length === 0
          : !props.value,
      })}
    >
      {props.label && (
        <label
          className={cx(
            "my-1 mr-2",
            props.touched && props.error ? "text-red-9" : "text-slateDark-11",
          )}
        >
          {props.label}
        </label>
      )}

      <AutocompleteSelect
        ref={props.autocompleteRef}
        name="groups"
        value={props.value}
        onBlur={props.onBlur}
        onChange={
          props.onChange as (
            newValue: MultiValue<IOption> | SingleValue<IOption>,
          ) => void
        }
        loadOptions={getFuzzyFilteringFn(options)}
        placeholder={
          props.touched && props.error
            ? "Channel group required..."
            : "Channel groups..."
        }
        autoFocus={props.autoFocus}
        multiple={props.multiple}
        classNames={
          (props.touched &&
            props.error &&
            `input-invalid ${channelGroupsInputCSS}`) ||
          ""
        }
        menuPortalTarget={props.autocompleteMenuEl}
        menuPlacement="bottom"
        components={components}
      />
    </div>
  );
}

const showLabelOnFocusCSS = css`
  &:not(:focus-within) label {
    display: none;
  }
`;

const channelGroupsInputCSS = css`
  &.input-invalid .react-select-placeholder {
    color: ${red.red9};
  }
`;

const Option: ComponentType<OptionProps<IGroupOption, boolean>> = (props) => {
  const option = props.data as IGroupOption;
  const folderPath = option.folderPath.map((folder) => folder.name).join(" > ");

  return (
    <div
      className={cx(
        "py-2 px-4 hover:cursor-pointer",
        !option.isDisabled && "hover:bg-blue-5",
        props.isFocused ? "bg-blue-5" : "bg-transparent",
      )}
      onClick={() => props.selectOption(option)}
    >
      {option.label}
      <span className="text-slateA-8 ml-4">{folderPath}</span>
    </div>
  );
};

const components = {
  Option,
};
