import { op } from "libs/transaction";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { navigateService } from "~/environment/navigate.service";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { write } from "./write";

export const toggleFocusMode = (enableFocusMode: boolean) =>
  onlyCallFnOnceWhilePreviousCallIsPending(
    withPendingRequestBar(async () => {
      const currentUser = getAndAssertCurrentUser();
      const environment = getEnvironment();

      const [settingsRec] = await environment.recordLoader.getRecord({
        table: "user_settings",
        id: currentUser.id,
      });

      const hasTheUserEverEnabledFocusModeBefore =
        !!settingsRec?.settings.focus_mode_exceptions;

      if (hasTheUserEverEnabledFocusModeBefore) {
        const transaction = op.transaction({
          authorId: currentUser.id,
          operations: [
            op.set(
              {
                table: "user_settings",
                id: currentUser.id,
              },
              ["settings", "enable_focus_mode"],
              enableFocusMode,
            ),
          ],
        });

        await write(environment, transaction);
      } else {
        // If the user has never enabled focus mode before,
        // we take them to the focus mode section of the
        // settings so that they can learn more about it
        // and do any initial setup that's necessary.
        navigateService("/settings#focus-mode");
      }
    }),
  );
