// NOTE:
// THIS ISN'T CURRENTLY USED

import { forwardRef, Fragment } from "react";
import { cx } from "@emotion/css";
import { IOption } from "~/components/forms/AutocompleteSelect";
import { IFormControl } from "solid-forms-react";
import { Select } from "~/components/forms/Select";

// TODO: upgrade the RecipientsInput to import from `react-select/async`
//       instead of from `react-select` so that we can lazily load the
//       options based on what the user has typed. This will require
//       fulltext searching.

export type TOnResponseAnswer = "if no reply" | "regardless";

export type IOnResponseOption = IOption<TOnResponseAnswer>;

export const OPTIONS: IOnResponseOption[] = [
  {
    label: "regardless",
    value: "regardless",
  },
  {
    label: "if no reply",
    value: "if no reply",
  },
];

export const OnResponsePicker = forwardRef<
  HTMLButtonElement,
  {
    control: IFormControl<IOnResponseOption>;
    onFocusInput?: () => void;
  }
>((props, ref) => {
  return (
    <div className="flex px-4 relative">
      <Select<IOnResponseOption>
        ref={ref}
        control={props.control}
        onFocusInput={props.onFocusInput}
      >
        {OPTIONS.map((option, index) => {
          return (
            <Fragment key={option.value}>
              <Select.Option option={option}>
                <div
                  className={cx(
                    "px-4 rounded m-1",
                    // active && "ring-1 ring-white",
                  )}
                >
                  <Select.OptionText>{option.label}</Select.OptionText>
                </div>
              </Select.Option>

              {index < OPTIONS.length - 1 && (
                <hr className="text-whiteA-8 mx-4" />
              )}
            </Fragment>
          );
        })}
      </Select>
    </div>
  );
});
