import { wait } from "libs/promise-utils";
import { MS_IN_SECOND } from "@libs/chet-stack/dateHelpers";
import { getAndAssertCurrentUserId } from "./user.service";
import { UnreachableCaseError } from "libs/errors";
import {
  ApiQuery,
  queryToTableMap,
  SimpleFilterQueries,
  SqlDatabaseBase,
} from "libs/QueryApi";
import { tableFilterKeys } from "libs/schema";

const DelayMs = 10 * MS_IN_SECOND;

export class SubscriptionManager {
  private subscriptionCounts = new Map<string, number>();

  constructor(
    private args: {
      onSubscribe(key: string): void;
      onUnsubscribe(key: string): void;
    },
  ) {}

  subscribe(key: string): () => void {
    this.inc(key);
    return async () => {
      await wait(DelayMs);
      this.dec(key);
    };
  }

  keys() {
    return Array.from(this.subscriptionCounts.keys());
  }

  private inc(key: string) {
    const subscriptionCount = this.subscriptionCounts.get(key);

    if (subscriptionCount === undefined) {
      this.subscriptionCounts.set(key, 1);
      this.args.onSubscribe(key);
    } else {
      this.subscriptionCounts.set(key, subscriptionCount + 1);
    }
  }

  private dec(key: string) {
    const subscriptionCount = this.subscriptionCounts.get(key);

    if (subscriptionCount === undefined || subscriptionCount <= 0) {
      throw new Error("We shouldn't be decrementing right now.");
    }

    if (subscriptionCount === 1) {
      this.subscriptionCounts.delete(key);
      this.args.onUnsubscribe(key);
    } else {
      this.subscriptionCounts.set(key, subscriptionCount - 1);
    }
  }
}

export function getQuerySubscriptionKeys({ type, params }: ApiQuery): string[] {
  switch (type) {
    case "getRecord": {
      return [`${params.table}:${params.id}`];
    }
    case "getDrafts": {
      const currentUserId = getAndAssertCurrentUserId();
      const keys = [`draft:user_id:${currentUserId}`];

      if (params.scheduledToBeSent) {
        keys.push(`draft:scheduled_to_be_sent:${params.scheduledToBeSent}`);
      }

      return keys;
    }
    case "getGroupChildrenUserIsSubscribedTo": {
      return [
        `tag_group_member:group_id:${params.groupId}`,
        `tag_subscription:user_id:${params.userId}`,
      ];
    }
    case "getGroupsUserIsSubscribedTo": {
      return [`tag_subscription:user_id:${params.userId}`];
    }
    case "getTagViewThreads": {
      return [`thread_tag:tag_id:${params.tagId}`];
    }
    case "getThreadTimelineEntries": {
      return [`thread_timeline:thread_id:${params.thread_id}`];
    }
    case "getUserOrganizationProfiles": {
      return [`organization_user_member:user_id:${params.userId}`];
    }
    default: {
      return getKeys(type, params);
    }
  }
}

function getKeys<T extends SimpleFilterQueries>(
  type: T,
  params: Parameters<SqlDatabaseBase[T]>[0],
): string[] {
  if (!(type in queryToTableMap)) {
    throw new UnreachableCaseError(type as never);
  }

  const table = queryToTableMap[type as keyof typeof queryToTableMap];

  const props = tableFilterKeys[table] as unknown as string[];

  return props.map((prop) => `${table}:${prop}:${(params as any)[prop]}`);
}
