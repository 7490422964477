import useLocalStorageState from "use-local-storage-state";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";

export function useAlwaysMakeHeaderStickySetting() {
  const { currentUser } = useAuthGuardContext();

  return useLocalStorageState(
    `${currentUser.id}.settings.forceHeaderToBeSticky`,
    { defaultValue: false },
  );
}
