import { BehaviorSubject } from "rxjs";
import { removeOneFromArray } from "@libs/utils/array-utils";
import uid from "@libs/utils/uid";
import { SetRequired } from "type-fest";
import { ComponentType } from "react";
import { ToastAction } from "./toasts";
import { stripIndents } from "common-tags";
import { UnreachableCaseError } from "@libs/utils/errors";

export type IToastOptions = Partial<Omit<IToastState, "timeoutId" | "type">>;

export interface IToastState {
  id: string;
  type: string;
  timeoutId: { current: number | null };
  subject?: string;
  description?: string;
  durationMs: number;
  toastCSS?: string;
  Action?: ComponentType<{}>;
  onAction?: () => void;
  onDismiss?: () => void;
}

export const TOAST_STATE$ = new BehaviorSubject<IToastState[]>([]);

function addToast(toast: IToastState) {
  const toasts = TOAST_STATE$.getValue();
  TOAST_STATE$.next([...toasts, toast]);
}

export function removeToast(toastId: string) {
  const toasts = TOAST_STATE$.getValue();
  const toast = toasts.find((t) => t.id === toastId);
  if (!toast) return;
  // `clearTimeout()` accepts `null` as a value, it's just a noop
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  clearTimeout(toast.timeoutId.current!);
  TOAST_STATE$.next(removeOneFromArray(toasts, (t) => t.id === toastId));
}

interface IToastMap {
  undo: SetRequired<Omit<IToastOptions, "Action">, "onAction">;
  vanilla: IToastOptions;
}

/**
 * Creates a toast notification
 * @returns Function to remove the toast
 */
export function toast<T extends keyof IToastMap>(
  type: T,
  options: IToastMap[T],
) {
  let toastId: string;

  switch (type) {
    case "undo": {
      // toastId = createUndoToast(type, options as IToastMap["undo"]);
      toastId = createVanillaToast("vanilla", {
        subject: "Attempted to create undo toast",
        description: `
          We've temporarily disabled these.
        `,
      });
      break;
    }
    case "vanilla": {
      toastId = createVanillaToast(type, options);
      break;
    }
    default: {
      throw new UnreachableCaseError(type);
    }
  }

  return () => removeToast(toastId);
}

// function createUndoToast(
//   type: "undo",
//   options: SetRequired<IToastOptions, "onAction">,
// ) {
//   const undoTimeout =
//     !options.durationMs || options.durationMs > 10_000
//       ? 10_000
//       : options.durationMs;

//   const action = options.onAction;

//   const toastId = createVanillaToast(type, {
//     subject: options.subject,
//     durationMs: undoTimeout,
//     Action: () => (
//       <ToastAction name="Undo">
//         <kbd className="mx-1">Z</kbd>
//       </ToastAction>
//     ),
//     onAction: () => {
//       undoCallback?.();
//       clearUndo();
//     },
//     onDismiss: () => {
//       clearUndo();
//     },
//   });

//   registerUndo(
//     () => {
//       removeToast(toastId);
//       action();
//     },
//     {
//       timeout: undoTimeout,
//     },
//   );

//   return toastId;
// }

function createVanillaToast(type: string, options: IToastOptions = {}) {
  const toastId = options.id || uid();
  const durationMs = options.durationMs || 3000;
  const timeoutId = {
    current:
      options.durationMs === Infinity
        ? null
        : (setTimeout(
            () => removeToast(toastId),
            durationMs,
          ) as unknown as number),
  };

  const state = {
    ...options,
    timeoutId,
    durationMs,
    id: toastId,
    type,
  };

  if (options.onAction) {
    const actionFn = options.onAction;

    state.onAction = () => {
      removeToast(toastId);
      actionFn();
    };
  }

  addToast(state);

  return toastId;
}

export function showNotImplementedToastMsg(
  description = stripIndents`
    Unfortunately, this feature isn't currently supported. 
    Annoying, I know. I want this feature too...
  `,
) {
  toast("vanilla", {
    subject: "Not yet implemented 😭",
    description,
    durationMs: 7000,
  });
}
