import { useObservable, useObservableState } from "observable-hooks";
import { switchMap } from "rxjs";
import { useClientEnvironment } from "~/environment/ClientEnvironmentContext";
import {
  observeUsersGroupsWithFolderAncestorRecords,
  ObserveUserGroupsWithFolderAncestorRecordsResult,
} from "~/observables/observeUsersGroupsWithFolderAncestorRecords";

export type UseUsersGroupsWithFolderAncestorRecordsResult =
  ObserveUserGroupsWithFolderAncestorRecordsResult;

export function useUsersGroupsWithFolderAncestorRecords(props: {
  userId: string;
}): UseUsersGroupsWithFolderAncestorRecordsResult {
  const environment = useClientEnvironment();

  const query = useObservable(
    (inputs$) => {
      return inputs$.pipe(
        switchMap(([userId]) =>
          observeUsersGroupsWithFolderAncestorRecords({
            userId,
          }),
        ),
      );
    },
    [
      props.userId,
      // Note, even though the environment isn't used as an argument in the query above,
      // we still want to re-run the query when the environment changes.
      // observeTagFolderAncestorRecords gets and uses the environment internally.
      environment,
    ],
  );

  return useObservableState(query, DEFAULT_VALUE);
}

const DEFAULT_VALUE: UseUsersGroupsWithFolderAncestorRecordsResult =
  Object.freeze({
    groups: Object.freeze([]) as [],
    isLoading: true,
  });
