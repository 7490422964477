import { RecordValue } from "libs/schema";
import { useObservableState } from "observable-hooks";
import { PropsWithChildren, createContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { LoadingText } from "~/components/LoadingText";
import { CURRENT_USER$ } from "~/environment/user.service";
import { createUseContextHook } from "~/utils/createUseContextHook";

/**
 * A HOC which ensures that the wrapped component is only rendered
 * if the user is logged in (else redirects to the login page).
 * Also provides an `AuthGuardContext` for children.
 */
export function withAuthGuard<P extends PropsWithChildren<unknown>>(
  Component: React.ComponentType<P>,
) {
  return function GuardedRoute(props: P) {
    const [currentUser] = useObservableState<
      "loading" | RecordValue<"user_profile"> | null
    >(() => CURRENT_USER$, "loading" as const);

    const location = useLocation();

    // undefined if we haven't yet ascertained the current auth status
    if (currentUser === "loading") {
      return <LoadingText />;
    }

    if (!currentUser) {
      return <Navigate to={"/login"} state={{ from: location }} replace />;
    }

    return (
      <AuthGuardContext.Provider value={{ currentUser }}>
        <Component {...props} />
      </AuthGuardContext.Provider>
    );
  };
}

export const AuthGuardContext = createContext<
  { currentUser: RecordValue<"user_profile"> } | undefined
>(undefined);

export const useAuthGuardContext = createUseContextHook(
  AuthGuardContext,
  "AuthGuardContext",
);
