import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { FetchStrategy } from "~/environment/RecordLoader";

export type GetTagFolderAncestorsResult = {
  /**
   * Returns an array of folderId arrays. Each folderId array represents a
   * possible path from the root folder to the current folder.
   */
  folderPaths: string[][];
};

export function getTagFolderAncestors(props: {
  tagId: string;
  maxDepth?: number;
  /** "cache-then-network" is the default */
  fetchStrategy?: FetchStrategy;
}) {
  const { tagId, maxDepth = Infinity, fetchStrategy } = props;

  return getTagFolderAncestorsInner({
    tagId,
    currentDepth: 0,
    maxDepth,
    fetchStrategy,
  });
}

async function getTagFolderAncestorsInner(props: {
  tagId: string;
  currentDepth: number;
  maxDepth: number;
  fetchStrategy?: FetchStrategy;
}): Promise<GetTagFolderAncestorsResult> {
  const { tagId, currentDepth, maxDepth, fetchStrategy } = props;
  const { recordLoader } = getEnvironment();

  const [parentFolders] = await recordLoader.getTagFolderMembers(
    { tag_id: tagId },
    { fetchStrategy },
  );

  const nextDepth = currentDepth + 1;
  const isComplete = parentFolders.length === 0 || nextDepth === maxDepth;

  if (isComplete) {
    return {
      folderPaths: parentFolders.map(({ folder_id }) => [folder_id]),
    };
  }

  const ancestors = await Promise.all(
    parentFolders.map(({ folder_id }) =>
      getTagFolderAncestorsInner({
        tagId: folder_id,
        currentDepth: nextDepth,
        maxDepth,
      }),
    ),
  );

  return {
    folderPaths: parentFolders.flatMap(({ folder_id }) => {
      return ancestors.flatMap((a) => {
        return a.folderPaths.map((folderIds) => {
          return [folder_id, ...folderIds];
        });
      });
    }),
  };
}
