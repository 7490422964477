import {
  RecordValue,
  RecordTable,
  tablePKeys,
  RecordPointer,
} from "libs/schema";
import { Decoder, DecoderError, DecoderSuccess } from "ts-decoders";
import * as d from "ts-decoders/decoders";
import { JsonValue } from "type-fest";

/* -------------------------------------------------------------------------------------------------
 * Decoders
 * -------------------------------------------------------------------------------------------------
 *
 * These objects help with taking an unknown value, validating it, and returning a new, known value.
 */

const tableNames = Object.keys(tablePKeys);

export const tableD = new Decoder<RecordTable>((input) => {
  if (typeof input !== "string") {
    return new DecoderError(input, "invalid-type", "must be a string");
  }

  if (!tableNames.includes(input)) {
    return new DecoderError(input, "invalid-value", "must be a valid table");
  }

  return new DecoderSuccess(input as RecordTable);
});

/** This decoder returns a record pointer known to be safe. */
export const pointerD = d.objectD({
  table: tableD,
  id: d.stringD(),
}) as Decoder<RecordPointer>;

/**
 * While `null` is a valid json type, we'd prefer top-level `null` values
 * to be saved to the database as `null` rather than saved as "null"
 * (i.e. JSON null). Additionally, if we want to allow top-level null values
 * we'll use the "nullable" version of the JSON decoder (created
 * inside the `getDatabaseDecoderBases` function). By "top-level" I mean
 * that nested JSON values should still accept null, but this decoder
 * rejects a simple `null` value.
 */
export const NonNullableJsonD = new Decoder((input) => {
  if (input === null) {
    return new DecoderError(
      input,
      "invalid-type",
      "top-level `null` value not allowed",
    );
  }

  return JsonD.decode(input);
});

export const JsonD: Decoder<JsonValue> = new Decoder((input) => {
  switch (typeof input) {
    case "object": {
      if (input === null) {
        return new DecoderSuccess(input);
      }

      if (Array.isArray(input)) {
        return JsonArrayD.decode(input);
      }

      return JsonDictionaryD.decode(input);
    }
    case "boolean":
    case "number":
    case "string": {
      return new DecoderSuccess(input);
    }
    default: {
      return new DecoderError(
        input,
        `invalid-type`,
        `invalid JSON type ${typeof input}`,
      );
    }
  }
});

const JsonArrayD = d.arrayD(JsonD);
const JsonDictionaryD = d.dictionaryD(JsonD);

export function getDatabaseDecoderBases<
  TextT extends Decoder<any>,
  BooleanT extends Decoder<any>,
  IntegerT extends Decoder<any>,
  JsonT extends Decoder<any>,
  UuidT extends Decoder<any>,
  DateTimeT extends Decoder<any>,
>(args: {
  TextD: TextT;
  BooleanD: BooleanT;
  IntegerD: IntegerT;
  JsonD: JsonT;
  UuidD: UuidT;
  DateTimeD: DateTimeT;
}) {
  const { TextD, BooleanD, IntegerD, JsonD, UuidD, DateTimeD } = args;
  const NullableTextD = d.nullableD(TextD);
  const NullableBooleanD = d.nullableD(BooleanD);
  const NullableIntegerD = d.nullableD(IntegerD);
  const NullableJsonD = d.nullableD(JsonD);
  const NullableUuidD = d.nullableD(UuidD);
  const NullableDateTimeD = d.nullableD(DateTimeD);

  return {
    attachment: {
      id: UuidD,
      filename: TextD,
      content_disposition: NullableTextD,
      content_type: TextD,
      url: TextD,
      size: IntegerD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    auth_token: {
      id: UuidD,
      user_id: UuidD,
      expires_at: DateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    draft_email_recipient: {
      id: UuidD,
      draft_id: UuidD,
      draft_user_id: UuidD,
      email_address: TextD,
      type: TextD,
      priority: IntegerD,
      is_implicit: BooleanD,
      is_mentioned: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    draft_group_recipient: {
      id: UuidD,
      draft_id: UuidD,
      draft_user_id: UuidD,
      group_id: UuidD,
      priority: IntegerD,
      is_implicit: BooleanD,
      is_mentioned: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    draft_tag: {
      id: UuidD,
      draft_id: UuidD,
      draft_user_id: UuidD,
      tag_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    draft_user_recipient: {
      id: UuidD,
      draft_id: UuidD,
      recipient_user_id: UuidD,
      draft_user_id: UuidD,
      priority: IntegerD,
      is_implicit: BooleanD,
      is_mentioned: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    draft: {
      id: UuidD,
      user_id: UuidD,
      thread_id: UuidD,
      type: TextD,
      new_thread_subject: NullableTextD,
      new_thread_visibility: NullableTextD,
      branched_from_thread_id: NullableUuidD,
      branched_from_message_id: NullableUuidD,
      branched_from_message_sent_at: NullableDateTimeD,
      branched_from_message_scheduled_to_be_sent_at: NullableDateTimeD,
      is_reply: BooleanD,
      body_html: TextD,
      sent: BooleanD,
      sent_at: NullableDateTimeD,
      scheduled_to_be_sent: BooleanD,
      scheduled_to_be_sent_at: NullableDateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    inbox_section: {
      id: UuidD,
      user_id: UuidD,
      name: TextD,
      order: IntegerD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    inbox_subsection: {
      id: UuidD,
      user_id: UuidD,
      inbox_section_id: UuidD,
      name: TextD,
      order: IntegerD,
      description: NullableTextD,
      query: TextD,
      parsed_query: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    user_lesson: {
      id: UuidD,
      user_id: UuidD,
      lesson_id: UuidD,
      lesson_version: IntegerD,
      is_completed: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    mention_frequency: {
      id: UuidD,
      type: TextD,
      user_id: UuidD,
      subject_id: UuidD,
      frequency: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message_attachment: {
      id: UuidD,
      message_id: UuidD,
      attachment_id: UuidD,
      thread_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message_email_recipient: {
      id: UuidD,
      message_id: UuidD,
      thread_id: UuidD,
      email_address: TextD,
      type: TextD,
      priority: IntegerD,
      is_implicit: BooleanD,
      is_mentioned: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message_email_reference: {
      id: UuidD,
      message_id: UuidD,
      thread_id: UuidD,
      email_message_id: TextD,
      order: IntegerD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message_group_recipient: {
      id: UuidD,
      message_id: UuidD,
      thread_id: UuidD,
      group_id: UuidD,
      priority: IntegerD,
      is_implicit: BooleanD,
      is_mentioned: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message_user_reaction: {
      id: UuidD,
      message_id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      reactions: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message_user_recipient: {
      id: UuidD,
      message_id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      priority: IntegerD,
      is_implicit: BooleanD,
      is_mentioned: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    message: {
      id: UuidD,
      thread_id: UuidD,
      type: TextD,
      is_reply: BooleanD,
      subject: TextD,
      body_text: TextD,
      body_html: TextD,
      sent_at: DateTimeD,
      scheduled_to_be_sent_at: DateTimeD,
      timeline_order: TextD,
      sender_user_id: NullableUuidD,
      was_edited: BooleanD,
      last_edited_at: NullableDateTimeD,
      reactions: JsonD,
      email_message_id: NullableTextD,
      email_sender: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    notification_tag: {
      id: UuidD,
      tag_id: UuidD,
      notification_id: UuidD,
      notification_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    notification: {
      id: UuidD,
      user_id: UuidD,
      thread_id: UuidD,
      message_id: UuidD,
      message_type: TextD,
      sent_at: DateTimeD,
      is_done: BooleanD,
      done_at: NullableDateTimeD,
      done_last_modified_by: TextD,
      oldest_sent_at_value_not_marked_done: NullableDateTimeD,
      priority: IntegerD,
      has_reminder: BooleanD,
      remind_at: NullableDateTimeD,
      is_starred: BooleanD,
      starred_at: NullableDateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    organization_profile: {
      id: UuidD,
      name: TextD,
      name_short: TextD,
      photo_url: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    organization_user_invitation: {
      id: UuidD,
      organization_id: UuidD,
      email_address: TextD,
      creator_user_id: UuidD,
      expires_at: DateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    organization_user_member: {
      id: UuidD,
      organization_id: UuidD,
      user_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    organization_controlled_domain: {
      id: UuidD,
      domain: TextD,
      organization_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    organization: {
      id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    provider_email_map: {
      id: UuidD,
      user_id: UuidD,
      provider: TextD,
      provider_id: TextD,
      provider_thread_id: TextD,
      email_message_id: TextD,
      message_id: UuidD,
      thread_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    tag_folder_member: {
      id: UuidD,
      tag_id: UuidD,
      folder_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    tag_group_member: {
      id: UuidD,
      tag_id: UuidD,
      group_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    tag_subscription: {
      id: UuidD,
      tag_id: UuidD,
      user_id: UuidD,
      creator_user_id: UuidD,
      preference: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    tag_user_member: {
      id: UuidD,
      tag_id: UuidD,
      user_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    tag: {
      id: UuidD,
      type: TextD,
      name: TextD,
      icon: NullableTextD,
      description: NullableTextD,
      data: NullableJsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_group_permission: {
      id: UuidD,
      thread_id: UuidD,
      group_id: UuidD,
      start_at: DateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_read_receipt: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      read_to_timeline_id: UuidD,
      read_to_timeline_order: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_seen_receipt: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      seen_to_timeline_id: UuidD,
      seen_to_timeline_order: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_subscription: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      preference: TextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_tag: {
      id: UuidD,
      thread_id: UuidD,
      tag_id: UuidD,
      creator_user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_timeline: {
      id: UuidD,
      thread_id: UuidD,
      type: TextD,
      order: TextD,
      data: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_user_participant: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread_user_permission: {
      id: UuidD,
      thread_id: UuidD,
      user_id: UuidD,
      start_at: DateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    thread: {
      id: UuidD,
      type: TextD,
      subject: TextD,
      visibility: TextD,
      first_message_id: UuidD,
      first_message_sent_at: DateTimeD,
      first_message_scheduled_to_be_sent_at: DateTimeD,
      last_message_id: UuidD,
      last_message_sent_at: DateTimeD,
      last_message_scheduled_to_be_sent_at: DateTimeD,
      first_timeline_id: UuidD,
      first_timeline_order: TextD,
      last_timeline_id: UuidD,
      last_timeline_order: TextD,
      is_branch: BooleanD,
      branched_from_thread_id: NullableUuidD,
      branched_from_message_id: NullableUuidD,
      branched_from_message_sent_at: NullableDateTimeD,
      branched_from_message_scheduled_to_be_sent_at: NullableDateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    user_contact_info: {
      id: UuidD,
      email_address: TextD,
      phone_number: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    user_oauth: {
      id: TextD,
      user_id: UuidD,
      provider: TextD,
      federated_id: TextD,
      oauth_id_token: NullableTextD,
      oauth_access_token: NullableTextD,
      firebase_auth_id: TextD,
      firebase_id_token: TextD,
      firebase_id_token_expires_at: DateTimeD,
      firebase_refresh_token: TextD,
      is_linked_to_user: BooleanD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    user_profile: {
      id: UuidD,
      first_name: TextD,
      middle_name: NullableTextD,
      last_name: TextD,
      name: TextD,
      photo_url: NullableTextD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    user_settings: {
      id: UuidD,
      settings: JsonD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
    user: {
      id: UuidD,
      firebase_auth_id: TextD,
      email: TextD,
      email_verified: BooleanD,
      email_verified_at: NullableDateTimeD,
      owner_organization_id: UuidD,
      version: IntegerD,
      created_at: DateTimeD,
      updated_at: DateTimeD,
      is_deleted: BooleanD,
    },
  } satisfies {
    [Table in RecordTable]: {
      [Prop in keyof RecordValue<Table>]: Decoder<any>;
    };
  };
}
