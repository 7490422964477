import { generateRecordId } from "libs/schema";
import { op } from "libs/transaction";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { withPendingUpdate } from "~/environment/loading.service";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { write } from "./write";

export const updateLessonForCurrentUser = withPendingUpdate(
  async (props: { name: string; version: number; isComplete: boolean }) => {
    const environment = getEnvironment();
    const currentUser = getAndAssertCurrentUser();

    const transaction = op.transaction({
      authorId: currentUser.id,
      operations: [
        op.set("user_lesson", {
          id: generateRecordId("user_lesson", {
            lesson_id: props.name,
            user_id: currentUser.id,
          }),
          lesson_id: props.name,
          user_id: currentUser.id,
          is_completed: props.isComplete,
          lesson_version: props.version,
          owner_organization_id: currentUser.owner_organization_id,
        }),
      ],
    });

    await write(environment, transaction);
  },
);
