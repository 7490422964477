/**
 * Intended to be used with a `switch` statement `case`
 * to indicate code which should never be called.
 *
 * For example:
 * ```ts
 * switch (obj.prop) {
 *   case "value 1": {
 *     return // stuff
 *   }
 *   default: {
 *     throw new UnreachableCaseError(obj.prop);
 *   }
 * }
 * ```
 */
export class UnreachableCaseError extends Error {
  static statusCode = 424;
  statusCode = 424;

  constructor(val: never, msg = "") {
    super(`Unreachable case: ${msg || JSON.stringify(val)}`);
  }
}

export function throwUnreachableCaseError(val: never, msg = ""): never {
  throw new UnreachableCaseError(val, msg);
}
