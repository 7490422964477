import { op, Transaction, CreateRecord } from "libs/transaction";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { generateRecordId } from "libs/schema";
import { findMatchingSubsection } from "libs/searchQueryMatcher";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { compact } from "lodash-comms";

export interface CreateNotificationProps {
  threadId: string;
  done?: boolean;
  triagedUntil?: Date | null;
  isStarred?: boolean;
}

export async function createNotificationTx(
  props: CreateNotificationProps,
): Promise<Transaction> {
  const currentUser = getAndAssertCurrentUser();
  const { recordLoader } = getEnvironment();

  const [[thread], [inboxSections], [inboxSubsections]] = await Promise.all([
    recordLoader.getRecord("thread", props.threadId),
    recordLoader.getInboxSections({
      user_id: currentUser.id,
    }),
    recordLoader.getInboxSubsections({
      user_id: currentUser.id,
    }),
  ]);

  if (!thread) {
    throw new Error(`Thread not found: ${props.threadId}`);
  }

  if (thread?.type === "EMAIL_BCC") {
    console.error(
      "Attempted to triage bcc thread. Instead triage the canonical thread " +
        "that this bcc thread is associated with.",
      thread,
    );

    throw new Error(`Attempted to triage bcc thread`);
  }

  const inboxSectionMatches = await Promise.all(
    inboxSections.map((inboxSection) => {
      return findMatchingSubsection({
        currentUserId: currentUser.id,
        messageId: thread.last_message_id,
        threadId: thread.id,
        loader: recordLoader,
        inboxSection,
        inboxSubsections: inboxSubsections.filter(
          (subsection) => subsection.inbox_section_id === inboxSection.id,
        ),
      });
    }),
  );

  const isDone = props.done ?? true;

  const transaction = op.transaction({
    authorId: currentUser.id,
    operations: [],
  });

  const notification: CreateRecord<"notification"> = {
    id: thread.id,
    message_id: thread.last_message_id,
    message_type: thread.type,
    done_at: isDone ? new Date().toISOString() : null,
    is_done: isDone,
    done_last_modified_by: "user",
    oldest_sent_at_value_not_marked_done: isDone
      ? null
      : thread.last_message_sent_at,
    has_reminder: !!props.triagedUntil,
    is_starred: !!props.isStarred,
    owner_organization_id: currentUser.owner_organization_id,
    priority: 200,
    remind_at: props.triagedUntil?.toISOString() || null,
    sent_at: thread.last_message_sent_at,
    starred_at: props.isStarred ? new Date().toISOString() : null,
    thread_id: thread.id,
    user_id: currentUser.id,
  };

  transaction.operations.push(op.set("notification", notification));

  for (const { section, subsection } of compact(inboxSectionMatches)) {
    transaction.operations.push(
      op.set("notification_tag", {
        id: generateRecordId("notification_tag", {
          notification_id: notification.id,
          tag_id: section.id,
        }),
        tag_id: section.id,
        notification_id: notification.id,
        owner_organization_id: currentUser.owner_organization_id,
      }),

      op.set("notification_tag", {
        id: generateRecordId("notification_tag", {
          notification_id: notification.id,
          tag_id: subsection.id,
        }),
        tag_id: subsection.id,
        notification_id: notification.id,
        owner_organization_id: currentUser.owner_organization_id,
      }),
    );
  }

  if (
    notification.is_done &&
    // setting a reminder shouldn't mark a thread as "read"
    !notification.has_reminder
  ) {
    transaction.operations.push(
      op.set("thread_read_receipt", {
        id: generateRecordId("thread_read_receipt", {
          thread_id: notification.id,
          user_id: currentUser.id,
        }),
        owner_organization_id: currentUser.owner_organization_id,
        thread_id: notification.id,
        user_id: currentUser.id,
        read_to_timeline_id: thread.last_timeline_id,
        read_to_timeline_order: thread.last_timeline_order,
      }),

      op.set("thread_seen_receipt", {
        id: generateRecordId("thread_seen_receipt", {
          thread_id: notification.id,
          user_id: currentUser.id,
        }),
        owner_organization_id: currentUser.owner_organization_id,
        thread_id: notification.id,
        user_id: currentUser.id,
        seen_to_timeline_id: thread.last_timeline_id,
        seen_to_timeline_order: thread.last_timeline_order,
      }),
    );
  }

  return transaction;
}

/* -----------------------------------------------------------------------------------------------*/
