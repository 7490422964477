import { ComponentType, memo, RefObject, useRef } from "react";
import { useNavigate, useParams, useMatch } from "react-router-dom";
import { ListScrollbox } from "~/components/list";
import { Helmet } from "react-helmet-async";
import { NotFound } from "~/components/NotFound";
import { cx } from "@emotion/css";
import { withPendingRequestBar } from "~/components/PendingRequestBar";
import {
  ICommandArgs,
  useRegisterCommands,
  withNewCommandContext,
} from "~/environment/command.service";
import { KBarState } from "~/dialogs/kbar";
import { isAppOnline } from "~/environment/network-connection.service";
import { toast, showNotImplementedToastMsg } from "~/environment/toast-service";
import { Tooltip } from "~/components/Tooltip";
import { IoMdEye } from "react-icons/io";

import { RemindMeDialogState } from "~/dialogs/remind-me";
import { onlyCallFnOnceWhilePreviousCallIsPending } from "~/utils/onlyCallOnceWhilePending";
import { map, Observable } from "rxjs";
import {
  tagSubscriptionCommands,
  ESCAPE_TO_INBOX_COMMAND,
  markDoneCommand,
  markNotDoneCommand,
  setThreadReminderCommand,
  removeThreadReminderCommand,
  starThreadCommand,
  unstarThreadCommand,
} from "~/utils/common-commands";
import { BsLockFill } from "react-icons/bs";
import { UnreachableCaseError } from "@libs/utils/errors";
import { DEFAULT_SUBSCRIPTION_PREFERENCE } from "@libs/firestore-models/utils";
import * as MainLayout from "~/page-layouts/main-layout";
import { OutlineDropdownButton } from "~/components/OutlineButtons";
import { useTag } from "~/hooks/useTag";
import { useTagViewThreads } from "~/hooks/useTagViewThreads";
import { useCurrentUserTagSubscription } from "~/hooks/useCurrentUserTagSubscription";
import {
  ContentList,
  EmptyListMessage,
  useKBarAwareFocusedEntry$,
} from "~/components/content-list/ContentList";
import {
  RecordValue,
  SpecialTagTypes,
  SubscriptionPreference,
} from "libs/schema";
import { useTopScrollShadow } from "~/hooks/useScrollShadow";
import { EditGroupDialogState } from "~/dialogs/group-edit/EditGroupDialog";
import { triageThread } from "~/actions/triageThread";
import { useTagSubscribersCount } from "~/hooks/useTagSubscribersCount";
import { useIsTagPrivate } from "~/hooks/useIsTagPrivate";
import { onThreadSelectNavigateToThread, ThreadEntry } from "./ThreadEntry";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";

/* -------------------------------------------------------------------------------------------------
 * TagView
 * -----------------------------------------------------------------------------------------------*/

export const TagView = withNewCommandContext(() => {
  const scrollboxRef = useRef<HTMLElement>(document.body);
  const headerRef = useRef<HTMLElement>(null);
  const params = useParams();
  const navigate = useNavigate();
  const [tag] = useTag(params.tagId);
  const isGroupView = useIsGroupView();
  const { threadIds } = useTagViewThreads(
    params.tagId,
    isGroupView ? "group" : "tag",
  );

  console.log("tag", tag);
  console.warn("threadIds", threadIds);

  const [subscribersCount] = useTagSubscribersCount(params.tagId);

  const { focusedEntry$, setFocusedEntry } =
    useKBarAwareFocusedEntry$<RecordValue<"thread">>();

  const { isTagPrivate } = useIsTagPrivate(params.tagId);

  useRegisterTagViewCommands({
    focusedEntry$,
    tag,
  });

  useApplyScrollShadowToHeader({
    tag,
    scrollboxRef,
    headerRef,
  });

  if (tag === undefined) {
    return <div>Loading...</div>;
  }

  if (
    tag === null ||
    (isGroupView
      ? tag.type !== SpecialTagTypes.GROUP
      : tag.type === SpecialTagTypes.GROUP)
  ) {
    return <NotFound title={`${isGroupView ? "Group" : "Tag"} Not Found`} />;
  }

  return (
    <>
      <Helmet>
        <title>
          {tag.name} | {isGroupView ? "Group" : "Tag"} | Comms
        </title>
      </Helmet>

      <MainLayout.Header
        ref={headerRef}
        theme={isTagPrivate ? "dark" : "light"}
        className="flex-col"
      >
        <div
          className={cx("flex items-center", {
            ["mb-1"]: !!tag.description,
          })}
        >
          <h1 className="text-3xl text-slate-8 truncate">
            <span className={isTagPrivate ? "text-white" : "text-black"}>
              #{tag.name}
            </span>

            {isTagPrivate && (
              <Tooltip
                side="bottom"
                content="This channel is private and only visible to invited members"
              >
                <span className="text-2xl inline-flex ml-2 hover:cursor-help mt-1">
                  <small>
                    <BsLockFill />
                  </small>
                </span>
              </Tooltip>
            )}

            {/* <ChannelGroupDetails tag={tag} /> */}
          </h1>

          <div className="flex-1" />
        </div>

        {tag.description && <p className="text-xl">{tag.description}</p>}

        <MainLayout.HeaderMenu>
          <li>
            <SubscriptionLevel tagId={tag.id} isTagPrivate={isTagPrivate} />
          </li>

          <li>
            <OutlineDropdownButton
              theme={isTagPrivate ? "dark" : "light"}
              onClick={(e) => {
                e.preventDefault();
                navigate("subscribers");
              }}
            >
              <small>
                {subscribersCount !== undefined && (
                  <span
                    className={cx(
                      "mr-2 font-medium",
                      isTagPrivate ? "text-whiteA-11" : "text-slate-11",
                    )}
                  >
                    {subscribersCount}
                  </span>
                )}
                Subscriber{subscribersCount === 1 ? "" : "s"}
              </small>
            </OutlineDropdownButton>
          </li>
        </MainLayout.HeaderMenu>
      </MainLayout.Header>

      <ListScrollbox
        isBodyElement
        offsetHeaderEl={headerRef}
        onlyOffsetHeaderElIfSticky
      >
        {threadIds.length === 0 ? (
          <EmptyListMessage text="Nothing yet." />
        ) : (
          <ContentList<RecordValue<"thread">>
            onEntryFocused={setFocusedEntry}
            onEntryAction={onThreadSelectNavigateToThread}
            className="mb-20"
            autoFocus
          >
            {threadIds.map((threadId, index) => (
              <ThreadEntry
                key={threadId}
                threadId={threadId}
                relativeOrder={index}
              />
            ))}
          </ContentList>
        )}
      </ListScrollbox>
    </>
  );
});

/* -----------------------------------------------------------------------------------------------*/

// const ChannelGroupDetails: ComponentType<{
//   channel: IChannelDocWithCurrentUserData;
// }> = memo(({ channel }) => {
//   return (
//     <span
//       title={channel.__local.knownChannelGroups.map((c) => c.name).join(", ")}
//     >
//       {channel.__local.knownChannelGroups.map((channelGroup, index) => {
//         return (
//           <span key={channelGroup.id} className="ml-2">
//             {channelGroup.name}
//             {index !== channel.channelGroupIds.length - 1 && ","}
//           </span>
//         );
//       })}
//     </span>
//   );
// });

/* -----------------------------------------------------------------------------------------------*/

function useApplyScrollShadowToHeader(args: {
  tag: unknown;
  scrollboxRef: RefObject<HTMLElement>;
  headerRef: RefObject<HTMLElement>;
}) {
  const { tag, scrollboxRef, headerRef } = args;

  useTopScrollShadow({
    scrollboxRef,
    targetRef: headerRef,
    deps: [!!tag],
  });
}

/* -------------------------------------------------------------------------------------------------
 * SubscriptionLevel
 * -----------------------------------------------------------------------------------------------*/

const SubscriptionLevel: ComponentType<{
  tagId: string;
  isTagPrivate: boolean;
}> = memo((props) => {
  const [subscription, { isLoading: isSubscriptionLoading }] =
    useCurrentUserTagSubscription(props.tagId);

  const isGroupView = useIsGroupView();

  const { label, hintText } = getSubscriptionText({
    preference: subscription?.preference,
    isLoading: isSubscriptionLoading,
    isGroupView,
  });

  return (
    <Tooltip
      side="bottom"
      content={
        <>
          <p className="text-center">{hintText}</p>
          <p className="mt-1">
            <em>
              (press <kbd>S</kbd> to update subscription )
            </em>
          </p>
        </>
      }
    >
      <span>
        <OutlineDropdownButton
          theme={props.isTagPrivate ? "dark" : "light"}
          onClick={(e) => {
            if (isSubscriptionLoading) return;
            e.preventDefault();
            KBarState.open({
              path: ["Update subscription"],
              mode: "hotkey",
            });
          }}
        >
          <IoMdEye className="mr-1 text-slate-11" /> <small>{label}</small>
        </OutlineDropdownButton>
      </span>
    </Tooltip>
  );
});

/* -----------------------------------------------------------------------------------------------*/

function getSubscriptionText(args: {
  preference?: SubscriptionPreference | null;
  isLoading: boolean;
  isGroupView: boolean;
}) {
  const { isLoading, preference, isGroupView } = args;

  if (isLoading) {
    return {
      label: "",
      hintText: `loading`,
    };
  }

  const tagType = isGroupView ? "group" : "tag";

  const normalizedPreference = !preference
    ? DEFAULT_SUBSCRIPTION_PREFERENCE
    : preference;

  switch (normalizedPreference) {
    case "all": {
      return {
        label: "Subscribed All",
        hintText: `You will receive all notifications for this ${tagType}.`,
      };
    }
    case "all-new": {
      return {
        label: "Subscribed",
        hintText: `
          You will receive a notification for every new
          thread added to this ${tagType}.
        `,
      };
    }
    case "involved": {
      return {
        label: "Unsubscribed",
        hintText: `
          You will only receive notifications for 
          threads you are participating or @mentioned in.
        `,
      };
      break;
    }
    default: {
      throw new UnreachableCaseError(normalizedPreference);
    }
  }
}

/* -------------------------------------------------------------------------------------------------
 * useRegisterTagViewCommands
 * -----------------------------------------------------------------------------------------------*/

function useRegisterTagViewCommands(args: {
  focusedEntry$: Observable<RecordValue<"thread"> | null>;
  tag: RecordValue<"tag"> | null | undefined;
}) {
  const { focusedEntry$, tag } = args;

  useRegisterCommands({
    commands: () => {
      return focusedEntry$.pipe(
        map((focusedThread) => {
          const commands: ICommandArgs[] = [ESCAPE_TO_INBOX_COMMAND];

          if (focusedThread) {
            commands.push(
              markDoneCommand({
                callback: () => {
                  triageThread({
                    threadId: focusedThread.id,
                    done: true,
                  });
                },
              }),
              markNotDoneCommand({
                callback: () => {
                  triageThread({
                    threadId: focusedThread.id,
                    done: false,
                  });
                },
              }),
              setThreadReminderCommand({
                callback: () => setThreadReminder(focusedThread.id),
              }),
              removeThreadReminderCommand({
                callback: () => {
                  triageThread({
                    threadId: focusedThread.id,
                    triagedUntil: null,
                  });
                },
              }),
              starThreadCommand({
                callback: () => {
                  triageThread({
                    threadId: focusedThread.id,
                    isStarred: true,
                  });
                },
              }),
              unstarThreadCommand({
                callback: () => {
                  triageThread({
                    threadId: focusedThread.id,
                    isStarred: false,
                  });
                },
              }),
            );
          }

          if (tag) {
            commands.push(...tagSubscriptionCommands(tag.id));

            commands.push(
              {
                label: "Update group",
                altLabels: [
                  "Edit channel",
                  "Move channel",
                  "Update channel",
                  "Edit group",
                  "Move group",
                ],
                callback: () => {
                  if (!isAppOnline()) {
                    toast("vanilla", {
                      subject: "Not supported in offline mode",
                      description: "Can't update channels when offline.",
                    });

                    return;
                  }

                  // if (channel.isOrganizationSharedChannel) {
                  //   toast("vanilla", {
                  //     subject: `Cannot edit`,
                  //     description: `The ${channel.name} is special and cannot be edited.`,
                  //   });

                  //   return;
                  // }

                  EditGroupDialogState.open({
                    prefill: {
                      id: tag.id,
                      name: tag.name,
                      description: tag.description,
                    },
                  });
                },
              },
              {
                label: "Delete group (n/a)",
                callback: () => showNotImplementedToastMsg(),
              },
            );

            // // The default compose command in SidebarLayout will be overridden to include
            // // the channel as a recipient.
            // commands.push(
            //   composeMessageCommand({
            //     callback: onlyCallFnOnceWhilePreviousCallIsPending(async () => {
            //       const draftId = uid();

            //       // We're intentionally not awaiting this response since it results in
            //       // this command taking too long in the UI
            //       createNewDraft({
            //         type: "COMMS",
            //         postId: draftId,
            //         ...getNewThreadDataWithChannelAsDefaultRecipient(channel),
            //       });

            //       await wait(50);

            //       openComposeNewThreadDialog(draftId);
            //     }),
            //   }),
            // );
          }

          return commands;
        }),
      );
    },
    deps: [tag, focusedEntry$],
  });
}

/* -----------------------------------------------------------------------------------------------*/

// function getNewThreadDataWithGroupAsDefaultRecipient(
//   group: RecordValue<"tag">,
// ) {
//   return {
//     visibility:
//       currentChannel.classification === "private"
//         ? ("private" as const)
//         : ("shared" as const),
//     to: [
//       {
//         type: "channelId" as const,
//         value: group.id,
//       },
//     ],
//   };
// }

const setThreadReminder = onlyCallFnOnceWhilePreviousCallIsPending(
  withPendingRequestBar(async (threadId: string) => {
    const { recordLoader } = getEnvironment();

    const [notification] = await recordLoader.getRecord(
      "notification",
      threadId,
    );

    RemindMeDialogState.open({
      id: threadId,
      hasReminder: notification?.has_reminder ?? false,
      isStarred: notification?.is_starred ?? false,
    });
  }),
);

function useIsGroupView() {
  const match = useMatch("/groups/:tagId");
  return !!match;
}

/* -----------------------------------------------------------------------------------------------*/
