import { startWith } from "@libs/utils/rxjs-operators";
import { getRecordId } from "libs/schema";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { observeRecord } from "./observeRecord";
import { observeQuery } from "./observeQuery";

export type ObserveTagsInFolderThatUserIsSubscribedToResult = {
  tagIds: string[];
  isLoading: boolean;
};

export function observeTagsInFolderThatUserIsSubscribedTo(props: {
  folderId: string;
  userId: string;
}): Observable<ObserveTagsInFolderThatUserIsSubscribedToResult> {
  return observeQuery("observeGetTagFolderMembers", {
    folder_id: props.folderId,
  }).pipe(
    switchMap(({ records: tags, isLoading }) => {
      if (tags.length === 0) {
        return of({ tagIds: [], isLoading });
      }

      return combineLatest(
        tags.map(({ tag_id }) =>
          observeIsUserSubscribedToFolderOrAFolderDescendent({
            folderId: tag_id,
            userId: props.userId,
          }).pipe(
            map(({ isSubscribed, isLoading }) => {
              return {
                isLoading,
                tagId: isSubscribed ? tag_id : null,
              };
            }),
          ),
        ),
      ).pipe(
        map((results) => {
          return results.reduce(
            (store, { tagId, isLoading }) => {
              store.isLoading = store.isLoading || isLoading;

              if (tagId) {
                store.tagIds.push(tagId);
              }

              return store;
            },
            { tagIds: [] as string[], isLoading },
          );
        }),
      );
    }),
  );
}

/**
 * Is the user subscribed to this folder directly or is there another folder
 * deeply nested within this folder (via `tag_folder_member`) that the user
 * is subscribed to?
 */
function observeIsUserSubscribedToFolderOrAFolderDescendent(props: {
  folderId: string;
  userId: string;
}): Observable<{ isSubscribed: boolean; isLoading: boolean }> {
  return combineLatest([
    observeRecord({
      table: "tag_subscription",
      id: getRecordId("tag_subscription", {
        tag_id: props.folderId,
        user_id: props.userId,
      }),
    }),
    observeQuery("observeGetTagFolderMembers", {
      folder_id: props.folderId,
    }),
  ]).pipe(
    switchMap(
      ([
        { record, isLoading: isRecordLoading },
        { records: tags, isLoading: areTagsLoading },
      ]) => {
        const isRecordSubscribedTo =
          !!record && ["all", "all-new"].includes(record.preference);

        const isLoading = isRecordLoading || areTagsLoading;

        if (isRecordSubscribedTo) {
          return of({ isSubscribed: true, isLoading });
        }

        if (tags.length === 0) {
          return of({ isSubscribed: false, isLoading });
        }

        return combineLatest(
          tags.map(({ tag_id }) =>
            observeIsUserSubscribedToFolderOrAFolderDescendent({
              folderId: tag_id,
              userId: props.userId,
            }),
          ),
        ).pipe(
          map((results) => {
            return results.reduce(
              (store, { isSubscribed, isLoading }) => {
                store.isLoading = store.isLoading || isLoading;

                if (isSubscribed) {
                  store.isSubscribed = true;
                }

                return store;
              },
              { isSubscribed: false, isLoading },
            );
          }),
        );
      },
    ),
    startWith(() => ({ isSubscribed: false, isLoading: true })),
  );
}
