import { ClientEnvironment } from "./ClientEnvironment";
import { LoaderCache } from "./LoaderCache";
// import { RecordStorage } from "./RecordStorage";
// import { SubscriptionManager } from "./SubscriptionManager";
// import { TransactionQueue } from "./TransactionQueue";
// import { UndoRedoStack } from "./UndoRedoStack";
// import { WebsocketPubsubClient } from "./WebsocketPubsubClient";
import { createApi } from "./api";
import { createConfig } from "./config";
import { SubscriptionManager } from "./SubscriptionManager";
import { WebsocketPubsubClient } from "./WebsocketPubsubClient";
// import { getQueryFromKey, getQueryKey } from "@libs/chet-stack/PubSubKeys";
import { getPointer, RecordPointer } from "libs/schema";
import { createLogger } from "libs/logger";
// import { getApiQueryFromKey, getApiQueryKey } from "libs/ApiQueryKey";
import { TransactionQueue } from "./TransactionQueue";
import { uniqWith } from "lodash-comms";
import { isEqual } from "@libs/utils/isEqual";
import { UndoRedoStack } from "./UndoRedoStack";
import { createClientDatabase } from "./database/ClientDatabase";
import { createRecordLoader } from "./RecordLoader";

export async function createEnvironment() {
  const logger = createLogger();
  const config = createConfig();
  const db = await createClientDatabase({ logger });
  const recordLoader = createRecordLoader(db);
  const api = createApi({ config, logger });
  const loaderCache = new LoaderCache();
  const undoRedo = new UndoRedoStack();

  const subManagerLogger = logger.child({
    for: "SubscriptionManager",
  });

  const subscriptionManager = new SubscriptionManager({
    onSubscribe: (key) => {
      subManagerLogger.debug("onSubscribe", key);
      pubsub.subscribe(key);
    },
    onUnsubscribe: (key) => {
      subManagerLogger.debug("onUnsubscribe", key);
      pubsub.unsubscribe(key);

      // const query = getApiQueryFromKey(key);

      // if (!query) {
      //   subManagerLogger.warn("onUnsubscribe: unknown subscription key", key);
      //   return;
      // }

      // switch (query.type) {
      //   case "getRecord": {
      //     recordCache.getRecord(query.params); // update the last read time for record
      //     unloadRecord(environment, query.params);
      //     break;
      //   }
      // }
    },
  });

  const pubsubLogger = logger.child({ for: "WebsocketPubsubClient" });

  const pubsub = new WebsocketPubsubClient({
    logger: pubsubLogger,
    config,
    onStart() {
      const keys = subscriptionManager.keys();
      for (const key of keys) pubsub.subscribe(key);
    },
    async onMessage(messages) {
      const pointersWithVersion = uniqWith(
        messages.map((msg) => {
          return { ...getPointer(msg), version: msg.version };
        }),
        isEqual,
      );

      const filteredPointers: RecordPointer[] = [];

      await Promise.all(
        pointersWithVersion.map(async ({ version, ...pointer }) => {
          const existingRecord = await db.getRecord(pointer);

          if (existingRecord && existingRecord.version >= version) {
            return;
          }

          filteredPointers.push(pointer);
        }),
      );

      const response = await api.getRecords({ pointers: filteredPointers });

      if (response.status !== 200) return;

      db.writeRecordMap(response.body.recordMap);
    },
  });

  const transactionQueue = new TransactionQueue({
    environment: { api },
    async onRollback(transaction) {},
  });

  // function unloadRecord(
  //   environment: ClientEnvironment,
  //   pointer: RecordPointer,
  // ) {
  //   const { loaderCache, recordCache } = environment;
  //   // recordCache.purgeRecords([pointer]);
  //   loaderCache.delete("getRecord", pointer);
  // }

  // function garbageCollectRecordCache() {
  //   const time = new Date().valueOf() - MS_IN_MINUTE * 5;

  //   const { pointers: potentialPointers } =
  //     recordCache.getRecordsLastReadBefore(time);

  //   const activeSubscriptions = subscriptionManager.keys();

  //   const pointers = potentialPointers.filter((pointer) => {
  //     const key = getApiQueryKey({ type: "getRecord", params: pointer });
  //     return !activeSubscriptions.includes(key);
  //   });

  //   console.debug("garbageCollect", pointers);

  //   recordCache.purgeRecords(pointers);
  // }

  // // Periodically run garbage collection.
  // setInterval(garbageCollectRecordCache, MS_IN_MINUTE * 5);

  const environment: ClientEnvironment = {
    config,
    db,
    recordLoader,
    subscriptionManager,
    loaderCache,
    api,
    pubsub,
    transactionQueue,
    undoRedo,
    logger,
  };

  return environment;
}
