import uid from "@libs/utils/uid";
import {
  updatableBehaviorSubject,
  IUpdatableBehaviorSubject,
} from "@libs/utils/updatableBehaviorSubject";
import { LinearProgress } from "@mui/material";
import { red } from "@radix-ui/colors";
import { useObservableEagerState } from "observable-hooks";
import { ComponentType, createContext, useEffect } from "react";
import { map, Observable } from "rxjs";
import useConstant from "use-constant";
import { createUseContextHook } from "~/utils/createUseContextHook";

export interface IPendingRequestBarState {
  loadingIds: IUpdatableBehaviorSubject<Set<string>>;
  /**
   * Adds a loading indicator. Returns a function that, when called,
   * removes the added loading indicator.
   */
  markLoading(): () => void;
  isLoading$(): Observable<boolean>;
}

const PendingRequestBarContext = createContext<IPendingRequestBarState | null>(
  null,
);

export const usePendingRequestBarContext = createUseContextHook(
  PendingRequestBarContext,
  "PendingRequestBarContext",
);

export const pendingRequestBarService: IPendingRequestBarState = {
  loadingIds: updatableBehaviorSubject<Set<string>>(new Set()),
  markLoading() {
    const id = uid();
    this.loadingIds.update((value) => value.add(id));

    return () => {
      this.loadingIds.update((value) => {
        value.delete(id);
        return value;
      });
    };
  },
  isLoading$() {
    return this.loadingIds.pipe(map((loadingIds) => loadingIds.size > 0));
  },
};

export const PendingRequestBarProvider: ComponentType<{}> = (props) => {
  const context = useConstant(() => pendingRequestBarService);

  return (
    <PendingRequestBarContext.Provider value={context}>
      <AppPendingRequestBar />
      {props.children}
    </PendingRequestBarContext.Provider>
  );
};

const AppPendingRequestBar: ComponentType<{}> = () => {
  const context = usePendingRequestBarContext();
  const isLoading$ = useConstant(() => context.isLoading$());
  const isVisible = useObservableEagerState(isLoading$);

  if (!isVisible) return null;

  return (
    <div className="fixed top-0 left-0 w-screen h-1 z-[9000]">
      <LinearProgress color="inherit" sx={{ color: red.red11 }} />
    </div>
  );
};

/**
 * Returns any provided children but also displays the app's "loading bar"
 * at the top of the page.
 */
export const PendingRequestBar: ComponentType<{}> = (props) => {
  const context = usePendingRequestBarContext();

  useEffect(() => {
    return context.markLoading();
  }, [context]);

  return <>{props.children || null}</>;
};

/**
 * This wraps a promise and turns on the pending request bar until
 * the promise resolves.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withPendingRequestBar<T>(promise: Promise<T>): Promise<T>;
/**
 * This higher order function wraps an async function and, when called,
 * turns on the pending request bar until the promise resolves.
 */
export function withPendingRequestBar<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => Promise<any>,
>(fn: T): T;
export function withPendingRequestBar(
  fnOrPromise: ((...args: unknown[]) => Promise<unknown>) | Promise<unknown>,
) {
  if (fnOrPromise instanceof Promise) {
    const onComplete = pendingRequestBarService.markLoading();
    return fnOrPromise.finally(onComplete);
  }

  return (...args: unknown[]) => {
    const onComplete = pendingRequestBarService.markLoading();
    return fnOrPromise(...args).finally(onComplete);
  };
}
