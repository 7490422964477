import { Observable } from "rxjs";
import { useEffect } from "react";
import { useObservableState } from "observable-hooks";

export function useObservableDepsState<
  TState,
  TInput extends [any, ...any[]],
  InitState = TState,
>(
  init: (
    input$: Observable<TInput>,
    initialState: TState,
  ) => Observable<TState>,
  options: {
    initialState?: InitState | (() => InitState);
    deps?: TInput;
  },
): TState {
  const [state, setInputs] = useObservableState(
    init,
    options.initialState as any,
  );

  useEffect(() => {
    if (!options.deps) return;
    setInputs(options.deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, options.deps);

  return state;
}
