import { resolvablePromise } from "libs/promise-utils";
import {
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  share,
  throttleTime,
} from "rxjs";
import { startWith } from "@libs/utils/rxjs-operators";

/**
 * Sorts DOM nodes based on their relative position in the DOM.
 */
// See https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition
export function domNodeComparer(a: Node, b: Node) {
  const compare = a.compareDocumentPosition(b);

  if (
    compare & Node.DOCUMENT_POSITION_FOLLOWING ||
    compare & Node.DOCUMENT_POSITION_CONTAINED_BY
  ) {
    // a < b
    return -1;
  }

  if (
    compare & Node.DOCUMENT_POSITION_PRECEDING ||
    compare & Node.DOCUMENT_POSITION_CONTAINS
  ) {
    // a > b
    return 1;
  }

  if (
    compare & Node.DOCUMENT_POSITION_DISCONNECTED ||
    compare & Node.DOCUMENT_POSITION_IMPLEMENTATION_SPECIFIC
  ) {
    throw Error("unsortable");
  } else {
    return 0;
  }
}

export function getMaxScrollTop(el: HTMLElement) {
  if (el === document.body) {
    const html = document.documentElement;

    const maxScrollTop =
      Math.max(
        el.scrollHeight,
        el.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      ) - html.clientHeight;

    return maxScrollTop;
  }

  return el.scrollHeight - el.clientHeight;
}

/** If passed the body element, returns document.documentElement.scrollTop instead */
export function getScrollTop(el: HTMLElement) {
  return el === document.body
    ? document.documentElement.scrollTop
    : el.scrollTop;
}

/** If passed the body element, sets document.documentElement.scrollTop instead */
export function setScrollTop(
  el: HTMLElement,
  value: number | ((oldValue: number) => number),
) {
  const normalizedEl = el === document.body ? document.documentElement : el;

  const newValue =
    typeof value === "function" ? value(normalizedEl.scrollTop) : value;

  normalizedEl.scrollTop = newValue;
}

/** If passed the body element, scrolls document.documentElement instead */
export function scrollElementTo(
  el: HTMLElement,
  options?: ScrollToOptions | undefined,
) {
  if (el === document.body) {
    document.documentElement.scrollTo(options);
  } else {
    el.scrollTo(options);
  }
}

export function observeFocusWithin(...elements: HTMLElement[]) {
  return merge(
    ...elements.map((el) =>
      fromEvent<FocusEvent>(el, "focusin").pipe(map(() => true)),
    ),
    ...elements.map((el) =>
      fromEvent<FocusEvent>(el, "focusout").pipe(
        map((e) =>
          elements.some((el) => el.contains(e.relatedTarget as Node | null)),
        ),
      ),
    ),
  ).pipe(
    startWith(() => elements.some((el) => el.matches(":focus-within"))),
    distinctUntilChanged(),
  );
}

/**
 * Copy a value to the clipboard. If the clipboard API isn't
 * supported, this will throw an error.
 * @returns promise which resolves when the copy is complete
 */
export function writeToClipboard<T extends BlobPart>(args: {
  /**
   * These are the only types supported by Firefox.
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/write
   */
  type: "text/plain" | "text/html" | "image/png";
  value: T;
}) {
  if (!("clipboard" in navigator)) {
    throw new Error("This browser doesn't support the clipboard API");
  }

  const blob = new Blob([args.value], { type: args.type });
  const data = [new ClipboardItem({ [args.type]: blob })];

  return navigator.clipboard.write(data);
}

export function isElementFocused(el: HTMLElement) {
  return document.activeElement === el;
}

export function isFocusWithinElement(el: HTMLElement) {
  return el.contains(document.activeElement);
}

/**
 * Loads a javascript script from the provided source and
 * appends it to the document body in a `script` el.
 *
 * @returns a promise which resolves when the script has
 *   finished loading.
 */
export function loadScript(src: string): Promise<unknown> {
  const scriptEl = document.createElement("script");
  scriptEl.type = "text/javascript";
  scriptEl.src = src;
  scriptEl.async = true;
  scriptEl.defer = true;
  const promise = resolvablePromise();
  scriptEl.onload = promise.resolve;
  scriptEl.onerror = promise.reject;
  document.body.appendChild(scriptEl);
  return promise;
}

function getElementPositionInContainer(args: {
  containerPos: Pick<DOMRect, "top" | "bottom">;
  elementPos: number;
  /**
   * Offsets the container position by the provided amount for
   * the top and/or bottom of the container.
   *
   * Useful if the container has a floating header/footer elements which
   * might hide content.
   */
  containerPosOffset?: {
    top?: number;
    bottom?: number;
  };
}) {
  const { elementPos, containerPos } = args;
  const topOffset = args.containerPosOffset?.top || 0;
  const bottomOffset = args.containerPosOffset?.bottom || 0;

  if (elementPos < containerPos.top + topOffset) {
    return "above" as const;
  } else if (elementPos > containerPos.bottom - bottomOffset) {
    return "below" as const;
  } else {
    return "visible" as const;
  }
}

/**
 * Get the position of an relevant it's container.
 */
export function elementPositionInContainer(args: {
  container: HTMLElement;
  element: HTMLElement;
  /**
   * Offsets the container position by the provided amount for
   * the top and/or bottom of the container.
   *
   * Useful if the container has a floating header/footer elements which
   * might hide content.
   */
  containerPosOffset?: {
    top?: number;
    bottom?: number;
  };
}) {
  const { container, element, containerPosOffset } = args;

  const elementPos = element.getBoundingClientRect();

  if (container === document.body) {
    const containerPos = {
      top: 0,
      // source https://stackoverflow.com/a/8876069/5490505
      bottom: Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0,
      ),
    };

    return {
      top: getElementPositionInContainer({
        containerPos,
        elementPos: elementPos.top,
        containerPosOffset,
      }),
      bottom: getElementPositionInContainer({
        containerPos,
        elementPos: elementPos.bottom,
        containerPosOffset,
      }),
    };
  }

  const containerPos = container.getBoundingClientRect();

  return {
    top: getElementPositionInContainer({
      containerPos,
      elementPos: elementPos.top,
      containerPosOffset,
    }),
    bottom: getElementPositionInContainer({
      containerPos,
      elementPos: elementPos.bottom,
      containerPosOffset,
    }),
  };
}

export function scrollContainerToBottomOfElement(args: {
  container: HTMLElement;
  element: HTMLElement;
  offset?: number;
}) {
  const { container, element, offset = 0 } = args;
  setScrollTop(container, element.offsetTop + element.offsetHeight - offset);
}

export function scrollContainerToTopOfElement(args: {
  container: HTMLElement;
  element: HTMLElement;
  offset?: number;
}) {
  const { container, element, offset = 0 } = args;
  setScrollTop(container, element.offsetTop + offset);
}

export const WINDOW_RESIZE_EVENT$ = fromEvent<UIEvent>(window, "resize").pipe(
  throttleTime(250, undefined, { leading: false, trailing: true }),
  share({ resetOnRefCountZero: true }),
);

export const WINDOW_SIZE$ = WINDOW_RESIZE_EVENT$.pipe(
  startWith(() => null),
  map(() => ({
    width: document.body.offsetWidth,
    height: document.body.offsetHeight,
  })),
);
