import { ApiInput } from "libs/ApiTypes";
import { ClientEnvironment } from "~/environment/ClientEnvironment";

export type CreateUserParams = ApiInput<"createUser">;

/**
 * Note that this is not an "optimistic" operation. We wait for it
 * to be confirmed by the server. Also, we don't support creating
 * users while offline.
 */
export async function createUser(
  environment: ClientEnvironment,
  params: CreateUserParams,
) {
  const { status, body } = await environment.api.createUser(params);

  if (status !== 200) return;
  if (!body.recordMap) return;

  console.log("createUser response", body.recordMap);

  environment.db.writeRecordMap(body.recordMap);
}
