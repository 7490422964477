import { ComponentType } from "react";
import { cx } from "@emotion/css";
import { List } from "~/components/list";
import useLocalStorageState from "use-local-storage-state";
import { oneLine } from "common-tags";
import { ShortcutHintContents } from "~/environment/hint-service/hints";
import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useConsoleLog } from "~/hooks/useConsoleLog";
import { useUsersOrganizationIds } from "~/hooks/useUserOrganizationsByUserId";
import { RecordValue } from "libs/schema";
import { useTagsInFolderThatUserIsSubscribedTo } from "~/hooks/useTagsInFolderThatUserIsSubscribedTo";
import { useGroup } from "~/hooks/useGroup";
import { useNavigate } from "react-router-dom";

export function convertShortcutToTrigger(shortcut: number) {
  return `g ${shortcut.toString().split("").join(" ")}`;
}

export const SidebarGroups: ComponentType<{}> = () => {
  const { currentUser } = useAuthGuardContext();
  const { organizationIds } = useUsersOrganizationIds(currentUser.id);

  useConsoleLog("debug", "SidebarOrganizations", organizationIds);

  return (
    <>
      {organizationIds.map((organizationId, index) => (
        <GroupEntry
          key={organizationId}
          groupId={organizationId}
          relativeOrder={index}
          level={1}
        />
      ))}
    </>
  );
};

const GroupEntry: ComponentType<{
  groupId: string;
  relativeOrder: number;
  level: number;
}> = (props) => {
  const [group] = useGroup(props.groupId);
  useConsoleLog("debug", "GroupEntry", props.groupId, group);
  const [isExpanded, setIsExpanded] = useIsGroupExpanded(props.groupId);
  const navigate = useNavigate();

  if (!group) return null;

  return (
    <li className={cx("flex flex-col")}>
      <List.Entry<RecordValue<"tag">>
        id={group.id}
        data={group}
        relativeOrder={props.relativeOrder}
        onEntryAction={() => {
          navigate(`/groups/${group.id}`);
        }}
      >
        <span
          className={cx(
            `flex items-center pl-3 pr-4 py-2`,
            `focus:bg-slate-4 outline-none`,
            `hover:cursor-pointer`,
          )}
          style={{ paddingLeft: `${props.level * 0.9}rem` }}
        >
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsExpanded((v) => !v);
            }}
          >
            <TriangleIcon className={cx({ "rotate-90": isExpanded })} />
          </button>

          <strong className="text-left font-medium">{group.name}</strong>
        </span>
      </List.Entry>

      {isExpanded && (
        <GroupsInFolderThatUserIsSubscribedTo
          folderId={props.groupId}
          level={props.level + 1}
        />
      )}
    </li>
  );
};

const GroupsInFolderThatUserIsSubscribedTo: ComponentType<{
  folderId: string;
  level: number;
}> = (props) => {
  const { currentUser } = useAuthGuardContext();

  const { tagIds: groupIds } = useTagsInFolderThatUserIsSubscribedTo({
    userId: currentUser.id,
    folderId: props.folderId,
  });

  useConsoleLog("debug", "SidebarChannelGroups", props.folderId, groupIds);

  if (groupIds.length === 0) return null;

  return (
    <ul className="list-none mb-10">
      {groupIds.map((groupId, index) => (
        <GroupEntry
          key={groupId}
          groupId={groupId}
          relativeOrder={index}
          level={props.level}
        />
      ))}
    </ul>
  );
};

function useIsGroupExpanded(tagId: string) {
  const { currentUser } = useAuthGuardContext();

  return useLocalStorageState(
    `${currentUser.id}.sidebar.groupExpanded.${tagId}`,
    { defaultValue: true },
  );
}

const TriangleIcon: ComponentType<{ className?: string }> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx("scale-150 mr-2", props.className)}
    >
      <path d="M6 11L6 4L10.5 7.5L6 11Z" fill="currentColor"></path>
    </svg>
  );
};

export const sidebarEntryCSS = oneLine`
  flex py-2 pl-9 pr-4 leading-6 focus:bg-slate-4 
  outline-none focus:border-black border-l-2 
  border-white group relative
`;

export const ShortcutHint: ComponentType<{
  hint: string;
}> = ({ hint }) => {
  return (
    <div
      className={cx(
        "items-center hidden group-hover:flex",
        "absolute top-0 right-0 h-full text-white",
        "mr-4",
      )}
    >
      <ShortcutHintContents
        hint={hint}
        keyClassName="bg-slate-10"
        adverbClassName="text-slate-10 font-medium"
      />
    </div>
  );
};
