import type { EmailAddress } from "@libs/utils/email-rfc";
import { UnreachableCaseError } from "@libs/utils/errors";
import addressparser from "nodemailer/lib/addressparser";

/**
 * If passed a group of addresses, will only return the `label`
 * value.
 */
export function parseEmailAddress(email: EmailAddress): {
  label: string | null;
  emailAddress: string | null;
  /** Formatted as either `"${label}" <${emailAddress}>` or label or emailAddress */
  rawValue: string | null;
};
export function parseEmailAddress(email?: null): null;
export function parseEmailAddress(email?: EmailAddress | null): null | {
  label: string | null;
  emailAddress: string | null;
  /** Formatted as either `"${label}" <${emailAddress}>` or label or emailAddress */
  rawValue: string | null;
};
export function parseEmailAddress(email?: EmailAddress | null) {
  if (!email) return null;

  const label = email?.label || email?.address || null;
  const emailAddress = email?.address || null;
  const rawValue =
    email?.label && email?.address
      ? `"${email.label}" <${email.address}>`
      : label;

  return {
    label,
    emailAddress,
    rawValue,
  };
}

// interface Address {
//   name: string;
//   address: string;
// }

// interface Group {
//   name: string;
//   group: AddressOrGroup[];
// }

// export interface Mailbox {
//   /** Optional label for the email address */
//   label?: string;
//   /** The email address. */
//   address: string;
//   /** only exists for typescript ergonomics */
//   addresses?: undefined;
// }

// export interface MailboxGroup {
//   /** Required label of the mailbox group */
//   label: string;
//   /** only exists for typescript ergonomics */
//   address?: undefined;
//   /** An array of grouped addresses. May be empty. */
//   addresses: Mailbox[];
// }

// type AddressOrGroup = Address | Group;

export function parseStringToEmailAddress(input: string): EmailAddress | null {
  const result = addressparser(input);
  const email = result[0];

  if (!email) return null;
  if (result.length > 1) {
    throw new Error("Expected only one email address");
  }

  if ("address" in email) {
    return email.name === email.address
      ? { address: email.address }
      : {
          label: email.name,
          address: email.address,
        };
  } else if ("group" in email) {
    return {
      label: email.name,
      addresses: email.group.map((_address) => {
        const address = _address as addressparser.Address;
        return address.name === address.address
          ? { address: address.address }
          : {
              label: address.name,
              address: address.address,
            };
      }),
    };
  } else {
    throw new UnreachableCaseError(email);
  }
}

/**
 * Starts with one or more characters, then an "@" symbol,
 * then one or more characters, then a period, then one or
 * more characters.
 *
 * Note that, technically, the only requirement for an email is
 * a character, then "@", then a character. This regexp is
 * intentionally more strict.
 */
export const EMAIL_ADDRESS_REGEXP = /^.+@.+\..+/;
// const emailWithLabelAndQuotesRegex = /^"(.*)" <(.+@.+\..+)>/;
// const emailWithLabelRegex = /^(.*) <(.+@.+\..+)>/;
