export * from "@libs/utils/errors";

/** Should never contain sensitive information in the message. */
export class ValidationError extends Error {
  static statusCode = 400;
  statusCode = 400;
}

/** Should never contain sensitive information in the message. */
export class TransactionConflictError extends Error {
  static statusCode = 409;
  statusCode = 409;
}

/** Should never contain sensitive information in the message. */
export class BrokenError extends Error {
  static statusCode = 424;
  statusCode = 424;
}

/** Should never contain sensitive information in the message. */
export class PermissionError extends Error {
  static statusCode = 403;
  statusCode = 403;
}
