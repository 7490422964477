// This module handles one-time configuration for library
// code as well as other one-time functions which need to
// be called before the app is run.

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

import { enableMapSet } from "immer";
// Enables using immer with Map and Set objects
enableMapSet();
