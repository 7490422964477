import { css } from "@emotion/css";
import { startWith } from "@libs/utils/rxjs-operators";
import { useEffect, RefObject, useCallback } from "react";
import { fromEvent, throttleTime } from "rxjs";
import { getScrollTop } from "~/utils/dom-helpers";

/**
 * Subscribes to scroll events on the provided scrollbox element
 * and adds a dropshadow to the target ref when the scroll position
 * is not at the top of the scrollbox.
 *
 * @returns a function which can be used to programmatically
 *          reevaluate if a drop shadow should be applied to the target
 */
export function useTopScrollShadow(args: {
  scrollboxRef: RefObject<HTMLElement>;
  targetRef: RefObject<HTMLElement>;
  deps?: unknown[];
}) {
  const { scrollboxRef, targetRef, deps = [] } = args;

  const reevaluateTarget = useCallback(() => {
    const scrollboxEl = scrollboxRef.current;
    const targetEl = targetRef.current;

    if (!scrollboxEl || !targetEl) return;

    if (getScrollTop(scrollboxEl) < 5) {
      targetEl.classList.remove("shadow-md");
    } else {
      targetEl.classList.add("shadow-md");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrollboxRef.current || !targetRef.current) return;

    const scrollboxEl =
      scrollboxRef.current === document.body ? window : scrollboxRef.current;

    // Apply a small dropshadow to the target element if the user
    // has scrolled down a bit.
    const sub = fromEvent(scrollboxEl, "scroll")
      .pipe(
        startWith(() => null),
        throttleTime(100, undefined, { leading: false, trailing: true }),
      )
      .subscribe(reevaluateTarget);

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return reevaluateTarget;
}

/**
 * Subscribes to scroll events on the provided scrollbox element
 * and adds a dropshadow to the target ref when the scroll position
 * is not at the bottom of the scrollbox.
 *
 * @returns a function which can be used to programmatically
 *          reevaluate if a drop shadow should be applied to the target
 */
export function useBottomScrollShadow(args: {
  scrollboxRef: RefObject<HTMLElement>;
  targetRef: RefObject<HTMLElement>;
  deps?: unknown[];
}) {
  const { scrollboxRef, targetRef, deps = [] } = args;

  const reevaluateTarget = useCallback(() => {
    const scrollboxEl = scrollboxRef.current;
    const targetEl = targetRef.current;

    if (!scrollboxEl || !targetEl) return;

    const targetHeight =
      scrollboxEl.scrollHeight - scrollboxEl.offsetHeight - 5;

    if (scrollboxEl.scrollTop > targetHeight) {
      targetEl.classList.remove("bottom-shadow", bottomShadowCSS);
    } else {
      targetEl.classList.add("bottom-shadow", bottomShadowCSS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!scrollboxRef.current || !targetRef.current) return;

    const scrollboxEl = scrollboxRef.current;

    // Apply a small dropshadow to the target element if the user
    // has scrolled down a bit.
    const sub = fromEvent(scrollboxEl, "scroll")
      .pipe(
        startWith(() => null),
        throttleTime(100, undefined, { leading: false, trailing: true }),
      )
      .subscribe(reevaluateTarget);

    return () => sub.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return reevaluateTarget;
}

export const bottomShadowCSS = css`
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px -4px 6px -1px rgba(0, 0, 0, 0.1), 0px -2px 4px -2px rgba(0, 0, 0, 0.1);
`;
