import { useAuthGuardContext } from "~/route-guards/withAuthGuard";
import { useRecord } from "./useRecord";

export function useCurrentUserTagSubscription(tagId?: string | null) {
  const { currentUser } = useAuthGuardContext();

  return useRecord("tag_subscription", {
    tag_id: tagId || undefined,
    user_id: currentUser.id,
  });
}
