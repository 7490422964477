import { startWith } from "@libs/utils/rxjs-operators";
import { RecordValue } from "libs/schema";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";

export type ObserveGroupFoldersResult = {
  /**
   * Returns an array of folderId arrays. Each folderId array represents a
   * possible path from the root folder to the current folder.
   */
  folderPaths: string[][];
  isLoading: boolean;
};

export function observeTagFolderAncestors(props: {
  tagId: string;
  maxDepth?: number;
  /**
   * If true, then this observable will emit an updated value as new folders
   * load. Otherwise, this observable will only emit once all folders have loaded.
   */
  emitIncrementally?: boolean;
}) {
  const { tagId, maxDepth = Infinity, emitIncrementally = false } = props;

  return observeTagFolderAncestorsInner({
    tagId,
    currentDepth: 0,
    maxDepth,
    emitIncrementally,
  });
}

function observeTagFolderAncestorsInner(props: {
  tagId: string;
  currentDepth: number;
  maxDepth: number;
  emitIncrementally?: boolean;
}): Observable<ObserveGroupFoldersResult> {
  const { tagId, currentDepth, maxDepth, emitIncrementally } = props;
  const { recordLoader } = getEnvironment();

  return recordLoader
    .observeGetTagFolderMembers({
      tag_id: tagId,
    })
    .pipe(
      switchMap(({ records: parentFolders, isLoading }) => {
        const nextDepth = currentDepth + 1;
        const isComplete = parentFolders.length === 0 || nextDepth === maxDepth;

        if (isComplete) {
          return of({
            folderPaths: parentFolders.map(({ folder_id }) => [folder_id]),
            isLoading,
          });
        }

        const observeNext = (folder: RecordValue<"tag_folder_member">) =>
          observeTagFolderAncestorsInner({
            tagId: folder.folder_id,
            currentDepth: nextDepth,
            maxDepth,
          });

        const observables = !emitIncrementally
          ? parentFolders.map(observeNext)
          : parentFolders.map((record) =>
              observeNext(record).pipe(
                startWith(() => ({ folderPaths: [], isLoading: true })),
              ),
            );

        return combineLatest(observables).pipe(
          map((ancestors) => {
            let isSomeAncestorLoading = false;

            return {
              folderPaths: parentFolders.flatMap(
                ({ folder_id: parentFolderId }) => {
                  return ancestors.flatMap((a) => {
                    if (a.isLoading) isSomeAncestorLoading = true;

                    return a.folderPaths.map((folderIds) => {
                      return [parentFolderId, ...folderIds];
                    });
                  });
                },
              ),
              isLoading: isSomeAncestorLoading || isLoading,
            };
          }),
        );
      }),
    );
}
