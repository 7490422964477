import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableOptions,
  connectFunctionsEmulator,
} from "firebase/functions";
import { ICallableFnsTypeMap } from "@libs/firebase-functions-types";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  connectStorageEmulator,
  getStorage,
  ref,
  StorageReference,
} from "firebase/storage";
import { config } from "rxjs";

export const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app);

export const auth = getAuth(app);

export const storage = getStorage(app);

export const isAnEmulatorActive = !!(
  import.meta.env.VITE_FIREBASE_EMULATORS === "true" &&
  import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME &&
  import.meta.env.VITE_FIREBASE_EMULATOR_FUNCTION_PORT &&
  import.meta.env.VITE_FIREBASE_EMULATOR_STORAGE_PORT
);

if (isAnEmulatorActive) {
  console.warn(
    "ENABLING FIREBASE EMULATORS",
    !import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL
      ? "EXCEPT FOR AUTH EMULATOR"
      : "",
  );

  if (import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL) {
    connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_EMULATOR_AUTH_URL, {
      disableWarnings: true,
    });
  } else {
    console.warn(
      "AuthEmulator NOT ACTIVE!!! VITE_FIREBASE_EMULATOR_AUTH_URL was not provided",
    );
  }

  connectFunctionsEmulator(
    functions,
    import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME,
    Number(import.meta.env.VITE_FIREBASE_EMULATOR_FUNCTION_PORT),
  );

  connectStorageEmulator(
    storage,
    import.meta.env.VITE_FIREBASE_EMULATOR_HOSTNAME,
    Number(import.meta.env.VITE_FIREBASE_EMULATOR_STORAGE_PORT),
  );

  // Global error handler for unhandled Promise rejections
  globalThis.onunhandledrejection = (event) => {
    console.error(event);
  };

  // Similar to promise "unhandledrejection" events,
  // onUnhandledError will be called if an observable has an
  // unhandled error.
  config.onUnhandledError = console.error;
}

// Global error handler for unhandled Promise rejections
globalThis.onunhandledrejection = (event) => {
  console.error(event);
};

// Similar to promise "unhandledrejection" events,
// onUnhandledError will be called if an observable has an
// unhandled error.
config.onUnhandledError = (error) => {
  console.error(error);
};

/**
 * Returns a reference to the callable HTTPS trigger with the given name
 * that has both argument and return value type safety.
 */
export const getTypedCallableFn = <T extends keyof ICallableFnsTypeMap>(
  name: T,
  options?: HttpsCallableOptions | undefined,
) => {
  return httpsCallable<ICallableFnsTypeMap[T][0], ICallableFnsTypeMap[T][1]>(
    functions,
    name,
    options,
  );
};

export function storageRef(
  a: "images",
  userId: string,
  imageId: string,
): StorageReference;
export function storageRef(...args: string[]): StorageReference {
  return ref(storage, args.join("/"));
}
