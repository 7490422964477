/* eslint-disable no-useless-escape */
import { createRegExp, RegExpHelper } from "@libs/utils/regexp-helper";

const dateSeperator = `[.\/-]`;

export function normalizeMeridiemFromInput(input: string | undefined) {
  if (!input || input[0] === "a") return "am";
  return "pm";
}

export function normalizeTimeFromInput(time?: string, meridiem?: string) {
  if (time) {
    if (meridiem) {
      return `${time} ${normalizeMeridiemFromInput(meridiem)}`;
    }

    time = time.replace(" ", ":");

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const hour = Number.parseInt(time.split(":")[0]!.slice(0, 2), 10);

    // In the case of military time we don't show the meridiem
    if (hour > 12) {
      if (time.includes(":")) return time;
      if (time.length === 4) {
        return `${time.slice(0, 2)}:${time.slice(2)}`;
      }

      const min =
        time.slice(1).length === 1 ? time.slice(1) + "0" : time.slice(1);

      return `0${time[0]}:${min}`;
    }

    const isHourBetween6And11 = 11 >= hour && 6 <= hour;
    return isHourBetween6And11 ? `${time} am` : `${time} pm`;
  } else {
    return meridiem?.[0] === "p" ? `5 pm` : `8 am`;
  }
}

export function normalizeDateFromInput(
  month: string,
  day: string,
  year = String(new Date().getFullYear()).slice(2),
) {
  return `${parseInt(month, 10)}/${parseInt(day, 10)}/${parseInt(year, 10)}`;
}

/**
 * Capture groups:
 * 1. Template variable
 *
 * Examples:
 * - {{9 am}}
 * - {{}}
 * - {{ }}
 * - {{ thisdafcoij}}
 */
export const templateVariable = createRegExp({
  caseInsensitive: true,
  global: true,
}).capture((b) =>
  b.then("{{").zeroOrMore(RegExpHelper.ANYTHING_EXCEPT_NEWLINE).then("}}"),
);

/**
 * Capture groups:
 * 1. Time. E.g. "9", "1700", "5:30 pm"
 * 2. Meridiem?
 *
 * Examples:
 * - 9:00 am
 * - 06:00 am
 * - 12:51 p
 * - 17:00
 * - 16:00 pm
 * - 1 37
 */
export const timeWithColonOrSpaceAndMaybeMeridiem = createRegExp({
  caseInsensitive: true,
  global: true,
})
  .capture((b) =>
    b
      .optional("[012]")
      .then(RegExpHelper.NUMBER)
      .anyOf(":", RegExpHelper.SPACE)
      .then("[0-5]")
      .then(RegExpHelper.NUMBER),
  )
  .then()
  .optional(RegExpHelper.SPACE)
  .then()
  .optional((b) => b.capture((c) => c.anyOf(`am?`, `pm?`)))
  .then(RegExpHelper.WORD_BOUNDARY);

/**
 * Capture groups:
 * 1. Time. E.g. "9", "1700", "5:30 pm"
 * 2. Meridiem
 *
 * Examples:
 * - 9 am
 * - 10p
 */
export const timeWithMeridiem = createRegExp({
  caseInsensitive: true,
  global: true,
})
  .capture((b) => b.optional("1").then(RegExpHelper.NUMBER))
  .then()
  .optional(RegExpHelper.SPACE)
  .then()
  .capture((c) => c.anyOf(`am?`, `pm?`))
  .then(RegExpHelper.WORD_BOUNDARY);

/**
 * Capture groups
 * 1. month - mm
 * 2. day - dd
 * 3. year? - yyyy
 *
 * Examples
 * - mm/dd/yyyy
 * - m/d/yy
 * - mm/dd
 * - m/d
 * - m-d-yy
 * - m.d.yyyy
 */

export const date = createRegExp({ caseInsensitive: true, global: true })
  // mm
  .capture((a) => a.optional("[01]").then(RegExpHelper.NUMBER))
  .then(dateSeperator)
  // dd
  .capture((c) => c.optional("[0123]").then(RegExpHelper.NUMBER))
  // yyyy
  .optional((a) =>
    a
      .then(dateSeperator)
      .capture((b) =>
        b.anyOf(
          (c1) =>
            c1
              .then("[12]")
              .then(RegExpHelper.NUMBER)
              .then(RegExpHelper.NUMBER)
              .then(RegExpHelper.NUMBER),
          (c2) => c2.then(RegExpHelper.NUMBER).then(RegExpHelper.NUMBER),
        ),
      )
      .then(RegExpHelper.WORD_BOUNDARY)
      .optional(RegExpHelper.SPACE),
  )
  .then(RegExpHelper.WORD_BOUNDARY)
  .optional(RegExpHelper.SPACE);

/**
 * Capture groups:
 * 1. Number
 *
 * Examples:
 * - 1700
 * - 060
 * - 1
 */
export const number = createRegExp({
  caseInsensitive: true,
  global: true,
}).capture((a) => a.oneOrMore(RegExpHelper.NUMBER));
