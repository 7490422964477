import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { observeQuery } from "./observeQuery";

export type ObserveGroupsUserIsAMemberOfResult = {
  groupIds: string[];
  isLoading: boolean;
};

export function observeGroupsUserIsAMemberOf(props: {
  userId: string;
  limit?: number;
}): Observable<ObserveGroupsUserIsAMemberOfResult> {
  const { userId } = props;

  const groupMemberships$ = observeQuery("observeGetOrganizationUserMembers", {
    user_id: userId,
  }).pipe(
    switchMap(({ records: organizationUserMembers, isLoading }) => {
      if (organizationUserMembers.length === 0) {
        return of({ groupIds: [], isLoading });
      }

      return combineLatest(
        organizationUserMembers.map(({ organization_id }) =>
          observeGroupsGroupIsAMemberOf({
            groupId: organization_id,
          }),
        ),
      ).pipe(
        map((descendentGroupIds) => {
          const combinedGroupIds = descendentGroupIds.reduce(
            (store, { groupIds }) => [...store, ...groupIds],
            organizationUserMembers.map((m) => m.organization_id),
          );

          return {
            groupIds: combinedGroupIds,
            isLoading:
              isLoading ||
              descendentGroupIds.some(({ isLoading }) => isLoading),
          };
        }),
      );
    }),
  );

  return combineLatest([
    observeQuery("observeGetTagUserMembers", {
      user_id: userId,
    }),
    groupMemberships$,
  ]).pipe(
    map(([tagUserMemberships, groupMemberships]) => {
      const combinedGroupIds = [
        ...tagUserMemberships.records.map((r) => r.tag_id),
        ...groupMemberships.groupIds,
      ];

      return {
        groupIds: combinedGroupIds,
        isLoading: tagUserMemberships.isLoading || groupMemberships.isLoading,
      };
    }),
  );
}

function observeGroupsGroupIsAMemberOf(args: {
  groupId: string;
}): Observable<{ groupIds: string[]; isLoading: boolean }> {
  const { recordLoader } = getEnvironment();

  return recordLoader.observeGetTagGroupMembers({ tag_id: args.groupId }).pipe(
    switchMap(({ records: tagGroupMembers, isLoading }) => {
      if (tagGroupMembers.length === 0) return of({ groupIds: [], isLoading });

      return combineLatest(
        tagGroupMembers.map(({ group_id }) =>
          observeGroupsGroupIsAMemberOf({ groupId: group_id }),
        ),
      ).pipe(
        map((descendentGroupIds) => {
          const combinedGroupIds = descendentGroupIds.reduce(
            (store, { groupIds }) => [...store, ...groupIds],
            tagGroupMembers.map((m) => m.group_id),
          );

          return {
            groupIds: combinedGroupIds,
            isLoading:
              isLoading ||
              descendentGroupIds.some(({ isLoading }) => isLoading),
          };
        }),
      );
    }),
  );
}
