import { combineLatest, map, Observable, of, switchMap } from "rxjs";
import { ClientEnvironment } from "~/environment/ClientEnvironment";
import { ENVIRONMENT$ } from "~/environment/ClientEnvironmentContext";
import { CURRENT_USER_ID$ } from "~/environment/user.service";

export function observeIsTagPrivate(tagId: string) {
  return combineLatest([ENVIRONMENT$, CURRENT_USER_ID$]).pipe(
    switchMap(([env, userId]) => {
      if (!env || !userId) {
        return of({ isTagPrivate: false, isLoading: false });
      }

      return env.recordLoader
        .observeGetOrganizationUserMembers({ user_id: userId })
        .pipe(
          switchMap(({ records, isLoading }) => {
            return observeIsTagSharedWithOrganization({
              environment: env,
              isLoading,
              organizationIds: records.map((m) => m.organization_id),
              tagId,
            });
          }),
        );
    }),
  );
}

// One problem with this function is that it will continue to process nested
// groups even if we've already found that one of the ancestors is shared.
// Ideally, this function would short-circuit once it finds a shared ancestor.
function observeIsTagSharedWithOrganization(args: {
  environment: ClientEnvironment;
  tagId: string;
  organizationIds: string[];
  isLoading: boolean;
}): Observable<{ isTagPrivate: boolean; isLoading: boolean }> {
  const {
    environment,
    tagId,
    organizationIds,
    isLoading: isRootLoading,
  } = args;

  if (organizationIds.length === 0) {
    return of({ isTagPrivate: true, isLoading: isRootLoading });
  }

  return environment.recordLoader
    .observeGetTagGroupMembers({
      tag_id: tagId,
    })
    .pipe(
      switchMap(
        ({ records: groupMembers, isLoading: groupMembersIsLoading }) => {
          const isShared = groupMembers.some((m) =>
            organizationIds.includes(m.group_id),
          );

          const isLoading = isRootLoading || groupMembersIsLoading;

          if (isShared) {
            return of({ isTagPrivate: false, isLoading });
          }

          if (groupMembers.length === 0) {
            return of({ isTagPrivate: true, isLoading });
          }

          return combineLatest(
            groupMembers.map(({ group_id }) => {
              return observeIsTagSharedWithOrganization({
                environment,
                isLoading,
                organizationIds,
                tagId: group_id,
              });
            }),
          ).pipe(
            map((results) => {
              return results.reduce((store, { isTagPrivate, isLoading }) => {
                return {
                  isTagPrivate: store.isTagPrivate && isTagPrivate,
                  isLoading: store.isLoading || isLoading,
                };
              });
            }),
          );
        },
      ),
    );
}
