import {
  cloneDeep,
  get,
  isEqual,
  set,
  update,
  isPlainObject,
} from "lodash-comms";
import {
  RecordMap,
  RecordPointer,
  RecordTable,
  canDeleteTables,
  getMapRecord,
  setMapRecord,
  RecordValue,
  GetOptionalProps,
} from "libs/schema";
import { SetOptional } from "type-fest";

export class ValidationError extends Error {}

export type SetOperation = {
  type: "set";
  table: string;
  id: string;
  key: string[];
  value: any;
};

export type UpdateOperation = {
  type: "update";
  table: string;
  id: string;
  key: string[];
  value: any;
};

export type UpsertOperation = {
  type: "upsert";
  onCreate: SetOperation;
  onUpdate: UpdateOperation;
};

export type ListInsertOperation = {
  type: "listInsert";
  table: string;
  id: string;
  key: string[];
  value: any;
  /** Defaults to append */
  where?: "prepend" | "append" | { before: any } | { after: any };
};

export type ListRemoveOperation = {
  type: "listRemove";
  table: string;
  id: string;
  key: string[];
  value: any;
};

export type Operation =
  | SetOperation
  | UpdateOperation
  | ListInsertOperation
  | ListRemoveOperation;

export type CreateRecord<T extends RecordTable> = SetOptional<
  Omit<RecordValue<T>, "created_at" | "updated_at">,
  // @ts-expect-error - typescript is unhappy because we don't know the keys of T.
  GetOptionalProps<T>
>;

export type Transaction = {
  txId: string;
  authorId: string;
  operations: Operation[];
  onUndo?: () => Promise<void> | void;
};

export type AutoField = "version" | "last_version";

function setOp<T extends RecordTable>(
  table: T,
  value: CreateRecord<T>,
): SetOperation;
function setOp<
  T extends RecordTable,
  A extends keyof Omit<RecordValue<T>, "id" | AutoField>,
>(pointer: RecordPointer<T>, keys: [A], value: RecordValue<T>[A]): SetOperation;
function setOp<
  T extends RecordTable,
  A extends keyof Omit<RecordValue<T>, "id" | AutoField>,
  B extends keyof RecordValue<T>[A],
>(
  pointer: RecordPointer<T>,
  keys: [A, B],
  value: RecordValue<T>[A][B],
): SetOperation;
function setOp<T extends RecordTable>(
  a: T | RecordPointer<T>,
  b: string[] | CreateRecord<T>,
  c?: unknown,
): SetOperation {
  if (typeof a === "string") {
    const pointer = {
      table: a,
      id: (b as unknown as { id: string }).id,
    } as RecordPointer;

    return {
      type: "set",
      ...pointer,
      key: [],
      value: b,
    } as SetOperation;
  } else {
    const pointer = a as RecordPointer;
    const keys = b;
    const value = c;

    return {
      type: "set",
      ...pointer,
      key: keys,
      value: value,
    } as SetOperation;
  }
}

function updateOp<
  T extends RecordTable,
  K extends keyof Omit<RecordValue<T>, "id" | AutoField>,
>(pointer: RecordPointer<T>, value: Partial<RecordValue<T>>): UpdateOperation;
function updateOp<
  T extends RecordTable,
  K extends keyof Omit<RecordValue<T>, "id" | AutoField>,
>(
  pointer: RecordPointer<T>,
  key: K,
  value: Partial<RecordValue<T>[K]>,
): UpdateOperation;
function updateOp<
  T extends RecordTable,
  K extends keyof Omit<RecordValue<T>, "id" | AutoField>,
>(
  pointer: RecordPointer<T>,
  a: K | Partial<RecordValue<T>>,
  b?: Partial<RecordValue<T>[K]>,
) {
  if (typeof a === "string") {
    const key = a;
    const value = b;

    return {
      type: "update",
      ...pointer,
      key: [key],
      value: value,
    } as UpdateOperation;
  }

  return {
    type: "update",
    ...pointer,
    key: [],
    value: b,
  } as UpdateOperation;
}

// function upsertOp<T extends RecordTable>(args: {
//   pointer: RecordPointer<T>;
//   onCreate: Omit<CreateRecord<T>, "id">;
//   onUpdate: Omit<Partial<RecordValue<T>>, "id">;
// }): UpsertOperation {}

// Helper functions to enforce better types.
export const op = {
  transaction(props: Pick<Transaction, "authorId" | "operations" | "onUndo">) {
    return {
      txId: crypto.randomUUID(),
      ...props,
    } as Transaction;
  },
  set: setOp,
  update: updateOp,
  // upsert: upsertOp,
  // insert: insertOp,
  // remove: removeOp,
};

export function applyOperation(
  recordMap: RecordMap,
  operation: Operation,
  updateUpdatedAt: boolean,
) {
  if (operation.type === "set") {
    return applySetOperation(recordMap, operation, updateUpdatedAt);
  }

  if (operation.type === "update") {
    return applyUpdateOperation(recordMap, operation, updateUpdatedAt);
  }

  if (operation.type === "listInsert") {
    return applyListInsertOperation(recordMap, operation, updateUpdatedAt);
  }

  if (operation.type === "listRemove") {
    return applyListRemoveOperation(recordMap, operation, updateUpdatedAt);
  }

  throw new ValidationError("Unknown operation type.");
}

export function invertOperation(recordMap: RecordMap, operation: Operation) {
  if (operation.type === "set") {
    return invertSetOperation(recordMap, operation);
  }

  if (operation.type === "update") {
    return invertUpdateOperation(recordMap, operation);
  }

  if (operation.type === "listInsert") {
    return invertListInsertOperation(recordMap, operation);
  }

  if (operation.type === "listRemove") {
    return invertListRemoveOperation(recordMap, operation);
  }

  throw new ValidationError("Unknown operation type.");
}

function applySetOperation(
  recordMap: RecordMap,
  operation: SetOperation,
  updateUpdatedAt: boolean,
) {
  const { table, id, key } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);

  if (!record) {
    if (key.length !== 0) throw new ValidationError("Record does not exist.");

    const record = { ...operation.value, version: 1 };

    if (updateUpdatedAt) {
      record.updated_at = new Date().toISOString();
    }

    setMapRecord(recordMap, pointer, record);
    return;
  }

  const newRecord = cloneDeep(record);
  if (updateUpdatedAt) {
    newRecord.updated_at = new Date().toISOString();
  }
  set(newRecord, key, operation.value);
  newRecord.version += 1;
  setMapRecord(recordMap, pointer, newRecord);
}

function applyUpdateOperation(
  recordMap: RecordMap,
  operation: UpdateOperation,
  updateUpdatedAt: boolean,
) {
  const { table, id, key } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);

  if (!record) {
    throw new ValidationError("Record does not exist.");
  }

  let newRecord = cloneDeep(record);

  console.log("applyUpdateOperation 1", recordMap, newRecord);

  if (updateUpdatedAt) {
    newRecord.updated_at = new Date().toISOString();
  }

  if (key.length > 0) {
    const oldValue = get(newRecord, key);

    const newValue = isPlainObject(oldValue)
      ? { ...oldValue, ...operation.value }
      : operation.value;

    set(newRecord, key, newValue);
  } else {
    newRecord = { ...newRecord, ...operation.value };
  }

  console.log("applyUpdateOperation 2", newRecord);

  newRecord.version += 1;
  setMapRecord(recordMap, pointer, newRecord);
}

function invertSetOperation(
  recordMap: RecordMap,
  operation: SetOperation | UpdateOperation,
) {
  const { table, id, key } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);

  if (!record) {
    if (key.length !== 0) throw new ValidationError("Record does not exist.");

    // Create an object -> soft delete the object.
    if (!(canDeleteTables as { [key: string]: boolean })[operation.table]) {
      return;
    }

    const op: SetOperation = {
      type: "set",
      table,
      id,
      key: ["is_deleted"],
      value: true,
    };

    return op;
  }

  const value = get(record, key);

  const op: SetOperation = {
    type: "set",
    table,
    id,
    key,
    value,
  };

  return op;
}

function invertUpdateOperation(
  recordMap: RecordMap,
  operation: UpdateOperation,
) {
  return invertSetOperation(recordMap, operation);
}

function applyListInsertOperation(
  recordMap: RecordMap,
  operation: ListInsertOperation,
  updateUpdatedAt: boolean,
) {
  const { key, table, id, value, where } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);

  if (!record) throw new ValidationError("Record does not exist.");

  const newRecord = cloneDeep(record);

  if (updateUpdatedAt) {
    newRecord.updated_at = new Date().toISOString();
  }

  update(newRecord, key, (list) => {
    if (list === null || list === undefined) {
      return [value];
    }

    if (Array.isArray(list)) {
      // Disallow duplicate items in a list so that this function is idempotent!
      list = list.filter((item) => item !== value);

      if (where === undefined || where === "append") {
        return [...list, value];
      }

      if (where === "prepend") {
        return [value, ...list];
      }

      if ("before" in where) {
        const i = deepEqualIndexOf(list, where.before);
        if (i === -1) return [value, ...list];
        return [...list.slice(0, i), value, ...list.slice(i)];
      }

      if ("after" in where) {
        const i = deepEqualIndexOf(list, where.after);
        if (i === -1) return [...list, value];
        return [...list.slice(0, i + 1), value, ...list.slice(i + 1)];
      }
    }

    throw new ValidationError("Cannot insert on a non-list.");
  });

  newRecord.version += 1;

  setMapRecord(recordMap, pointer, newRecord);
}

function invertListInsertOperation(
  recordMap: RecordMap,
  operation: ListInsertOperation,
) {
  const { type, key, table, id, value, where } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);
  if (!record) throw new ValidationError("Record does not exist.");

  const list = get(record, key);

  if (Array.isArray(list)) {
    const index = list.indexOf(value);
    // Restore position in the list.
    if (index !== -1) {
      if (index === 0) {
        const op: ListInsertOperation = {
          type,
          key,
          table,
          id,
          value,
          where: "prepend",
        };

        return op;
      } else {
        const prev = list[index - 1];
        const op: ListInsertOperation = {
          type,
          key,
          table,
          id,
          value,
          where: { after: prev },
        };

        return op;
      }
    }
  }

  // Remove from the list.
  const op: ListRemoveOperation = {
    type: "listRemove",
    key,
    table,
    id,
    value,
  };

  return op;
}

function deepEqualIndexOf<T>(list: T[], value: T) {
  for (let i = 0; i < list.length; i++) {
    if (isEqual(list[i], value)) return i;
  }

  return -1;
}

function applyListRemoveOperation(
  recordMap: RecordMap,
  operation: ListRemoveOperation,
  updateUpdatedAt: boolean,
) {
  const { table, id, value } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);
  if (!record) throw new ValidationError("Record does not exist.");

  const newRecord = cloneDeep(record);

  if (updateUpdatedAt) {
    newRecord.updated_at = new Date().toISOString();
  }

  update(newRecord, operation.key, (list) => {
    if (list === null || list === undefined) {
      return list;
    }
    if (Array.isArray(list)) {
      return list.filter((item) => item !== value);
    }
    throw new ValidationError("Cannot remove from a non-list.");
  });

  newRecord.version += 1;
  setMapRecord(recordMap, pointer, newRecord);
}

function invertListRemoveOperation(
  recordMap: RecordMap,
  operation: ListRemoveOperation,
) {
  const { key, table, id, value } = operation;
  const pointer = { table, id } as RecordPointer;

  const record = getMapRecord(recordMap, pointer);
  if (!record) throw new ValidationError("Record does not exist.");

  const list = get(record, key);

  if (Array.isArray(list)) {
    const index = list.indexOf(value);
    // Restore position in the list.
    if (index !== -1) {
      if (index === 0) {
        const op: ListInsertOperation = {
          type: "listInsert",
          key,
          table,
          id,
          value,
          where: "prepend",
        };

        return op;
      } else {
        const prev = list[index - 1];
        const op: ListInsertOperation = {
          type: "listInsert",
          key,
          table,
          id,
          value,
          where: { after: prev },
        };

        return op;
      }
    }
  }
}
