import { generateRecordId, RecordValue } from "libs/schema";
import { op, Operation } from "libs/transaction";
import { memoize } from "lodash-comms";
import { getEnvironment } from "~/environment/ClientEnvironmentContext";
import { PendingUpdates } from "~/environment/loading.service";
import { toast } from "~/environment/toast-service";
import { getAndAssertCurrentUser } from "~/environment/user.service";
import { createNotificationTx } from "./createNotification";
import { runTransaction } from "./write";

export interface ITriageThreadArgs {
  threadId: string;
  done?: boolean;
  triagedUntil?: Date | null;
  isStarred?: boolean;
  /** Surpresses toast notifications when true */
  noToast?: boolean;
  /**
   * When true, will change the toast notification to indicate
   * that the user just undid an action.
   */
  isUndo?: boolean;
}

/**
 * Triages a thread by updating the associated inbox notification.
 * If no inbox notification currently exists for this thread, one will
 * be created using the last post in the thread. When marking the
 * thread as "done", we will also record that the user has read up
 * to the current last post in the thread.
 */
export const triageThread = memoize(
  (args: ITriageThreadArgs) => {
    const onComplete = PendingUpdates.add();

    const pendingTransaction = runTransaction({
      tx: async (transaction) => {
        const operations = await createTriageThreadOps(args);
        if (!operations) return;
        transaction.operations.push(...operations);
      },
    });

    pendingTransaction.finally(() => {
      triageThread.cache.delete(JSON.stringify(args));
      onComplete();
    });

    return pendingTransaction;
  },
  (args) => JSON.stringify(args),
);

export async function createTriageThreadOps(args: ITriageThreadArgs) {
  if (
    args.done === undefined &&
    args.triagedUntil === undefined &&
    args.isStarred === undefined
  ) {
    console.warn("Provided an empty update to updateThreadNotification()");
    return;
  }

  const now = new Date().toISOString();

  const currentUser = getAndAssertCurrentUser();
  const environment = getEnvironment();
  const { recordLoader } = environment;

  const [[notification], [thread]] = await Promise.all([
    recordLoader.getRecord("notification", args.threadId),
    recordLoader.getRecord("thread", args.threadId),
  ]);

  const operations: Operation[] = [];

  if (!thread) {
    console.error(`Could not find thread associated with notificationId.`);
    return;
  }

  if (!notification) {
    const result = await createNotificationTx(args);

    operations.push(...result.operations);
  } else {
    const notificationUpdate: Partial<
      Pick<
        RecordValue<"notification">,
        | "is_done"
        | "done_at"
        | "done_last_modified_by"
        | "oldest_sent_at_value_not_marked_done"
        | "has_reminder"
        | "remind_at"
        | "is_starred"
        | "starred_at"
      >
    > = {};

    if (typeof args.done !== "undefined") {
      notificationUpdate.is_done = args.done;
      notificationUpdate.done_at = args.done ? now : null;
      notificationUpdate.done_last_modified_by = "user";
      notificationUpdate.oldest_sent_at_value_not_marked_done = args.done
        ? null
        : notification.oldest_sent_at_value_not_marked_done ||
          notification.sent_at;
    }

    if (typeof args.triagedUntil !== "undefined") {
      notificationUpdate.has_reminder = !!args.triagedUntil;
      notificationUpdate.remind_at = args.triagedUntil?.toISOString() || null;
    }

    if (typeof args.isStarred !== "undefined") {
      notificationUpdate.is_starred = args.isStarred;
      notificationUpdate.starred_at = args.isStarred ? now : null;
    }

    if (
      notificationUpdate.is_done &&
      // setting a reminder shouldn't mark a thread as "read".
      !notificationUpdate.has_reminder
    ) {
      operations.push(
        op.set("thread_read_receipt", {
          id: generateRecordId("thread_read_receipt", {
            thread_id: notification.id,
            user_id: currentUser.id,
          }),
          owner_organization_id: currentUser.owner_organization_id,
          thread_id: notification.id,
          user_id: currentUser.id,
          read_to_timeline_id: thread.last_timeline_id,
          read_to_timeline_order: thread.last_timeline_order,
        }),

        op.set("thread_seen_receipt", {
          id: generateRecordId("thread_seen_receipt", {
            thread_id: notification.id,
            user_id: currentUser.id,
          }),
          owner_organization_id: currentUser.owner_organization_id,
          thread_id: notification.id,
          user_id: currentUser.id,
          seen_to_timeline_id: thread.last_timeline_id,
          seen_to_timeline_order: thread.last_timeline_order,
        }),
      );
    }

    operations.push(
      op.update(
        { table: "notification", id: notification.id },
        notificationUpdate,
      ),
    );
  }

  if (!args.noToast) {
    if (typeof args.triagedUntil !== "undefined") {
      toast("vanilla", {
        subject: args.triagedUntil ? "Reminder set." : "Reminder removed.",
        durationMs: 5000,
      });
    } else if (typeof args.isStarred !== "undefined") {
      toast("vanilla", {
        subject: args.isStarred ? "Starred thread." : "Unstarred thread.",
        durationMs: 5000,
      });
    } else {
      toast("vanilla", {
        subject: args.done ? "Thread marked done." : "Thread marked not done.",
        durationMs: 5000,
      });
    }
  }

  return operations;
}
