import { mapRecordsHelper, useRecordLoader } from "./useRecordLoader";

export function useUsersOrganizationIds(userId: string | null | undefined) {
  return useRecordLoader({
    load({ loader, limit, deps: [userId] }) {
      if (!userId) {
        return loader.createObserveQueryResult<"organization_user_member">();
      }

      return loader.observeGetOrganizationUserMembers({
        user_id: userId,
        limit,
      });
    },
    deps: [userId],
    initialLimit: 25,
    limitStep: 25,
    map: (result) =>
      mapRecordsHelper(
        result,
        "organizationIds",
        result.records.map((record) => record.organization_id),
      ),
  });
}
