import { startWith } from "@libs/utils/rxjs-operators";
import { fromEvent, map, share, throttleTime } from "rxjs";

export const WINDOW_RESIZE_EVENT$ = fromEvent<UIEvent>(window, "resize").pipe(
  throttleTime(250, undefined, { leading: false, trailing: true }),
  share({ resetOnRefCountZero: true }),
);

export const WINDOW_SIZE$ = WINDOW_RESIZE_EVENT$.pipe(
  startWith(() => null),
  map(() => ({
    width: document.body.offsetWidth,
    height: document.body.offsetHeight,
  })),
);
