import { differenceWith, sortedIndexBy } from "lodash-comms";

export function removeOneFromArray<T>(
  array: T[],
  fn: (item: T) => boolean,
): T[] {
  const newArray = array.slice();

  const index = array.findIndex(fn);

  if (index < 0) return newArray;

  newArray.splice(index, 1);

  return newArray;
}

export function mutateAndRemoveOneFromArray<T>(
  array: T[],
  fn: (item: T) => boolean,
) {
  const index = array.findIndex(fn);
  if (index < 0) return;
  array.splice(index, 1);
}

/**
 * Performantly adds an item to a sorted array.
 *
 * @param array
 * @param item element to add
 * @param fn function which returns a value to determine this items sort order
 */
export function mutateAndAddOneToSortedArray<T>(
  array: T[],
  item: T,
  fn: (item: T) => unknown,
) {
  const newIndex = sortedIndexBy(array, item, fn);
  array.splice(newIndex, 0, item);
}

export function arrayChange<T>(
  newArray: T[],
  oldArray: T[],
  comparer: (a: T, b: T) => boolean = (a, b) => a === b,
) {
  const removed = differenceWith(oldArray, newArray, comparer);
  const added = differenceWith(newArray, oldArray, comparer);
  return { added, removed };
}

/** returns `true` if arr1 contains a value that === a value in arr2 */
export function intersects(arr1: unknown[], arr2: unknown[]) {
  return arr1.some((a) => arr2.some((b) => b === a));
}
