import * as d from "ts-decoders/decoders";
import { Decoder, DecoderReturnType } from "ts-decoders";
import { tableD } from "./schema";
import { UnreachableCaseError } from "./errors";

export type ClientPubsubMessage =
  | { type: "SUBSCRIBE"; key: string }
  | { type: "UNSUBSCRIBE"; key: string };

export type ServerPubsubMessage = {
  key: string;
  value: string;
};

export type ParsedServerPubsubMessage = DecoderReturnType<
  typeof serverPubSubMessageD
>;

export function createServerPubsubMessage(
  args: ParsedServerPubsubMessage,
): ServerPubsubMessage {
  switch (args.type) {
    case "RECORD_UPDATE": {
      return {
        key: `${args.table}:${args.id}`,
        value: String(args.version),
      };
    }
    case "QUERY_UPDATE": {
      /**
       * Example:
       * ```
       * {
       *   key: `message:thread_id:f633e173-1562-4c4a-8f82-b6595acd3069`,
       *   value: `5df884a6-5d4e-464a-b2ab-ce1a12dfc204:134`,
       * }
       * ```
       */
      return {
        key: `${args.table}:${args.column}:${args.columnValue}`,
        value: `${args.id}:${args.version}`,
      };
    }
    default: {
      throw new UnreachableCaseError(args);
    }
  }
}

export const clientPubSubMessageD: Decoder<ClientPubsubMessage> = d.anyOfD([
  d.objectD({ type: d.exactlyD("SUBSCRIBE"), key: d.stringD() }),
  d.objectD({ type: d.exactlyD("UNSUBSCRIBE"), key: d.stringD() }),
]);

export const recordChangeMessageD = d
  .objectD({
    key: d
      .stringD()
      .map((i) => i.split(":"))
      .chain(d.tupleD([tableD, d.stringD()])),
    value: d
      .stringD()
      .map((i) => Number(i))
      .chain(d.integerD()),
  })
  .map(({ key, value }) => ({
    type: "RECORD_UPDATE" as const,
    table: key[0],
    id: key[1],
    version: value,
  }));

export const queryChangeMessageD = d
  .objectD({
    key: d
      .stringD()
      .map((i) => i.split(":"))
      .chain(d.tupleD([tableD, d.stringD(), d.stringD()])),
    value: d
      .stringD()
      .map((i) => i.split(":"))
      .chain(
        d.tupleD([
          d.stringD(),
          d
            .stringD()
            .map((i) => Number(i))
            .chain(d.integerD()),
        ]),
      ),
  })
  .map(({ key, value }) => ({
    type: "QUERY_UPDATE" as const,
    table: key[0],
    column: key[1],
    columnValue: key[2],
    id: value[0],
    version: value[1],
  }));

// export const queryChangeMessageD = d
//   .objectD({
//     key: d
//       .stringD()
//       .map((i) => i.split(":"))
//       .chain(d.tupleD([d.stringD(), d.stringD(), d.stringD()])),
//     value: d
//       .stringD()
//       .map((i) => i.split(":"))
//       .chain(
//         d.tupleD([
//           d.stringD(),
//           d.stringD(),
//           d
//             .stringD()
//             .map((i) => Number(i))
//             .chain(d.integerD()),
//         ]),
//       ),
//   })
//   .map(({ key, value }) => ({
//     type: "QUERY_UPDATE" as const,
//     query: key[0],
//     queryProp: key[1],
//     queryPropValue: key[2],
//     table: value[0],
//     id: value[1],
//     version: value[2],
//   }));

export const serverPubSubMessageD = d.anyOfD([
  recordChangeMessageD,
  queryChangeMessageD,
]);
