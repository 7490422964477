import { isEqual } from "@libs/utils/isEqual";
import { FormEvent } from "react";
import { distinctUntilChanged, from, Observable } from "rxjs";
import {
  IFormControl,
  IFormGroup,
  isFormGroup,
  useControlState as _useControlState,
} from "solid-forms-react";
import { observable as sObservable } from "solid-js";

export function observable<T>(input: () => T) {
  const obs = from(sObservable(input)) as Observable<T>;

  return obs.pipe(distinctUntilChanged(isEqual));
}

export function onSubmitFn<T extends IFormGroup | IFormControl, R>(
  control: T,
  fn: (value: T["rawValue"]) => Promise<R>,
) {
  return (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    return handleSubmit(control, fn);
  };
}

export async function handleSubmit<T extends IFormGroup | IFormControl, R>(
  control: T,
  fn: (value: T["rawValue"]) => Promise<R>,
) {
  if (isFormGroup(control)) {
    control.children.markTouched(true, { deep: true });
  } else {
    control.markTouched(true);
  }

  // TODO
  // After toast notifications are implemented, when the
  // user attempts to submit a form that's not valid we should
  // give them a warning.
  if (control.status !== "VALID") return;

  control.markSubmitted(true);

  return fn(control.rawValue);
}

export const useControlState: typeof _useControlState = (fn, deps) => {
  return _useControlState(fn, deps, isEqual);
};
