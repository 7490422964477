import { Loader } from "@libs/chet-stack/Loader";
import { Logger } from "libs/logger";
import { ClientConfig } from "./ClientConfig";
import { httpRequest, HttpResponse } from "./httpRequest";
import {
  ApiInput,
  ApiOutput,
  ApiResponse,
  ApiTypes,
  ErrorResponse,
} from "libs/ApiTypes";

class ApiService {
  private responseQueue: Array<{
    response: HttpResponse;
    loader: Loader<any>;
  }> = [];
  private timeoutId: number | null = null;
  private readonly batchIntervalMs = 100;

  constructor(private options: { config: ClientConfig; logger?: Logger }) {}

  async request<T extends keyof ApiTypes>(
    name: T,
    args: ApiTypes[T]["input"],
  ): Promise<ApiResponse<ApiOutput<T>>> {
    const loader = new Loader<ApiResponse<ApiOutput<T>>>();

    this.fetch(name, args).then((response) => {
      this.enqueueResponse(response, loader);
    });

    return loader.promise;
  }

  private enqueueResponse(response: HttpResponse, loader: Loader<any>) {
    this.responseQueue.push({ response, loader });

    if (this.timeoutId) return;

    this.timeoutId = setTimeout(() => {
      const queue = this.responseQueue;
      this.responseQueue = [];
      this.timeoutId = null;

      for (const { response, loader } of queue) {
        if (response.status === 200) {
          loader.resolve(response);
        } else {
          loader.reject(response);
        }
      }
    }, this.batchIntervalMs) as unknown as number;
  }

  private async fetch<T extends keyof ApiTypes>(
    name: T,
    args: ApiTypes[T]["input"],
  ): Promise<HttpResponse<T>> {
    return httpRequest({
      url: `/api/${String(name)}`,
      body: args as Record<string, unknown> | undefined,
      logger: this.options.logger,
    });
  }
}

export type ClientApi = {
  [ApiName in keyof ApiTypes]: ApiInput<ApiName> extends undefined
    ? (args?: ApiInput<ApiName>) => Promise<ApiResponse<ApiOutput<ApiName>>>
    : (args: ApiInput<ApiName>) => Promise<ApiResponse<ApiOutput<ApiName>>>;
};

export function createApi(args: { config: ClientConfig; logger?: Logger }) {
  const apiService = new ApiService(args);

  return new Proxy(
    {},
    {
      get(_target, key: any) {
        console.log("calling", key);
        return (args: any) => apiService.request(key, args);
      },
    },
  ) as ClientApi;
}

export function formatResponseError(response: ErrorResponse) {
  const { status, body } = response;
  if (body === null) return `${status}: Unkown error.`;
  if (body === undefined) return `${status}: Unkown error.`;
  if (typeof body === "string") return body;
  if (typeof body === "object") {
    if ("message" in body) {
      if (typeof body.message === "string") {
        return body.message;
      }
    }
  }

  return `${status}: ${JSON.stringify(body)}`;
}
