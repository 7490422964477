import { hasIntersection } from "@libs/utils/predicates";
import { Logger } from "libs/logger";
import {
  ApiQueryMinusGetRecords,
  ApiQueryMinusGetRecordsType,
  SqlDatabaseBase,
  SqlDatabaseBaseResult,
} from "libs/QueryApi";
import { RecordTable, RecordValue } from "libs/schema";
import { Statement } from "libs/sql-statement";
import { lowerFirst } from "lodash-comms";
import { Observable } from "rxjs";
import { Decoder } from "ts-decoders";
import { CamelCase, Simplify } from "type-fest";
import {
  ClientDatabase,
  ObserveMethods,
  OriginalMethod,
  RecordsResult,
} from "./ClientDatabaseApi";
import {
  parseTableNames,
  queryObservable,
  SQLiteDatabase,
} from "./SQLiteDatabase";

export async function createClientDatabase(props: { logger: Logger }) {
  const db = await SQLiteDatabase.init(props);

  return new Proxy(db as unknown as ClientDatabase, {
    get(target, prop: keyof ClientDatabase, receiver) {
      if (prop in target) {
        return Reflect.get(target, prop, receiver);
      }

      if (prop.startsWith("observe")) {
        const originalMethod = lowerFirst(
          prop.slice(7),
        ) as OriginalMethod<ObserveMethods>;

        return (
          ...params: Parameters<SqlDatabaseBase[typeof originalMethod]>
        ) => {
          const method = target[originalMethod]!;

          const { statement, primaryTable, runQuery } = (method as any).apply(
            target,
            params,
          ) as SqlDatabaseBaseResult<RecordTable>;

          const affectedTableNames = parseTableNames(statement.text);

          if (affectedTableNames.length === 0) {
            throw new Error("Could not calculate selected table names");
          }

          const subscribe = (onChange: () => void) =>
            target.subscribeToRecordChanges(({ tableNames }) => {
              if (!hasIntersection(affectedTableNames, tableNames)) return;
              onChange();
            });

          return queryObservable<RecordsResult<RecordValue>>({
            async runQuery() {
              const recordMap = await runQuery();

              return {
                records: Object.values(recordMap[primaryTable] || {}),
              };
            },
            subscribe,
          });
        };
      }
    },
  });
}
