import { BehaviorSubject } from "rxjs";

export type IUpdatableBehaviorSubject<T> = BehaviorSubject<T> & {
  /**
   * Accepts a callback fn which receives the current value of the
   * `BehaviorSubject` and is expected to return the new value of
   * the `BehaviorSubject`. This new value will be emitted by the
   * observable.
   */
  update(fn: (value: T) => T): void;
};

/**
 * This returns an RxJS `BehaviorSubject` with an added `update()` method.
 */
export function updatableBehaviorSubject<T>(initialValue: T) {
  const subject = new BehaviorSubject(
    initialValue,
  ) as IUpdatableBehaviorSubject<T>;

  subject.update = function (fn) {
    const newValue = fn(this.getValue());
    this.next(newValue);
  };

  return subject;
}
